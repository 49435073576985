<template>
  <div class="flex">
    <label
      class="block my-3"
    >
      {{ t(config.label) }}
    </label>
  </div>
  <BaseDropdown
    v-model="computedSelectedValue"
    :options="options"
    @update:model-value="handleChange"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  onMounted,
  computed,
  watch,
} from 'vue';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';

import {
  ParserFieldObject,
  Property,
} from '@/types';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BackgroundImageOption',

  components: {
    BaseDropdown,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },

    displayProperties: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['on-change'],

  setup(props, context) {
    const { t } = useI18n();

    const selectedProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties.filter((property) => property.name === props.config.type)[0]);
    const selectedValue = ref();

    const options = ref(props.config.options.map((option: any) => ({
      label: t(option.label),
      value: option.value,
    })));

    const mounted = ref(false);

    const computedSelectedValue = computed({
      get() {
        if (selectedProperty.value.value && options.value.find((option: any) => option.value === selectedProperty.value.value)) {
          return selectedProperty.value.value;
        }
        return props.config.defaultValue;
      },
      set(newValue) {
        selectedValue.value = newValue;
      },
    });

    const savedSelectedValue = ref(computedSelectedValue.value);

    const handleChange = () => {
      if (!props.config.customSelector) {
        selectedProperty.value.value = selectedValue.value;
        savedSelectedValue.value = selectedValue.value;
        if (selectedProperty.value) {
          context.emit('on-change', selectedProperty.value);
        }
      }
    };

    watch(() => props.displayProperties, () => {
      if (!props.displayProperties) {
        savedSelectedValue.value = selectedValue.value;
        selectedProperty.value.value = null;
        context.emit('on-change', selectedProperty.value, !mounted.value);
      } else {
        selectedValue.value = savedSelectedValue.value;
        selectedProperty.value.value = selectedValue.value;
        context.emit('on-change', selectedProperty.value, !mounted.value);
      }
    }, { deep: true });

    onMounted(() => {
      if (selectedProperty.value) {
        computedSelectedValue.value = selectedProperty.value.value ?? props.config.defaultValue;
        if (!props.config.customSelector) {
          context.emit('on-change', selectedProperty.value, true);
        }
      }
      mounted.value = true;
    });

    return {
      t,
      computedSelectedValue,
      options,

      handleChange,
    };
  },
});
</script>
