import {
  TemplateStructureEnum,
  ElementStructureConfiguration,
} from '@/types';

// eslint-disable-next-line import/no-cycle
import { getWidgetType, getTemplateIframeDocument } from '../utils/helpers';

import { TEMPLATE_SECTION_CONTENT_ID_SUFFIX } from '../utils/constants';

const elementStructureConfiguration: Record<string, any> = {
  [TemplateStructureEnum.SECTION]: {
    type: TemplateStructureEnum.SECTION,
    getIdentifier: (id: string) => id.replaceAll(TEMPLATE_SECTION_CONTENT_ID_SUFFIX, ''),
  },
  [TemplateStructureEnum.WIDGET]: {
    type: TemplateStructureEnum.WIDGET,
    getIdentifier: (id: string) => id,
    getElementType: (id: string) => {
      const template = getTemplateIframeDocument();
      if (template) {
        const element = template.querySelector(`#${id}`);
        if (element) {
          return getWidgetType(element.getAttribute('data-widgettype'));
        }
      }
      return '';
    },
  },
  [TemplateStructureEnum.DESIGN]: {
    type: TemplateStructureEnum.DESIGN,
    getIdentifier: (id: string) => id,
  },
};
const getElementStructureConfig = (elementId: string, elementType: string): ElementStructureConfiguration => {
  const structure = elementStructureConfiguration[elementType];
  const structureConfiguration: ElementStructureConfiguration = {
    type: structure.type,
    identifier: structure.getIdentifier(elementId),
  };
  if (elementType === TemplateStructureEnum.WIDGET) {
    structureConfiguration.widgetType = structure.getElementType(elementId);
  }
  return structureConfiguration;
};

export default getElementStructureConfig;
