
import Gleap from 'gleap';
import {
  computed, ComputedRef,
  defineComponent, onBeforeMount, Ref, ref,
  onUnmounted, reactive, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { getMonthlyPagesViewed } from '@/composables/shop/Shops';
import ShopBills from '@/views/shop/ShopBills.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Loader from '@/components/layout/Loader.vue';
import Image from 'primevue/image';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import { formatDate } from '@/helpers/Date';
import { List } from '@/composables/GraphQL';
import {
  AdyenTokens, AdyenTokensUpdateInputItem, OperatorType,
} from '@/types/generated-types/graphql';
import {
  findPermission,
  setCurrentPlanUnsubscription,
  cancelTrial,
  UserState, extendTrialPlan, cancelTrialExtend,
} from '@/composables/User';
import { formatNumberToCurrency, formatNumbersInString } from '@/helpers/Number';
import { useStore } from '@/store';
import { showToastError, showToastSuccess } from '@/helpers';
import GetPaymentMethods, {
  AddPaymentMethod, adyenConfig, MakeDefault, MakeDetailsCall, MarkUserTokenAsDelete,
} from '@/composables/user/AdyenConfig';
import { useConfirm } from 'primevue/useconfirm';
import AdyenCheckout from '@adyen/adyen-web';
import { buyableElements } from '@/composables/shop/shopsPlans';
import { getShopsCredits, toggleSmsCreditsRenewal } from '@/composables/credits/Credits';
import { SmsPricing } from '@/types/offer-types';
import { getConfigurationKey } from '@/composables/configs/configuration';
import ConfirmPopup from 'primevue/confirmpopup';
import Message from 'primevue/message';
import Button from 'primevue/button';
import BuyableElementV14 from '@/components/my-offer/MyOffer14/BuyableElementV14.vue';
import BlockUI from 'primevue/blockui';

// External library
import moment from 'moment';
import { getShopPendingRecurringCharges, getShopPendingSimpleCharges } from '@/composables/user/ShopifyConfig';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import CryptoJS from 'crypto-js';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';
import { CountryAndStateValues } from '@/types/country-state-types';
import ProgressBar from 'primevue/progressbar';
import Checkbox from 'primevue/checkbox';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';

export default defineComponent({
  name: 'SummaryPlanV14',
  components: {
    TermsAndConditions,
    CountryDropdown,
    Loader,
    SpmButton,
    ShopBills,
    Image,
    Dialog,
    ConfirmDialog,
    ConfirmPopup,
    Message,
    Button,
    BuyableElementV14,
    BlockUI,
    ProgressBar,
    Checkbox,
  },

  props: {
    hasOfferV3: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['show-old-offer-page'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const confirmDialog = useConfirm();
    const typeOffer = computed(() => (UserState.hasOfferV3 ? 'v3' : 'v4'));
    const elementsToBuy = buyableElements.filter((buyableElement) => buyableElement.availableForOffers.includes(typeOffer.value));

    // User and shop settings
    const userId = UserState.activeShop?.idUser ?? 0;
    const locale = UserState.user.lang;
    const countryCode = locale.toLocaleUpperCase();
    const smsPricing = ref<Record<string, SmsPricing[]>>({});
    const smsPricingOld = ref<Record<string, SmsPricing[]>>({});
    const planSmsPricing = ref<SmsPricing[]>([]);
    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;
    const priceOfPlan = ref(UserState.pricePlan ?? null);
    const dateBillTrialExtension = ref(moment().add(14, 'days').format('D MMMM YYYY'));
    const acceptTTC = ref(false);
    const showTACModal = ref(false);

    const trialExtensionModalVisible = ref(false);
    const isTrialExtensionEligible = ref(
      (!UserState.activeOffer || (Object.keys(UserState.activeOffer).length === 0 && UserState.activeOffer.constructor === Object))
      && !UserState.hasOfferV3 && UserState?.planData
      && ('eligibleToExtendTrial' in UserState?.planData)
      && UserState?.planData?.eligibleToExtendTrial
      && ('completedTrialCount' in UserState?.planData && UserState?.planData?.completedTrialCount === 1),
    );
    const extendMyTrialLoading = ref(false);
    // Current plan of shop
    const pagesViewed = ref(0);
    const offerV3 = ref(UserState.offerV3);
    const shopPlan: Ref<Record<string, any> | null> = ref(UserState.activeOffer);
    const shopPlanFrequency: ComputedRef<string | null> = computed(() => ((shopPlan.value && 'currentPlanFrequency' in shopPlan.value && shopPlan.value.currentPlanFrequency)
      ? shopPlan.value.currentPlanFrequency
      : null));
    const shopSmsCredits: Ref<Record<string, any>> = ref({
      type: 'smsV4',
      date: null,
      amount: '0',
      renewData: {},
      autoRenew: 0,
    });
    const shopSmsCreditsOld: Ref<Record<string, any>> = ref({
      type: 'sms',
      date: null,
      amount: '0',
      renewData: {},
      autoRenew: 0,
    });
    const shopSmsPlanCredits: Ref<Record<string, any>> = ref({
      type: 'plan_sms',
      commitmentEndDate: null,
      amount: '0',
      visible: false,
    });

    const defaultCountry = ref({
      country: UserState.user.country ?? 'FR',
      state: '',
    });
    const countryDropdownConfiguration = {
      showStates: false,
      showLabels: false,
      showFlags: true,
      showPhonesCodes: false,
      showOnlyPhoneCodeForSelection: false,
    };
    const selectedCountry: Ref<CountryAndStateValues> = ref(defaultCountry.value);

    const smsCreditsUnits = ref(0);
    const smsPlanCreditsUnits = ref(0);

    const handleCountryDropdownUpdate = () => {
      const isoCodeCountry = selectedCountry.value.country;
      smsPlanCreditsUnits.value = 0;
      smsCreditsUnits.value = 0;

      if (!isoCodeCountry) {
        return;
      }

      if (shopSmsPlanCredits.value && shopSmsPlanCredits.value.visible && shopSmsPlanCredits.value.amount) {
        let unitPlanPriceByCountry: any = planSmsPricing.value.find((item: any) => item.value === isoCodeCountry);
        unitPlanPriceByCountry = unitPlanPriceByCountry ? unitPlanPriceByCountry.smsPrice : 0;
        smsPlanCreditsUnits.value = Math.floor(shopSmsPlanCredits.value.amount / unitPlanPriceByCountry);
      }

      if (UserState.activeOffer && shopSmsCredits.value && shopSmsCredits.value.amount) {
        const planName = UserState.activeOffer.currentPlan ?? UserState.activeOffer.forcedPlan ?? UserState.activeOffer.trialPlan ?? 'standard';
        let unitPriceByCountry: any = smsPricing.value[planName].find((item: any) => item.value === isoCodeCountry);
        unitPriceByCountry = unitPriceByCountry ? unitPriceByCountry.smsPrice : 0;
        smsCreditsUnits.value = Math.floor(shopSmsCredits.value.amount / unitPriceByCountry);
      }

      if (shopSmsCreditsOld.value && shopSmsCreditsOld.value.amount) {
        let unitPriceByCountry: any = smsPricingOld.value.v3.find((item: any) => item.value === isoCodeCountry);
        unitPriceByCountry = unitPriceByCountry ? unitPriceByCountry.smsPrice : 0;
        smsCreditsUnits.value += Math.floor(shopSmsCreditsOld.value.amount / unitPriceByCountry);
      }
    };

    const shopifyUrlConfirmationRedirect: any = ref('');
    const isRedirectShopifyModalVisible = computed(() => shopifyUrlConfirmationRedirect.value !== '');

    watch(isRedirectShopifyModalVisible, (newVal) => {
      if (newVal) {
        // Redirect after 5 seconds
        setTimeout(() => {
          window.location.href = shopifyUrlConfirmationRedirect.value;
        }, 5000);
      }
    });

    const shopPlanOffer = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'trialPlan' in shopPlan.value && shopPlan.value.trialPlan) {
        returnValue = shopPlan.value.trialPlan;
      } else if (shopPlan.value && 'forcedPlan' in shopPlan.value && shopPlan.value.forcedPlan) {
        returnValue = shopPlan.value.forcedPlan;
      } else if (shopPlan.value && 'currentPlan' in shopPlan.value && shopPlan.value.currentPlan) {
        returnValue = shopPlan.value.currentPlan;
      }

      return returnValue;
    });
    const shopPlanDateLimit = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'trialPlanLimit' in shopPlan.value && shopPlan.value.trialPlanLimit) {
        returnValue = formatDate(shopPlan.value.trialPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY');
      } else if (shopPlan.value && 'forcedPlanLimit' in shopPlan.value && shopPlan.value.forcedPlanLimit) {
        returnValue = formatDate(shopPlan.value.forcedPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY');
      } else if (shopPlan.value && 'currentPlanLimit' in shopPlan.value && shopPlan.value.currentPlanLimit) {
        returnValue = formatDate(shopPlan.value.currentPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY');
      }

      return returnValue;
    });
    const shopPlanPrice: ComputedRef<string | null> = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'currentPlanPrice' in shopPlan.value && shopPlan.value.currentPlanPrice
        && 'currentPlanPriceCurrency' in shopPlan.value && shopPlan.value.currentPlanPriceCurrency) {
        returnValue = formatNumberToCurrency(shopPlan.value.currentPlanPrice, shopPlan.value.currentPlanPriceCurrency);
      }

      return returnValue;
    });
    const isCurrentPlanTrial = computed(() => {
      if (shopPlan.value && shopPlan.value.trialPlan) {
        if (moment().isBefore(moment(shopPlan.value.trialPlanLimit, 'YYYY-MM-DD'))) {
          return true;
        }
      }
      return false;
    });
    const actualShopOffer = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'forcedPlan' in shopPlan.value && shopPlan.value.forcedPlan) {
        returnValue = {
          plan: shopPlan.value.forcedPlan,
          limit: shopPlan.value.forcedPlanLimit ? formatDate(shopPlan.value.forcedPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
        };
      } else if (shopPlan.value && 'currentPlan' in shopPlan.value && shopPlan.value.currentPlan) {
        returnValue = {
          plan: shopPlan.value.currentPlan,
          limit: shopPlan.value.currentPlanLimit ? formatDate(shopPlan.value.currentPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
        };
      }

      return returnValue;
    });
    const shopPlanSmsPrice = computed(() => {
      let returnValue = null;

      if (shopPlan.value && shopPlan.value.smsPlan && shopPlan.value.smsPlanCountry && shopPlan.value.smsPlanCurrency) {
        const actualSmsPricingValue = planSmsPricing.value.find((item: any) => item.value === shopPlan?.value?.smsPlanCountry);
        if (actualSmsPricingValue) {
          const price = Math.round(actualSmsPricingValue.smsPrice * shopPlan.value.smsPlan * 100) / 100;
          returnValue = formatNumberToCurrency(price, shopPlan.value.smsPlanCurrency);
        }
      }

      return returnValue;
    });
    const shopPlanDiscount = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && 'discount' in shopPlan.value
        && shopPlan.value.discount
        && 'plan' in shopPlan.value.discount
        && (
          (!('discountLimit' in shopPlan.value)
            || !shopPlan.value.discountLimit
            || !('plan' in shopPlan.value.discountLimit)
            || ('plan' in shopPlan.value.discountLimit
              && (shopPlan.value.discountLimit.plan >= moment().format('YYYY-MM-DD')
                || !shopPlan.value.discountLimit.plan)))
        )
      ) {
        returnValue = shopPlan.value.discount.plan;
      }

      return returnValue;
    });
    const shopPlanDiscountLimit = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && ('discountLimit' in shopPlan.value)
        && 'plan' in shopPlan.value.discountLimit
        && shopPlan.value.discountLimit.plan
        && shopPlan.value.discountLimit.plan >= moment().format('YYYY-MM-DD')
      ) {
        returnValue = formatDate(shopPlan.value.discountLimit.plan, 'YYYY-MM-DD', 'DD/MM/YYYY');
      }

      return returnValue;
    });
    const shopPlanPriceDiscount = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && 'discount' in shopPlan.value
        && shopPlan.value.discount
        && 'plan_price' in shopPlan.value.discount
        && (
          (!('discountLimit' in shopPlan.value)
            || !shopPlan.value.discountLimit
            || !('plan_price' in shopPlan.value.discountLimit)
            || ('plan_price' in shopPlan.value.discountLimit
              && (shopPlan.value.discountLimit.plan_price >= moment().format('YYYY-MM-DD')
                || !shopPlan.value.discountLimit.plan_price)))
        )
      ) {
        returnValue = formatNumberToCurrency(shopPlan.value.discount.plan_price, currency.value);
      }

      return returnValue;
    });
    const shopPlanPriceDiscountLimit = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && ('discountLimit' in shopPlan.value)
        && 'plan_price' in shopPlan.value.discountLimit
        && shopPlan.value.discountLimit.plan_price
        && shopPlan.value.discountLimit.plan_price >= moment().format('YYYY-MM-DD')
      ) {
        returnValue = formatDate(shopPlan.value.discountLimit.plan_price, 'YYYY-MM-DD', 'DD/MM/YYYY');
      }

      return returnValue;
    });
    const isFirstTrial = computed(() => {
      if (UserState.hasOfferV3) {
        return false;
      }
      if (shopPlan.value) {
        return !shopPlan.value.currentPlan && !shopPlan.value.forcedPlan && shopPlan.value.trialPlan;
      }
      return false;
    });
    const isExtendTrial = computed(() => {
      if (UserState.hasOfferV3) {
        return false;
      }
      if (shopPlan.value) {
        return !shopPlan.value.currentPlan
          && !shopPlan.value.forcedPlan
          && shopPlan.value.trialPlan
          && 'completedTrialCount' in shopPlan.value
          && shopPlan.value.completedTrialCount === 1;
      }
      return false;
    });
    // Current plan of shop

    // Adyen configuration
    const adyenTokens: Ref<AdyenTokens[]> = ref([]);
    const adyenTokensLoading = ref(false);
    const addPaymentMethodModal = ref(false);
    const adyenLocale = `${locale}_${countryCode}`;
    const adyenEnvironment: string = process.env.VUE_APP_ADYEN_ENVIRONMENT ?? 'live';
    // Adyen configuration

    /**
     * Ask to unsubscribe next month
     */
    const unsubscribe = (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: t('offers.unsubscribe.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          setCurrentPlanUnsubscription(true);
        },
      });
    };

    /**
     * Cancel unsubscription
     */
    const cancelUnsubscription = (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: t('offers.resubscribe.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-primary confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          setCurrentPlanUnsubscription(false);
        },
      });
    };

    /**
     * Cancel trial
     */
    const handleCancelTrial = (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: t('offers.cancelTrial.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          cancelTrial();
        },
      });
    };

    /**
     * Open plans component
     */
    const updatePlan = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    const cancelExtendTrial = () => {
      confirmDialog.require({
        group: 'dialog',
        rejectLabel: t('no'),
        acceptLabel: t('yes'),
        icon: 'pi pi-info-circle',
        header: 'Confirmation',
        message: t('offers.extendTrial.confirmationMessage'),
        accept: async () => {
          await cancelTrialExtend();
          showToastSuccess(t('offers.extendTrial.successMessage'));
        },
      });
    };

    /**
     * Load payment tokens from database
     */
    const loadAdyenTokens = async () => {
      adyenTokensLoading.value = true;
      adyenTokens.value = [];

      const { items } = await List<AdyenTokens>({
        name: 'AdyenTokens',
        settings: {
          offset: 0,
          limit: 0,
          order: [
            { field: 'is_default', type: 'DESC' },
            { field: 'id', type: 'ASC' },
          ],
          filter: [
            {
              field: 'id_user',
              value: UserState.user.id,
              operator: OperatorType.Equals,
            },
            {
              field: 'deleted',
              value: 0,
              operator: OperatorType.Equals,
            },
          ],
        },
        fields: ['id', 'id_user', 'payment_method', 'brand_name', 'brand', 'last_four', 'is_default'],
      });

      if (items && items.length > 0) {
        adyenTokens.value = items;
      }

      adyenTokensLoading.value = false;
    };

    const openGleap = () => {
      Gleap.open();
    };

    /**
     * Add new payment method
     */
    const addPaymentMethod = async (forTrialExtend = false) => {
      addPaymentMethodModal.value = true;
      const paymentMethods = await GetPaymentMethods({
        id_user: userId, currency: 'EUR', amount: 0, country_code: countryCode, locale: adyenLocale,
      });

      const configuration = {
        translations: {
          'fr-FR': {
            payButton: t('profile.myBankCards.buttons.register'),
          },
        },

        paymentMethodsResponse: paymentMethods.data,
        removePaymentMethods: ['paypal'], // Retrait temporaire avant de pouvoir générer des tokens
        clientKey: adyenConfig[adyenEnvironment]['client-key'],
        locale: adyenLocale,
        environment: adyenConfig[adyenEnvironment].environment,
        onSubmit: async (state: any, dropin: any) => {
          dropin.setStatus('loading');

          // Your function calling your server to make the `/payments` request
          AddPaymentMethod(userId, state.data).then(async (response) => {
            if (response.data.action) {
              // Drop-in handles the action object from the /payments response
              dropin.handleAction(response.data.action);
            } else if (response.data.resultCode === 'Authorised' || response.data.resultCode === 'Received') {
              // Your function to show the final result to the shopper
              dropin.setStatus('success', { message: t('profile.myBankCards.addPayment.addCardAccepted') });
              await loadAdyenTokens();
              if (forTrialExtend) {
                const extended = await extendTrialPlan();
                if (extended) {
                  window.location.reload();
                } else {
                  await showToastError('GENERIC_ERROR');
                }
              }
            } else {
              if (typeof (response.data.message) !== 'undefined') {
                showToastError(response.message);
              }
              dropin.setStatus('error', { message: response.data.message });
            }
          }).catch((reason: any) => {
            dropin.setStatus('error', { message: reason.response.data.refusalReason });
          });
        },

        onAdditionalDetails: (state: any, dropin: any) => {
          // Your function calling your server to make a `/payments/details` request
          MakeDetailsCall(JSON.stringify(state.data))
            .then((response) => {
              if (response.data.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.data.action);
              } else if (response.data.resultCode === 'Authorised') {
                // Your function to show the final result to the shopper
                dropin.setStatus('success', { message: t('profile.myBankCards.addPayment.addCardAccepted') });

                window.setTimeout(() => {
                  showToastSuccess(t('profile.myBankCards.addPayment.addCardAccepted'));
                }, 2000);
              } else {
                if (typeof (response.message) !== 'undefined') {
                  showToastError(response.message);
                }

                dropin.setStatus('error', { message: response.message });
              }
            })
            .catch((error) => {
              throw Error(error);
            });
        },

        onPaymentCompleted: (result: any, component: any) => {
          console.info(result, component);
        },

        onError: (error: any, component: any) => {
          console.error(error.name, error.message);
        },

        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            hideCVC: false,
          },
        },
      };

      const checkout = await AdyenCheckout(configuration);
      checkout.create('dropin', { showStoredPaymentMethods: false }).mount('#dropInComponent').setComponentRef('dropInComponent');
    };

    /**
     * Mark token as default token
     * @param token
     */
    const setDefaultPaymentMethod = (token: AdyenTokens) => {
      confirmDialog.require({
        group: 'dialog',
        rejectLabel: t('no'),
        acceptLabel: t('yes'),
        icon: 'fas fa-money-check-alt',
        header: t('profile.myBankCards.buttons.default'),
        message: t('profile.myBankCards.confirm.defaultTitle'),
        accept: async () => {
          const adyenInput: AdyenTokensUpdateInputItem = {
            id: token.id,
            id_user: token.id_user,
          };
          const {
            err,
          } = await MakeDefault([adyenInput]);

          if (err !== '') {
            await showToastError('GENERIC_ERROR');
          } else {
            await showToastSuccess('Success');
            await loadAdyenTokens();
          }
        },
      });
    };

    /**
     * Delete a payment token
     * @param token
     */
    const deletePaymentMethod = (token: AdyenTokens) => {
      confirmDialog.require({
        group: 'dialog',
        rejectLabel: t('no'),
        acceptLabel: t('yes'),
        icon: 'far fa-trash',
        header: t('profile.myBankCards.buttons.delete'),
        message: t('profile.myBankCards.confirm.deleteTitle'),
        accept: async () => {
          MarkUserTokenAsDelete(token.id_user, { id: token.id }).then(async (response) => {
            if (!response.data.success) {
              await showToastError('GENERIC_ERROR');
            } else {
              await showToastSuccess('success');
              await loadAdyenTokens();
            }
          });
        },
      });
    };

    /**
     * Returns logo of payment method
     * @param paymentMethod
     * @param brand
     */
    const getPaymentMethodLogo = (paymentMethod: string, brand: string) => {
      let image = '';

      switch (paymentMethod) {
        case 'scheme':
          switch (brand) {
            case 'mc':
              image = '/images/payment/MasterCard-dark.svg';
              break;
            case 'visa':
              image = '/images/payment/Visa-card-dark.svg';
              break;
            case 'cartebancaire':
              image = '/images/payment/CB-dark.svg';
              break;
            case 'amex':
              image = '/images/payment/AmericanExpress-dark.svg';
              break;
            default:
              image = '';
          }
          break;
        case 'sepadirectdebit':
          image = '/images/payment/Sepa-card-dark.svg';
          break;
        case 'paypal':
          image = '/images/payment/Paypal-card-dark.svg';
          break;
        default:
          image = '';
      }

      return image;
    };

    const toggleSmsCreditsPackRenewal = async (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: shopSmsCredits.value.autoRenew ? t('offers.deactivateSmsCreditsRenewal.popupMessage') : t('offers.activateSmsCreditsRenewal.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          toggleSmsCreditsRenewal(UserState.activeShop?.id ?? 0, shopSmsCredits.value);
          shopSmsCredits.value.autoRenew = shopSmsCredits.value.autoRenew ? 0 : 1;
          shopSmsCredits.value.renewData.autoRenew = shopSmsCredits.value.autoRenew.toString();
        },
      });
    };

    const updateOldPlan = () => {
      // emit('show-old-offer-page');
      const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;
      const cryptedKey = CryptoJS.SHA1(`remoteplanZn26687S2682MF2ZV4Ag2g9JGbhF686X${idShop}`).toString();
      let query = '/advisor/logAsShop/';
      query += `${idShop}/remoteplan/${cryptedKey}`;
      const url = `${process.env.VUE_APP_ZEND_URL}${query}`;
      const options = 'width=1280,height=768,top=100,left=100,toolbar=no,menubar=no,scrollbars=no,status=no'; // Ajustez les valeurs selon vos besoins
      const newWindow = window.open(url, '_blank', options);
      if (newWindow) newWindow.opener = null;
    };

    const shopifyPaymentState = reactive({
      isShopify: UserState.activeShop && UserState.activeShop.solutionEcommerce === 'shopify',
      plan: {
        blockPaymentUI: false,
        idCharge: 0,
        paymentMessage: '',
        urlConfirmation: '',
        ctaMessage: '',
        trial: false,
      },
      credits: {
        blockPaymentUI: false,
        idCharge: 0,
        paymentMessage: '',
        urlConfirmation: '',
        ctaMessage: '',
      },
      redirectMessageType: 'trial', // "plan" and "credits"
    });
    const intervalCheckShopifyPaymentState = ref();
    const redirectToLinkShopify = (url: string, type = 'trial') => {
      shopifyUrlConfirmationRedirect.value = url;
      shopifyPaymentState.redirectMessageType = type;
    };

    const checkShopifyPaymentState = async (): Promise<any> => {
      const shopPendingSimpleCharges = await getShopPendingSimpleCharges(UserState.activeShop?.id ?? 0);

      if (shopPendingSimpleCharges && shopPendingSimpleCharges.success) {
        if (shopPendingSimpleCharges.credits && shopPendingSimpleCharges.credits.id) {
          shopifyPaymentState.credits.blockPaymentUI = true;
          shopifyPaymentState.credits.paymentMessage = t('shop.menu.myOffer.payment.shopify.pendingOneTimePayment',
            [shopPendingSimpleCharges.credits.title, shopPendingSimpleCharges.credits.price]);
          shopifyPaymentState.credits.idCharge = shopPendingSimpleCharges.credits.id;
          shopifyPaymentState.credits.urlConfirmation = shopPendingSimpleCharges.credits.urlConfirmation;
        } else {
          shopifyPaymentState.credits.blockPaymentUI = false;
          shopifyPaymentState.credits.paymentMessage = '';
          shopifyPaymentState.credits.idCharge = 0;
          shopifyPaymentState.credits.urlConfirmation = '';
        }
      }
      const shopPendingRecurringCharges = await getShopPendingRecurringCharges(UserState.activeShop?.id ?? 0);

      if (shopPendingRecurringCharges.charge && shopPendingRecurringCharges.charge.id) {
        shopifyPaymentState.plan.blockPaymentUI = true;
        shopifyPaymentState.plan.idCharge = shopPendingRecurringCharges.charge.id;
        shopifyPaymentState.plan.urlConfirmation = shopPendingRecurringCharges.charge.urlConfirmation;
        if ('extendTrial' in shopPendingRecurringCharges.charge) {
          shopifyPaymentState.plan.paymentMessage = t('shop.menu.myOffer.payment.shopify.block.plan.trialMessage');
          shopifyPaymentState.plan.ctaMessage = t('shop.menu.myOffer.payment.shopify.block.plan.trialCTA');
          shopifyPaymentState.plan.trial = true;
        } else if ('name' in shopPendingRecurringCharges.charge) {
          shopifyPaymentState.plan.paymentMessage = t('shop.menu.myOffer.payment.shopify.block.plan.subscriptionMessage',
            [shopPendingRecurringCharges.charge.name, shopPendingRecurringCharges.charge.price]);
          shopifyPaymentState.plan.ctaMessage = t('shop.menu.myOffer.payment.shopify.block.plan.subscriptionCTA');
          shopifyPaymentState.plan.trial = false;
        }
      } else {
        shopifyPaymentState.plan.blockPaymentUI = false;
        shopifyPaymentState.plan.paymentMessage = '';
        shopifyPaymentState.plan.idCharge = 0;
        shopifyPaymentState.plan.urlConfirmation = '';
        shopifyPaymentState.plan.trial = false;
      }

      if (intervalCheckShopifyPaymentState.value && !shopifyPaymentState.plan.blockPaymentUI && !shopifyPaymentState.credits.blockPaymentUI) {
        clearInterval(intervalCheckShopifyPaymentState.value);
        window.location.reload();
      }
    };

    const extendMyTrial = async () => {
      extendMyTrialLoading.value = true;
      if (UserState.activeShop) {
        if (UserState.activeShop.solutionEcommerce !== 'shopify') {
          if (adyenTokens.value.length === 0) {
            await addPaymentMethod(true);
          } else {
            const extended = await extendTrialPlan();
            if (extended) {
              window.location.reload();
            } else {
              await showToastError('GENERIC_ERROR');
            }
          }
        } else {
          const extended = await extendTrialPlan();
          if (extended && extended.success && !('confirmation_url' in extended)) {
            window.location.reload();
          } else if (extended && extended.success && ('confirmation_url' in extended)) {
            shopifyUrlConfirmationRedirect.value = extended.confirmation_url;
            shopifyPaymentState.redirectMessageType = 'trial';
          } else {
            await showToastError('GENERIC_ERROR');
          }
        }
      }
      extendMyTrialLoading.value = false;
    };

    onBeforeMount(async () => {
      try {
        store.commit('general/showTheSpinner');
        if (shopifyPaymentState.isShopify) {
          try {
            await checkShopifyPaymentState();
            if (shopifyPaymentState.plan.blockPaymentUI || shopifyPaymentState.credits.blockPaymentUI) {
              intervalCheckShopifyPaymentState.value = setInterval(async () => {
                await checkShopifyPaymentState();
              }, 5000);
            }
          } catch (error) {
            await showToastError('GENERIC_ERROR');
          }
        }

        // Get correct SMS prices according to offer and CMS
        let keyPriceSms;
        let keyPricePlanSms;

        if (UserState.hasOfferV3) {
          keyPriceSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_usd_version_12') : await getConfigurationKey('prices_sms_version_12');
          keyPricePlanSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_plan_usd_version_12') : await getConfigurationKey('prices_sms_plan_version_12');
        } else {
          keyPriceSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_usd_version_14') : await getConfigurationKey('prices_sms_version_14');
          keyPricePlanSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_plan_usd_version_14') : await getConfigurationKey('prices_sms_plan_version_14');
        }

        if (keyPriceSms && keyPriceSms.value) {
          smsPricing.value = JSON.parse(keyPriceSms.value);
        }
        if (keyPricePlanSms && keyPricePlanSms.value) {
          planSmsPricing.value = JSON.parse(keyPricePlanSms.value);
        }

        const keyPriceSmsOld = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_usd_version_12') : await getConfigurationKey('prices_sms_version_12');

        if (keyPriceSmsOld && keyPriceSmsOld.value) {
          smsPricingOld.value = JSON.parse(keyPriceSmsOld.value);
        }

        // Get monthly pages viewed
        const pagesViews = await getMonthlyPagesViewed();

        if (pagesViews) {
          pagesViewed.value = pagesViews;
        }

        // Display SMS credits V4
        const shopCreditsV4 = await getShopsCredits(UserState.activeShop?.id ?? 0, 'smsV4');
        if (shopCreditsV4 && typeof shopCreditsV4 !== 'number') {
          shopSmsCredits.value.date = shopCreditsV4.date;
          shopSmsCredits.value.type = shopCreditsV4.type;
          shopSmsCredits.value.amount = shopCreditsV4.amount;
          const renewData = shopCreditsV4.renew_data ? JSON.parse(shopCreditsV4.renew_data) : {};
          if (renewData) {
            shopSmsCredits.value.renewData = renewData;
            shopSmsCredits.value.autoRenew = renewData.autoRenew || 0;
          }
        }

        // Display SMS plan credits V4
        const shopPlanCreditsV4 = await getShopsCredits(UserState.activeShop?.id ?? 0, 'plan_sms');
        if (shopPlanCreditsV4 && typeof shopPlanCreditsV4 !== 'number') {
          shopSmsPlanCredits.value.amount = shopPlanCreditsV4.amount;
          shopSmsPlanCredits.value.visible = true;
        }

        // Display SMS credits V3
        const shopCreditsV3 = await getShopsCredits(UserState.activeShop?.id ?? 0, 'sms');
        if (shopCreditsV3 && typeof shopCreditsV3 !== 'number') {
          shopSmsCreditsOld.value.amount = shopCreditsV3.amount;
        }

        await loadAdyenTokens();

        if (isTrialExtensionEligible.value) {
          if (UserState.activeShop?.solutionEcommerce === 'shopify') {
            const pendingRecCharge = await getShopPendingRecurringCharges(UserState.activeShop?.id ?? 0);
            trialExtensionModalVisible.value = pendingRecCharge && pendingRecCharge.success && !pendingRecCharge.charge;
          } else {
            trialExtensionModalVisible.value = true;
          }
          priceOfPlan.value = UserState.pricePlan ?? null;
        }

        handleCountryDropdownUpdate();
        store.commit('general/hideTheSpinner');
      } catch (error) {
        showToastError('GENERIC_ERROR');
      } finally {
        store.commit('general/hideTheSpinner');
      }
    });

    const formatNumber = (number: string) => formatNumbersInString(number.toString());

    onUnmounted(() => {
      if (intervalCheckShopifyPaymentState.value) {
        clearInterval(intervalCheckShopifyPaymentState.value);
      }
    });

    return {
      t,
      elementsToBuy,
      pagesViewed,
      offerV3,
      shopPlan,
      shopPlanOffer,
      shopPlanDateLimit,
      shopPlanFrequency,
      shopPlanPrice,
      adyenTokens,
      adyenTokensLoading,
      addPaymentMethodModal,
      shopSmsCredits,
      shopSmsPlanCredits,
      isCurrentPlanTrial,
      actualShopOffer,
      shopPlanSmsPrice,
      isFirstTrial,
      isExtendTrial,
      smsPricing,
      shopifyPaymentState,
      shopPlanDiscount,
      shopPlanDiscountLimit,
      shopPlanPriceDiscount,
      shopPlanPriceDiscountLimit,
      unsubscribe,
      cancelUnsubscription,
      updatePlan,
      openGleap,
      getPaymentMethodLogo,
      addPaymentMethod,
      setDefaultPaymentMethod,
      deletePaymentMethod,
      toggleSmsCreditsPackRenewal,
      handleCancelTrial,
      updateOldPlan,
      redirectToLinkShopify,
      formatNumber,
      trialExtensionModalVisible,
      extendMyTrial,
      selectedCountry,
      handleCountryDropdownUpdate,
      countryDropdownConfiguration,
      smsCreditsUnits,
      smsPlanCreditsUnits,
      extendMyTrialLoading,
      isRedirectShopifyModalVisible,
      priceOfPlan,
      acceptTTC,
      showTACModal,
      dateBillTrialExtension,
      cancelExtendTrial,
    };
  },

  methods: { findPermission },
});
