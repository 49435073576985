<template>
  <Dialog
    v-model:visible="displayModal"
    class="p-dialog-section"
    data-test-id="column-widget-dialog"
    position="center"
    :header="t(headerTitle)"
    :modal="true"
    :breakpoints="{ '960px': '60vw', '640px': '100vw' }"
    :style="{ width: '40vw' }"
    :dismissable-mask="true"
    @update:visible="handleClose"
  >
    <LoadElements
      :payload="payload"
      :show-drag-icon="false"
      :types="elementsTypes"
      :load-action-title="loadActionTitle"
      @insert-section="handleClose"
      @insert-element="handleInsertElement"
    />

    <template #footer>
      <Button
        data-test-id="input-text-button-cancel"
        :label="t('templateBuilder.modals.cancel')"
        icon="far fa-times"
        class="p-button-secondary"
        @click="handleClose"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ComputedRef,
  Ref,
  ref,
  onUpdated,
} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { hideElementsModal, TemplateEditorState as state } from '@/composables/template-editor/TemplateEditor';
import LoadElements from '@/components/template-builder/LoadElements.vue';
import { useI18n } from 'vue-i18n';
import { LoadSectionPayload, TemplateStructureEnum } from '@/types';
import { getTemplateIframeDocument } from '@/components/template-builder/utils/helpers';

export default defineComponent({
  name: 'LoadElementsModal',

  components: {
    LoadElements,
    Dialog,
    Button,
  },

  emits: ['insert-element'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const displayModal: ComputedRef<boolean> = computed(() => state.isLoadElementsModalVisible);
    const elementsTypes = computed(() => state.loadElementsType);
    const payload: Ref<LoadSectionPayload | null> = ref({ position: 'top', selector: '#spm_body .spm_section:first-child' });

    const headerTitle = computed(() => {
      if (elementsTypes.value.length === 1 && elementsTypes.value[0] === TemplateStructureEnum.DESIGN) {
        return 'templateBuilder.modals.loadElements.header.loadDesign';
      }
      return 'templateBuilder.modals.loadElements.header.loadElement';
    });

    const loadActionTitle = computed(() => {
      if (elementsTypes.value.length === 1 && elementsTypes.value[0] === TemplateStructureEnum.DESIGN) {
        return t('templateBuilder.modals.loadElements.actions.chooseDesign');
      }
      return t('templateBuilder.modals.loadElements.actions.chooseElement');
    });

    const handleClose = () => {
      hideElementsModal();
    };

    const handleInsertElement = () => {
      hideElementsModal();
      emit('insert-element');
    };

    onUpdated(() => {
      // We search for placeholder in the template
      const template = getTemplateIframeDocument();
      const placeholder = template?.querySelector('.sortable-group-section.sortable-placeholder') as HTMLElement;

      if (placeholder) {
        // We define the new payload
        payload.value = { position: 'bottom', selector: '.sortable-group-section.sortable-placeholder' };
      }
    });

    return {
      t,
      displayModal,
      payload,
      elementsTypes,
      headerTitle,
      loadActionTitle,
      handleClose,
      handleInsertElement,
    };
  },
});
</script>

<style lang="scss">
.p-dialog-section .p-dialog-content {
  max-height: 750px;
}
</style>
