<template>
  <div class="configuration-wrapper">
    <Panel>
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.panels.loadElements.header') }}</h4>
        </div>
      </template>

      <template #icons>
        <i
          class="far fa-times icons-header-panel"
          @click="hideLeftToolbar"
        />
      </template>

      <LoadElements
        :payload="payload"
      />
    </Panel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadElements from '@/components/template-builder/LoadElements.vue';
import { LoadSectionPayload } from '@/types';
import { useI18n } from 'vue-i18n';
import { hideLeftToolbar } from '@/composables/template-editor/TemplateEditor';
import Panel from 'primevue/panel';

export default defineComponent({
  name: 'LoadElementsPanel',

  components: {
    LoadElements,
    Panel,
  },

  setup() {
    const { t } = useI18n();
    const payload: LoadSectionPayload = { position: 'top', selector: '#spm_body .spm_section:first-child' };

    return {
      t,
      payload,
      hideLeftToolbar,
    };
  },
});
</script>

<style lang="scss" scoped>
.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}
</style>
