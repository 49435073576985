import { Request } from '@/composables/GraphQL';
import { SpmTableState } from '@/types';
import { Templates } from '@/types/generated-types/graphql';

interface ListResponse<T> {
  items: Array<T>;
  total: number;
  err: string;
}

export default async function getTemplatesUsedBySyncElement({ idShop, syncElementId }: { idShop: number; syncElementId: string }): Promise<SpmTableState> {
  const query = `query ( $id_shop: Int!, $sync_element_id: String! ) {
    TemplatesListUsedBySyncElement(id_shop: $id_shop, sync_element_id: $sync_element_id)
      {
        items {
          id_template
          label
          type
        }
        total
      }
    }`;

  const variables = { id_shop: idShop, sync_element_id: syncElementId };

  const {
    data,
    err,
  } = await Request<ListResponse<Templates>>({
    name: 'TemplatesListUsedBySyncElement',
    query,
    variables,
  });

  const result: ListResponse<Templates> = data ?? {
    items: [],
    total: 0,
    err: '',
  };

  return {
    error: result.err,
    items: result.items,
    total: result.total,
  };
}
