
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  PropType,
  computed,
} from 'vue';

import { useStore } from '@/store';

import InputSwitch from 'primevue/inputswitch';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import { ParserTabItemObject, TemplateStructureEnum } from '@/types';

export default defineComponent({
  name: 'ToggleMobileSettings',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    InputSwitch,
  },

  props: {
    tabItemLabel: {
      type: String,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserTabItemObject>,
      required: true,
    },

    fieldGroupLabel: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    tooltipLabel: {
      type: String,
      required: true,
    },

    headerTitle: {
      type: String,
      required: false,
      default: '',
    },

    isSynchronizedPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: {
    'toggle-settings': Object,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const displaySettings: Ref<boolean> = ref(false);
    const isMounted = ref(false); // Variable de contrôle
    const selectedStructure = computed(() => store.getters['liveEditor/getSelectedStructure']);
    const selectedSyncElement = computed(() => store.getters['liveEditor/getSelectedSyncElement']);

    const handleChange = async () => {
      const acceptCallback = async () => {
        const toggleHandler = props.parserValues.toggleItemsDisplay?.[props.fieldGroupLabel].toggleHandler;
        if (toggleHandler && selectedStructure.value) {
          await toggleHandler(`#${selectedStructure.value.identifier}`, displaySettings.value);
        }
        emit('toggle-settings', {
          tabItemLabel: props.tabItemLabel,
          fieldGroupLabel: props.fieldGroupLabel,
          value: displaySettings.value,
        });
      };

      if (props.isSynchronizedPage
          && !selectedSyncElement.value
          && selectedStructure.value
          && selectedStructure.value.type === TemplateStructureEnum.PAGE
      ) {
        store.dispatch('liveEditor/showSyncElementWarning', {
          elementId: selectedStructure.value.identifier,
          elementType: TemplateStructureEnum.DESIGN,
          name: props.headerTitle,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          acceptCallback,
          rejectCallback: () => {
            displaySettings.value = !displaySettings.value;
          },
        });
      } else {
        await acceptCallback();
      }
    };

    onMounted(() => {
      isMounted.value = true; // Le composant est monté
      displaySettings.value = props.parserValues.toggleItemsDisplay?.[props.fieldGroupLabel].value ?? false;
    });

    return {
      t,
      displaySettings,

      handleChange,
    };
  },
});
