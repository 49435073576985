import {
  TemplateStructureEnum,
} from '@/types';

import { useI18n } from 'vue-i18n';

// eslint-disable-next-line import/no-cycle
import { store } from '@/store';

// eslint-disable-next-line import/no-cycle
import {
  showElementsModal,
} from '@/composables/template-editor/TemplateEditor';

type StructurePanelConfigMap = {
  [key: string]: Record<string, any>;
};

const getStructurePanelConfigMap = (): StructurePanelConfigMap => {
  const { t } = useI18n();
  return {
    [TemplateStructureEnum.PAGE]: {
      actions: [
        {
          icon: 'far fa-upload',
          label: t('templateBuilder.structureActions.page.loadDesign'),
          command: () => {
            showElementsModal([TemplateStructureEnum.DESIGN]);
          },
        },
        {
          icon: 'far fa-star',
          label: t('templateBuilder.structureActions.page.saveDesign'),
          command: () => {
            store.commit('liveEditor/showSaveElementModal', {
              selector: '',
              type: TemplateStructureEnum.DESIGN,
            });
          },
        },
      ],
    },
  };
};

export default getStructurePanelConfigMap;
