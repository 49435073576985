
import {
  computed,
  ComputedRef,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  Ref,
  watch,
} from 'vue';

import { useStore } from '@/store';
import {
  HistoryType,
  TemplateParentTypeEnum,
  TemplateStructureEnum,
  TemplateWidth,
} from '@/types/enums';
import {
  TEMPLATE_PAGE_IDENTIFIER,
  MEDIA_URL_PART_THUMB_GENERATION,
  HIDE_MOBILE_ATTRIBUTE,
  TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
  TEMPLATE_SYNC_ELEMENT_NAME,
  TEMPLATE_SYNC_ELEMENT_CLASS,
} from '@/components/template-builder/utils/constants';
import ConfigurationPanel from '@/components/template-builder/panels/ConfigurationPanel.vue';
import InformationPanel from '@/components/template-builder/panels/InformationPanel.vue';
import TranslationPanel from '@/components/template-builder/panels/TranslationPanel.vue';
import HistoryPanel from '@/components/template-builder/panels/HistoryPanel.vue';
import CssPanel from '@/components/template-builder/panels/CssPanel.vue';
import CssPanelMediaQueries from '@/components/template-builder/panels/CssPanelMediaQueries.vue';
import LoadElementsPanel from '@/components/template-builder/panels/LoadElementsPanel.vue';
import TemplateLiveView from '@/components/template-builder/TemplateLiveView.vue';
import InputTextModal from '@/components/modals/InputTextModal.vue';
import SaveElementModal from '@/components/modals/SaveElementModal.vue';
import WidgetsPanel from '@/components/template-builder/panels/WidgetsPanel.vue';
import ColumnWidgetModal from '@/components/modals/ColumnWidgetModal.vue';
import LoadElementsModal from '@/components/modals/LoadElementsModal.vue';
import SyncElementUsedTemplates from '@/components/template-builder/SyncElementUsedTemplates.vue';
import useToggleBodyScrollBars from '@/composables/useToggleBodyScrollBars';
import {
  activate,
  checkIfPartExistsWithSameName,
  deactivate,
  hideConfigurationPanel,
  hideTemplateEditor,
  refreshSectionsConfig,
  refreshTranslations,
  rename,
  savePartInDatabase,
  saveTemplatesInLocalStorage,
  setRefreshTemplatesList,
  showConfigurationPanel,
  TemplateEditorState as state,
  toggleAdvancedMode,
  togglePanel,
  updateSectionsInState,
  sendTestNotification,
  getTestPushNotificationData,
  beforeSaveTemplate,
  saveTemplate,
  setTemplateConfigurationKeyInState,
  informationPanelSettingsValidate,
  updateLastRollbackGroupId,
  setTestEmailSent,
  resetNeedToTranslate,
  resetTemplateToDesktop,
  setTemplateWidth,
  refreshSmartProductsListsOriginalStructure,
  refreshAttributeInDOM,
  restoreSmartListsOriginalCode,
  sortTranslationInState,
  removeUnusedTranslations,
  hideLeftToolbar,
  restoreState,
  unlinkSyncElement,
  linkSection,
  addNewSyncElementToSection,
  renamePartInDatabase,
  updateUsedSyncElements,
  getAllSyncElements,
  removeCustomWidgetCss,
  getTemplatePartsBySyncElementIds,
  unlinkElementsInSection,
  updateUsedSyncElementsName,
} from '@/composables/template-editor/TemplateEditor';

import EditorModal from '@/components/modals/EditorModal.vue';
import {
  ActiveLiveEditorItemData,
  PrimvueMenuModel,
  SelectedSyncElement,
  SyncElementWarning,
  TemplatePart,
} from '@/types';
import { useI18n } from 'vue-i18n';
import {
  getTemplateIframeDocument,
  isDisplayTemplate,
  isEmailTemplate,
  isFacebookTemplate,
  isPushTemplate,
  isSmsTemplate,
  clearPlaceholder,
  showEditSyncElementWarning,
  getSectionParent,
} from '@/components/template-builder/utils/helpers';
import {
  showToastError,
  showToastSuccess,
  generateSha1Hash,
  asInt,
} from '@/helpers';
import { getShopDefaultLang, UserState } from '@/composables/User';
import {
  createHistory,
  getClosestPointOfSave, getHistoryIndexFromGroupId,
  getLastPointOfSaveId,
  getTemplateHistoryGroupList,
  loadHistoryGroups,
} from '@/composables/template-editor/History';
import { TemplatesElementsHistoryGroup } from '@/types/generated-types/graphql';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Divider from 'primevue/divider';
import {
  EditingTemplateStatusEnum,
  getEditingTemplateFromCampaign,
  setEditingTemplateFromCampaign,
  setCampaignTemplateInformationFromBuilder,
} from '@/composables/AutomatedScenarios';
import TemplateSelector from '@/components/automated-scenarios/fields/TemplateSelector.vue';
import { duplicateTemplate } from '@/composables/shop/Templates';
import SendTestModal, { Suggestion } from '@/components/modals/SendTestModal.vue';
import { destroyAllSortables, resetListeners } from '@/components/template-builder/utils/listeners';
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
import { removeActiveItemElementDecorations } from '@/components/template-builder/utils/active-item-builder';
import DuplicateModal from '@/components/modals/DuplicateModal.vue';
import { nestGet, nestPost } from '@/composables/nestApi';
import getStructurePanelConfigMap from '@/components/template-builder/config/structure-panel-config';
import ChooseZipFileModal from '@/components/modals/ChooseZipFileModal.vue';
import { delay } from '@/helpers/helpers';
import { updateIframeTranslations, fixMissingTranslations } from './utils/translate';
import { duplicateElement } from './utils/action-buttons-handlers';
import { cleanupDynamicStyle } from './utils/parser';
import { updateSyncElementsInOpenedTemplates } from './utils/sync-elements-helpers';
import { isPanelVisible } from './config/sync-elements-config';

export default defineComponent({
  name: 'TemplateEditor',

  components: {
    DuplicateModal,
    TemplateSelector,
    SendTestModal,
    EditorModal,
    ConfigurationPanel,
    InformationPanel,
    TranslationPanel,
    HistoryPanel,
    CssPanel,
    CssPanelMediaQueries,
    LoadElementsPanel,
    TemplateLiveView,
    InputTextModal,
    SaveElementModal,
    WidgetsPanel,
    ConfirmDialog,
    ColumnWidgetModal,
    LoadElementsModal,
    ChooseZipFileModal,
    Dialog,
    SyncElementUsedTemplates,
    Button,
    ProgressSpinner,
    Divider,
  },

  setup() {
    const { t, locale } = useI18n();
    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;

    const store = useStore();
    const confirm = useConfirm();
    const selectedTemplateId = computed(() => state.template?.id ?? 0);
    const selectedTemplateType = computed(() => state.template?.type ?? '');
    const selectedTemplateName = computed(() => state.template?.informations.name);
    const selectedTemplateIdCampaign = computed(() => state.template?.idCampaign ?? null);
    const selectedStructure = computed(() => store.getters['liveEditor/getSelectedStructure']);
    const syncElementWarning = computed<SyncElementWarning>(() => store.getters['liveEditor/getSyncElementWarning']);
    const selectedSyncElement = computed<SelectedSyncElement>(() => store.getters['liveEditor/getSelectedSyncElement']);
    const exitModeModalVisible = computed<boolean>(() => store.getters['liveEditor/getExitEditModeModalVisible']);
    const exitModeModalCallback = computed<Function | null>(() => store.getters['liveEditor/getExitEditModeCallback']);
    const displayElementsModal: ComputedRef<boolean> = computed(() => state.isLoadElementsModalVisible);
    const subtitle = computed(() => {
      if (selectedSyncElement.value) {
        return t(
          'templateBuilder.toolbar.header.subtitle.editElement',
          [t(`templateBuilder.toolbar.header.subtitle.elements.${selectedSyncElement.value.elementType}`)],
        );
      }
      return '';
    });

    const structurePanelConfigMap = getStructurePanelConfigMap();
    const selectedStructureActions = computed(() => {
      const structureType = selectedStructure.value?.type;
      if (!structureType) return [];
      const structureTypeConfig = structurePanelConfigMap[structureType];
      if (!structureTypeConfig) return [];
      return structureTypeConfig.actions;
    });

    const isConfigurationPanelVisible = computed(() => state.isConfigurationPanelVisible);
    const isConfigurationPanelClosable = computed(() => {
      if (selectedTemplateType.value !== TemplateParentTypeEnum.SMS
      && selectedTemplateType.value !== TemplateParentTypeEnum.PUSHNOTIFICATIONS) {
        if (selectedSyncElement.value && [TemplateStructureEnum.WIDGET, TemplateStructureEnum.SECTION].includes(selectedSyncElement.value.elementType as TemplateStructureEnum)) {
          return false;
        }
        return true;
      }
      return false;
    });
    const isLeftToolbarVisible = computed(() => {
      let returnValue = false;
      if (state.isLeftToolbarVisible && (state.leftToolbar.show.configurationPanel || state.leftToolbar.show.designPanel) && selectedStructure.value) {
        returnValue = true;
      } else if (state.isLeftToolbarVisible && !state.leftToolbar.show.configurationPanel && !state.leftToolbar.show.designPanel) {
        returnValue = true;
      }

      return returnValue;
    });
    const isWidgetPanelClosable = computed(() => {
      if (selectedTemplateType.value === TemplateParentTypeEnum.EMAIL && !selectedSyncElement.value) {
        return false;
      }
      if (selectedTemplateType.value === TemplateParentTypeEnum.EMAIL && selectedSyncElement.value && selectedSyncElement.value.elementType !== TemplateStructureEnum.SECTION) {
        return true;
      }
      return true;
    });
    const isTranslationPanelClosable = computed(() => !state.template?.informations.imported);
    const advancedModeEnabled = computed(() => {
      if (state.advancedModeEnabled) {
        return true;
      }
      if (selectedSyncElement.value && isPanelVisible(selectedSyncElement.value.elementType, 'advancedCssPanel')) {
        return true;
      }
      return false;
    });
    const showTemplateSelector = ref(false);
    const showDuplicationModal = ref(false);
    const displaySyncElementTemplatesModal = ref(false);
    const displaySaveSyncElementTemplatesModal = ref(false);

    const isChooseZipFileModalVisible = computed(() => store.getters['liveEditor/getIsChooseZipFileModalVisible']);

    const width = computed({
      get() {
        return state.templateWidth;
      },
      set(newWidth: string) {
        setTemplateWidth(newWidth);
      },
    });

    const historyList: Ref<TemplatesElementsHistoryGroup[]> = ref([]);
    const selectedHistory: Ref<TemplatesElementsHistoryGroup|null> = ref(null);
    const lastHistoryGroupId: ComputedRef<number|null> = computed(() => state.template?.configuration.lastIdTemplateElementsHistoryGroup);
    const currentHistoryGroupId: ComputedRef<number|null> = computed(() => state.template?.configuration.currentIdTemplateElementsHistoryGroup ?? lastHistoryGroupId.value);
    const firstHistoryGroupId: ComputedRef<number|null> = computed(() => {
      let lastHistoryPoint: TemplatesElementsHistoryGroup|null = null;

      if (historyList.value.length > 0) {
        lastHistoryPoint = historyList.value[historyList.value.length - 1];
      }

      return lastHistoryPoint?.id_template_elements_history_group ?? null;
    });

    // key used to rerender live preview on history change
    const livePreviewKey = ref(0);

    const displayRenameTemplateModal: Ref<boolean> = ref(false);
    const valueRenameTemplateModal: Ref<string> = ref('');

    const displaySaveElementModal: ComputedRef<boolean> = computed(() => store.state.liveEditor.saveElementModalPayload.visible);
    const typeSaveElementModal: ComputedRef<TemplateStructureEnum | null> = computed(() => store.state.liveEditor.saveElementModalPayload.type);

    const nameSaveElementModal: Ref<string> = ref('');
    const syncStateSaveElementModal: Ref<boolean> = ref(false);

    const informationPanelValidation = ref<any>({});
    const newListName = ref();

    /* Check if this template was edited from a campaign.d. */
    const openingFromCampaign: ComputedRef<boolean> = computed(() => selectedTemplateIdCampaign.value !== null
      && getEditingTemplateFromCampaign() !== null);

    const setLastHistory = (historyGroupId: number | null) => {
      if (historyGroupId) {
        const currentHistory: TemplatesElementsHistoryGroup[] = historyList.value
          .filter((history: TemplatesElementsHistoryGroup) => history.id_template_elements_history_group === historyGroupId);

        if (currentHistory.length) {
          [selectedHistory.value] = currentHistory;
        }
      }
    };

    const handleChangeHistory = async (index: number) => {
      store.commit('liveEditor/setCheckSyncElements', true); // Set this to make sure sync elements are checked in case of update
      const filteredHistoryList = historyList.value.slice(index); // delete history after index
      await loadHistoryGroups(filteredHistoryList);
      refreshSectionsConfig(JSON.parse(historyList.value[index].sections_order_after ?? '').map((id: string) => id.toString()));
      refreshTranslations();
      selectedHistory.value = historyList.value[index];
      setTemplateConfigurationKeyInState('currentIdTemplateElementsHistoryGroup', selectedHistory.value.id_template_elements_history_group);
      setLastHistory(selectedHistory.value.id_template_elements_history_group);
      livePreviewKey.value += 1;
    };

    const handlePreviousHistory = () => {
      const currentIndex = historyList.value
        .findIndex((current: TemplatesElementsHistoryGroup) => current.id_template_elements_history_group === selectedHistory.value?.id_template_elements_history_group);

      if (currentIndex === undefined) handleChangeHistory(0);
      // eslint-disable-next-line prefer-destructuring
      else if (currentIndex < (historyList.value.length - 1)) handleChangeHistory((currentIndex + 1));
    };

    const handleNextHistory = () => {
      const currentIndex = historyList.value
        .findIndex((current: TemplatesElementsHistoryGroup) => current.id_template_elements_history_group === selectedHistory.value?.id_template_elements_history_group);
      if (currentIndex) handleChangeHistory((currentIndex - 1));
    };

    useToggleBodyScrollBars();

    const prepareStructureConfiguration = () => {
      store.dispatch('liveEditor/prepareStructureConfiguration', {
        type: TemplateStructureEnum.PAGE,
        identifier: TEMPLATE_PAGE_IDENTIFIER,
      });
    };

    const getPageConfig = () => {
      if (isConfigurationPanelVisible.value && selectedStructure.value?.type === TemplateStructureEnum.PAGE) {
        hideConfigurationPanel();
        return;
      }
      prepareStructureConfiguration();
      showConfigurationPanel();
    };

    const handleSaveElementClose = () => {
      store.commit('liveEditor/hideSaveElementModal');
    };

    const toEraseElementId = ref();

    const saveElement = async (
      element: {
        name: string;
        type: string;
        isSync: boolean;
        renameElement?: {
          itemId: number;
          itemType: string;
          oldName: string;
          syncElementId?: string;
        };
      },
      done: Function,
    ) => {
      if (!element.renameElement) {
        // We check if a template_part exists with the same name
        const activeItemSelector = computed(() => store.getters['liveEditor/getSaveElementSelector']);
        const eraseId = await checkIfPartExistsWithSameName(element.type, element.name, activeItemSelector.value);

        const updateElementInState = (syncId: string) => {
          const applyClassAndAttribute = (isDesign = false) => {
            const template = getTemplateIframeDocument();
            if (template) {
              let elementHtml = isDesign ? template.body : template.body.querySelector(activeItemSelector.value);
              if (isDisplayTemplate(state.template?.type) && isDesign) {
                elementHtml = template.querySelector('#spm_body');
              }
              if (elementHtml) {
                elementHtml.setAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER, syncId);
                elementHtml.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, element.name);
                if (!isDesign) {
                  elementHtml.classList.add(TEMPLATE_SYNC_ELEMENT_CLASS);
                }
              }
              return elementHtml;
            }
            return null;
          };

          let elementHtml: HTMLElement | null = null;

          switch (element.type) {
            case TemplateStructureEnum.SECTION:
              elementHtml = applyClassAndAttribute();
              if (elementHtml) {
                linkSection(elementHtml.getAttribute('data-spmelementid'), syncId, element.name);
              }
              break;
            case TemplateStructureEnum.DESIGN:
              elementHtml = applyClassAndAttribute(true);
              linkSection('', syncId, element.name, TemplateStructureEnum.DESIGN);
              break;
            default:
              elementHtml = applyClassAndAttribute();
              if (elementHtml) {
                const parentSection = getSectionParent(elementHtml as HTMLElement);
                if (parentSection) {
                  addNewSyncElementToSection(parentSection.getAttribute('data-spmelementid'), syncId);
                }
              }
          }
        };

        const reset = (newSection: TemplatePart) => {
          store.commit('liveEditor/hideSaveElementModal');
          store.dispatch('templateEditor/updateSection', { section: newSection });
        };

        const save = async (idToErase: any) => {
          if (element.type !== TemplateStructureEnum.DESIGN && element.isSync) {
            unlinkElementsInSection(activeItemSelector.value);
          }
          const newPart = await savePartInDatabase(element.type, element.name, activeItemSelector.value, idToErase, element.isSync);
          if (newPart) {
            const { newSection, syncId } = newPart;
            if (element.isSync) {
              updateElementInState(syncId);
              updateSectionsInState();
              await hideLeftToolbar();
            }
            if (newSection) {
              reset(newSection);
            }
          }
        };

        if (typeof eraseId === 'string' && eraseId !== '') {
          // Show error
          await showToastError(t(eraseId));
        } else if (eraseId) {
          toEraseElementId.value = eraseId;

          // If used, we ask confirmation to erase the old one
          confirm.require({
            group: 'saveElement',
            message: t('templateBuilder.confirm.saveNewSection.duplicateName.message', [element.name]),
            header: t('templateBuilder.confirm.saveNewSection.duplicateName.header'),
            icon: 'far fa-exclamation-triangle',
            acceptLabel: t('yes'),
            acceptClass: 'p-button-danger',
            rejectLabel: t('no'),
            rejectClass: 'p-button-secondary',
            accept: async () => {
              await save(eraseId);
              toEraseElementId.value = null;
              done();
            },
            reject: () => {
              store.commit('liveEditor/hideSaveElementModal');
              toEraseElementId.value = null;
              done();
            },
          });
        } else {
          // No part with same name
          await save(null);
          done();
          nameSaveElementModal.value = '';
          syncStateSaveElementModal.value = false;
        }
      } else {
        try {
          if (element.renameElement.oldName.toLowerCase().trim() !== element.name.toLowerCase().trim()) {
            const existingElement = await checkIfPartExistsWithSameName(element.renameElement.itemType, element.name, '');
            if (existingElement) {
              showToastError(t('templateBuilder.renameElement.error.existingElement', [element.name]));
              done(false);
            } else {
              await renamePartInDatabase(element.renameElement.itemId, element.name);

              if (element.renameElement.syncElementId) {
                await nestPost(
                  'v4',
                  '/template/updateSyncElements',
                  {},
                  {
                    idShop,
                    syncElementId: element.renameElement.syncElementId,
                    elementType: element.renameElement.itemType,
                    idTemplate: state.template?.id,
                    deleted: false,
                  },
                );
                updateUsedSyncElementsName(element.renameElement.syncElementId || '', element.name);
                const newElement = await checkIfPartExistsWithSameName(element.renameElement.itemType, element.name, '', true);
                if (newElement) {
                  updateSyncElementsInOpenedTemplates(state?.template?.id ?? 0, newElement);
                }
              }

              store.dispatch('templateEditor/updateSectionName', { id: element.renameElement.itemId, name: element.name });
              done();
              nameSaveElementModal.value = '';
              syncStateSaveElementModal.value = false;
            }
          }
        } catch (error) {
          showToastError(t('errorMessages.GENERIC_ERROR'));
          done(false);
        }
      }
    };

    const sendTestTemplate = async (params: Suggestion[]) => {
      if (state.template) {
        store.commit('general/showTheSpinner');

        // Send query to NestJS
        const getData = {
          type: selectedTemplateType.value,
          params,
        };

        const dataQuery = btoa(encodeURIComponent(JSON.stringify(getData)));
        const result = await nestGet('v4', `/template/test/${state.template.id}/${dataQuery}`, {}, '');

        if (result && result.success) {
          if (isEmailTemplate(selectedTemplateType.value)) {
            await showToastSuccess('emailSent');
          } else if (isSmsTemplate(selectedTemplateType.value)) {
            await showToastSuccess('smsSent');
          }
        } else {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        store.commit('general/hideTheSpinner');
      }
    };

    const executeBeforeTemplateSave = async () => {
      if (!isSmsTemplate(selectedTemplateType.value) && !isPushTemplate(selectedTemplateType.value) && !isFacebookTemplate(selectedTemplateType.value)) {
        await resetTemplateToDesktop();
      }

      return beforeSaveTemplate(t);
    };

    // Function executer when the user click on the save & exit button or the save & stay button
    const executeTemplateSave = async (exit = false, treatSwitchToCampaign = true, validateInformationPanel = true, historyType = HistoryType.POINT_OF_SAVE) => {
      store.commit('general/showTheSpinner');
      let validation: Record<string, any> = { success: true };

      if (validateInformationPanel) {
        // Validate InformationPanel values
        validation = await informationPanelSettingsValidate(true);
        informationPanelValidation.value = validation.validate;
      }

      if (!validation.success) {
        // Open InformationPanel to see errors
        if (!state.isLeftToolbarVisible || !state.leftToolbar.show.informationPanel) {
          await togglePanel('informationPanel');
        }
      } else if (await executeBeforeTemplateSave()) {
        // Update state
        updateSectionsInState();

        await createHistory(historyType);
        if (await saveTemplate()) {
          if (exit) {
            if (openingFromCampaign.value && treatSwitchToCampaign) {
              const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
              if (editingTemplateFromCampaign !== null && editingTemplateFromCampaign.status === EditingTemplateStatusEnum.IN_TEMPLATE_EDITOR) {
                editingTemplateFromCampaign.status = EditingTemplateStatusEnum.SAVE_QUIT_TEMPLATE_EDITOR;
                setEditingTemplateFromCampaign(editingTemplateFromCampaign);
                setCampaignTemplateInformationFromBuilder(state.template?.informations);
              }
            }

            deactivate(state.template?.id ?? 0);
          } else {
            // Refresh smart products lists and listeners
            await refreshSmartProductList();
            resetListeners();
          }
        }
      }

      store.commit('general/hideTheSpinner');
    };

    const restoreTemplateBeforeEdit = () => {
      const currentState = store.getters['liveEditor/getCurrentTemplateState'];
      if (currentState) {
        restoreState(currentState.index, currentState.template);
        store.commit('liveEditor/destroySortableInstances');
        livePreviewKey.value += 1;
        store.commit('liveEditor/setCurrentTemplateState', null);
      }
    };

    const saveForAllInstancesLoading = ref(false);

    const saveForAllInstances = async () => {
      try {
        saveForAllInstancesLoading.value = true;

        const {
          elementType,
          elementName,
          elementId,
          syncElementId,
        } = selectedSyncElement.value;

        const templatePart = await getTemplatePartsBySyncElementIds(idShop, [syncElementId ?? '']);

        const existingPart = await checkIfPartExistsWithSameName(
          elementType,
          elementName ?? '',
          `#${elementId}`,
          true,
        );

        if ((existingPart
          && templatePart.items
          && templatePart.items.length
          && asInt(existingPart.id_template_parts) === asInt(templatePart.items[0].id_template_parts)
        ) || (!existingPart && templatePart.items && templatePart.items.length)) {
          const result = await savePartInDatabase(elementType, elementName ?? '', `#${elementId}`, asInt(templatePart.items[0].id_template_parts), true, syncElementId);

          await nestPost(
            'v4',
            '/template/updateSyncElements',
            {},
            {
              idShop,
              syncElementId,
              elementType,
              idTemplate: state.template?.id,
              deleted: false,
            },
          );

          if (elementType !== TemplateStructureEnum.DESIGN) {
            const template = getTemplateIframeDocument();
            const elementToDuplicate: HTMLElement | null = template.querySelector(`#${elementId}`);
            if (elementToDuplicate) {
              elementToDuplicate.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, elementName || '');
            }
            const otherSyncElementsToUpdate = getAllSyncElements(syncElementId ?? '', elementId);

            const itemData: ActiveLiveEditorItemData = {
              selector: `#${elementId}`,
              type: elementType as TemplateStructureEnum,
            };

            // eslint-disable-next-line no-restricted-syntax
            for (const otherSyncElementToUpdate of Array.from(otherSyncElementsToUpdate)) {
              if (elementToDuplicate) {
                // eslint-disable-next-line no-await-in-loop
                await duplicateElement(
                  itemData,
                  itemData.type,
                  elementToDuplicate,
                  null,
                  null,
                  null,
                  null,
                  null,
                  false,
                  `#${otherSyncElementToUpdate.id}`,
                  otherSyncElementToUpdate.getAttribute('data-spmelementid') || '',
                );
              }
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const otherSyncElementToUpdate of Array.from(otherSyncElementsToUpdate)) {
              otherSyncElementToUpdate.remove();
              removeCustomWidgetCss(`#${otherSyncElementToUpdate.id}`);
            }
          } else {
            const template = getTemplateIframeDocument();
            if (template) {
              template.body.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, elementName ?? '');
            }
          }

          await nextTick();

          if (result) {
            updateSyncElementsInOpenedTemplates(state?.template?.id ?? 0, result.newPart);
          }

          cleanupDynamicStyle();
          removeActiveItemElementDecorations();
          updateSectionsInState();
          removeUnusedTranslations();
          refreshSectionsConfig();
          resetListeners();
          store.commit('liveEditor/setSelectedSyncElement', null);
          store.commit('liveEditor/setCurrentTemplateState', null);

          await togglePanel('widgetsPanel');
          saveForAllInstancesLoading.value = false;
          displaySaveSyncElementTemplatesModal.value = false;
          showToastSuccess(t('templateBuilder.saveSyncElementModal.success.saveForAllInstances'));
          // If from edit mode
          if (exitModeModalCallback.value) {
            await delay(100);
            await exitModeModalCallback.value();
          }
        } else if (existingPart
          && templatePart.items
          && templatePart.items.length
          && asInt(existingPart.id_template_parts) !== asInt(templatePart.items[0].id_template_parts)
        ) {
          showToastError(t('templateBuilder.renameElement.error.existingElement', elementName));
          saveForAllInstancesLoading.value = false;
        } else {
          saveForAllInstancesLoading.value = false;
          throw new Error(t('templateBuilder.saveSyncElementModal.error.saveForAllInstances'));
        }
      } catch (err) {
        saveForAllInstancesLoading.value = false;
        showToastError(t('templateBuilder.saveSyncElementModal.error.saveForAllInstances'));
      }
    };

    // Command in edit mode
    const commandSaveForAllInstances = async () => {
      const validation = await informationPanelSettingsValidate(true, selectedSyncElement.value.elementName);
      informationPanelValidation.value = validation.validate;

      if (!validation.success) {
        // Open InformationPanel to see errors
        if (!state.isLeftToolbarVisible || state.leftToolbar.show.informationPanel === false) {
          await togglePanel('informationPanel');
        }
      } else {
        displaySaveSyncElementTemplatesModal.value = true;
      }
    };
    const commandUnlinkAndSave = async () => {
      confirm.require({
        group: 'syncedElements',
        message: t('templateBuilder.confirm.unlinkAndSave.message'),
        header: t('templateBuilder.confirm.unlinkAndSave.header'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-success',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: async () => {
          unlinkSyncElement(selectedSyncElement.value);
          removeActiveItemElementDecorations();
          updateSectionsInState(false);
          store.commit('liveEditor/setSelectedSyncElement', null);
          await togglePanel('widgetsPanel');
          if (exitModeModalCallback.value) {
            await delay(100);
            await exitModeModalCallback.value();
          }
        },
      });
    };
    const commandCancelModifications = async () => {
      confirm.require({
        group: 'syncedElements',
        message: t('templateBuilder.confirm.cancelEditSyncElement.message'),
        header: t('templateBuilder.confirm.cancelEditSyncElement.header'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: async () => {
          removeActiveItemElementDecorations();
          store.commit('liveEditor/setSelectedSyncElement', null);
          await togglePanel('widgetsPanel');
          restoreTemplateBeforeEdit();
        },
      });
    };

    const btnSaveComputed: ComputedRef<PrimvueMenuModel> = computed(() => {
      const button = {
        label: t('templateBuilder.save'),
        class: 'p-button-secondary',
        items: [
          {
            label: t('templateBuilder.saveAndLeave'),
            command: async () => {
              const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
              let primaryTemplateToSwitch: any = null;

              if (openingFromCampaign.value && editingTemplateFromCampaign) {
                const templatesLinkedToThisCampaign = state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === editingTemplateFromCampaign.id_campaign);
                const idTemplatesLinkedToThisCampaign = templatesLinkedToThisCampaign.map((c: any) => c.id);
                const primaryTemplate = editingTemplateFromCampaign.idTemplate;

                // on est dans un template de redirection
                if (idTemplatesLinkedToThisCampaign.includes(state.template?.id) && state.template?.id !== primaryTemplate) {
                  primaryTemplateToSwitch = state.templatesLinkedToCampaign.filter((c: any) => c.id === primaryTemplate);
                  primaryTemplateToSwitch = primaryTemplateToSwitch.length ? primaryTemplateToSwitch[0] : null;
                }
              } else {
                // vérifier si on est dans un template de redirection
                primaryTemplateToSwitch = state.templates.filter((c: any) => {
                  if (c.configuration.nextIdTemplate) {
                    const [keyNextIdTemplate, valueNextIdTemplate] = Object.entries(c.configuration.nextIdTemplate)[0];
                    const valueNextIdTemplateNumber = valueNextIdTemplate && Array.isArray(valueNextIdTemplate) ? valueNextIdTemplate.map(Number) : [];
                    if (valueNextIdTemplateNumber && Array.isArray(valueNextIdTemplateNumber) && valueNextIdTemplateNumber.includes(Number(state.template?.id))) {
                      return true;
                    }
                  }
                  return false;
                });
                primaryTemplateToSwitch = primaryTemplateToSwitch.length ? primaryTemplateToSwitch[0] : null;
              }

              if (primaryTemplateToSwitch) {
                await executeTemplateSave(true, false);
                await activate(primaryTemplateToSwitch.id, '', primaryTemplateToSwitch.type ?? '');
              } else {
                await executeTemplateSave(true);
              }
              updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));
              setRefreshTemplatesList(true);
            },
          },
          {
            label: t('templateBuilder.saveAndStay'),
            command: async () => {
              const editingTemplateFromCampaign = getEditingTemplateFromCampaign();

              if (openingFromCampaign.value && editingTemplateFromCampaign) {
                const templatesLinkedToThisCampaign = state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === editingTemplateFromCampaign.id_campaign);
                const idTemplatesLinkedToThisCampaign = templatesLinkedToThisCampaign.map((c: any) => c.id);
                const primaryTemplate = editingTemplateFromCampaign.idTemplate;

                // on est dans un template de redirection
                if (idTemplatesLinkedToThisCampaign.includes(state.template?.id) && state.template?.id !== primaryTemplate) {
                  await executeTemplateSave(false, false);
                  return true;
                }
              }

              await executeTemplateSave(false);
              updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));
              return true;
            },
          },
          {
            label: t('templateBuilder.leaveWithoutSaving'),
            command: async () => {
              if (state.testEmailSent) {
                const rollbackPointIndex = getHistoryIndexFromGroupId(historyList.value, state.lastRollbackId);
                const filteredHistoryList = [historyList.value[rollbackPointIndex]]; // Keep only point of save
                await loadHistoryGroups(filteredHistoryList);
                refreshSectionsConfig(JSON.parse(historyList.value[rollbackPointIndex].sections_order_after ?? '').map((id: string) => id.toString()));
                refreshTranslations();
                setTemplateConfigurationKeyInState('currentIdTemplateElementsHistoryGroup', +state.lastRollbackId);
                await saveTemplate();
                setTestEmailSent(false);
              }

              if (!openingFromCampaign.value) {
                state.templates.map(
                  async (s) => {
                    deactivate(selectedTemplateId.value ?? 0);
                    if (state.hasAny) {
                      await activate(state.templates[state.index].id, '', state.templates[state.index].type);
                      return true;
                    }
                    return false;
                  },
                );
              } else {
                const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
                const idCampaign = editingTemplateFromCampaign?.id_campaign ?? 0;
                if (idCampaign > 0) {
                  state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === idCampaign).map(
                    async (s) => {
                      deactivate(selectedTemplateId.value ?? 0);
                      if (state.hasAny) {
                        await activate(state.templates[state.index].id, '', state.templates[state.index].type);
                        return true;
                      }
                      return false;
                    },
                  );
                }
              }
            },
          },
        ],
      };

      if (!openingFromCampaign.value) {
        button.items.splice(2, 0, {
          label: t('templateBuilder.minimize'),
          command: async () => hideTemplateEditor(),
        });
      }

      if (selectedSyncElement.value) {
        button.items = [
          {
            label: t('templateBuilder.editSyncElementActions.saveForAllInstances'),
            command: commandSaveForAllInstances,
          },
          {
            label: t('templateBuilder.editSyncElementActions.unlinkAndSave'),
            command: commandUnlinkAndSave,
          },
          {
            label: t('templateBuilder.editSyncElementActions.previewInstances'),
            command: async () => {
              displaySyncElementTemplatesModal.value = true;
            },
          },
          {
            label: t('templateBuilder.editSyncElementActions.cancel'),
            command: commandCancelModifications,
          },
        ];
      }

      return button;
    });

    const toolbarRightButtons: ComputedRef<PrimvueMenuModel[]> = computed(() => [btnSaveComputed.value]);

    const advancedCssItems = computed(() => {
      const advancedCssMediaQueries = {
        label: t('templateBuilder.toolbar.openCssPanelMediaQueries'),
        command: async () => {
          await togglePanel('cssPanelMediaQueries');
        },
      };

      const cssItems = [
        {
          label: t('templateBuilder.toolbar.openCssPanel'),
          command: async () => {
            await togglePanel('cssPanel');
          },
        },
      ];

      if (state.template) {
        const design = state.template.sections.find((section) => section.id_template_elements.toString() === state.template?.content.design.toString());
        if (design) {
          const designData = JSON.parse(design.data || '{}');
          if (!designData[HIDE_MOBILE_ATTRIBUTE]) {
            cssItems.push(advancedCssMediaQueries);
          }
        } else {
          cssItems.push(advancedCssMediaQueries);
        }
      } else {
        cssItems.push(advancedCssMediaQueries);
      }

      return cssItems;
    });

    const contentHeaderButtonsLeft = computed(() => ([
      {
        multipleType: 'buttons',
        items: [
          {
            icon: 'far fa-fw fa-info',
            class: () => `${state.leftToolbar.show.informationPanel && 'active'}`,
            tooltip: t('templateBuilder.toolbar.tooltip.information'),
            command: async () => {
              togglePanel('informationPanel', null, !selectedSyncElement.value).then(() => {
                if (selectedSyncElement.value) {
                  store.dispatch('liveEditor/prepareStructureConfiguration', selectedSyncElement.value.structureConfiguration);
                }
              });
            },
          },
          {
            icon: 'far fa-fw fa-cogs',
            class: () => `${state.leftToolbar.show.configurationPanel && 'active'}`,
            hide:
            (!state.leftToolbar.show.configurationPanel
              || isSmsTemplate(selectedTemplateType.value)
              || isPushTemplate(selectedTemplateType.value)
            ) && (!selectedSyncElement.value
              || (selectedSyncElement.value && !isPanelVisible(selectedSyncElement.value.elementType, 'configurationPanel'))
            ),
            command: () => {
              togglePanel('configurationPanel', null, !selectedSyncElement.value).then(() => {
                if (selectedSyncElement.value) {
                  store.dispatch('liveEditor/prepareStructureConfiguration', selectedSyncElement.value.structureConfiguration);
                } else {
                  removeActiveItemElementDecorations();
                  store.commit('liveEditor/resetSelectedStructure');
                }
              });
            },
          },
          {
            icon: 'far fa-fw fa-th-large',
            class: () => `${state.leftToolbar.show.widgetsPanel && 'active'}`,
            command: async () => {
              togglePanel('widgetsPanel', null, !selectedSyncElement.value).then(() => {
                if (selectedSyncElement.value) {
                  store.dispatch('liveEditor/prepareStructureConfiguration', selectedSyncElement.value.structureConfiguration);
                }
              });
            },
            hide: isSmsTemplate(selectedTemplateType.value)
              || isPushTemplate(selectedTemplateType.value)
              || state.template?.informations.imported
              || (selectedSyncElement.value && !isPanelVisible(selectedSyncElement.value.elementType, 'widgetsPanel')),
          },
          {
            icon: 'far fa-fw fa-pager',
            class: () => `${state.leftToolbar.show.loadElementsPanel && 'active'}`,
            command: () => {
              togglePanel('loadElementsPanel', null, !selectedSyncElement.value).then(() => {
                if (selectedSyncElement.value) {
                  store.dispatch('liveEditor/prepareStructureConfiguration', selectedSyncElement.value.structureConfiguration);
                }
              });
            },
            hide: (!isEmailTemplate(selectedTemplateType.value) && !isDisplayTemplate(selectedTemplateType.value))
              || state.template?.informations.imported
              || (selectedSyncElement.value !== null && !isPanelVisible(selectedSyncElement.value.elementType, 'loadElementsPanel')),
          },
          {
            icon: isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value) ? 'far fa-fw fa-th-large' : 'far fa-fw fa-paint-brush',
            class: () => `${state.leftToolbar.show.designPanel && 'active'}`,
            command: async () => {
              // If template is not SMS or Push, or if another panel is opened, we let the user click the button
              if ((!isSmsTemplate(selectedTemplateType.value) && !isPushTemplate(selectedTemplateType.value)) || !state.leftToolbar.show.designPanel) {
                if (isEmailTemplate(selectedTemplateType.value)) {
                  getPageConfig();
                }

                await togglePanel('designPanel');
              }
            },
            hide: isFacebookTemplate(selectedTemplateType.value)
              || state.template?.informations.imported
              || (selectedSyncElement.value !== null && !isPanelVisible(selectedSyncElement.value.elementType, 'designPanel')),
          },
          {
            icon: 'far fa-fw fa-text',
            class: (): string => `${state.leftToolbar.show.translationPanel && 'active'}`,
            command: async () => {
              togglePanel('translationPanel', null, !selectedSyncElement.value).then(() => {
                if (selectedSyncElement.value) {
                  store.dispatch('liveEditor/prepareStructureConfiguration', selectedSyncElement.value.structureConfiguration);
                }
              });
            },
            hide: selectedSyncElement.value !== null && !isPanelVisible(selectedSyncElement.value.elementType, 'translationPanel'),
          },
          {
            icon: 'fab fa-fw fa-css3',
            class: () => `${(state.leftToolbar.show.cssPanel || state.leftToolbar.show.cssPanelMediaQueries) && 'active'}`,
            hide: !state.template?.informations.imported
              && ((!isEmailTemplate(selectedTemplateType.value) && !isDisplayTemplate(selectedTemplateType.value)) || !advancedModeEnabled.value),
            items: advancedCssItems.value,
          },
        ],
      },
      {
        multipleType: 'buttons',
        hide: historyList.value.length === 0 || selectedSyncElement.value !== null,
        items: [
          {
            icon: 'far fa-regular fa-clock',
            class: () => `${(state.leftToolbar.show.historyPanel && 'active') || (historyList.value.length === 0 && 'disabled')}`,
            command: async () => {
              if (historyList.value.length !== 0) {
                historyList.value = await getTemplateHistoryGroupList();
                await togglePanel('historyPanel');
              }
            },
          },
          {
            icon: 'far fa-fw fa-undo',
            class: () => `${firstHistoryGroupId.value === currentHistoryGroupId.value && 'disabled'}`,
            disabled: () => firstHistoryGroupId.value === currentHistoryGroupId.value,
            command: () => {
              if (firstHistoryGroupId.value !== currentHistoryGroupId.value) {
                handlePreviousHistory();
              }
            },
          },
          {
            icon: 'far fa-fw fa-redo',
            class: () => `${lastHistoryGroupId.value === currentHistoryGroupId.value && 'disabled'}`,
            disabled: () => lastHistoryGroupId.value === currentHistoryGroupId.value,
            command: () => {
              if (lastHistoryGroupId.value !== currentHistoryGroupId.value) {
                handleNextHistory();
              }
            },
          },
        ],
      },
      {
        multipleType: 'buttons',
        hide: selectedSyncElement.value !== null,
        items: [
          {
            icon: 'far fa-fw fa-paper-plane',
            hide: isDisplayTemplate(selectedTemplateType.value) || isFacebookTemplate(selectedTemplateType.value),
            command: async () => {
              // Save template before sending a test message
              await executeTemplateSave(false, false, false, HistoryType.INTERMEDIATE_POINT_OF_SAVE);
              // say that the test email has been sent
              setTestEmailSent(true);

              updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));

              if (isPushTemplate(selectedTemplateType.value)) {
                // If push notification template, we ask for browser authorization and we sent the notification
                sendTestNotification(getTestPushNotificationData());
              } else {
                // Validate InformationPanel values
                const validation = await informationPanelSettingsValidate();
                informationPanelValidation.value = validation.validate;

                if (!validation.success) {
                  // Open InformationPanel to see errors
                  if (!state.isLeftToolbarVisible || state.leftToolbar.show.informationPanel === false) {
                    await togglePanel('informationPanel');
                  }
                } else {
                  // If email or SMS, we open the modal
                  store.commit('general/showSendTestModal');
                }
              }
            },
          },
          {
            icon: 'far fa-cog',
            hide: isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value) || isFacebookTemplate(selectedTemplateType.value)
              || state.template?.informations.imported,
            items: [
              {
                label: t('templateBuilder.toolbar.toggleAdvancedMode'),
                icon: !state.advancedModeEnabled ? 'far fa-square' : 'far fa-check-square',
                iconPos: 'left',
                command: () => {
                  toggleAdvancedMode();
                },
              },
            ],
          },
        ],
      },
      {
        multipleType: 'buttons',
        hide: isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value) || isFacebookTemplate(selectedTemplateType.value),
        items: [
          {
            icon: 'far fa-fw fa-desktop',
            class: () => `${width.value === TemplateWidth.DESKTOP && 'active'}`,
            command: () => { width.value = TemplateWidth.DESKTOP; },
          },
          {
            icon: 'far fa-fw fa-tablet',
            class: () => `${width.value === TemplateWidth.TABLET && 'active'}`,
            command: () => { width.value = TemplateWidth.TABLET; },
          },
          {
            icon: 'far fa-fw fa-mobile',
            class: () => `${width.value === TemplateWidth.MOBILE && 'active'}`,
            command: () => { width.value = TemplateWidth.MOBILE; },
          },
        ],
      },
    ]));

    const closeDuplicationModal = () => {
      showDuplicationModal.value = false;
    };

    const duplicateConfirmationDialog = (newName: string) => {
      showDuplicationModal.value = false;
      duplicateTemplate(idShop, selectedTemplateId.value, null, null, newName).then((result) => {
        if (result.success && result.id) {
          showToastSuccess(t('templates.confirm.duplicate.success.message'));
          updateSectionsInState();
          activate(result.id, '', selectedTemplateType.value);
          setRefreshTemplatesList(true);
        } else {
          showToastError(t('templates.confirm.duplicate.error.message'));
        }
      });
    };

    const changeTab = (s: any) => {
      restoreTemplateBeforeEdit();
      updateSectionsInState(false);
      activate(s.id, s.informations.name, s.type);
    };

    const tabs: ComputedRef<PrimvueMenuModel[]> = computed(() => {
      if (selectedSyncElement.value) {
        return [];
      }
      let buttons: PrimvueMenuModel[] = [];
      if (!openingFromCampaign.value) {
        buttons = state.templates.map(
          (s) => {
            const isActiveTemplate = (s.id === selectedTemplateId.value);

            return {
              label: s.informations.name,
              class: `${isActiveTemplate ? 'p-button-primary' : 'p-button-secondary'} footer-btn`,
              command: !isActiveTemplate ? () => { changeTab(s); } : undefined,
              items: [
                {
                  label: t('templateBuilder.rename'),
                  command: () => {
                    valueRenameTemplateModal.value = s.informations.name;
                    displayRenameTemplateModal.value = true;
                  },
                },
                {
                  label: t('templateBuilder.duplicate'),
                  command: () => {
                    newListName.value = t('myLists.manage.actions.cloneText') + s.informations.name;
                    showDuplicationModal.value = true;
                  },
                },
                {
                  label: t('templateBuilder.close'),
                  command: () => {
                    deactivate(selectedTemplateId.value ?? 0);
                    if (state.hasAny) {
                      activate(state.templates[state.index].id, '', state.templates[state.index].type);
                    }
                  },
                },
              ],
            };
          },
        );
        buttons.push({
          icon: 'far fa-plus',
          class: 'create-new-btn p-button-primary footer-btn',
          command: () => {
            showTemplateSelector.value = true;
          },
        });
      } else {
        const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
        const idCampaign = editingTemplateFromCampaign?.id_campaign ?? 0;
        if (idCampaign > 0) {
          buttons = state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === idCampaign).map(
            (s) => {
              const isActiveTemplate = (s.id === selectedTemplateId.value);

              return {
                label: s.informations.name,
                class: `${isActiveTemplate ? 'p-button-primary' : 'p-button-secondary'} footer-btn`,
                command: !isActiveTemplate ? () => { updateSectionsInState(false); activate(s.id, s.informations.name, s.type); } : undefined,
                items: [
                  {
                    label: t('templateBuilder.rename'),
                    command: () => {
                      valueRenameTemplateModal.value = s.informations.name;
                      displayRenameTemplateModal.value = true;
                    },
                  },
                  {
                    label: t('templateBuilder.duplicate'),
                    command: () => {
                      newListName.value = t('myLists.manage.actions.cloneText') + s.informations.name;
                      showDuplicationModal.value = true;
                    },
                  },
                  {
                    label: t('templateBuilder.close'),
                    command: () => {
                      deactivate(selectedTemplateId.value ?? 0);
                      if (state.hasAny) {
                        activate(state.templates[state.index].id, '', state.templates[state.index].type);
                      }
                    },
                  },
                ],
              };
            },
          );
        }
      }
      return buttons;
    });

    watch(() => selectedStructure.value, (values) => {
      if (values && values.type !== TemplateStructureEnum.PAGE && !isConfigurationPanelVisible.value) {
        showConfigurationPanel();
      }

      if (values && values.type !== TemplateStructureEnum.PAGE && !isLeftToolbarVisible.value) {
        togglePanel('configurationPanel');
      }
    });

    watch(() => state.leftToolbar.show, () => {
      if (state.leftToolbar.show.designPanel) { // If we show designPanel, prepare structure configuration
        prepareStructureConfiguration();
      }
    }, { deep: true });

    watch(() => state.leftToolbar.show.translationPanel, () => {
      if (!state.leftToolbar.show.translationPanel) {
        refreshAttributeInDOM(locale.value);

        // Restore original code of all smart lists
        restoreSmartListsOriginalCode();

        const defaultLanguage = getShopDefaultLang();
        updateIframeTranslations(state.template?.translations.filter((translation) => translation.language === defaultLanguage) ?? []);

        resetNeedToTranslate();

        // Store original code of lists
        refreshSmartProductsListsOriginalStructure();

        // Recalculate smart list with default language
        refreshSmartProductList();

        resetListeners();
      } else {
        // Restore original code of all smart lists
        restoreSmartListsOriginalCode();
        removeUnusedTranslations();
        fixMissingTranslations();
        sortTranslationInState();
        // Store original code of lists
        refreshSmartProductsListsOriginalStructure();

        // Recalculate smart list with default language
        refreshSmartProductList();
      }
    });

    watch(lastHistoryGroupId, async (newValue) => {
      historyList.value = await getTemplateHistoryGroupList();
      setLastHistory(newValue);
    });

    watch(() => syncElementWarning, () => {
      if (syncElementWarning.value) {
        showEditSyncElementWarning(
          confirm,
          t,
          syncElementWarning.value.elementType,
          syncElementWarning.value.acceptCallback,
          syncElementWarning.value.rejectCallback,
          syncElementWarning.value.additionalMessage ?? '',
          syncElementWarning.value.overrideMessage ?? '',
        );
      }
    }, { deep: true });

    onBeforeMount(async () => {
      historyList.value = await getTemplateHistoryGroupList();

      updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));
      if (!state.template?.configuration.currentIdTemplateElementsHistoryGroup) {
        // In case we open a template directly from database, we set the last point of save as the current history point
        const lastPointOfSave = getClosestPointOfSave(historyList.value);
        const lastHistoryPointOfSave: TemplatesElementsHistoryGroup = historyList.value[lastPointOfSave];

        if (lastHistoryPointOfSave) {
          setTemplateConfigurationKeyInState('currentIdTemplateElementsHistoryGroup', lastHistoryPointOfSave.id_template_elements_history_group);
        }
      }
      setLastHistory(currentHistoryGroupId.value);

      if (historyList.value.length === 0) {
        // Create first point of save of template if history list is empty
        await createHistory(HistoryType.POINT_OF_SAVE);
      }
    });

    onMounted(() => {
      window.addEventListener('unload', saveTemplatesInLocalStorage);
    });

    onBeforeUnmount(() => {
      store.commit('liveEditor/setSelectedSyncElement', null);
      store.commit('liveEditor/destroySortableInstances');
      saveTemplatesInLocalStorage();
      window.removeEventListener('unload', saveTemplatesInLocalStorage);
      destroyAllSortables();
    });

    const closeTemplateSelector = () => {
      showTemplateSelector.value = false;
    };

    const selectTemplateFromTemplateSelector = async (idTemplate: number, label: string, type: string) => {
      activate(idTemplate, label, type).then(() => {
        showTemplateSelector.value = false;
        store.commit('general/hideTheSpinner');
      });
    };

    /**
     * Execute functions after iframe is loaded
     */
    const iframeLoaded = async (done: Function) => {
      if (isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value)) {
        await togglePanel('designPanel');
        prepareStructureConfiguration();
      } else if (state.template?.informations.imported) {
        await togglePanel('translationPanel');
      } else if (isEmailTemplate(selectedTemplateType.value) && !state.template?.informations.imported) {
        await togglePanel('widgetsPanel');
      }
      clearPlaceholder();
      await updateUsedSyncElements(idShop);
      refreshSmartProductsListsOriginalStructure();
      done();
      if (isDisplayTemplate(selectedTemplateType.value)) {
        const design = state.template ? state.template.sections[0] : null;
        if (state.leftToolbar.show.designPanel) {
          prepareStructureConfiguration();
          store.commit('liveEditor/refreshConfigurationPanelKey');
        }
        if (design) {
          const data = JSON.parse(design.data || '{}');
          if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] && data[TEMPLATE_SYNC_ELEMENT_NAME]) {
            const template = getTemplateIframeDocument();
            if (!template) return;

            const body = template.querySelector('#spm_body');
            if (!body) return;

            body.setAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER, data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]);
            body.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, data[TEMPLATE_SYNC_ELEMENT_NAME]);
          }
        }
      }
      if (exitModeModalCallback.value) {
        await exitModeModalCallback.value();
      }
    };

    const handleInsertElement = () => {
      livePreviewKey.value += 1;
    };

    const syncElementUsedTemplatesTotal = ref(0);
    const syncElementUsedTemplatesLoaded = ref(false);

    const handleSyncElementUsedTemplatesLoaded = (total: number) => {
      syncElementUsedTemplatesTotal.value = total;
      syncElementUsedTemplatesLoaded.value = true;
    };

    const hideExitEditModeModal = () => {
      store.commit('liveEditor/setExitEditModeModalVisible', false);
      store.commit('liveEditor/setExitEditModeCallback', null);
    };

    return {
      width,
      selectedTemplateId,
      selectedTemplateName,
      selectedTemplateType,
      selectedStructure,
      selectedStructureActions,
      state,
      isLeftToolbarVisible,
      toolbarRightButtons,
      contentHeaderButtonsLeft,
      tabs,
      livePreviewKey,
      historyList,
      selectedHistory,
      displayRenameTemplateModal,
      valueRenameTemplateModal,
      displaySaveElementModal,
      typeSaveElementModal,
      nameSaveElementModal,
      syncStateSaveElementModal,
      displaySyncElementTemplatesModal,
      rename,
      handleChangeHistory,
      saveElement,
      handleSaveElementClose,
      sendTestTemplate,
      showTemplateSelector,
      closeTemplateSelector,
      selectTemplateFromTemplateSelector,
      iframeLoaded,
      isConfigurationPanelClosable,
      informationPanelValidation,
      newListName,
      t,
      showDuplicationModal,
      closeDuplicationModal,
      duplicateConfirmationDialog,
      isChooseZipFileModalVisible,
      isWidgetPanelClosable,
      isTranslationPanelClosable,
      generateSha1Hash,
      MEDIA_URL_PART_THUMB_GENERATION,
      toEraseElementId,
      subtitle,
      idShop,
      displayElementsModal,
      handleInsertElement,
      displaySaveSyncElementTemplatesModal,
      saveForAllInstancesLoading,
      saveForAllInstances,
      selectedSyncElement,
      handleSyncElementUsedTemplatesLoaded,
      syncElementUsedTemplatesTotal,
      syncElementUsedTemplatesLoaded,
      exitModeModalVisible,
      hideExitEditModeModal,
      commandSaveForAllInstances,
      commandUnlinkAndSave,
      commandCancelModifications,
    };
  },
});
