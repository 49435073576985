<template>
  <li
    v-if="fieldGroup.type"
    class="field-group"
  >
    <div
      v-if="fieldGroup.type === 'structure-edit'"
      class="field-group-control"
    >
      <StructureEdit
        :structure-config="fieldGroup.structureConfig"
        :children-count="parserValues[tabItem.label].childrenStructureCount"
      />
    </div>
    <div
      v-else-if="fieldGroup.type === 'tab-item'"
      class="tabs-field-group"
    >
      <div
        v-if="fieldGroup.isNotCollapsible"
      >
        <span v-if="fieldGroup.label !== ''">{{ t(fieldGroup.label) }}</span>
      </div>
      <button
        v-else
        class="field-group-control field-group-control--collapsible flex justify-content-between align-items-center"
        @click="toggleFieldGroup(fieldGroupIndex)"
      >
        <span>{{ t(fieldGroup.label) }}</span>
        <i
          class="far"
          :class="activeFieldGroupIndex.includes(fieldGroupIndex) ? 'fa-chevron-up' : 'fa-chevron-down'"
        />
      </button>
      <div
        class="field-group-content field-group-content-tabbed"
        :class="{ 'hidden': (!activeFieldGroupIndex.includes(fieldGroupIndex) && !fieldGroup.isNotCollapsible) }"
      >
        <TabView
          :key="`tabs_${fieldGroup.id}`"
          :multiple="true"
          :scrollable="Object.prototype.hasOwnProperty.call(fieldGroup, 'scrollable') && fieldGroup.scrollable"
        >
          <TabPanel
            v-for="(subTabItem, subIndex) of fieldGroup.tabPanel.items"
            :key="subIndex"
          >
            <template
              #header
              :title="t(subTabItem.label)"
            >
              {{ t(subTabItem.label) }}
            </template>
            <ConfigurationPanelTabContentFieldGroup
              :field-group="subTabItem"
              :tab-item="tabItem"
              :parser-values="parserValues"
              :is-synchronized-page="isSynchronizedPage"
              :header-title="headerTitle"
              @refresh="handleRefresh"
            />
          </TabPanel>
        </TabView>
      </div>
    </div>
    <div
      v-else-if="fieldGroup.type === 'fields-group'"
    >
      <div
        v-if="fieldGroup.isNotCollapsible"
      >
        <span v-if="fieldGroup.label !== ''">{{ t(fieldGroup.label) }}</span>
      </div>
      <button
        v-else
        class="field-group-control field-group-control--collapsible flex justify-content-between align-items-center"
        @click="toggleFieldGroup(fieldGroupIndex)"
      >
        <span>{{ t(fieldGroup.label) }}</span>
        <i
          class="far"
          :class="activeFieldGroupIndex.includes(fieldGroupIndex) ? 'fa-chevron-up' : 'fa-chevron-down'"
        />
      </button>
      <ConfigurationPanelTabContentFieldGroup
        :field-group="fieldGroup"
        :tab-item="tabItem"
        :parser-values="parserValues"
        :custom-class="{ 'hidden': (!activeFieldGroupIndex.includes(fieldGroupIndex) && !fieldGroup.isNotCollapsible) }"
        :is-synchronized-page="isSynchronizedPage"
        :header-title="headerTitle"
        @refresh="handleRefresh"
      />
    </div>
    <div
      v-else-if="fieldGroup.type === 'parent-fields'"
    >
      <div
        v-if="fieldGroup.isNotCollapsible"
      >
        <span v-if="fieldGroup.label !== ''">{{ t(fieldGroup.label) }}</span>
      </div>
      <button
        v-else
        class="field-group-control field-group-control--collapsible flex justify-content-between align-items-center"
        @click="toggleFieldGroup(fieldGroupIndex)"
      >
        <span>{{ t(fieldGroup.label) }}</span>
        <i
          class="far"
          :class="activeFieldGroupIndex.includes(fieldGroupIndex) ? 'fa-chevron-up' : 'fa-chevron-down'"
        />
      </button>
      <div
        class="parent-fields-content"
        :class="{ 'hidden': (!activeFieldGroupIndex.includes(fieldGroupIndex) && !fieldGroup.isNotCollapsible) }"
      >
        <ToggleMobileSettings
          v-if="tabItem.canToggleSettings && tabItem.canToggleSettings[fieldGroup.label]"
          class="mt-3 mb-3"
          :tab-item-label="tabItem.label"
          :parser-values="parserValues[tabItem.label]"
          :is-synchronized-page="isSynchronizedPage"
          :header-title="headerTitle"
          :field-group-label="fieldGroup.label"
          :tooltip-label="tabItem.canToggleSettings[fieldGroup.label].tooltipLabel"
          :label="tabItem.canToggleSettings[fieldGroup.label].label"
          @toggle-settings="handleChangeMobileSettings"
        />
        <div v-if="showFieldGroup">
          <div
            v-for="(group, indexGroup) of fieldGroup.fieldGroups"
            :key="`${fieldGroupIndex}-${indexGroup}`"
          >
            <div
              v-if="group.type === 'fields-group'"
            >
              <div class="mt-2">
                <span class="parent-fields-content-group-label">{{ t(group.label) }}</span>
              </div>
              <Divider class="parent-fields-content-divider" />
              <ConfigurationPanelTabContentFieldGroup
                :field-group="fieldGroup"
                :tab-item="tabItem"
                :parser-values="parserValues"
                :item-group="group"
                :is-synchronized-page="isSynchronizedPage"
                :header-title="headerTitle"
                @refresh="handleRefresh"
              />
            </div>
            <div
              v-else-if="group.type === 'tab-item'"
              class="tabs-field-group"
            >
              <div class="mt-2">
                <span class="parent-fields-content-group-label">{{ t(group.label) }}</span>
              </div>
              <Divider class="parent-fields-content-divider" />
              <div
                class="field-group-content field-group-content-tabbed"
              >
                <TabView
                  :key="`tabs_${group.id}`"
                  :multiple="true"
                  :scrollable="true"
                >
                  <TabPanel
                    v-for="(subTabItem, subIndex) of group.tabPanel.items"
                    :key="subIndex"
                  >
                    <template
                      #header
                      :title="t(subTabItem.label)"
                    >
                      {{ t(subTabItem.label) }}
                    </template>
                    <ConfigurationPanelTabContentFieldGroup
                      :field-group="subTabItem"
                      :tab-item="tabItem"
                      :parser-values="parserValues"
                      :sub-group-label="fieldGroup.label"
                      :is-synchronized-page="isSynchronizedPage"
                      :header-title="headerTitle"
                      @refresh="handleRefresh"
                    />
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';

import { useStore } from '@/store';

import {
  FieldsGroup,
  TabItem,
  TabSubitem,
  ParserObject,
} from '@/types';
import StructureEdit from '@/components/template-builder/fields/StructureEdit.vue';
import ToggleMobileSettings from '@/components/template-builder/fields/partials/ToggleMobileSettings.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Divider from 'primevue/divider';

import ConfigurationPanelTabContentFieldGroup from '@/components/template-builder/panels/ConfigurationPanelTabContentFieldGroup.vue';

export default defineComponent({
  name: 'ConfigurationPanelTabContentItem',

  components: {
    StructureEdit,
    TabView,
    TabPanel,
    ConfigurationPanelTabContentFieldGroup,
    Divider,
    ToggleMobileSettings,
  },

  props: {
    tabItem: {
      type: Object as PropType<TabItem | TabSubitem>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    fieldGroup: {
      type: Object as PropType<FieldsGroup | TabItem>,
      required: true,
    },

    fieldGroupIndex: {
      type: Number,
      required: true,
    },

    activeFieldGroupIndex: {
      type: Array as PropType<number[]>,
      required: true,
    },

    headerTitle: {
      type: String,
      required: false,
      default: '',
    },

    isSynchronizedPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['toggle-field-group', 'refresh'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const showFieldGroup = computed(() => props.parserValues[props.tabItem.label].toggleItemsDisplay?.[props.fieldGroup.label]?.value ?? true);

    const toggleFieldGroup = (index: number) => {
      emit('toggle-field-group', index);
    };

    const handleRefresh = () => {
      emit('refresh');
    };

    const handleChangeMobileSettings = (data: { tabItemLabel: string; fieldGroupLabel: string; value: boolean }) => {
      store.dispatch('liveEditor/toggleSetting', data);
    };

    return {
      t,
      showFieldGroup,

      toggleFieldGroup,
      handleRefresh,
      handleChangeMobileSettings,
    };
  },
});
</script>

<style lang="scss" scoped>
.field-group:not(:last-child) {
  margin-bottom: .5rem;
}

.configuration-wrapper {
  & .p-panel {
    & > .p-toggleable-content {
      & .p-panel-content {
        & .field-group {
            & .field-group-control {
              text-align: left;
              background-color: $shuttle-light-grey !important;

              &--collapsible:deep() {
                width: 100%;
                border: 0 none;
                cursor: pointer;
                background-color: #fbfbfb !important;
                border-bottom: 1px solid #e9ecef;
              }
            }
          }
      }
    }
  }

  .tabs-field-group:deep() {
    & .p-tabview {
      margin: 5px 0;

      & .p-tabview-nav {
        font-size: 0.875rem;
        line-height: 1.0625rem;
        white-space: nowrap;

        & li {
          flex-grow: unset !important;

          & .p-tabview-nav-link {
            text-transform: none;
            font-weight: bold !important;
            color: $tuna !important;
            padding: 12px;
          }
        }
      }
    }

    & .p-tabview-panels {
      padding: 0;

      & .field-group-content {
        padding: 0;
      }
    }
  }
}

.parent-fields-content {
  padding: 0 1.5rem;
}
.parent-fields-content-divider {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0 !important;
}
.parent-fields-content-group-label {
  color: #6c757d;
  font-size: 0.95rem;
}
</style>
