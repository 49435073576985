/* eslint-disable no-param-reassign */
import {
  computed, reactive, readonly, watch, nextTick,
} from 'vue';
// eslint-disable-next-line import/no-cycle
import {
  Maybe,
  OperatorType,
  TemplateAndTemplateElementsUpdateInput,
  Templates,
  TemplatesElementsInputItem,
  TemplatesParts,
  TemplatesPartsInputItem,
  TemplatesPartsUpdateInputItem,
  TemplatesUpdateInputItem,
  TemplatesInputItem,
  TemplatesLangInputItem,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {
  asInt,
  br2nl,
  createDomFromString,
  getFirstParent,
  getFirstParentWithId,
  getParents,
  showToastError,
  showToastSuccess,
  generateSha1Hash,
} from '@/helpers';
// eslint-disable-next-line import/no-cycle
import {
  CustomMutation,
  Delete,
  Get,
  Insert,
  List,
  ListSettings,
  Update,
  Request,
} from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  Configuration,
  Content,
  HistoryType,
  Property,
  Section,
  SmartLists,
  StringMap,
  TemplateInformations,
  TemplateParentTypeEnum,
  TemplatePart,
  TemplateStructureEnum,
  Translation,
  TypeCampaignEnum,
} from '@/types';
import {
  TemplateWidth,
} from '@/types/enums';
// eslint-disable-next-line import/no-cycle
import {
  addSpmElementId,
  addTranslation, createNewStylesheet,
  formatStylesForSection,
  formatTranslationsForSection,
  getElementFromIframe,
  getTemplateIframeDocument,
  getTemplateParentTypeByType,
  getWidgetType,
  isDisplayTemplate,
  isEmailTemplate,
  isFacebookTemplate,
  isPushTemplate,
  replaceTranslationsByKey,
  sanitizeSection,
  addSelectorToRules,
  getBase64FromUrl,
  isElementVisible,
  clearPlaceholder,
  getCssOfElement,
  getTranslationIdsFromElement,
  generateUniqStructureId,
  getSectionParent,
} from '@/components/template-builder/utils/helpers';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
// eslint-disable-next-line import/no-cycle
import { getShopDefaultLang, UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import { duplicateElement } from '@/components/template-builder/utils/action-buttons-handlers';
import { Md5 } from 'ts-md5';
// eslint-disable-next-line import/no-cycle
import { getAttributeByName, getContent, getCssPropertyByName } from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import { i18n } from '@/i18n';
import {
  BUILDER_ELEMENT_ACTIVE_CLASS,
  BUILDER_ELEMENT_HOVER_CLASS,
  BUILDER_EMBED_REPLACED_ELEMENT_CLASS,
  BUILDER_SORTABLE_CHOSEN_CLASS,
  BUILDER_SORTABLE_DRAG_CLASS,
  BUILDER_SORTABLE_GHOST_CLASS, AI_IMAGE_MAX_HEIGHT, AI_IMAGE_MAX_WIDTH,
  PERCENTAGE_WIDTH_ATTRIBUTE,
  PRODUCTS_LISTS_CLASSES,
  TEMPLATE_SECTION_IDENTIFIER,
  TEMPLATE_WIDGET_IDENTIFIER,
  TRANSLATION_ATTRIBUTE,
  VARIABLES_V3_TO_V4,
  WIDGET_DRAGGABLE_CLASS,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
  CUSTOM_WIDGET_CSS_PREFIX,
  TRANSLATION_MARKUP,
  MEDIA_URL_PART_RELOAD_CACHE,
  TEMPLATE_SECTION_CLASS,
  TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
  TEMPLATE_SYNC_ELEMENT_NAME,
  TEMPLATE_SYNC_ELEMENT_CLASS,
  TEMPLATE_SYNC_ELEMENT_IDs,
  TEMPLATE_COLUMN_IDENTIFIER,
  HIDE_MOBILE_ATTRIBUTE,
} from '@/components/template-builder/utils/constants';
// eslint-disable-next-line import/no-cycle
import {
  addOrUpdateDynamicStyle,
  computeActiveSection, getDynamicStylesForSelector,
  getTemplateWidgets, removeDynamicStyle, removeDynamicStyleWithoutPseudoClasses,
} from '@/components/template-builder/utils/parser';
import { GetShopsConfigurationList } from '@/composables/shop/ShopsConfiguration';
// eslint-disable-next-line import/no-cycle
import { rawPrivacyPolicy } from '@/components/template-builder/config/templates/popup/raw-html-config';
// eslint-disable-next-line import/no-cycle
import { rawMandatoryVariablesWidget } from '@/components/template-builder/config/templates/email/raw-html-config';
// eslint-disable-next-line import/no-cycle
import {
  destroyIframeSortables,
  resetListeners,
} from '@/components/template-builder/utils/listeners';
// eslint-disable-next-line import/no-cycle
import { RAW_PLACEHOLDER_STRUCTURE_MAP } from '@/components/template-builder/utils/raw-html-templates';
// eslint-disable-next-line import/no-cycle
import {
  translateDocument,
  updateTranslation,
  generateTranslations,
  createTranslationIdentifier,
} from '@/components/template-builder/utils/translate';
// eslint-disable-next-line import/no-cycle
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
// eslint-disable-next-line import/no-cycle
import { store } from '@/store';
import { getLocalStorageElement, removeLocalStorageElement, setLocalStorageElement } from '@/helpers/LocalStorage';
// eslint-disable-next-line import/no-cycle
import { removeActiveItemElementDecorations } from '@/components/template-builder/utils/active-item-builder';
import { AutomatedScenarioState, getEditingTemplateFromCampaign } from '@/composables/AutomatedScenarios';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { maxLength, minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import CryptoJS from 'crypto-js';
import { loadImage } from '@/composables/loadImage';
import { retrieveServicesData } from '@/composables/shop/MyShopParameters';
import { ZipFile } from '@/types/zip-file-uploader-types';
import { uploadFileToMedia } from '@/composables/configs/configuration';
import beautifier from 'js-beautify';
// eslint-disable-next-line import/no-cycle
import { setSmartListImageWidth } from '@/components/template-builder/setters';

import { TemplateTypeEnum } from '@/composables/shop/Templates';
import axios from 'axios';

import * as cheerio from 'cheerio';
import { getDefaultPanel } from '@/components/template-builder/config/sync-elements-config';
import { customDataDisplay } from '@/components/template-builder/utils/elements-save-definitions';

export interface TemplateRaw {
  id_template: number;
  id_campaign: number | null;
  created_from_campaign: string | null;
  template_config: string;
  label?: string;
  date_modification?: string | null;
}

interface TemplateLangRaw {
  id_template: number;
  lang: string;
  subject: string;
  from_email: string;
  from_name: string;
}

interface ListResponse<T> {
  items: Array<T>;
  total: number;
  err: string;
}

export class Template {
  id: number;

  type: string;

  idCampaign: number | null;

  createdFromCampaign: string | null;

  informations: TemplateInformations;

  content: Content;

  sections: Section[];

  translations: Translation[];

  configuration: Configuration;

  activeSection: string | number;

  smartLists: SmartLists;

  dateModification: string | null;

  constructor(id = 0, type = '', idCampaign: number | null = null, createdFromCampaign: string | null = null, informations = {
    name: '', lang: '',
  }, config: Content = { design: 0, sections: [] }, sections: Section[] = [], templateConfig = {}, dateModification: string | null = null) {
    this.id = id;
    this.type = type;
    this.idCampaign = idCampaign;
    this.createdFromCampaign = createdFromCampaign;
    this.informations = informations;
    this.content = config;
    this.sections = sections;
    this.configuration = templateConfig;
    this.translations = [];
    this.sections.forEach((section) => {
      const sectionTranslation = JSON.parse((section.translations && section.translations !== '' ? section.translations : '{}').replace(/(\r\n|\n|\r)/gm, ' '));
      if (sectionTranslation) {
        Object.keys(sectionTranslation).forEach((language: string) => {
          Object.keys(sectionTranslation[language]).forEach((key: string) => {
            this.translations.push(...(addTranslation(sectionTranslation[language], key, section.id_template_elements, language, type) ?? []));
          });
        });
      }
      const sectionData = JSON.parse(section.data || '{}');
      if (Array.isArray(sectionData) && sectionData.length === 0) {
        section.data = '';
      }
    });
    this.activeSection = -1;
    this.smartLists = {};
    this.dateModification = dateModification;
  }
}

interface ToolbarPanels {
  informationPanel: boolean;
  translationPanel: boolean;
  designPanel: boolean;
  configurationPanel: boolean;
  historyPanel: boolean;
  cssPanel: boolean;
  cssPanelMediaQueries: boolean;
  loadElementsPanel: boolean;
  widgetsPanel: boolean;
}

interface LeftToolbarState {
  show: ToolbarPanels;
  hasChanged: boolean;
  needToTranslate: boolean;
}

interface State {
  isTemplateEditorVisible: boolean;
  isLeftToolbarVisible: boolean;
  isConfigurationPanelVisible: boolean;
  lastRollbackId: number;
  leftToolbar: LeftToolbarState; // which tabs are visible in left toolbar
  hasAny: boolean;
  templates: Array<Template>;
  templatesLinkedToCampaign: Array<Template>; // array of templates linked to campaign
  index: number;
  template: Template | null;
  intervals: Record<string, number | null> | null;
  advancedModeEnabled: boolean;
  refreshTemplatesList: boolean;
  isColumnWidgetModalVisible: boolean;
  isLoadElementsModalVisible: boolean;
  loadElementsType: string[];
  testEmailSent: boolean;
  templateWidth: string;
  selectedTranslationId: string;
  showConfigurationPanelCallback: Function | null;
  sectionsToRefresh: number[];
}

const state = reactive<State>({
  isTemplateEditorVisible: false,
  isLeftToolbarVisible: false,
  isConfigurationPanelVisible: false,
  lastRollbackId: 0,
  leftToolbar: {
    show: {
      informationPanel: false,
      translationPanel: false,
      designPanel: false,
      configurationPanel: false,
      historyPanel: false,
      cssPanel: false,
      cssPanelMediaQueries: false,
      loadElementsPanel: false,
      widgetsPanel: false,
    },
    hasChanged: false,
    needToTranslate: false,
  },
  hasAny: false,
  templates: [],
  templatesLinkedToCampaign: [],
  index: -1,
  template: null,
  intervals: null,
  advancedModeEnabled: false,
  refreshTemplatesList: false,
  isColumnWidgetModalVisible: false,
  isLoadElementsModalVisible: false,
  testEmailSent: false,
  templateWidth: TemplateWidth.DESKTOP,
  selectedTranslationId: '',
  showConfigurationPanelCallback: null,
  sectionsToRefresh: [],
  loadElementsType: [],
});

let translator: any;
(async () => {
  translator = await i18n;
})();

export const TemplateEditorState = readonly(state);

const getTemplatesSavedInLocalStorage = () => {
  // Get templates from local storage if exists
  const templatesSavedInLocalStorage = JSON.parse(getLocalStorageElement('templates') ?? '{}');
  if (Object.keys(templatesSavedInLocalStorage).length > 0) {
    state.templates = templatesSavedInLocalStorage.templates.map((template: Template) => new Template(
      template.id,
      template.type,
      template.idCampaign,
      template.createdFromCampaign,
      template.informations,
      template.content,
      template.sections,
      template.configuration,
      template.dateModification,
    ));
    state.hasAny = true;
    state.index = templatesSavedInLocalStorage.index;
    // eslint-disable-next-line prefer-destructuring
    state.template = state.templates[templatesSavedInLocalStorage.index];
  }
  const elementSyncCurrentTemplate = JSON.parse(getLocalStorageElement('elementSyncCurrentTemplate') ?? '{}');
  if (elementSyncCurrentTemplate && elementSyncCurrentTemplate.template) {
    const templateIndex = state.templates.findIndex((t) => t.id === elementSyncCurrentTemplate.template.id);
    if (templateIndex > -1) {
      state.templates[templateIndex] = new Template(
        elementSyncCurrentTemplate.template.id,
        elementSyncCurrentTemplate.template.type,
        elementSyncCurrentTemplate.template.idCampaign,
        elementSyncCurrentTemplate.template.createdFromCampaign,
        elementSyncCurrentTemplate.template.informations,
        elementSyncCurrentTemplate.template.content,
        elementSyncCurrentTemplate.template.sections,
        elementSyncCurrentTemplate.template.configuration,
        elementSyncCurrentTemplate.template.dateModification,
      );
      state.index = elementSyncCurrentTemplate.index;
      state.template = state.templates[templateIndex];
      state.hasAny = true;
    }
  }
  const templatesLinkedToCampaignSavedInLocalStorage = JSON.parse(getLocalStorageElement('templatesLinkedToCampaign') ?? '{}');
  if (Object.keys(templatesLinkedToCampaignSavedInLocalStorage).length > 0) {
    state.templatesLinkedToCampaign = templatesLinkedToCampaignSavedInLocalStorage.templates.map((template: Template) => new Template(
      template.id,
      template.type,
      template.idCampaign,
      template.createdFromCampaign,
      template.informations,
      template.content,
      template.sections,
      template.configuration,
      template.dateModification,
    ));
  }
};

watch(
  [() => UserState.isAuthenticated, () => UserState.activeShop?.id],
  () => {
    // wait for user to be authenticated and active shop to be set
    if (UserState.isAuthenticated && UserState.activeShop?.id) {
      getTemplatesSavedInLocalStorage();
    }
  },
);

// Return array of HTMLStyleElement for active template split by section and type
export const getActiveTemplateStylesheets = (elementType = ''): HTMLStyleElement[] => {
  const sections = elementType ? state.template?.sections.filter((section) => section.type === elementType) : state.template?.sections;
  const stylesheets = sections?.reduce((acc: HTMLStyleElement[], section: Section) => {
    const sectionStyles = section.css != null ? JSON.parse(section.css.replace(/(\r\n|\n|\r|<br>)/gm, ' ')) : [];
    const sectionData = JSON.parse(section.data || '{}');
    if (Object.keys(sectionStyles).length > 0) {
      acc.push(...Object.keys(sectionStyles).map((styleType: string) => {
        const s = document.createElement('style');

        // If styles are for mobile devices, we add the media query rule if it's not in the CSS rule
        if (new RegExp('_mobile$').test(styleType) && !(new RegExp('@media')).test(sectionStyles[styleType])) {
          s.innerHTML = `@media only screen and (max-width: 599px) { ${sectionStyles[styleType]} }`;
        } else {
          s.innerHTML = sectionStyles[styleType];
        }

        if (sectionData[HIDE_MOBILE_ATTRIBUTE] && new RegExp('_mobile$').test(styleType)) {
          s.innerHTML = `/*${HIDE_MOBILE_ATTRIBUTE} ${s.innerHTML} ${HIDE_MOBILE_ATTRIBUTE}*/`;
        }

        s.setAttribute('data-spm-styles', styleType);
        s.setAttribute('data-spm-section', section.id_template_elements.toString());
        s.setAttribute('type', 'text/css');
        return s;
      }));
    }
    return acc;
  }, []) ?? [];

  return stylesheets;
};

// Return full html document for active template
export const getActiveTemplateFullHtml = (): string => {
  if (state.template?.type === TemplateParentTypeEnum.EMAIL) {
    // First get design
    const design = state.template?.sections.filter((section: Section) => section.id_template_elements === state.template?.content.design)[0].html;
    const sections = state.template?.sections
      .filter((section: Section) => state.template?.content.sections.includes(section.id_template_elements)
        || state.template?.content.sections.includes(section.id_template_elements.toString()))
      .sort((a: Section, b: Section) => {
        if (
          state.template
          && state.template.content.sections.indexOf(a.id_template_elements.toString()) > state.template.content.sections.indexOf(b.id_template_elements.toString())
        ) return 1;
        return -1;
      })
      .map((section: Section) => addSpmElementId(section).html);
    return design.replace('{SECTIONS}', `<spm-sections>${sections.join(' ')}</spm-sections>`);
  }
  return state.template?.sections
    .map((section: Section) => addSpmElementId(section).html)
    .join(' ') ?? '';
};

function closePanels() {
  // Check if we need to reapply translations when closing a panel
  let needToTranslate = false;

  // Check if at least one panel is opened and open designPanel in case of SMS or Push notification
  let onePanelIsOpened = false;

  if (state.leftToolbar.show.translationPanel) {
    needToTranslate = true;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(state.leftToolbar.show)) {
    state.leftToolbar.show[key as keyof ToolbarPanels] = false;
  }

  let resetStructure = true;

  if (getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.SMS
    || getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.PUSHNOTIFICATIONS) {
    state.leftToolbar.show.designPanel = true;
    onePanelIsOpened = true;
  } else if (getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.EMAIL && !state.template?.informations.imported) {
    const selectedSyncElement = store.getters['liveEditor/getSelectedSyncElement'];
    if (!selectedSyncElement) {
      state.leftToolbar.show.widgetsPanel = true;
    } else {
      const defaultPanel = getDefaultPanel(selectedSyncElement.elementType);
      state.leftToolbar.show[defaultPanel as keyof ToolbarPanels] = true;
      if (defaultPanel === 'configurationPanel') {
        resetStructure = false;
      }
    }
    onePanelIsOpened = true;
  } else if (state.template?.informations.imported) {
    state.leftToolbar.show.translationPanel = true;
    onePanelIsOpened = true;
  }

  if (!state.isTemplateEditorVisible) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(state.leftToolbar.show)) {
      state.leftToolbar.show[key as keyof ToolbarPanels] = false;
    }
    onePanelIsOpened = false;
  }

  state.isLeftToolbarVisible = onePanelIsOpened;
  state.leftToolbar.needToTranslate = needToTranslate;

  return resetStructure;
}

const formatDesignHtml = (design: Element, sections: Element, designId: string, syncElementId = '', syncElementName = ''): string => {
  // Remove useless elements from design
  // eslint-disable-next-line no-unused-expressions
  design.querySelectorAll('title, meta')?.forEach((item) => item.remove());

  if (!syncElementId && !syncElementName) {
    const structure = '<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd">'
      + '<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">'
      + '<head>'
      + '<title>{var=template.subject}</title>'
      + '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">'
      + '<meta name="viewport" content="width=device-width">'
      + '<meta name="format-detection" content="telephone=no">'
      + '<!--[if mso]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->'
      + '</head>'
      + '<body data-spmelementid="{DESIGN_ID}">'
      + '{SPM_TEMPLATE}'
      + '</body>'
      + '</html>';

    // We format design element to include doctype, metas, and structure
    return structure.replace('{DESIGN_ID}', designId).replace('{SPM_TEMPLATE}', design.innerHTML.replace(sections.outerHTML, '{SECTIONS}'));
  }
  const structure = '<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd">'
      + '<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">'
      + '<head>'
      + '<title>{var=template.subject}</title>'
      + '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">'
      + '<meta name="viewport" content="width=device-width">'
      + '<meta name="format-detection" content="telephone=no">'
      + '<!--[if mso]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->'
      + '</head>'
      + `<body data-spmelementid="{DESIGN_ID}" ${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="{SYNC_ELEMENT_ID}" ${TEMPLATE_SYNC_ELEMENT_NAME}="{SYNC_ELEMENT_NAME}">`
      + '{SPM_TEMPLATE}'
      + '</body>'
      + '</html>';
  // We format design element to include doctype, metas, and structure
  return structure
    .replace('{DESIGN_ID}', designId)
    .replace('{SYNC_ELEMENT_ID}', syncElementId)
    .replace('{SYNC_ELEMENT_NAME}', syncElementName)
    .replace('{SPM_TEMPLATE}', design.innerHTML.replace(sections.outerHTML, '{SECTIONS}'));
};

// TODO: element sync state is going to be retrieved from state, not in the HTML attributes anymore
const getElementSyncIfExists = ($: cheerio.CheerioAPI, identifier: string) => {
  const syncElementAttributeName = 'data-spm-element-sync-id';
  const syncedElements = $(`[${syncElementAttributeName}]`);

  const elementSync: { id: string | undefined; type: string | null } = { id: '', type: null };

  syncedElements.each((_, syncedElement) => {
    const cheerioSyncedElement = $(syncedElement);
    const foundTranslationElementInside = cheerioSyncedElement.find(identifier).length > 0;
    if (foundTranslationElementInside || cheerioSyncedElement.attr(identifier)) {
      elementSync.id = cheerioSyncedElement.attr(syncElementAttributeName);
      elementSync.type = cheerioSyncedElement.hasClass(TEMPLATE_SECTION_CLASS) ? TemplateStructureEnum.SECTION : TemplateStructureEnum.WIDGET;
    }
  });

  return elementSync;
};

export const sortTranslationInState = () => {
  const template = getTemplateIframeDocument();
  if (template) {
    const matches = template.body.innerHTML.match(/LANG_([a-z0-9]+)\.?\w*/g);
    if (matches && matches.length) {
      const extractedKeys = matches.map((str) => str.replace('LANG_', '')).filter(Boolean) as string[];

      if (state.template) {
        state.template.translations = state.template.translations.sort((a, b) => extractedKeys.indexOf(a.key) - extractedKeys.indexOf(b.key));
      }
    }
    // Add group to translations
    const $ = cheerio.load(template.body.innerHTML);
    const customKeyName = 'custom-key-for-group';
    if (state.template) {
      state.template.translations.forEach((translation) => {
        const { key, fieldType } = translation;
        const elementIdentifier = `[id="LANG_${key}"]`;

        const elementSync = getElementSyncIfExists($, elementIdentifier);

        if (elementSync.id) {
          translation.syncGroupId = `${elementSync.type}.${elementSync.id}`;
        }

        let isMarkup = false;
        const keySplitted = key.split('.');
        isMarkup = (keySplitted.length > 1 && keySplitted[1] === 'text')
          || (keySplitted.length === 1 && (fieldType === 'html' || fieldType === 'text' || fieldType === 'textarea'));
        if (isMarkup) {
          const element = $(elementIdentifier);
          if (element.length) {
            const parent = element.parent().eq(0);
            if (parent) {
              let groupKey = createTranslationIdentifier();
              if (parent.parent() && parent.parent().attr(customKeyName)) {
                parent.attr(customKeyName, parent.parent().attr(customKeyName));
              }
              if (parent.attr(customKeyName) === undefined) {
                parent.attr(customKeyName, groupKey);
              } else {
                groupKey = parent.attr(customKeyName) as string;
              }
              translation.groupKey = groupKey;
            }
            const htmlElement = template.body.querySelector(elementIdentifier);
            if (htmlElement && state.template && state.template.type !== TemplateTypeEnum.PUSHNOTIFICATIONS) {
              const isVisible = isElementVisible(htmlElement);
              translation.hidden = !isVisible;
            }
            // TODO : SYNC ELEMENT WARNING
            const section = element.closest(`.${TEMPLATE_SECTION_CLASS}`);
            if (section && section.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER)) {
              translation.syncElement = {
                id: section.attr('id') ?? '',
                type: TemplateStructureEnum.SECTION,
                syncElementId: section.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER) ?? '',
              };
            } else {
              const widget = element.closest('[data-widgettype]');
              if (widget && widget.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER)) {
                translation.syncElement = {
                  id: widget.attr('id') ?? '',
                  type: TemplateStructureEnum.WIDGET,
                  syncElementId: section.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER) ?? '',
                };
              }
            }
          }
        } else {
          let attributeIdentifier = '';
          let attributeName = '';
          const attributeFieldTypes: Record<string, any> = {
            link: 'href',
            image: 'src',
            placeholder: 'placeholder',
            'data-redirect': 'data-redirect',
          };
          if (keySplitted.length > 1) {
            attributeIdentifier = `[data-spmtranslationid-${keySplitted[1]}="LANG_${key}"]`;
          } else if (keySplitted.length === 1 && attributeFieldTypes[fieldType]) { // Some translations in model don't have the type in the key
            attributeIdentifier = `[data-spmtranslationid-${attributeFieldTypes[fieldType]}="LANG_${key}"]`;
          } else if (keySplitted.length === 1 && fieldType === 'attribute') {
            const regex = new RegExp(`${TRANSLATION_ATTRIBUTE}-[^=]*="LANG_${key}"`, 'gm');
            const attributeNameRegex = new RegExp(`${TRANSLATION_ATTRIBUTE}-([^=]*)="LANG_${key}"`);
            const attribute = $('body').html()?.match(regex);
            const attributeNames = $('body').html()?.match(attributeNameRegex);
            if (attribute && attribute.length) {
              attributeIdentifier = `[${attribute[0]}]`;
              if (attributeNames && attributeNames.length > 1) {
                // eslint-disable-next-line prefer-destructuring
                attributeName = attributeNames[1];
              }
            }
          }

          const attributeSync = getElementSyncIfExists($, attributeIdentifier);
          if (attributeSync) {
            translation.syncGroupId = `${attributeSync.type}.${attributeSync.id}`;
          }

          let element = $(attributeIdentifier);
          if (element.length) {
            element = element.eq(0);
            let groupKey = createTranslationIdentifier();
            if (element.parent() && element.parent().attr(customKeyName)) {
              element.attr(customKeyName, element.parent().attr(customKeyName));
            }
            if (element.attr(customKeyName) === undefined) {
              element.attr(customKeyName, groupKey);
            } else {
              groupKey = element.attr(customKeyName) as string;
            }
            translation.groupKey = groupKey;
            if (attributeName) {
              translation.attributeName = attributeName;
            }
            // Check if visible
            const htmlElement = template.body.querySelector(attributeIdentifier);
            if (htmlElement && state.template && state.template.type !== TemplateTypeEnum.PUSHNOTIFICATIONS) {
              const isVisible = isElementVisible(htmlElement);
              translation.hidden = !isVisible;
            }
            // TODO : SYNC ELEMENT WARNING
            const section = element.closest(`.${TEMPLATE_SECTION_CLASS}`);
            if (section && section.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER)) {
              translation.syncElement = {
                id: section.attr('id') ?? '',
                type: TemplateStructureEnum.SECTION,
                syncElementId: section.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER) ?? '',
              };
            } else {
              const widget = element.closest('[data-widgettype]');
              if (widget && widget.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER)) {
                translation.syncElement = {
                  id: widget.attr('id') ?? '',
                  type: TemplateStructureEnum.WIDGET,
                  syncElementId: section.attr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER) ?? '',
                };
              }
            }
          }
        }
      });
    }
  }
};

// Update html, css and translations properties is sections objects
export const executeUpdateSectionsInState = () => {
  const template = getTemplateIframeDocument();
  if (state.template && template) {
    const sections = [];
    // Update design
    const design = template.querySelector('spm-template');
    const spmSections = design?.querySelector('spm-sections');
    if (design && spmSections) {
      sections.push(...state.template.sections
        .filter((current: Section) => current.id_template_elements.toString() === state.template?.content.design.toString())
        .map((current: Section) => {
          const syncElementId = template.body.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
          const syncElementName = template.body.getAttribute(TEMPLATE_SYNC_ELEMENT_NAME);
          const returnValue: Section = {
            ...current,
            html: formatDesignHtml(design, spmSections, current.id_template_elements.toString(), syncElementId ?? '', syncElementName ?? ''),
            css: formatStylesForSection(Array.from(template.querySelectorAll(`style[data-spm-section="${state.template?.content.design}"]`))),
            translations: formatTranslationsForSection(state.template?.translations
            .filter((translation: Translation) => translation.section.toString() === current.id_template_elements.toString()) ?? []),
            type: 'design',
          };
          if (syncElementId && syncElementName) {
            const data = {
              [TEMPLATE_SYNC_ELEMENT_IDENTIFIER]: syncElementId,
              [TEMPLATE_SYNC_ELEMENT_NAME]: syncElementName,
            };
            returnValue.data = JSON.stringify(data);
          }

          const data = JSON.parse(returnValue.data || '{}');
          const designElement = template.querySelector(`[data-spmelementid="${state.template?.content.design}"]`) as HTMLElement;
          if (designElement && designElement.getAttribute(HIDE_MOBILE_ATTRIBUTE)) {
            data[HIDE_MOBILE_ATTRIBUTE] = true;
          } else {
            delete data[HIDE_MOBILE_ATTRIBUTE];
          }
          returnValue.data = JSON.stringify(data);

          return returnValue;
        }));
    }
    if (design && !spmSections) {
      sections.push(...state.template.sections
        .filter((current: Section) => current.id_template_elements.toString() === state.template?.content.design.toString())
        .map((current: Section) => ({
          ...current,
          translations: formatTranslationsForSection(state.template?.translations
            .filter((translation: Translation) => translation.section.toString() === current.id_template_elements.toString()) ?? []),
          type: 'design',
        })));
    }

    // Update sections
    state.template.content.sections.forEach((sectionId: string | number) => {
      const section = template.querySelector(`[data-spmelementid="${sectionId}"]`) as HTMLElement;

      if (section) {
        const newSection = sanitizeSection(section, true);
        if (state.template && newSection) {
          sections.push(...state.template.sections
            .filter((current: Section) => current.id_template_elements.toString() === sectionId.toString())
            .map((current: Section) => {
              const synchronizedWidgets = section.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}]`);
              const returnValue: Section = {
                ...current,
                html: replaceTranslationsByKey(newSection.outerHTML, state.template?.translations ?? []),
                css: formatStylesForSection(Array.from(template.querySelectorAll(`style[data-spm-section="${sectionId}"]`))),
                translations: formatTranslationsForSection(state.template?.translations
                  .filter((translation: Translation) => translation.section.toString() === current.id_template_elements.toString()) ?? []),
                data: section.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER)
                  ? JSON.stringify({ [TEMPLATE_SYNC_ELEMENT_IDENTIFIER]: section.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER) }) : current.data,
                type: 'section',
              };
              // Update sync widgets ids
              if (synchronizedWidgets.length) {
                const data = JSON.parse(returnValue.data || '{}');
                if (!data[TEMPLATE_SYNC_ELEMENT_IDs]) {
                  data[TEMPLATE_SYNC_ELEMENT_IDs] = [];
                }
                Array.from(synchronizedWidgets).forEach((synchronizedWidget) => {
                  if (!data[TEMPLATE_SYNC_ELEMENT_IDs].find((item: string) => item === synchronizedWidget.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER))) {
                    data[TEMPLATE_SYNC_ELEMENT_IDs].push(synchronizedWidget.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER));
                  }
                });
                returnValue.data = JSON.stringify(data);
              } else {
                const data = JSON.parse(returnValue.data || '{}');
                if (data[TEMPLATE_SYNC_ELEMENT_IDs]) {
                  delete data[TEMPLATE_SYNC_ELEMENT_IDs];
                }
                returnValue.data = JSON.stringify(data);
              }

              const syncElementId = section.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
              const syncElementName = section.getAttribute(TEMPLATE_SYNC_ELEMENT_NAME);
              if (syncElementId && syncElementName) {
                const returnValueData = JSON.parse(returnValue.data || '{}');
                returnValueData[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] = syncElementId;
                returnValueData[TEMPLATE_SYNC_ELEMENT_NAME] = syncElementName;
                returnValue.data = JSON.stringify(returnValueData);
              }
              return returnValue;
            }));
        }
      }
    });
    state.template.sections = sections;

    nextTick().then(() => {
      // Sort translations
      sortTranslationInState();
    });
  }
};

let updateSectionsTimeout: ReturnType<typeof setTimeout> | null = null;

// Update html, css, and translations properties in sections objects
export const updateSectionsInState = (useTimeout = true) => {
  if (useTimeout) {
    if (updateSectionsTimeout) {
      clearTimeout(updateSectionsTimeout);
    }
    updateSectionsTimeout = setTimeout(() => {
      executeUpdateSectionsInState();
      updateSectionsTimeout = null; // Reset timeout reference
    }, 300); // Ajustez le délai selon vos besoins
  } else {
    // Exécute immédiatement sans timeout
    executeUpdateSectionsInState();
  }
};

export async function hideLeftToolbar(e = null, force = false, name = '', removeDecorations = true) {
  // Trigger history save before hiding the panels
  let historyType: HistoryType | null = null;

  if (state.leftToolbar.hasChanged) {
    if (state.leftToolbar.show.cssPanel) {
      historyType = HistoryType.ADVANCED_CSS_STATIC_CHANGE;
    } else if (state.leftToolbar.show.cssPanelMediaQueries) {
      historyType = HistoryType.ADVANCED_CSS_STATIC_MOBILE_CHANGE;
    } else if (state.leftToolbar.show.configurationPanel) {
      // Create history for design or element (section, line, column, widget) updates
      const activeItemData = store.getters['liveEditor/getActiveItemData'];

      if (activeItemData) {
        switch (activeItemData.type) {
          case TemplateStructureEnum.WIDGET:
            historyType = HistoryType.WIDGET_CHANGE;
            break;
          case TemplateStructureEnum.COLUMN:
            historyType = HistoryType.COLUMN_CHANGE;
            break;
          case TemplateStructureEnum.LINE:
            historyType = HistoryType.ROW_CHANGE;
            break;
          case TemplateStructureEnum.SECTION:
            historyType = HistoryType.SECTION_CHANGE;
            break;
          default:
            break;
        }
      }
    }
  }

  if (historyType) {
    // Update state
    updateSectionsInState(false);

    if (!store.getters['liveEditor/getSelectedSyncElement']) {
      await createHistory(historyType);
    }
  }

  if (!force) {
    const resetStructure = closePanels();
    if (resetStructure) {
      store.commit('liveEditor/resetSelectedStructure');
    }
  } else if (force && name) {
    if (!state.leftToolbar.show[name as keyof ToolbarPanels] || (name === 'configurationPanel' && e)) {
      state.leftToolbar.show[name as keyof ToolbarPanels] = true;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(state.leftToolbar.show)) {
        if (key !== name) {
          state.leftToolbar.show[key as keyof ToolbarPanels] = false;
        }
      }
      state.isLeftToolbarVisible = true;
    } else {
      closePanels();
    }
  }
  if (removeDecorations) {
    removeActiveItemElementDecorations();
  }
}

const delay = (ms: number) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const resetTemplateToDesktop = async () => {
  if (state.templateWidth !== TemplateWidth.DESKTOP) {
    state.templateWidth = TemplateWidth.DESKTOP;

    // Delay 1s because of the transition css
    await delay(1000);
  }
};

export async function togglePanel(name: keyof ToolbarPanels, e: any = null, removeDecorations = true) {
  store.commit('liveEditor/resetSelectedStructure');
  state.showConfigurationPanelCallback = null;
  await hideLeftToolbar(e, true, name, removeDecorations);
}

export function setChangeInLeftToolbar(newValue = true) {
  state.leftToolbar.hasChanged = newValue;
}

export function showTemplateEditor() {
  state.isTemplateEditorVisible = true;
}

export function hideTemplateEditor() {
  state.isTemplateEditorVisible = false;
  hideLeftToolbar();
}

export function showConfigurationPanel() {
  state.isConfigurationPanelVisible = true;
}

export function hideConfigurationPanel() {
  state.isConfigurationPanelVisible = false;
}

export function showColumnWidgetModal() {
  state.isColumnWidgetModalVisible = true;
}

export function resetNeedToTranslate() {
  state.leftToolbar.needToTranslate = false;
}

function removeColumnWidgetPlaceholders() {
  const template = getTemplateIframeDocument();
  const placeholders = template?.querySelectorAll('.sortable-group-line.sortable-placeholder');
  (placeholders ?? []).forEach((placeholder) => placeholder.remove());
}

export function hideColumnWidgetModal() {
  // Remove column widget's placeholders from template (if any)
  removeColumnWidgetPlaceholders();

  // Hide the modal
  state.isColumnWidgetModalVisible = false;
}

export function showElementsModal(types = [TemplateStructureEnum.SECTION]) {
  state.isLoadElementsModalVisible = true;
  state.loadElementsType = types;
}

function removeSectionWidgetPlaceholders() {
  const template = getTemplateIframeDocument();
  const placeholders = template?.querySelectorAll('.sortable-group-section.sortable-placeholder');
  (placeholders ?? []).forEach((placeholder) => placeholder.remove());
}

export function hideElementsModal() {
  // Remove column widget's placeholders from template (if any)
  removeSectionWidgetPlaceholders();

  // Hide the modal
  state.isLoadElementsModalVisible = false;
  state.loadElementsType = [];
}

export function calculateClock() {
  const now = new Date();

  const template = getTemplateIframeDocument();
  const time = template?.querySelector('#display-time');
  const date = template?.querySelector('#display-date');

  if (time && date) {
    const month = now.toLocaleString('default', { month: 'long' });
    date.innerHTML = `${now.getDate().toString()} ${month} ${now.getFullYear().toString()}`;

    const h = now.getHours();
    let m: number | string = now.getMinutes();

    if (m < 10) {
      m = `0${m}`;
    }

    time.innerHTML = `${h}:${m}`;
  }
}

/* Start the clock and its interval for push notifications templates */
export function launchPushNotificationsClock() {
  calculateClock();

  if (state.intervals === null) {
    state.intervals = {};
  }

  if (state.intervals?.pushNotificationsClock) {
    window.clearInterval(state.intervals.pushNotificationsClock);
    state.intervals.pushNotificationsClock = null;
  }

  state.intervals.pushNotificationsClock = window.setInterval(() => {
    calculateClock();
  }, 1000);
}

/* Stop the clock and its interval for push notifications templates */
export function stopPushNotificationsClock() {
  if (state.intervals?.pushNotificationsClock) {
    window.clearInterval(state.intervals.pushNotificationsClock);
    state.intervals.pushNotificationsClock = null;
  }
}

/* Launch additional functions in editor after activation of a template */
export function triggerAdditionalEditorActivationFunctions() {
  if (isPushTemplate(state.template?.type)) {
    launchPushNotificationsClock();
  } else {
    stopPushNotificationsClock();
  }
}

/* Launch additional functions in editor before deactivation */
export function triggerAdditionalEditorDeactivationFunctions() {
  stopPushNotificationsClock();
}

// Return the translation object for a specific key for specific language
export const getTranslationByKeyAndLang = (key: string, lang: string) => {
  if (state.template) {
    const translationToReturn = state.template.translations
      .filter((translation: Translation) => translation.key === key && translation.language === lang);

    if (translationToReturn && translationToReturn.length > 0) {
      return translationToReturn[0];
    }
  }

  return null;
};

// replace translation value in state object
export const replaceTranslationInState = (key: string, language: string, value: string) => {
  if (state.template) {
    const translation = getTranslationByKeyAndLang(key, language);

    if (translation) {
      // Get shop default language
      const defaultLanguage = getShopDefaultLang();

      // If translation to replace is set to default language, we synchronize the new value with all translations having the same old value
      if (translation.language === defaultLanguage) {
        state.template.translations
          .filter((currentTranslation: Translation) => currentTranslation.key === translation.key && currentTranslation.value === translation.value)
          .forEach((currentTranslation: Translation) => {
            currentTranslation.value = value;
          });
      } else {
        // Else we set the new value
        translation.value = value;
      }
    }
  }
};

// replace translation value in state object for every language available
export const replaceTranslationInStateAllLanguages = (key: string, value: string) => {
  if (state.template) {
    state.template.translations
      .filter((translation: Translation) => translation.key === key)
      .forEach((translation: Translation) => {
        // eslint-disable-next-line no-param-reassign
        translation.value = value;
      });
  }
};

export const updateTranslationPropertyInStateAllLanguages = <K extends keyof Translation>(key: string, property: K, value: Translation[K]) => {
  if (state.template) {
    state.template.translations
      .filter((translation: Translation) => translation.key === key)
      .forEach((translation: Translation) => {
        // eslint-disable-next-line no-param-reassign
        if (Object.prototype.hasOwnProperty.call(translation, property)) {
          translation[property] = value;
        }
      });
  }
};

export const backwardCompatibilityMediaRestrictionClasses = (template: Document) => {
  const showForDesktopElements = template.querySelectorAll('.spm_draggable_row.hide-for-small.show-for-desktop');
  const showForMobileElements = template.querySelectorAll('.spm_draggable_row.show-for-small.hide-for-desktop');

  // Helper function to move classes from parent to child elements
  const moveClasses = (elements: NodeListOf<Element>, classesToAdd: string[], classesToRemove: string[]) => {
    elements.forEach((element) => {
      if (element.nodeType === Node.ELEMENT_NODE) {
        // Find all child elements with class .spm_draggable_widget
        const childWidgets = element.querySelectorAll('.spm_draggable_widget');
        // Add classes to child elements
        childWidgets.forEach((child) => {
          if (child.nodeType === Node.ELEMENT_NODE) {
            classesToAdd.forEach((cls) => child.classList.add(cls));
            child.classList.remove('show-for-all');
          }
        });

        // Remove classes from parent element
        classesToRemove.forEach((cls) => element.classList.remove(cls));
      }
    });
  };

  // Handle showForDesktopElements
  if (showForDesktopElements) {
    moveClasses(showForDesktopElements, ['hide-for-small', 'show-for-desktop'], ['hide-for-small', 'show-for-desktop']);
  }

  // Handle showForMobileElements
  if (showForMobileElements) {
    moveClasses(showForMobileElements, ['show-for-small', 'hide-for-desktop'], ['show-for-small', 'hide-for-desktop']);
  }

  return true;
};

export const setTemplateConfigurationKeyInState = (key: string, value: any) => {
  const templateState = state.template;

  if (templateState) {
    templateState.configuration[key] = value;
  }
};

const getTypeCampaignRelatedToTemplate = (idCampaignFromTemplate: any) => {
  if (idCampaignFromTemplate && idCampaignFromTemplate > 0) {
    if (AutomatedScenarioState.scenario && Number(AutomatedScenarioState.scenario.id) === Number(idCampaignFromTemplate)) {
      return AutomatedScenarioState.scenario.type;
    } if (AutomatedScenarioState.automationCampaigns
      .some((campaign) => Number(campaign.scenario?.id) === Number(idCampaignFromTemplate))) {
      return TypeCampaignEnum.AUTOMATION;
    } if (AutomatedScenarioState.bulkCampaigns
      .some((campaign) => Number(campaign.scenario?.id) === Number(idCampaignFromTemplate))) {
      return TypeCampaignEnum.BULK;
    }
  }

  return null;
};

const verifyIfTheCurrentTemplateIsRedirection = (idTemplate: number, fromCampaign = false) => {
  const dataToUse = fromCampaign ? state.templatesLinkedToCampaign : state.templates;

  const primaryTemplateToSwitch = dataToUse.filter((c: any) => {
    if (c.configuration.nextIdTemplate) {
      const [keyNextIdTemplate, valueNextIdTemplate] = Object.entries(c.configuration.nextIdTemplate)[0];
      const valueNextIdTemplateNumber = valueNextIdTemplate && Array.isArray(valueNextIdTemplate) ? valueNextIdTemplate.map(Number) : [];
      if (valueNextIdTemplateNumber && Array.isArray(valueNextIdTemplateNumber) && valueNextIdTemplateNumber.includes(Number(state.template?.id))) {
        return true;
      }
    }
    return false;
  });

  return primaryTemplateToSwitch.length ? primaryTemplateToSwitch[0].id : false;
};

/**
 * Update template's configuration in state according to its content
 */
export const updateTemplateConfiguration = async () => {
  const { template: templateState } = state;

  const template = getTemplateIframeDocument();

  if (template && templateState) {
    const bodyOuterHTML = template.querySelector('body')?.outerHTML ?? '';
    // Check if a form widget is in the template
    const hasForm = !!template.querySelector('.spm_form');

    const regexPattern = '(\\{VOUCHER_BUTTON)|(\\{CODE_BTN)|(VOUCHER_NUMBER)|(CODE_NBR)|{var=voucher.[a-zA-Z_]+}';
    // Check if voucher button is in the template
    const hasVoucher = !!bodyOuterHTML.match(new RegExp(regexPattern));

    // TODO ANTHONY : verifier si on doit garder cette partie, car les variables se trouvent directement dans le bodyOuterHTML
    //  (en plus y a un probléme de nettoyage de traductions, voir notes Soufiane)
    // Check if voucher variable is in the template's translations
    // if (!hasVoucher) {
    //   hasVoucher = templateState.translations.some((translation) => {
    //     const regexPattern = '(\\{VOUCHER_BUTTON)|(VOUCHER_NUMBER)|(CODE_NBR)';
    //     return bodyOuterHTML.includes(`="LANG_${translation.key}"`) && translation.value.match(new RegExp(regexPattern));
    //   });
    // }

    setTemplateConfigurationKeyInState('hasForm', hasForm);
    setTemplateConfigurationKeyInState('hasVoucher', hasVoucher);

    // Check for redirect templates
    const oldRedirects: (string | number)[] = [];

    if (templateState.configuration.nextIdTemplate) {
      Object.keys(templateState.configuration.nextIdTemplate).forEach((lang: string) => {
        if (templateState.configuration.nextIdTemplate[lang]) {
          if (Array.isArray(templateState.configuration.nextIdTemplate[lang])) {
            // It's an array of IDs
            templateState.configuration.nextIdTemplate[lang].forEach((id: string | number) => {
              if (oldRedirects.indexOf(id) === -1) {
                oldRedirects.push(id);
              }
            });
          } else if (oldRedirects.indexOf(templateState.configuration.nextIdTemplate[lang]) === -1) {
            // It's an ID
            oldRedirects.push(templateState.configuration.nextIdTemplate[lang]);
          }
        }
      });
    }

    // We search for new redirects in the template
    const currentRedirects: { [key: string]: (string | number)[] } = {};
    const needUpdateSectionsInState = false;

    await templateState.translations.reduce(async (a, translation) => {
      await a; // Wait for the end of the previous Promise

      const { value } = translation;

      if (value.match(new RegExp('#redirect_'))) {
        const idRedirect = window.atob(value.replace('#redirect_', ''));
        // We check if it's an ID
        if (!idRedirect.match(new RegExp('[a-zA-Z]'))) {
          if (!Object.prototype.hasOwnProperty.call(currentRedirects, translation.language)) {
            currentRedirects[translation.language] = [];
          }

          currentRedirects[translation.language].push(idRedirect);
        }
      }
    }, Promise.resolve());

    if (needUpdateSectionsInState) {
      updateSectionsInState(false);
    }

    setTemplateConfigurationKeyInState('nextIdTemplate', Object.keys(currentRedirects).length ? currentRedirects : false);
    setTemplateConfigurationKeyInState('informations', JSON.parse(JSON.stringify(templateState.informations)));
  }
};

export const fixUndefinedFieldTypeInTranslations = (): void => {
  if (state.template) {
    state.template.translations.filter((translation) => translation.fieldType === undefined).forEach((translation) => {
      const { key } = translation;
      const splittedKey = key.split('.');
      if (splittedKey && splittedKey.length > 1) {
        switch (splittedKey[1]) {
          case 'text':
          case 'alt':
            translation.fieldType = 'text';
            break;
          case 'href':
            translation.fieldType = 'link';
            break;
          case 'src':
            translation.fieldType = 'image';
            break;
          default:
            break;
        }
      }
    });
  }
};

// Hide model editing iframe
export const hideIframeTemplateWrapper = () => {
  const iframeElement = document.querySelector('iframe.template-wrapper') as HTMLIFrameElement | null;
  if (iframeElement) {
    iframeElement.style.transition = 'none';
    iframeElement.style.opacity = '0';
  }
};

// Show template editing iframe
export const showIframeTemplateWrapper = () => {
  // Cibler l'iframe avec document.querySelector et affirmer le type
  const iframeElement = document.querySelector('iframe.template-wrapper') as HTMLIFrameElement | null;
  if (iframeElement) {
    // Applique une transition pour l'opacité
    iframeElement.style.transition = 'opacity 0.5s ease-in-out';
    // Change l'opacité pour déclencher l'effet
    requestAnimationFrame(() => {
      iframeElement.style.opacity = '1';
    });
  }
};

export async function activate(id: number, name: string, type: string): Promise<Maybe<Template>> {
  store.commit('liveEditor/setCheckSyncElements', true);
  hideIframeTemplateWrapper();
  await hideLeftToolbar(null, true);
  destroyIframeSortables();
  const ex = state.templates.findIndex((s) => s.id === id);
  const exCampaign = state.templatesLinkedToCampaign.findIndex((s) => s.id === id);
  if (ex !== -1 || exCampaign !== -1) {
    if (ex !== -1) {
      state.index = ex;
      state.template = state.templates[ex];
    } else {
      state.template = state.templatesLinkedToCampaign[exCampaign];
    }

    showTemplateEditor();
    triggerAdditionalEditorActivationFunctions();
    sortTranslationInState();
    return state.template;
  }

  /* Get template informations */
  const settings: ListSettings = {
    limit: 10,
    offset: 0,
    order: [],
    filter: [{ field: 'id_template', value: id, operator: 'EQUALS' }],
  };

  /* Get template config */
  const template = await Get<TemplateRaw>({
    name: 'Templates',
    id,
    keyName: 'id_template',
    fields: [
      'id_template',
      'created_from_campaign',
      'id_campaign',
      'template_config',
      'label',
      'date_modification',
    ],
  });

  const templateConfiguration = JSON.parse(template.item?.template_config ?? '{}');

  const structure = templateConfiguration.body_structure ?? '';
  const sectionIds = [
    structure.design,
    ...structure.sections.map((sectionId: any) => (sectionId ? sectionId.toString() : sectionId)),
  ];

  const sectionsSettings: ListSettings = {
    limit: sectionIds.length,
    offset: 0,
    order: [],
    filter: [{ field: 'id_template_elements', value: sectionIds.join(','), operator: OperatorType.In }],
  };

  /* Get template sections */
  const sections = await List<Section>({
    name: 'TemplatesElements',
    settings: sectionsSettings,
    fields: [
      'id_template_elements',
      'data',
      'html',
      'css',
      'translations',
      'type',
    ],
  });

  let label = name;
  if (name === '') {
    label = template?.item?.label ?? '';
  }

  const idCampaign = (template?.item?.id_campaign && template?.item?.id_campaign > 0) ? template.item.id_campaign : null;
  const createdFromCampaign = template?.item?.created_from_campaign ?? null;

  let informations: TemplateInformations | null = null;
  if (Object.prototype.hasOwnProperty.call(templateConfiguration, 'informations') && Object.keys(templateConfiguration.informations).length > 0) {
    // If the JSON contains information about subject and sender, we use them to configure the information panel
    informations = {
      name: label,
      lang: templateConfiguration.informations.lang,
      imported: templateConfiguration.informations.imported === true,
    };

    if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.EMAIL) {
      informations.subject = { ...templateConfiguration.informations.subject };
      informations.fromEmail = { ...templateConfiguration.informations.fromEmail };
      informations.fromName = { ...templateConfiguration.informations.fromName };
    } else if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.SMS) {
      informations.fromName = { ...templateConfiguration.informations.fromName };
    }
  } else {
    // Otherwise (old template), we use the data stored in template_langs
    const {
      items,
      err,
    } = await List<TemplateLangRaw>({
      name: 'TemplatesLang',
      settings,
      fields: [
        'id_template',
        'subject',
        'from_email',
        'from_name',
        'lang',
      ],
    });

    if (items.length === 0) {
      await showToastError(err);
      return null;
    }

    informations = {
      name: label,
      lang: items[0].lang,
    };

    if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.EMAIL) {
      informations.subject = items.reduce((acc: StringMap, item: TemplateLangRaw) => ({
        ...acc,
        [item.lang]: item.subject,
      }), {});
      informations.fromEmail = items.reduce((acc: StringMap, item: TemplateLangRaw) => ({
        ...acc,
        [item.lang]: item.from_email,
      }), {});
      informations.fromName = items.reduce((acc: StringMap, item: TemplateLangRaw) => ({
        ...acc,
        [item.lang]: item.from_name,
      }), {});
    } else if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.SMS) {
      informations.fromName = items.reduce((acc: StringMap, item: TemplateLangRaw) => ({
        ...acc,
        [item.lang]: item.from_name,
      }), {});
    }
  }

  // We check if the template is edited from a campaign
  const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
  if (editingTemplateFromCampaign) {
    // If we edit the current template from an active campaign we get template information from selected operator
    const form = AutomatedScenarioState.selectedOperator.currentConfigurationForm;

    if (form) {
      if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.EMAIL) {
        informations.subject = { ...form.template_message_subject };
        informations.fromEmail = { ...form.template_message_expmail };
        informations.fromName = { ...form.template_message_expname };
      } else if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.SMS) {
        informations.fromName = { ...form.template_message_expname };
      }
    }
  }

  // If sender name/email are empty, we get default values from database
  if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.EMAIL
    && Object.prototype.hasOwnProperty.call(informations, 'fromEmail')
    && informations.fromEmail
    && !Object.values(informations.fromEmail).some((value) => value !== null && value !== '')) {
    const field = reactive<Record<string, any>>({
      shopRequiredDataEmail_senderEmail: {},
    });

    await retrieveServicesData(UserState.activeShop?.id ?? 0, field);
    informations.fromEmail = field.shopRequiredDataEmail_senderEmail;
  }

  if (Object.prototype.hasOwnProperty.call(informations, 'fromName')
    && informations.fromName
    && !Object.values(informations.fromName).some((value) => value !== null && value !== '')) {
    let key = 'shopRequiredDataEmail_senderName';
    if (getTemplateParentTypeByType(type) === TemplateParentTypeEnum.SMS) {
      key = 'shopRequiredDataSms_senderName';
    }

    const field = reactive<Record<string, any>>({
      shopRequiredDataEmail_senderName: {},
      shopRequiredDataSms_senderName: {},
    });

    await retrieveServicesData(UserState.activeShop?.id ?? 0, field);
    informations.fromName = field[key];
  }

  const t: Template = new Template(
    template.item?.id_template,
    type,
    idCampaign,
    createdFromCampaign,
    informations,
    {
      design: asInt(structure.design),
      sections: structure.sections.map((sectionId: any) => (sectionId ? sectionId.toString() : sectionId)),
    },
    sections.items,
    JSON.parse(template.item?.template_config ?? '{}'),
    template.item?.date_modification ?? '',
  );

  if (idCampaign !== null) {
    state.templatesLinkedToCampaign.push(t);
  } else {
    state.templates.push(t);
    state.index = state.templates.length - 1;
  }
  state.template = t;
  state.hasAny = state.templates.length !== 0;

  fixUndefinedFieldTypeInTranslations();

  showTemplateEditor();
  triggerAdditionalEditorActivationFunctions();

  sortTranslationInState();

  return t;
}

export function rename(id: number, name: string): void {
  if (state.template) {
    state.template.informations.name = name;
  }
}

export const deleteTemplate = async (idTemplate: number): Promise<boolean | string> => {
  // Check if used as reference template
  const templates = await List<Templates>({
    name: 'Templates',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: UserState.activeShop?.id ?? 0,
        },
        {
          field: 'deleted',
          operator: OperatorType.Equals,
          value: 0,
        },
        {
          field: 'id_template_reference',
          operator: OperatorType.Equals,
          value: idTemplate,
        },
      ],
      order: [
        { field: 'id_template', type: 'ASC' },
      ],
      offset: 0,
      limit: 1,
    },
    fields: ['id_template'],
  });

  if (templates.err !== '') {
    return templates.err;
  }

  if (templates.total > 0) {
    return 'templates.confirm.delete.error.messageTemplateReference';
  }

  // Check if used in an automated scenario
  const workflows = await List<Templates>({
    name: 'Templates',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: UserState.activeShop?.id ?? 0,
        },
        {
          field: 'deleted',
          operator: OperatorType.Equals,
          value: 0,
        },
        {
          field: 'id_campaign',
          operator: OperatorType.IsNotNull,
          value: 'null',
        },
        {
          field: 'template_config',
          operator: OperatorType.Contains,
          value: idTemplate,
        },
      ],
      order: [
        { field: 'id_template', type: 'ASC' },
      ],
      offset: 0,
      limit: 1,
    },
    fields: ['id_template'],
  });

  if (workflows.err !== '') {
    return workflows.err;
  }

  if (workflows.total > 0) {
    // TODO : récupérer la liste des scénarios qui contient ce modèle et créer des liens permettant d'éditer la boite concernée
    return 'templates.confirm.delete.error.messageUsedInWorkflows';
  }

  // We can delete the template
  const templateInput: TemplatesUpdateInputItem[] = [{
    id_template: idTemplate,
    id_shop: UserState.activeShop?.id ?? 0,
    deleted: true,
    date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
  }];

  const update = await Update<TemplatesUpdateInputItem>({
    name: 'Templates',
    input: templateInput,
    type: 'TemplatesUpdateInput',
  });

  if (update.err !== '') {
    return update.err;
  }

  // After template deletion, we check if the template is opened in state and local storage.
  // If true, we need to remove it
  const templatesInLocalStorage = JSON.parse(getLocalStorageElement('templates') ?? '{}');
  if (Object.keys(templatesInLocalStorage).length > 0) {
    const newTemplatesToStore: Template[] = templatesInLocalStorage.templates.filter((template: Template) => template.id !== idTemplate);

    if (newTemplatesToStore.length === 0) {
      // If there is no template, we hide the editor icon and reset everything
      state.hasAny = false;
      state.templates = [];
      state.index = -1;
      removeLocalStorageElement('templates');
      removeLocalStorageElement('elementSyncCurrentTemplate');
    } else {
      const index: number = newTemplatesToStore.length - 1;
      setLocalStorageElement('templates', JSON.stringify({ templates: newTemplatesToStore, index }));
      state.templates = newTemplatesToStore;
      state.index = index;
    }
  }

  return true;
};

export function setRefreshTemplatesList(refreshList: boolean) {
  state.refreshTemplatesList = refreshList;
}

export function deactivate(id: number, hideTmpEditor = true): void {
  hideIframeTemplateWrapper();
  state.templates = state.templates.filter((s) => s.id !== id);
  state.templatesLinkedToCampaign = state.templatesLinkedToCampaign.filter((s) => s.id !== id);
  state.index = state.templates.length - 1;
  state.hasAny = state.templates.length !== 0;
  triggerAdditionalEditorDeactivationFunctions();

  if (hideTmpEditor) {
    hideTemplateEditor();
  }
}

export function updateTemplateInformations(informations: TemplateInformations) {
  if (state.template) {
    state.template.informations = {
      ...state.template.informations,
      ...informations,
    };
  }
}

export const resetActiveSection = () => {
  if (state.template) {
    state.template.activeSection = -1;
  }
};

export const setActiveSection = (sectionId: number | string) => {
  if (state.template) {
    state.template.activeSection = sectionId;
  }
};

export const addTranslationsToState = (translations: Translation[]) => {
  if (state.template) {
    const existingTranslationIds = new Set(state.template.translations.map((t) => t.key));
    const newTranslations = translations.filter((t) => !existingTranslationIds.has(t.key));
    state.template.translations.push(...newTranslations);
  }
};

// eslint-disable-next-line max-len
export const getSectionIdsFromIframe = (widthDesign = false) => (Array.from(getTemplateIframeDocument().querySelectorAll('[data-spmelementid]')) as HTMLElement[])
  .reduce((acc: (string | number)[], section: HTMLElement) => {
    const sectionId = section.getAttribute('data-spmelementid');
    if (!widthDesign) {
      if (sectionId && sectionId.toString() !== (state.template?.content.design ?? '').toString()) acc.push(sectionId);
    } else if (sectionId && sectionId.toString() !== ('').toString()) acc.push(sectionId);

    return acc;
  }, []);

// Refresh sections order in config
export const refreshSectionsConfig = (sections: number[] | null = null) => {
  if (state.template) {
    if (sections) {
      state.template.content.sections = sections;
    } else {
      state.template.content.sections = getSectionIdsFromIframe();
    }
  }
};

const addNewSectionInState = (section: Section) => {
  if (state.template && section) {
    state.template.sections.push(section);
  }
};

export const replaceSectionContent = (idSection: number, html: string | null, css: string | null, translations: string | null) => {
  if (state.template) {
    const foundSection = state.template.sections.filter((current: Section) => current.id_template_elements === idSection);

    if (foundSection.length) {
      foundSection.forEach((current: Section) => {
        if (html) current.html = html;
        if (css) current.css = css;
        if (translations) current.translations = translations;
      });
    } else {
      const restoredSectionFromHistory: Section = {
        id_template_elements: idSection,
        html: html ?? '',
        css: css ?? '',
        translations: translations ?? '',
        type: 'section',
      };

      addNewSectionInState(restoredSectionFromHistory);
    }
  }
};

export const refreshTranslations = () => {
  if (state.template) {
    state.template.translations = [];
    state.template.sections.forEach((section) => {
      const sectionTranslation = JSON.parse((section.translations && section.translations !== '' ? section.translations : '{}').replace(/(\r\n|\n|\r)/gm, ' '));
      Object.keys(sectionTranslation).forEach((language: string) => {
        Object.keys(sectionTranslation[language]).forEach((key: string) => {
          if (state.template) {
            state.template.translations.push(...(addTranslation(sectionTranslation[language], key, section.id_template_elements, language, state.template.type) ?? []));
          }
        });
      });
    });
  }
};

/**
 * Restore each original code of smart lists widgets (before refreshing the page or saving the template)
 * @param widgetId if specified, restore only specific widget original code
 */
export const restoreSmartListsOriginalCode = (widgetId: Maybe<string> = null): boolean => {
  let returnValue = false;
  const widgets: HTMLElement[] = getTemplateWidgets();
  widgets
    .filter((widget: HTMLElement) => (
      widget.getAttribute('data-widgettype') === 'spm_smart_products_list'
      || widget.getAttribute('data-widgettype') === 'spm_fb_productslist'
      || widget.getAttribute('data-widgettype') === 'spm_widget_import_zip'
    ) && (!widgetId || widgetId === widget.getAttribute('id') || getFirstParent(widget, `#${widgetId}`)))
    .forEach(async (widget: HTMLElement) => {
      const widgetElementId = widget.getAttribute('id') ?? '';
      if (state.template && Object.keys(state.template.smartLists).includes(widgetElementId)) {
        widget.innerHTML = state.template?.smartLists[widgetElementId];
        returnValue = true;
      }
    });

  return returnValue;
};

/**
 * Remove all hover classes
 */
export const removeHoverStyles = () => {
  const template = getTemplateIframeDocument();

  if (template) {
    const elements = template.querySelectorAll(`.${BUILDER_ELEMENT_HOVER_CLASS}`);

    if (elements) {
      elements.forEach((element) => element.classList.remove(BUILDER_ELEMENT_HOVER_CLASS));
    }
  }
};

/**
 * Remove all active items classes
 */
export const removeActiveStyles = () => {
  const template = getTemplateIframeDocument();

  if (template) {
    const elements = template.querySelectorAll(`.${BUILDER_ELEMENT_ACTIVE_CLASS}`);

    if (elements) {
      elements.forEach((element) => element.classList.remove(BUILDER_ELEMENT_ACTIVE_CLASS));
    }
  }
};

/**
 * Remove all sortable items classes
 */
export const removeSortableStyles = () => {
  const template = getTemplateIframeDocument();

  if (template) {
    const elements = template.querySelectorAll(`.${BUILDER_SORTABLE_DRAG_CLASS}, .${BUILDER_SORTABLE_GHOST_CLASS}, .${BUILDER_SORTABLE_CHOSEN_CLASS}`);

    if (elements) {
      elements.forEach((element) => {
        element.classList.remove(BUILDER_SORTABLE_DRAG_CLASS);
        element.classList.remove(BUILDER_SORTABLE_GHOST_CLASS);
        element.classList.remove(BUILDER_SORTABLE_CHOSEN_CLASS);
      });
    }
  }
};

export const restoreState = (index: number, stateToRestore: Template) => {
  state.templates[index] = stateToRestore;
  state.template = state.templates[index];
};

export const saveTemplatesInLocalStorage = () => {
  if (state.hasAny || state.templatesLinkedToCampaign.length) {
    const currentTemplateState = store.getters['liveEditor/getCurrentTemplateState'];
    if (currentTemplateState) {
      setLocalStorageElement('elementSyncCurrentTemplate', JSON.stringify({ template: currentTemplateState.template, index: currentTemplateState.index }));
    } else {
      removeLocalStorageElement('elementSyncCurrentTemplate');
    }
    restoreSmartListsOriginalCode();

    // Remove all hover styles
    removeHoverStyles();

    // Remove all active styles
    removeActiveStyles();

    // Remove all sortable styles
    removeSortableStyles();

    const syncElementWarning = store.getters['liveEditor/getSyncElementWarning'];
    if (syncElementWarning && syncElementWarning.rejectCallback) {
      syncElementWarning.rejectCallback();
    }

    // Clear elements placeholder
    clearPlaceholder(true);

    // update sections in state first
    updateSectionsInState(false);

    // then save templates in local storage
    if (state.hasAny) {
      setLocalStorageElement('templates', JSON.stringify({ templates: state.templates, index: state.index }));
    } else {
      removeLocalStorageElement('templates');
    }

    if (state.templatesLinkedToCampaign.length) {
      setLocalStorageElement('templatesLinkedToCampaign', JSON.stringify({ templates: state.templatesLinkedToCampaign }));
    } else {
      removeLocalStorageElement('templatesLinkedToCampaign');
    }
  } else {
    removeLocalStorageElement('templates');
    removeLocalStorageElement('templatesLinkedToCampaign');
    removeLocalStorageElement('elementSyncCurrentTemplate');
  }
};

export const UpdateTemplateColumnInDb = async (templatesInput: TemplatesUpdateInputItem[]) => {
  const {
    id, err,
  } = await Update<TemplatesUpdateInputItem>({
    name: 'Templates',
    input: templatesInput,
    type: 'TemplatesUpdateInput',
  });

  if (err === '') {
    return id;
  }

  throw new Error(err);
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const updateTemplateColumn = async (field: string, data: any) => {
  // Update the current template
  const input: TemplatesUpdateInputItem = {
    id_template: data.id_template,
    id_shop: UserState.activeShop?.id ?? 0,
    [field]: data[field],
    date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
  };

  await UpdateTemplateColumnInDb([input]).catch((err: Error) => {
    showToastError(err.message);
  });
};

export const toggleAdvancedMode = () => {
  state.advancedModeEnabled = !state.advancedModeEnabled;
};

const insertNewTemplateElement = async (tmpId: string | null, section: Section): Promise<Maybe<number | null>> => {
  if (!tmpId) {
    return null;
  }

  const inputsElements: TemplatesElementsInputItem[] = [{
    id_template: state.template?.id,
    id_shop: UserState.activeShop?.id ?? 0,
    type: 'section',
    data: section.data,
    html: section.html,
    css: section.css,
    translations: section.translations,
    date_creation: moment().format('YYYY-MM-DD HH:mm:ss'),
    date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
  }];

  const {
    id, err,
  } = await Insert<TemplatesElementsInputItem>({
    name: 'TemplatesElements',
    input: inputsElements,
    type: 'TemplatesElementsInput',
  });

  if (err !== '') {
    await showToastError(err);
    return null;
  }

  return id;
};

export const addNewSectionToState = async (clonedElement: HTMLElement, customCssTypes: string[] = ['dynamic'], replaceSectionId = '') => {
  // Update the sections config in the state
  refreshSectionsConfig();

  // Add the new section in state template's sections list
  const newSection: Section = {
    id_template_elements: clonedElement.getAttribute('data-spmelementid') ?? '',
    name: '',
    data: '',
    html: '',
    css: '',
    translations: '',
    type: '',
  };

  if (replaceSectionId && state.template && state.template.sections) {
    state.template.sections = state.template.sections.filter((section) => section.id_template_elements.toString() !== replaceSectionId.toString());
  }

  addNewSectionInState(newSection);
  updateSectionsInState(false);

  const section = [...state.template?.sections
    .filter((current: Section) => current.id_template_elements.toString() === clonedElement.getAttribute('data-spmelementid')) || []];

  if (section.length) {
    const tmpId = clonedElement.getAttribute('data-spmelementid') ?? null;
    const newId = !replaceSectionId ? await insertNewTemplateElement(tmpId, section[0]) : replaceSectionId;

    if (newId) {
      // Save new ID in HTML and section
      const template = getTemplateIframeDocument();

      if (template) {
        // Change ID of element in HTML
        if (replaceSectionId) {
          const sectionToReplace = template.querySelector(`[data-spmelementid="${replaceSectionId}"]`) as HTMLElement;
          if (sectionToReplace) {
            sectionToReplace.remove();
          }
        }
        const sectionHtml = template.querySelector(`[data-spmelementid="${tmpId}"]`) as HTMLElement;
        sectionHtml.setAttribute('data-spmelementid', newId.toString());

        // Update the config of the template in the state and mark the section as active
        refreshSectionsConfig();
        setActiveSection(newId);

        // Update the ID of the section in the template's sections config
        const savedSection = state.template?.sections.filter((current: Section) => current.id_template_elements === tmpId);

        if (savedSection) {
          savedSection[0].id_template_elements = parseInt(newId.toString(), 10);

          const updateCSSSectionId = (type = 'dynamic') => {
            // Update CSS section ID
            if (replaceSectionId) {
              const dynamicStylesheetToReplace = getElementFromIframe(`style[data-spm-styles="${type}"][data-spm-section="${replaceSectionId}"]`);
              if (dynamicStylesheetToReplace) {
                dynamicStylesheetToReplace.remove();
              }
            }
            const dynamicStylesheet = getElementFromIframe(`style[data-spm-styles="${type}"][data-spm-section="${tmpId}"]`);
            if (dynamicStylesheet) {
              dynamicStylesheet.setAttribute('data-spm-section', newId.toString());
            }
          };

          customCssTypes.forEach((type) => {
            updateCSSSectionId(type);
          });

          if (replaceSectionId && state.template && state.template.translations) {
            state.template.translations = state.template.translations.filter((trans) => trans.section.toString() !== replaceSectionId);
          }

          // Update section ID in translations
          const translationsOfElement = state.template?.translations
            .filter((current: Translation) => current.section === tmpId);

          if (translationsOfElement) {
            translationsOfElement.forEach((currentTranslation: Translation) => {
              currentTranslation.section = newId.toString();
            });
          }
        }
      }
    }
  }
};

interface GetAllSectionsArgs {
  shopId: number;
  type: string[];
  templateType: string[];
  limit: number;
  offset: number;
  idTemplatePart?: string;
}

export const getTemplatesParts = async ({
  shopId,
  type,
  templateType,
  limit = 0,
  offset = 0,
  idTemplatePart = '',
}: GetAllSectionsArgs): Promise<Array<TemplatePart> | null> => {
  const filter = [
    {
      field: 'id_shop',
      operator: OperatorType.Equals,
      value: shopId,
    },
    {
      field: 'id_template',
      operator: OperatorType.IsNull,
      value: '',
    },
  ];
  if (type.length) {
    filter.push({
      field: 'type',
      operator: OperatorType.In,
      value: type.join(','),
    });
  }
  if (idTemplatePart) {
    filter.push({
      field: 'id_template_parts',
      operator: OperatorType.Equals,
      value: idTemplatePart,
    });
  }
  if (templateType.length) {
    filter.push({
      field: 'template_type',
      operator: OperatorType.In,
      value: templateType.join(','),
    });
  }
  const { items, err } = await List<TemplatesParts>({
    name: 'TemplatesParts',
    settings: {
      filter,
      order: [
        { field: 'id_template_parts', type: 'DESC' },
      ],
      limit,
      offset,
    },
    fields: [
      'id_template_parts',
      'type',
      'id_template',
      'name',
      'data',
      'html',
      'css',
      'translations',
      'template_part_hash',
      'part_type',
    ],
  });

  if (err === '') {
    return items;
  }

  return null;
};

export const deleteSavedSection = async (section: TemplatePart): Promise<boolean | string> => {
  // Remove in datatabse
  const templatePartInput: TemplatesPartsUpdateInputItem[] = [{
    id_template_parts: section.id_template_parts,
    id_shop: UserState.activeShop?.id ?? 0,
  }];

  const update = await Delete<TemplatesPartsUpdateInputItem>({
    name: 'TemplatesParts',
    input: templatePartInput,
    type: 'TemplatesPartsDeleteInput',
  });

  if (update.err !== '') {
    return update.err;
  }

  return true;
};

export const insertSavedElement = async (item: TemplatePart, insertSelector: string, insertPosition: string, addHistory = true, replaceSectionId = '') => {
  // Create an HTML element containing our HTML code and get the HTMLElement we need to insert in the template
  const div = document.createElement('div');
  div.innerHTML = (item.html ?? '').trim();
  const htmlElement = div.firstChild as HTMLElement;

  // Get dynamic style of section
  let cssString = item.css ? JSON.parse(item.css) : null;
  let addValueToCss = false;
  let customWidgetCss: Record<string, any> | null = null;
  if (cssString && Object.keys(cssString).find((key) => key.toLowerCase().startsWith(CUSTOM_WIDGET_CSS_PREFIX))) {
    addValueToCss = true;
  }
  if (cssString && 'dynamic' in cssString) {
    cssString = cssString.dynamic;
  }
  if (addValueToCss) {
    const originalCss = item.css ? JSON.parse(item.css) : null;
    if (originalCss) {
      const keys = Object.keys(originalCss).filter((key) => key.toLowerCase().startsWith(CUSTOM_WIDGET_CSS_PREFIX));
      keys.forEach((key) => {
        if (!customWidgetCss) {
          customWidgetCss = {};
        }
        customWidgetCss[key] = originalCss[key];
      });
    }
  }

  // Get translations of saved section
  const elementTranslations = item.translations && item.translations !== '' ? JSON.parse(item.translations.replace(/(\r\n|\n|\r)/gm, ' ')) : {};

  let syncElementId = '';
  if (item.data) {
    const data = JSON.parse(item.data);
    if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
      syncElementId = data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER];
    }
  }
  let syncElement: { name: string; syncElementId: string } | null = null;
  if (syncElementId) {
    syncElement = {
      name: item.name ?? '',
      syncElementId,
    };
  }

  // Insert the HTMLELement in the template
  await duplicateElement(
    { selector: insertSelector, type: item.type as TemplateStructureEnum ?? TemplateStructureEnum.SECTION },
    item.type as TemplateStructureEnum ?? TemplateStructureEnum.SECTION,
    htmlElement,
    cssString,
    insertPosition,
    elementTranslations,
    customWidgetCss,
    syncElement,
    addHistory,
    null,
    replaceSectionId,
  );
};

/**
 * Insert new template part in database
 * @param inputParts
 */
const insertNewTemplatePart = async (inputParts: TemplatesPartsInputItem[]): Promise<Maybe<number | null>> => {
  if (!inputParts.length) {
    return null;
  }

  const insert = await Insert<TemplatesPartsInputItem>({
    name: 'TemplatesParts',
    input: inputParts,
    type: 'TemplatesPartsInput',
  });

  if (insert.err !== '') {
    await showToastError(insert.err);
    return null;
  }

  return insert.id;
};

/**
 * Update template part in database
 * @param inputParts
 */
const updateTemplatePart = async (inputParts: TemplatesPartsUpdateInputItem[]): Promise<Maybe<number | null>> => {
  if (!inputParts.length) {
    return null;
  }

  const update = await Update<TemplatesPartsUpdateInputItem>({
    name: 'TemplatesParts',
    input: inputParts,
    type: 'TemplatesPartsUpdateInput',
  });

  if (update.err !== '') {
    return null;
  }

  return update.id;
};

/**
 * Duplicate template part in database
 * @param inputParts
 */
const duplicateTemplatePart = async (parts: any): Promise<any> => {
  const update = await CustomMutation<any>({
    name: 'DuplicateTemplatesParts',
    input: parts,
    type: 'TemplatesPartsDuplicateInput',
  });

  if (update.err !== '') {
    await showToastError(update.err);
    return null;
  }

  return update;
};

/**
 * Calculate part hash based on html, css and translations
 * @param html
 * @param css
 * @param translations
 */
export const calculatePartHash = (html: string, css: string, translations: string): string => {
  // On récupère l'intégralité des ID à remplacer
  const ids = [...html.matchAll(new RegExp('id="(spm_.*?[0-9]+.*?)"', 'g'))];

  if (ids) {
    ids.reverse();

    let i = 1;
    // Loop on each translation key to generate a new one and store in the state
    ids.forEach((id) => {
      const newId = id[1].replace(new RegExp('[0-9]+'), i.toString());
      html = html.replace(id[1], newId);
      css = css.replace(id[1], newId);
      i += 1;
    });
  }

  return Md5.hashStr(`${html}${css}${translations}`);
};

/**
 * Check if a part already exists with the same name. In that case, return its ID
 * @param type
 * @param name
 * @param selector
 */
export const checkIfPartExistsWithSameName = async (type: string, name: string, selector: string, returnObject = false): Promise<Maybe<any>> => {
  const parts = await List<TemplatesParts>({
    name: 'TemplatesParts',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: UserState.activeShop?.id ?? 0,
        },
        {
          field: 'type',
          operator: OperatorType.Equals,
          value: type,
        },
        {
          field: 'template_type',
          operator: OperatorType.Equals,
          value: getTemplateParentTypeByType(TemplateEditorState.template?.type),
        },
        {
          field: 'name',
          operator: OperatorType.Equals,
          value: name,
        },
      ],
      order: [
        { field: 'id_template_parts', type: 'ASC' },
      ],
      limit: 1,
      offset: 0,
    },
    fields: ['id_template_parts', 'type', 'id_template', 'name', 'data', 'html', 'css', 'translations'],
  });

  if (parts.err !== '') {
    return parts.err;
  }

  let returnValue = null;

  if (parts.items.length > 0) {
    // We check if the template linked to the part if different from the current template
    if (parts.items[0].id_template && parts.items[0].id_template > 0 && parts.items[0].id_template !== state.template?.id) {
      // Then, we check if the part to save contains a part ID in the HTML code
      const activeStructure = getElementFromIframe(selector);

      if (activeStructure && selector) {
        sanitizeSection(activeStructure);

        // We check if the current section contains a part ID
        const partHtml = activeStructure.outerHTML;
        const regExpIdPart = new RegExp('id="spm_section_([a-zA-Z0-9]*)"');
        const matches = regExpIdPart.exec(partHtml);

        if (matches && matches.length) {
          // If true, we get the template linked to the part from the database
          const template = await Get<Templates>({
            name: 'Templates',
            id: parts.items[0].id_template,
            keyName: 'id_template',
            fields: ['id_template_reference'],
          });

          if (template.item) {
            // We get the part ID linked to the reference template
            const htmlId = `spm_section_${matches[1]}`;
            const filters = [
              {
                field: 'id_shop',
                operator: OperatorType.Equals,
                value: UserState.activeShop?.id ?? 0,
              },
              {
                field: 'type',
                operator: OperatorType.Equals,
                value: type,
              },
              {
                field: 'template_type',
                operator: OperatorType.Equals,
                value: getTemplateParentTypeByType(TemplateEditorState.template?.type),
              },
              {
                field: 'html',
                operator: OperatorType.Like,
                value: `%id="${htmlId}"%`,
              },
            ];

            if (template.item.id_template_reference) {
              filters.push({
                field: 'id_template',
                operator: OperatorType.Equals,
                value: template.item.id_template_reference,
              });
            }

            const referencePart = await List<TemplatesParts>({
              name: 'TemplatesParts',
              settings: {
                filter: filters,
                order: [
                  { field: 'id_template_parts', type: 'ASC' },
                ],
                limit: 1,
                offset: 0,
              },
              fields: ['id_template_parts'],
            });

            if (referencePart.items && referencePart.items.length) {
              // If we found an ID, we use that ID for erasing
              returnValue = referencePart.items[0].id_template_parts;
            }
          }
        }
      }
    }

    // Otherwise, we erase the current part
    returnValue = parts.items[0].id_template_parts;
    if (returnObject) {
      // eslint-disable-next-line prefer-destructuring
      returnValue = parts.items[0];
    }
  }

  return returnValue;
};

/**
 * Save a new template part in database
 * @param type
 * @param name
 * @param selector
 * @param eraseId
 */
// eslint-disable-next-line consistent-return
export const savePartInDatabase = async (type: string, name: string, selector: string, eraseId: Maybe<number> = null, isSync = false, existingSyncId = '') => {
  const template = getTemplateIframeDocument();

  const savePart = async (element: { type: any; data: any; html: any; css: any; translations: any; hash: any }) => {
    if (!eraseId) {
      // We save the new part in the database
      const parts: TemplatesPartsInputItem[] = [{
        id_shop: UserState.activeShop?.id ?? 0,
        name,
        type,
        template_type: getTemplateParentTypeByType(TemplateEditorState.template?.type),
        part_type: element.type,
        data: element.data ? JSON.stringify(element.data) : null,
        html: element.html.replace(/spm_active_element/g, ''),
        css: element.css,
        translations: element.translations,
        template_part_hash: element.hash,
        id_template: null,
        id_template_from: null,
      }];

      const templatePartId = await insertNewTemplatePart(parts) ?? 0;
      return {
        newSectionId: templatePartId.toString(),
        newSection: {
          ...parts[0],
          id_template_parts: asInt(templatePartId),
        },
      };
    }
    // We update the existing part
    const parts: TemplatesPartsUpdateInputItem[] = [{
      id_template_parts: eraseId,
      id_shop: UserState.activeShop?.id ?? 0,
      data: element.data ? JSON.stringify(element.data) : null,
      html: element.html.replace(/spm_active_element/g, ''),
      css: element.css,
      translations: element.translations,
      template_part_hash: element.hash,
      name,
    }];

    const templatePartId = await updateTemplatePart(parts) ?? 0;
    if (!templatePartId) {
      throw new Error('');
    }

    try {
      if (!state.sectionsToRefresh.find((itemId) => itemId === eraseId)) {
        state.sectionsToRefresh.push(eraseId);
      }
      await axios.get(MEDIA_URL_PART_RELOAD_CACHE.replace('{SHA1_KEY}', generateSha1Hash(eraseId)));
    } catch (err) {
      // No need to continue
    }
    return {
      newSectionId: templatePartId.toString(),
      newSection: {
        ...parts[0],
        id_template_parts: asInt(templatePartId),
        name,
        type,
      },
    };
  };

  const syncId = existingSyncId || generateUniqStructureId();

  if (type === TemplateStructureEnum.DESIGN) {
    updateSectionsInState(false);
    const selectedStructure = store.getters['liveEditor/getSelectedStructure'];
    const activeStructure = isEmailTemplate(state.template?.type) ? template.body : getElementFromIframe(`#${selectedStructure.identifier}`);
    const sectionId = activeStructure.getAttribute('data-spmelementid');
    if (sectionId) {
      const design = state.template?.sections.find((item) => item.id_template_elements.toString() === sectionId.toString());
      if (design) {
        let data = JSON.parse(design.data || '{}');
        if (isDisplayTemplate(state.template?.type)) {
          data = {};
        }
        if (isSync) {
          data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] = syncId;
          data[TEMPLATE_SYNC_ELEMENT_NAME] = name;
        }

        const html = isDisplayTemplate(state.template?.type) ? '' : design.html;
        const translations = isDisplayTemplate(state.template?.type) ? '' : design.translations;

        let { css } = design;
        // Remove widgets, row, columns css if the template is a display
        if (isDisplayTemplate(state.template?.type)) {
          css = css.replace(/([^{}]*#spm_(?:widget|column|row)_[a-zA-Z0-9]+[^{]*{[^}]*})/gm, '');
          // Add custom data
          const customDataDefinitions = customDataDisplay[type][getTemplateParentTypeByType(state.template?.type)] || [];
          const customDatas = [];
          const $designHtml = cheerio.load(design.html);
          // eslint-disable-next-line no-restricted-syntax
          for (const customDataDefinition of customDataDefinitions) {
            const selectedElement = $designHtml('body').find(customDataDefinition.selector);
            if (selectedElement) {
              const selectedElementClasses = selectedElement.attr('class')?.split(' ') || [];
              const matchingClasses = selectedElementClasses.filter((cls) => customDataDefinition.classes.includes(cls));

              const matchingAttributes = customDataDefinition.attributes
                .map((attrName) => {
                  const attrValue = selectedElement.attr(attrName);
                  return attrValue !== undefined ? { attribute: attrName, value: attrValue } : null;
                })
                .filter((attr) => attr !== null);

              customDatas.push({
                selector: customDataDefinition.selector,
                classes: matchingClasses,
                attributes: matchingAttributes,
              });
            }
          }
          if (customDatas.length) {
            data.specificCssRules = customDatas;
          } else {
            delete data.specificCssRules;
          }
        }

        const hash = calculatePartHash(html, css, translations);

        const { newSection } = await savePart({
          type,
          data,
          html,
          css,
          translations,
          hash,
        });

        const templateState = state.template;

        if (templateState) {
          refreshSectionsConfig();
          updateSectionsInState();
          refreshSmartProductList();
          await refreshSmartProductList();

          const returnValue: Record<string, any> = {
            newSection,
            syncId: null,
            newPart: {
              html,
              css: design.css,
              translations,
              data: JSON.stringify(data),
              hash,
              type,
              name,
            },
          };

          if (isSync) {
            returnValue.syncId = syncId;
          }

          return returnValue;
        }
      }
    }
  } else if (type !== TemplateStructureEnum.DESIGN && template) {
    // Get part element and sanitize (remove useless elements)
    const activeStructure = getElementFromIframe(selector);
    sanitizeSection(activeStructure);

    const elementId = activeStructure.getAttribute('id');
    let sectionId: any = null;
    if (type === TemplateStructureEnum.SECTION) {
      // Get part ID
      sectionId = activeStructure.getAttribute('data-spmelementid');
    } else if (type === TemplateStructureEnum.WIDGET) {
      let sectionClass = TEMPLATE_SECTION_CLASS;
      if (isDisplayTemplate(state.template?.type)) {
        sectionClass = 'spm_display';
      }
      const section = activeStructure.closest(`.${sectionClass}`);
      if (section) {
        sectionId = section.getAttribute('data-spmelementid');
      }
    }

    const oldSectionId = sectionId;

    const sectionCss = template.querySelectorAll(`style[data-spm-section="${sectionId}"]`);

    let partCss: Maybe<string> = '';
    if (type === TemplateStructureEnum.SECTION) {
      partCss = formatStylesForSection(Array.from(sectionCss));
    } else if (type === TemplateStructureEnum.WIDGET) {
      // Extract widget css
      const widgetCss: Record<string, string> = {};
      Array.from(sectionCss).forEach((css) => {
        const styleType = css.getAttribute('data-spm-styles');
        if (styleType) {
          const elementCss = getCssOfElement(elementId ?? '', styleType);
          if (elementCss) {
            widgetCss[styleType] = elementCss;
          }
        }
      });
      partCss = JSON.stringify(widgetCss);
    }

    // Get part data
    if (Object.keys(JSON.parse(partCss)).length === 0) {
      partCss = null;
    }

    const translationIds = getTranslationIdsFromElement(activeStructure);

    let partTranslations: Maybe<string> = formatTranslationsForSection(state.template?.translations
      .filter((translation: Translation) => translationIds.includes(translation.key)) ?? []);

    if (Object.keys(partTranslations).length === 0) {
      partTranslations = null;
    }

    let partData: Maybe<{ [key: string]: any }> = {};

    if (activeStructure.hasAttribute('data-spm_element_sync')) {
      partData['data-spm_element_sync'] = activeStructure.getAttribute('data-spm_element_sync') ?? '';
    }

    let partType = null;
    if (activeStructure.hasAttribute('data-spm_section_type')) {
      partData['data-spm_section_type'] = activeStructure.getAttribute('data-spm_section_type') ?? '';
      partType = activeStructure.getAttribute('data-spm_section_type') ?? '';
    }

    if (isSync) {
      partData[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] = syncId;
    } else {
      const syncWidgets = activeStructure.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}]`);
      const syncWidgetsId = Array.from(syncWidgets).map((element) => element.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER));
      if (syncWidgetsId && syncWidgetsId.length) {
        partData[TEMPLATE_SYNC_ELEMENT_IDs] = syncWidgetsId;
      }
    }

    if (Object.keys(partData).length === 0) {
      partData = null;
    }

    // Remove attributes for hash calculation
    activeStructure.setAttribute('data-spm_element_hash', '');
    activeStructure.removeAttribute('data-spmelementid');

    // Part hash calculation
    const partHash = calculatePartHash(activeStructure.outerHTML, partCss ?? '', partTranslations ?? '');

    // Set hash and elementid attributes
    activeStructure.setAttribute('data-spm_element_hash', partHash);
    if (type === TemplateStructureEnum.SECTION) {
      activeStructure.setAttribute('data-spmelementid', oldSectionId ?? '');
    }

    // First, we need to reset the smart products lists original codes (to duplicate the real HTML code)
    restoreSmartListsOriginalCode();

    // Get part HTML, CSS and translations
    const partHtml = replaceTranslationsByKey(activeStructure.outerHTML, state.template?.translations ?? []);

    const { newSection, newSectionId } = await savePart({
      type: partType,
      data: partData,
      html: partHtml,
      css: partCss,
      translations: partTranslations,
      hash: partHash,
    });

    sectionId = newSectionId;

    const templateState = state.template;

    if (templateState) {
      refreshSectionsConfig();
      updateSectionsInState(false);
      refreshSmartProductList();
      await refreshSmartProductList();

      const returnValue: Record<string, any> = {
        newSection,
        syncId: null,
        newPart: {
          html: partHtml,
          css: partCss,
          translations: partTranslations,
          data: JSON.stringify(partData),
          hash: partHash,
          type,
          name,
        },
      };

      if (isSync) {
        returnValue.syncId = syncId;
      }

      return returnValue;
    }
  }
};

/**
 * Save a new template part in database
 * @param item
 * @param newName
 * @param eraseId
 */
// eslint-disable-next-line consistent-return
export const duplicatePartInDatabase = async (item: TemplatePart, newName: string, eraseId: Maybe<number> = null) => {
  const duplicatePartsInput: Record<string, any> = {
    id_template_parts: item.id_template_parts,
    id_shop: UserState.activeShop?.id ?? 0,
    name: newName,
    erase_id: eraseId,
  };

  const templateParts = await duplicateTemplatePart(duplicatePartsInput);
  if (templateParts && templateParts.id) {
    const templateType = TemplateEditorState.template?.type ? [TemplateEditorState.template?.type] : [];
    const newTemplatePart = await getTemplatesParts({
      shopId: UserState.activeShop?.id ?? 0,
      type: [],
      templateType,
      limit: 0,
      offset: 0,
      idTemplatePart: templateParts.id,
    });

    if (newTemplatePart && newTemplatePart.length) {
      return {
        newSectionId: newTemplatePart[0].id_template_parts,
        newSection: newTemplatePart[0],
      };
    }
  }
  return null;
};

/**
 * Rename a template part in database
 * @param itemId
 * @param name
 */
// eslint-disable-next-line consistent-return
export const renamePartInDatabase = async (itemId: number, name: string) => {
  // We update the existing part
  const parts: TemplatesPartsUpdateInputItem[] = [{
    id_template_parts: itemId,
    id_shop: UserState.activeShop?.id ?? 0,
    name,
  }];

  const templatePartId = await updateTemplatePart(parts) ?? 0;
  return templatePartId;
};

/**
 * Check if a form widget already exists in the current template
 */
export const formExistsInTemplate = (): boolean => {
  const template = getTemplateIframeDocument();
  let returnValue = false;

  if (template) {
    const forms = template.querySelector('.spm_widget_display_form_registration, .spm_widget_display_form_newsletter, .spm_widget_display_form_newsletter_unsubscribe');

    if (forms) {
      returnValue = true;
    }
  }

  return returnValue;
};

/**
 * Store in the state of each template_element the original code of the smart products list
 */
export const getSmartProductsListsOriginalStructure = () => {
  if (state.template && !Object.keys(state.template.smartLists).length) {
    const smartLists: SmartLists = {};
    const template = getTemplateIframeDocument();
    const type: TemplateParentTypeEnum = getTemplateParentTypeByType(state.template?.type);

    // We get all products lists according to template type
    const productsLists = template.querySelectorAll(`.${PRODUCTS_LISTS_CLASSES[type]}`);

    if (productsLists) {
      Array.from(productsLists).forEach((list) => {
        // We remove the originalCode element if it's an old products' list
        const originalCode = list.querySelector('.originalCode');

        if (originalCode) {
          // If exists, we get its content to replace it in the parent of the element
          const { parentElement } = originalCode;

          if (parentElement) {
            parentElement.innerHTML = originalCode.innerHTML.trim();
          }
        }
        // For each products' list, we get the inner code and the ID of the widget, and we store the list in the state of the current template
        const widgetId = list.getAttribute('id') ?? '';
        smartLists[widgetId] = list.innerHTML;
      });
    }

    state.template.smartLists = smartLists;
  }
};

export const htmlHasSmartlist = (html: string) => {
  const $ = cheerio.load(html);
  return $('[s-nb], [s-grid]', '.spm_product_list_element').length > 0;
};

/**
 * Add the original code of the smart list for widget
 * @param widgetId
 * @param html
 */
export const addSmartListOriginalCode = (widgetId: string, html: Maybe<string> = null) => {
  const template = getTemplateIframeDocument();

  if (template && state.template) {
    if (html) {
      state.template.smartLists[widgetId] = html;
    } else {
      const widget = template.querySelector(`#${widgetId}`) as HTMLElement;

      // We reset hover styles on the widget (if any)
      widget.classList.remove(BUILDER_ELEMENT_HOVER_CLASS);

      // Remove the element with ID 'widget-actions-buttons-group' within the widget
      const actionButtonsGroup = widget.querySelector('#widget-actions-buttons-group');
      if (actionButtonsGroup) {
        actionButtonsGroup.remove();
      }

      // eslint-disable-next-line max-len
      if (widget && (!Object.keys(state.template.smartLists).includes(widgetId) || (state.template.smartLists[widgetId] !== widget.innerHTML && widget.innerHTML.match('s-engine=')))) {
        state.template.smartLists[widgetId] = widget.innerHTML;
      }
    }
  }
};

export interface Notification {
  title: string;
  options: NotificationOptions;
}

/**
 * Get test data for push notification
 * @return Notification
 */
export const getTestPushNotificationData = (): Notification => {
  const title: string | undefined = getContent('.push-notification-title');
  const body: string | undefined = getContent('.push-notification-body');
  const icon: string | undefined = getAttributeByName('.push-notification-icon img', 'src');
  const image: string | undefined = getAttributeByName('.push-notification-image img', 'src');
  const url: string | undefined = getAttributeByName('.push-notification-link', 'data-redirect');

  return {
    title: title ?? '',
    options: {
      body: body ? br2nl(body) : '',
      icon: icon ?? '',
      image: image ?? '',
      data: {
        url: url?.match('/^http/') ? url : null,
      },
    },
  };
};

/**
 * Send a notification
 * @param notificationData
 */
export const sendTestNotification = (notificationData: Notification) => {
  Notification.requestPermission().then((permission) => {
    const notificationTitle = notificationData.title;
    const notificationOptions: NotificationOptions = notificationData.options;

    // If the user accepts, we send him the notification
    if (permission === 'granted') {
      const notification = new Notification(notificationTitle, notificationOptions);

      if (notificationData.options.data.url) {
        // If there is a link, we redirect the user to the url
        notification.onclick = (event) => {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.open(notificationData.options.data.url, '_blank');
        };
      }
    }
  });
};

/**
 * Get smart list original code for widget as an HTMLElement
 * @param widgetId
 */
export const getSmartListHtmlELementFromState = (widgetId: string): Maybe<string> => {
  let returnValue = null;

  if (state.template && Object.keys(state.template.smartLists).includes(widgetId)) {
    returnValue = state.template.smartLists[widgetId];
  }

  return returnValue;
};

/**
 * Add or remove spm_just_image class
 */
export const addOrRemoveJustImageClass = (): boolean => {
  let returnValue = false;
  const template = getTemplateIframeDocument();

  if (template) {
    const rows = template.querySelectorAll('.spm_draggable_row');

    if (rows.length) {
      rows.forEach((row) => {
        if (row.querySelectorAll('.spm_draggable_widget:not(.spm_widget_image)').length === 0 && row.querySelector('.spm_draggable_widget.spm_widget_image')) {
          // Rows containing only images without the correct CSS properties and classes
          const cells = row.querySelectorAll('td, th');

          if (cells.length) {
            cells.forEach((cell) => {
              (cell as HTMLElement).style.fontSize = '0px';
              (cell as HTMLElement).style.lineHeight = '0px';
              cell.classList.add('spm_just_image');
            });
          }

          returnValue = true;
        } else {
          // Rows containing not images widgets but with images only properties
          const cells = row.querySelectorAll('td.spm_just_image, th.spm_just_image');

          if (cells.length) {
            cells.forEach((cell) => {
              (cell as HTMLElement).style.fontSize = '';
              (cell as HTMLElement).style.lineHeight = '';
              cell.classList.remove('spm_just_image');
            });
          }

          returnValue = true;
        }
      });
    }
  }

  return returnValue;
};

/**
 * Check email template format before saving
 * @param t
 */
const checkEmailTemplateFormatOld = async (t: any): Promise<boolean> => {
  let returnValue = false;
  const template = getTemplateIframeDocument();

  if (template) {
    // We add the responsive class for not responsive products lists inside a responsive element
    const productsListsNotResponsive = template.querySelectorAll('.spm_responsive .spm_product_list:not(.spm_responsive)');

    if (productsListsNotResponsive.length) {
      productsListsNotResponsive.forEach((element) => {
        element.classList.add('spm_responsive');
      });

      returnValue = true;
    }

    // We check if there are rows containing only images to remove spaces (font-size, line-height ...)
    if (addOrRemoveJustImageClass()) {
      returnValue = true;
    }

    const bodyHtml = template.querySelector('body')?.outerHTML ?? '';

    // We check if each variable is available in the HTML
    const hasUnsubscribeUrlLink = /\{var=template.unsubscribe_url}/.test(bodyHtml);
    // If one variable is missing, we add a new wisdget at the end of the last section and we save the section
    if (!hasUnsubscribeUrlLink) {
      checkIfSavePointNeeded().then(() => {
        const htmlToInsert = rawMandatoryVariablesWidget(t, hasUnsubscribeUrlLink);
        const rows = template.querySelectorAll('.spm_draggable_row');

        if (rows && rows.length) {
          const lastRow = Array.from(rows).slice(-1);

          if (lastRow) {
            const row = lastRow[0] as HTMLElement;
            row.insertAdjacentHTML('beforeend', htmlToInsert);
            resetListeners();
            const section = row.closest('[data-spmelementid]');

            if (section) {
              // Mark the contact as the current active section and save history
              const sectionId = section.getAttribute('data-spmelementid');

              if (sectionId) {
                setActiveSection(parseInt(sectionId, 10));

                // Update state
                updateSectionsInState(false);

                createHistory(HistoryType.LEGAL_MENTION_ADDED);

                resetActiveSection();
              }
            }

            returnValue = true;
          }
        }
      });
    }
  }

  return returnValue;
};

/**
 * Check display template format before saving
 * @param t
 */
const checkDisplayTemplateFormat = async (t: any): Promise<boolean> => {
  let returnValue = false;

  const template = getTemplateIframeDocument();

  if (template) {
    const formWidget = template.querySelector('#spm_body .spm_widget_display_form_newsletter, #spm_body .spm_widget_display_form_registration');

    if (formWidget) {
      const privacyPolicyLink = template.querySelector('#spm_body a[href="{var=shop.privacy_policy_url}"]');

      if (!privacyPolicyLink) {
        const contentElement = template.querySelector('#spm_content');

        if (contentElement) {
          const section = contentElement.closest('[data-spmelementid]');

          if (section) {
            // Mark the contact as the current active section and save history
            const sectionId = section.getAttribute('data-spmelementid');

            if (sectionId) {
              checkIfSavePointNeeded().then(() => {
                setActiveSection(parseInt(sectionId, 10));
                const htmlToInsert = rawPrivacyPolicy(t);
                const { newHtml } = generateTranslations(htmlToInsert, { newElement: true });
                formWidget.insertAdjacentHTML('afterend', newHtml);
                resetListeners();

                // Update state
                updateSectionsInState(false);

                createHistory(HistoryType.LEGAL_MENTION_ADDED);

                resetActiveSection();
              });
            }
          }

          returnValue = true;
        }
      }
    }
  }

  return returnValue;
};

/**
 * Facebook profile picture replacement in widgets
 */
const replaceFacebookProfilePicture = (): boolean => {
  let returnValue = false;
  const template = getTemplateIframeDocument();

  if (template) {
    const fbPagePicture = UserState.activeShop?.linkedFacebookPage?.pagePicture ?? null;

    if (fbPagePicture) {
      // If the linked page picture is defined, we check if we need to replace it in the HTML
      (template.querySelectorAll('.fb_profile_picture') ?? []).forEach((picture) => {
        const pictureElement = picture.querySelector('img');

        if (pictureElement) {
          const currentPicture = pictureElement.getAttribute('src');
          if (currentPicture) {
            if (fbPagePicture !== currentPicture) {
              pictureElement.setAttribute('src', fbPagePicture);
              returnValue = true;
            }
          }
        }
      });
    }
  }

  return returnValue;
};

/**
 * Check for links with spaces at the beginning or at the end, or encoded links
 */
const checkBadFormattedLinks = (): boolean => {
  let returnValue = false;
  const template = getTemplateIframeDocument();

  if (template) {
    // We check for links with spaces at the beginning or at the end, or encoded links
    const links = template.querySelectorAll('a');

    if (links.length) {
      links.forEach((link) => {
        const originalHref = link.getAttribute('href');

        // We check if the link is a variable, in that case we skip the link
        if (originalHref) {
          let href = originalHref;

          if (href) {
            try {
              href = decodeURIComponent(href);
            } catch (e) {
              // Do nothing
            }

            if (href.trim() !== originalHref) {
              // If link was not good, we replace with the good value
              link.setAttribute('href', href.trim());

              // We also check the value in translations
              const translationKey = link.getAttribute(`${TRANSLATION_ATTRIBUTE}-href`);

              if (translationKey) {
                (state.template?.translations ?? []).filter((current) => current.key === translationKey.replace('LANG_', ''))
                  .forEach((currentTranslation) => {
                    if (currentTranslation.value === originalHref) {
                      replaceTranslationInState(currentTranslation.key, currentTranslation.language, href.trim());
                    }
                  });
              }

              returnValue = true;
            }
          }
        }
      });
    }
  }

  return returnValue;
};

// Delete a translation key for each language
const deleteTranslationKey = (originalTranslations: Record<string, any>, key: string): Record<string, any> => {
  const newTranslations = { ...originalTranslations };

  Object.keys(newTranslations).forEach((lang) => {
    if (Object.prototype.hasOwnProperty.call(newTranslations, lang)) {
      const translations = newTranslations[lang];

      if (Object.prototype.hasOwnProperty.call(translations, key)) {
        delete translations[key];
      }
    }
  });

  return newTranslations;
};

// Remove unused translations from each section of the current template
export const removeUnusedTranslations = (): void => {
  const template = getTemplateIframeDocument();
  if (template && state.template && state.template.sections) {
    const defaultLanguage = getShopDefaultLang();
    let hasChanged = false;

    state.template.sections
      .filter((section: Section) => section.type !== 'design')
      .forEach((section: Section) => {
        // For each section, we will check if all translations exist in the HTML code, otherwise we remove the translation
        if (section.translations) {
          let sectionHasChanged = false;
          let translations = JSON.parse(section.translations);

          if (Object.keys(translations).length > 0) {
            const defaultTranslations = translations[defaultLanguage];

            Object.keys(defaultTranslations)
              .forEach((key: string) => {
                // For each translation key, we check if it exists in the HTML code, otherwise we delete the key from the translations
                if (!(new RegExp(`LANG_${key}`)).test(section.html)) {
                  translations = deleteTranslationKey(translations, key);
                  sectionHasChanged = true;
                  hasChanged = true;
                }
              });

            if (sectionHasChanged) {
              // In case we deleted a key, we update the section's translations string
              section.translations = JSON.stringify(translations);
            }
          }
        }
      });

    // Update global template's translations with updated sections
    if (hasChanged) {
      refreshTranslations();
    }
  }
};
export const setPerfectSizeForImage = async (image: HTMLImageElement, forceMaxWidth: string | number | null): Promise<void> => {
  const parent = getParents(image, 'th');
  let widthSetted = false;
  // Remove unused attribut
  if (image.hasAttribute(PERCENTAGE_WIDTH_ATTRIBUTE)) {
    image.removeAttribute(PERCENTAGE_WIDTH_ATTRIBUTE);
  }
  if (parent && parent.length) {
    const parentElement = parent[0];
    const parentWidth = parentElement.offsetWidth;
    // When image is loaded, we calculate its width
    const currentImage = image as HTMLImageElement;
    const imgWidth = currentImage.naturalWidth;
    if (parentWidth) {
      // We set the proper CSS rule according to the lowest calculated width
      let maxWidth = (imgWidth && imgWidth < parentWidth) ? imgWidth : parentWidth;

      // We check if image has forced max-width
      let cssOfImage = window.getComputedStyle(image);
      let imgWidthAsPercent = '';
      // Check if parent is image widget
      const hasParentId = getFirstParentWithId(image);
      if (cssOfImage.getPropertyValue('max-width') || (forceMaxWidth && typeof forceMaxWidth === 'string')) {
        let currentMaxWidth: string | number = cssOfImage.getPropertyValue('max-width');
        let currentWidth: string | number = 0;
        if (forceMaxWidth && typeof forceMaxWidth === 'string') {
          currentMaxWidth = forceMaxWidth;
        } else if (hasParentId) {
          // Is included in image widget, we get the widget ID to create the CSS rule
          const selector2 = `#${hasParentId.id} img`;
          const cssOfImage2 = getDynamicStylesForSelector(selector2);

          currentWidth = cssOfImage2.width;
          if ((new RegExp('%$')).test(currentWidth)) {
            currentMaxWidth = currentWidth;
          }
        }
        if (currentMaxWidth && currentMaxWidth !== '') {
          if (!(new RegExp('%$')).test(currentMaxWidth)) {
            currentMaxWidth = parseInt(currentMaxWidth.replace('px', ''), 10);
          } else if ((new RegExp('%$')).test(currentMaxWidth)) {
            imgWidthAsPercent = currentMaxWidth.toString();
            // We set the correct value in pixels according to percentage of max width
            currentMaxWidth = 0;
            if (imgWidth) {
              maxWidth = imgWidth;
            }
          }
          if (currentMaxWidth && currentMaxWidth < maxWidth) {
            // If forced max-width is less than the calculated max-width, we keep the forced one
            maxWidth = currentMaxWidth;
          }
        }
      }

      if (maxWidth) {
        if (hasParentId) {
          // Is included in image widget, we get the widget ID to create the CSS rule
          const selector = `#${hasParentId.id} img`;

          // Set active section in state (usefull to get/set css values in correct stylesheet)
          setActiveSection(computeActiveSection(TemplateStructureEnum.WIDGET, hasParentId.id));
          // Add CSS rule with max-width
          addOrUpdateDynamicStyle(selector, { 'max-width': `${maxWidth}px` });
          if (imgWidthAsPercent !== '') {
            addOrUpdateDynamicStyle(selector, { height: 'auto', width: imgWidthAsPercent });
            // We add the width attribute with the correct value
            cssOfImage = window.getComputedStyle(image);
            let imgComputedWidth = 0;
            if (cssOfImage.getPropertyValue('width')) {
              imgComputedWidth = parseInt(cssOfImage.getPropertyValue('width'), 10);
            }
            if (imgComputedWidth) {
              image.setAttribute('width', String(imgComputedWidth));
              widthSetted = true;
            }
          } else {
            removeDynamicStyle(selector, { height: '', width: '' });
            // We add the width attribute with the correct value
            image.setAttribute('width', maxWidth);
            widthSetted = true;
          }
          image.style.maxWidth = '';
          // Reset active section
          resetActiveSection();
        } else {
          // Image is not included in image widget, we add the CSS rule on the element
          image.style.maxWidth = `${maxWidth}px`;
          // We add the width attribute with the correct value
          image.setAttribute('width', maxWidth);
          widthSetted = true;
        }
      }
    }
  }
  if (!widthSetted) {
    image.removeAttribute('width');
  }
};
/**
 * Add max-width CSS rule and width attribute for each image having a valid src attribute
 * Convert percentage to pixels and store old percentage as a data attribute of the image
 * @param template
 */
export const fixImagesWidthBeforeSave = async (template: Document): Promise<boolean> => {
  const images = template.querySelectorAll('img');

  if (images && images.length) {
    images.forEach((image) => {
      // eslint-disable-next-line max-len
      if (!getFirstParent(image, '.spm_widget_import_zip') && image.hasAttribute('src') && (new RegExp('^http')).test(image.src) && !getFirstParent(image, '.spm_product_list')) {
        image.width = 0;
      }
    });

    await Array.from(images).reduce(async (a, image) => {
      await a;
      // eslint-disable-next-line max-len
      if (!getFirstParent(image, '.spm_widget_import_zip') && image.hasAttribute('src') && (new RegExp('^http')).test(image.src) && !getFirstParent(image, '.spm_product_list')) {
        await setPerfectSizeForImage(image, null);
      }
    }, Promise.resolve());
  }

  return true;
};

const checkLineHeightCssRuleForImages = () => {
  // Check if image CSS rule is set
  const design = TemplateEditorState.template?.sections
    .filter((sectionOfTemplate) => sectionOfTemplate.type === 'design');

  if (design && design.length > 0) {
    // Get static stylesheet
    let staticStylesheet = getElementFromIframe(`style[data-spm-styles="static"][data-spm-section="${design[0].id_template_elements}"]`);

    if (!staticStylesheet) {
      // We create the style element if it doesn't exist
      staticStylesheet = createNewStylesheet('static', design[0].id_template_elements.toString());
    }

    const css = staticStylesheet.innerHTML;

    // If the rule is not in the stylesheet, we add it
    if (!(new RegExp('table.spm_widget_image th', 'i')).test(css)) {
      staticStylesheet.append('table.spm_widget_image th { line-height:0!important; font-size:0!important; mso-line-height-alt:0!important;}');
    }
  }
};

const checkCssRuleForSmartProductsListsResponsiveness = () => {
  // Check if CSS rule is set
  const design = TemplateEditorState.template?.sections
    .filter((sectionOfTemplate) => sectionOfTemplate.type === 'design');

  if (design && design.length > 0) {
    // Get static_persistent_mobile stylesheet
    const staticStylesheet = getElementFromIframe(`style[data-spm-styles="static_persistent_mobile"][data-spm-section="${design[0].id_template_elements}"]`);

    if (staticStylesheet) {
      const css = staticStylesheet.innerHTML;

      // If the rule is not in the stylesheet, we add it
      if ((new RegExp('#spm_body table.row.spm_responsive > tbody > tr > td,'
        + ' #spm_body table.row.spm_responsive > tbody > tr > th,'
        + ' #spm_body table.row.spm_responsive table.spm_draggable_row > tbody > tr > td,'
        + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th,'
        + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th', 'i')).test(css)
        && !(new RegExp('#spm_body table.row.spm_responsive > tbody > tr > td,'
          + ' #spm_body table.row.spm_responsive > tbody > tr > th,'
          + ' #spm_body table.row.spm_responsive table.spm_draggable_row > tbody > tr > td,'
          + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th,'
          + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th,'
          + ' #spm_body .row.spm_responsive table > tbody > tr > th.column', 'i')).test(css)) {
        staticStylesheet.innerHTML = staticStylesheet.innerHTML
          .replace(
            '#spm_body table.row.spm_responsive > tbody > tr > td,'
            + ' #spm_body table.row.spm_responsive > tbody > tr > th,'
            + ' #spm_body table.row.spm_responsive table.spm_draggable_row > tbody > tr > td,'
            + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th,'
            + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th',
            '#spm_body table.row.spm_responsive > tbody > tr > td,'
            + ' #spm_body table.row.spm_responsive > tbody > tr > th,'
            + ' #spm_body table.row.spm_responsive table.spm_draggable_row > tbody > tr > td,'
            + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th,'
            + ' #spm_body .row.spm_responsive table.spm_draggable_widget > tbody > tr > th,'
            + ' #spm_body .row.spm_responsive table > tbody > tr > th.column',
          );
      }
    }
  }
};

export const checkMultipleClassesSelectors = () => {
  // Check if CSS rule is set
  const design = TemplateEditorState.template?.sections
    .filter((sectionOfTemplate) => sectionOfTemplate.type === 'design');

  if (design && design.length > 0) {
    // Get static_persistent_mobile stylesheet
    const staticStylesheet = getElementFromIframe(`style[data-spm-styles="static_persistent_mobile"][data-spm-section="${design[0].id_template_elements}"]`);

    if (staticStylesheet) {
      let css = staticStylesheet.innerHTML;
      let updateCss = false;
      if (css !== '') {
        if (css.match(/table\[id="spm_body"]/ig)) {
          updateCss = true;
          css = css.replace(/table\[id="spm_body"]/ig, '#spm_body');
        }
        if (css.match(/.row:not\(\.spm_no_responsive\)/ig)) {
          updateCss = true;
          css = css.replace(/.row:not\(\.spm_no_responsive\)/ig, '.row.spm_responsive');
        }
        if (css.match(/#spm_body \.row\.spm_responsive\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*>\s*\.columns,[^}]*display:\s*block\s*!important;/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/(#spm_body \.row\.spm_responsive\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*>\s*\.columns,[^{]*)([^}]*)(display:\s*block\s*!important;)/ig, '$1, #spm_body .row.spm_responsive > tbody > tr > td > .columns > tbody > tr > .column table $2 display: table !important;');
        }
        if (css.match(/#spm_body\s*\.hide-for-small,\s*#spm_body\s*\.show-for-desktop\s*\{/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/(#spm_body\s*\.hide-for-small,\s*#spm_body\s*\.show-for-desktop)\s*\{/ig, '$1, #spm_body .row.spm_responsive > tbody > tr > td > .columns > tbody > tr > .column table.hide-for-small, #spm_body .row.spm_responsive > tbody > tr > td > .columns > tbody > tr > .column table.show-for-desktop {');
        }
        if ((new RegExp('.row.spm_responsive', 'i')).test(css)) {
          updateCss = true;
          // Issue found, we fix it
          css = css
            .replace(/\.row\.spm_responsive/gi, '.spm_responsive')
            .replace(/\.spm_droppable_widget\.show-for-small/gi, '.show-for-small')
            .replace(/\.spm_draggable_row\.show-for-small/gi, '.show-for-small')
            .replace(/\.spm_draggable_row\.hide-for-desktop/gi, '.hide-for-desktop')
            .replace(/\.spm_droppable_widget\.hide-for-desktop/gi, '.hide-for-desktop');
        }
        if (css.match(/#spm_body\s*.spm_responsive img\s*{/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/#spm_body\s*.spm_responsive img\s*{/ig, '#spm_body img {');
        }
        // Correction responsive lors de l'utilisation cummulé de non responsive et de la restriction à un media
        // eslint-disable-next-line max-len
        if (css.match(/#spm_body\s*.show-for-small,\s*#spm_body\s*.hide-for-desktop,\s*#spm_body\s*.show-for-small\s*th,\s*#spm_body\s*.hide-for-desktop\s*th,\s*#spm_body\s*.show-for-small\s*td,\s*#spm_body\s*.hide-for-desktop\s*td,\s*#spm_body\s*.show-for-small\s*table,\s*#spm_body\s*.hide-for-desktop\s*table\s*{\s*display:\s*block\s*!important;/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/#spm_body\s*.show-for-small,\s*#spm_body\s*.hide-for-desktop,\s*#spm_body\s*.show-for-small\s*th,\s*#spm_body\s*.hide-for-desktop\s*th,\s*#spm_body\s*.show-for-small\s*td,\s*#spm_body\s*.hide-for-desktop\s*td,\s*#spm_body\s*.show-for-small\s*table,\s*#spm_body\s*.hide-for-desktop\s*table\s*{\s*display:\s*block\s*!important;/ig, '#spm_body .show-for-small,\n'
            + '        #spm_body .hide-for-desktop,\n'
            + '        #spm_body .show-for-small th,\n'
            + '        #spm_body .hide-for-desktop th,\n'
            + '        #spm_body .show-for-small td,\n'
            + '        #spm_body .hide-for-desktop td,\n'
            + '        #spm_body .show-for-small table,\n'
            + '        #spm_body .hide-for-desktop table {\n'
            + '          display: table-cell !important;\n'
            + '          height: auto !important;\n'
            + '          overflow: visible !important;\n'
            + '          float: none !important;\n'
            + '          visibility: visible !important;\n'
            + '          width: auto !important;\n'
            + '          max-height: inherit !important;\n'
            + '        }\n'
            + '\t\t#spm_body .show-for-small .container,\n'
            + '        #spm_body .hide-for-desktop .container {\n'
            + '\t\t\twidth: 95% !important;\n'
            + '\t\t\tmin-width: 0 !important;\n'
            + '\t\t}\n'
            + '\n'
            + '        #spm_body .spm_responsive .show-for-small,\n'
            + '        #spm_body .spm_responsive .hide-for-desktop,\n'
            + '        #spm_body .spm_responsive .show-for-small th,\n'
            + '        #spm_body .spm_responsive .hide-for-desktop th,\n'
            + '        #spm_body .spm_responsive .show-for-small td,\n'
            + '        #spm_body .spm_responsive .hide-for-desktop td,\n'
            + '        #spm_body .spm_responsive .show-for-small table,\n'
            + '        #spm_body .spm_responsive .hide-for-desktop table {\n'
            + '          display: block !important;');
        }

        // eslint-disable-next-line max-len
        if (css.match(/#spm_body\s*th.show-for-small,\s*#spm_body\s*th.hide-for-desktop,\s*#spm_body\s*table.show-for-small\s*>\s*tbody\s*>\s*tr\s*>\s*td,\s*#spm_body\s*table.hide-for-desktop\s*>\s*tbody\s*>\s*tr\s*>\s*td,\s*#spm_body\s*table.show-for-small\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*th.spm_droppable_widget,\s*#spm_body\s*table.hide-for-desktop\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*th.spm_droppable_widget\s*{\s*max-width:\s*inherit\s*!important;/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/#spm_body\s*th.show-for-small,\s*#spm_body\s*th.hide-for-desktop,\s*#spm_body\s*table.show-for-small\s*>\s*tbody\s*>\s*tr\s*>\s*td,\s*#spm_body\s*table.hide-for-desktop\s*>\s*tbody\s*>\s*tr\s*>\s*td,\s*#spm_body\s*table.show-for-small\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*th.spm_droppable_widget,\s*#spm_body\s*table.hide-for-desktop\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*th.spm_droppable_widget\s*{\s*max-width:\s*inherit\s*!important;/ig, '#spm_body .spm_responsive th.show-for-small,\n'
            + '        #spm_body .spm_responsive th.hide-for-desktop,\n'
            + '        #spm_body .spm_responsive table.show-for-small>tbody>tr>td,\n'
            + '        #spm_body .spm_responsive table.hide-for-desktop>tbody>tr>td,\n'
            + '        #spm_body .spm_responsive table.show-for-small>tbody>tr>td th.spm_droppable_widget,\n'
            + '        #spm_body .spm_responsive table.hide-for-desktop>tbody>tr>td th.spm_droppable_widget {\n'
            + '          max-width: inherit !important;');
        }

        // eslint-disable-next-line max-len
        if (css.match(/#spm_body\s+.show-for-small,\s*#spm_body\s+.hide-for-desktop,\s*#spm_body\s+.show-for-small\s+th,\s*#spm_body\s+.hide-for-desktop\s+th,\s*#spm_body\s+.show-for-small\s+td,\s*#spm_body\s+.hide-for-desktop\s+td,\s*#spm_body\s+.show-for-small\s+table,\s*#spm_body\s+.hide-for-desktop\s+table\s*\{\s*display:\s*table-cell\s*!important;\s*height:\s*auto\s*!important;\s*overflow:\s*visible\s*!important;\s*float:\s*none\s*!important;\s*visibility:\s*visible\s*!important;\s*width:\s*auto\s*!important;/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/(#spm_body\s+.show-for-small,\s*#spm_body\s+.hide-for-desktop,\s*#spm_body\s+.show-for-small\s+th,\s*#spm_body\s+.hide-for-desktop\s+th,\s*#spm_body\s+.show-for-small\s+td,\s*#spm_body\s+.hide-for-desktop\s+td,\s*#spm_body\s+.show-for-small\s+table,\s*#spm_body\s+.hide-for-desktop\s+table\s*\{\s*display:\s*table-cell\s*!important;\s*height:\s*auto\s*!important;\s*overflow:\s*visible\s*!important;\s*float:\s*none\s*!important;\s*visibility:\s*visible\s*!important;)\s*width:\s*auto\s*!important;/ig, '$1');
        }

        // eslint-disable-next-line max-len
        if (css.match(/(<|&lt;)br\s*\/*(>|&gt;)/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '');
        }
      }
      if (updateCss) {
        staticStylesheet.innerHTML = css.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '');
      }
    }
    const staticStylesheet2 = getElementFromIframe(`style[data-spm-styles="static"][data-spm-section="${design[0].id_template_elements}"]`);

    if (staticStylesheet2) {
      let css = staticStylesheet2.innerHTML;
      let updateCss = false;
      if (css !== '') {
        // eslint-disable-next-line max-len
        if (css.match(/.show-for-small,\s*.hide-for-desktop,\s*.show-for-small\s+th,\s*.hide-for-desktop\s+th,\s*.show-for-small\s+td,\s*.hide-for-desktop\s+td,\s*.show-for-small\s+table,\s*.hide-for-desktop\s+table\s*\{\s*display:\s*none\s*!important;\s*width:\s*0\s*!important;/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/(.show-for-small,\s*.hide-for-desktop,\s*.show-for-small\s+th,\s*.hide-for-desktop\s+th,\s*.show-for-small\s+td,\s*.hide-for-desktop\s+td,\s*.show-for-small\s+table,\s*.hide-for-desktop\s+table\s*{\s*display:\s*none\s*!important;)\s*width:\s*0\s*!important;/ig, '$1');
        }
        // eslint-disable-next-line max-len
        if (css.match(/(<|&lt;)br\s*\/*(>|&gt;)/ig)) {
          updateCss = true;
          // eslint-disable-next-line max-len
          css = css.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '');
        }
      }
      if (updateCss) {
        staticStylesheet2.innerHTML = css.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '');
      }
    }
  }
};

export const removeBrFromStylesheets = () => {
  const template = getTemplateIframeDocument();

  if (template) {
    const stylesheets = template.querySelectorAll('style[data-spm-styles]');

    let updateState = false;
    stylesheets.forEach((stylesheet) => {
      const css = beautifier.css(stylesheet.innerHTML);
      if (stylesheet.innerHTML !== '' && css.match(/(\r\n|\n|\r|<br>)/gm)) {
        updateState = true;
        stylesheet.innerHTML = css.replace(/(\r\n|\n|\r|<br>)/gm, '');
      }
    });

    if (updateState) {
      updateSectionsInState(false);
    }
  }
};

const checkCssRulesForHeadingsColors = () => {
  const sections = TemplateEditorState.template?.sections
    .filter((sectionOfTemplate) => sectionOfTemplate.type === 'section');

  if (sections && sections.length > 0) {
    sections.forEach((section: Section) => {
      // Get dynamic stylesheet
      const dynamicStylesheet = getElementFromIframe(`style[data-spm-styles="dynamic"][data-spm-section="${section.id_template_elements}"]`);

      if (dynamicStylesheet) {
        const css = dynamicStylesheet.innerHTML;

        if ((new RegExp('#spm_[a-z]{3,}_[a-z0-9]*[^,]*h4\\s*?\\{\\s+color', 'i')).test(css)) {
          dynamicStylesheet.innerHTML = dynamicStylesheet.innerHTML.replace(
            /(#spm_[a-z]{3,}_[a-z0-9]*[^,]*) h4\s*?\{(\s+color)/gi,
            '$1 h4, $1 h1 a, $1 h2 a, $1 h3 a, $1 h4 a {$2',
          );
        }

        if ((new RegExp('(#spm_body th h)([1234])\\s*?\\{\\s+color', 'i')).test(css)) {
          dynamicStylesheet.innerHTML = dynamicStylesheet.innerHTML.replace(
            /(#spm_body th h)([1234])\s*?\{(\s+color)/gi,
            '$1$2, body #spm_body th h$2 a {$3',
          );
        }
      }
    });
  }
};

const removeUnnecessaryStaticCss = () => {
  const sections = TemplateEditorState.template?.sections
    .filter((sectionOfTemplate) => sectionOfTemplate.type === 'design');

  if (sections && sections.length > 0) {
    sections.forEach((section: Section) => {
      // Get dynamic stylesheet
      const staticStylesheet = getElementFromIframe(`style[data-spm-styles="static"][data-spm-section="${section.id_template_elements}"]`);

      if (staticStylesheet) {
        const css = staticStylesheet.innerHTML;
        // Remove an unused CSS style that is causing problems on outlook for setting button size to text width
        if ((new RegExp('table.button\\s*?th\\s*?\\{[^}]*width:\\s*auto\\s*!important;\\s*}', 'gm')).test(css)) {
          staticStylesheet.innerHTML = staticStylesheet.innerHTML.replace(
            /(table.button\s*?th\s*?\{[^}]*)width:\s*auto\s*!important;(\s*})/gm,
            '$1$2',
          );
        }
      }
    });
  }
};

const removeUselessHiddenElements = (template: Document) => {
  if (template) {
    const hiddenElements = template.querySelectorAll('.spm_draggable_row[style*="display: none"],'
      + ' .spm_draggable_widget[style*="display: none"],'
      + ' .spm_droppable_widget[style*="display: none"],'
      + ' .spm_section[style*="display: none"]');

    if (hiddenElements && hiddenElements.length > 0) {
      // If there are sone hidden elements, we remove them from the template
      hiddenElements.forEach((element) => element.remove());
    }
  }
};

const checkEmailTemplateFormat = async (template: Document, t: any): Promise<boolean> => {
  let returnValue = false;

  // Do not execute if it's an imported model
  if (!TemplateEditorState?.template?.informations.imported) {
    // Check and calculate max-width for images, and set pixels values if width is a percentage, and store the percentage as a data attribute
    await fixImagesWidthBeforeSave(template);

    // Check CSS rule for image widget
    checkLineHeightCssRuleForImages();

    // Check CSS rule for smart products lists responsiveness
    checkCssRuleForSmartProductsListsResponsiveness();

    // Fix responsive compatibility for Outlook not supporting multiple classes selector for one element
    // see https://shopimind.atlassian.net/jira/software/c/projects/SC/issues/SC-1126
    checkMultipleClassesSelectors();

    // Check CSS rules for headings colors
    checkCssRulesForHeadingsColors();

    // Clean BR from stylesheets
    removeBrFromStylesheets();
  }
  // Remove hidden elements which were not hidden by the builder itself
  removeUselessHiddenElements(template);

  const bodyHtml = template.querySelector('body')?.outerHTML ?? '';

  // We check if each variable is available in the HTML
  const hasUnsubscribeUrlLink = /\{var="?template.unsubscribe_url"?}/.test(bodyHtml);
  // If one variable is missing, we add a new wisdget at the end of the last section and we save the section
  if (!hasUnsubscribeUrlLink) {
    checkIfSavePointNeeded().then(() => {
      const rows = template.querySelectorAll('.spm_draggable_row');

      if (rows && rows.length) {
        const lastRow = Array.from(rows).slice(-1);

        if (lastRow) {
          const row = lastRow[0] as HTMLElement;

          resetListeners();
          const section = row.closest('[data-spmelementid]');

          if (section) {
            // Mark the contact as the current active section and save history
            const sectionId = section.getAttribute('data-spmelementid');

            if (sectionId) {
              setActiveSection(parseInt(sectionId, 10));
              const htmlToInsert = rawMandatoryVariablesWidget(t, hasUnsubscribeUrlLink);
              const { newHtml } = generateTranslations(htmlToInsert, { newElement: true, checkDiv: false });
              row.insertAdjacentHTML('afterend', newHtml);
              // Update state
              updateSectionsInState(false);

              createHistory(HistoryType.LEGAL_MENTION_ADDED);

              resetActiveSection();
            }
          }

          returnValue = true;
        }
      }
    });
  }
  return true;
};

/**
 * Replace variables V3 by V4 if the user has written an old variable in the template
 */
const replaceOldVariables = () => {
  let returnValue = false;

  const arrayOfVariables = VARIABLES_V3_TO_V4;
  if (state.template) {
    state.template.translations.forEach((translation) => {
      arrayOfVariables.forEach((variable) => {
        if ((new RegExp(variable.oldVariable)).test(translation.value)) {
          // Variable has been found, we replace it
          translation.value = translation.value.replace(new RegExp(variable.oldVariable, 'g'), variable.newVariable);
          returnValue = true;
        }
      });
    });
  }

  return returnValue;
};

/**
 * Mandatory checks before saving in database and exiting
 * @param t
 */
export const beforeSaveTemplate = async (t: any): Promise<boolean> => {
  // We close the left panel to trigger history saves if needed
  await hideLeftToolbar();

  // Remove unused translation from sections and templates
  removeUnusedTranslations();

  // We update template's configuration in state
  await updateTemplateConfiguration();

  // We apply specific treatments according to template type
  const template = getTemplateIframeDocument();

  if (template) {
    let updateState = false;

    // Convert old variables to new format
    if (replaceOldVariables()) {
      updateState = true;
    }

    if (restoreSmartListsOriginalCode()) {
      // We restore original code for smart lists widgets
      updateState = true;
    }

    if (
      (isFacebookTemplate(state.template?.type) && replaceFacebookProfilePicture())
      || (isDisplayTemplate(state.template?.type) && await checkDisplayTemplateFormat(t))
      || (isEmailTemplate(state.template?.type) && await checkEmailTemplateFormat(template, t))
    ) {
      updateState = true;
    }

    if (checkBadFormattedLinks()) {
      updateState = true;
    }

    if (updateState) {
      // In case of some changes were made, we update the sections in the state
      updateSectionsInState(false);
    }
  }

  return true;
};

/**
 * Get sync elements ids in template
 */
export const getSyncElementsIdsInTemplate = () => {
  const template = getTemplateIframeDocument();
  if (!template) return [];

  const syncElements = template.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}]`);
  const syncElementsIds = new Set();
  Array.from(syncElements).forEach((syncElement) => {
    const syncElementId = syncElement.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
    if (syncElementId) {
      syncElementsIds.add(syncElementId);
    }
  });

  return Array.from(syncElementsIds);
};

/**
 * Save template in database
 */
export const saveTemplate = async (): Promise<boolean> => {
  const templateToSave = state.template;
  if (templateToSave && UserState.activeShop) {
    const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
    const idCampaign = (templateToSave.idCampaign === null && editingTemplateFromCampaign !== null
      && editingTemplateFromCampaign.idTemplate === templateToSave.id) ? editingTemplateFromCampaign.id_campaign : templateToSave.idCampaign;

    const templateInput = {
      id: templateToSave.id,
      content: templateToSave.content,
      type: templateToSave.type,
      idCampaign,
      informations: templateToSave.informations,
      sections: templateToSave.sections,
      configuration: templateToSave.configuration,
    };

    // Get all synced elements
    const syncElementsIds = getSyncElementsIdsInTemplate();
    if (!templateInput.configuration[TEMPLATE_SYNC_ELEMENT_IDs]) {
      templateInput.configuration[TEMPLATE_SYNC_ELEMENT_IDs] = [];
    }
    templateInput.configuration[TEMPLATE_SYNC_ELEMENT_IDs] = [...syncElementsIds];

    const { err } = await CustomMutation<TemplateAndTemplateElementsUpdateInput>({
      name: 'UpdateTemplateAndTemplateElements',
      input: {
        id_shop: UserState.activeShop.id,
        template: JSON.stringify(templateInput),
      },
      type: 'TemplateAndTemplateElementsUpdateInput',
    });

    if (err === '') {
      const baseUrl = process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX;
      const cryptedImageKey = CryptoJS.SHA1(`salt-${templateToSave.id}`).toString();
      const potentialImageUrl = `${baseUrl + cryptedImageKey}${isDisplayTemplate(templateToSave.type) ? '-isdisplay' : ''}-reloadcache.png`;
      loadImage(potentialImageUrl).catch((errTemp: any) => {
        // console.log('err', errTemp);
      });
      await showToastSuccess('savedSuccessful');
      return true;
    }
  }

  await showToastError('noSave');
  return false;
};

/**
 * Convert old widgets' placeholders to new format
 */
const convertOldWidgetsPlaceholders = () => {
  const template = getTemplateIframeDocument();
  ((Array.from(template.querySelectorAll(`[class*="${WIDGETS_PLACEHOLDER_IDENTIFIER}"]`)) as HTMLElement[]) ?? [])
    .forEach((element: HTMLElement) => {
      // Remove content and insert new icon
      const parentColumn = element.parentElement;
      element.remove();
      const div = document.createElement('div');
      div.innerHTML = RAW_PLACEHOLDER_STRUCTURE_MAP[getTemplateParentTypeByType(TemplateEditorState.template?.type)]();
      const placeholder = div.getElementsByClassName(WIDGETS_PLACEHOLDER_IDENTIFIER)[0];
      // eslint-disable-next-line no-unused-expressions
      parentColumn?.appendChild(placeholder);
    });
};

/**
 * Remove contenteditable attributes from old templates
 */
const removeContentEditableFromOldTemplates = () => {
  const template = getTemplateIframeDocument();
  ((Array.from(template.querySelectorAll('[contenteditable]')) as HTMLElement[]) ?? [])
    .forEach((element: HTMLElement) => {
      element.removeAttribute('contenteditable');
    });
};

/**
 * Add element's type as data attribute
 * @param element
 */
export const addElementTypeByLang = (element: HTMLElement) => {
  if (!element.hasAttribute('data-spm-elementtype-text')) {
    let elementType = '';

    if (element.matches(TEMPLATE_SECTION_IDENTIFIER)) {
      // Section
      elementType = translator.global.t('templateBuilder.widgets.section');
    } else if (element.matches(TEMPLATE_COLUMN_IDENTIFIER)) {
      elementType = translator.global.t('templateBuilder.widgets.columnTitle');
    } else {
      // Widget
      const classOfElement = Array.from(element.classList).filter((className) => className !== WIDGET_DRAGGABLE_CLASS.replace('.', '') && className !== 'spm_widget');
      const widgetType = getWidgetType(classOfElement[0]);
      elementType = translator.global.t(`templateBuilder.widgetsNameBubble.${widgetType}`);
    }

    element.setAttribute('data-spm-elementtype-text', elementType);
  }
};

/**
 * Add type of each element as data attribute
 */
const addElementsTypesByLang = () => {
  const template = getTemplateIframeDocument();
  (Array.from(template.querySelectorAll(`${TEMPLATE_SECTION_IDENTIFIER}, ${TEMPLATE_WIDGET_IDENTIFIER}`) ?? []) as HTMLElement[])
    .forEach((element) => addElementTypeByLang(element));
};

/**
 * Prepare template for edition when opening
 */
export const prepareTemplateForEdition = () => {
  // Convert old widgets' placeholders to new format
  convertOldWidgetsPlaceholders();

  // Remove contenteditable attributes from old templates
  removeContentEditableFromOldTemplates();

  // Remove unused static CSS
  removeUnnecessaryStaticCss();

  // Add elements types by language
  addElementsTypesByLang();
};

/**
 * Update section's ID for translations in case of a widget moves from a section to another
 * @param keys
 * @param newSectionId
 */
export const updateSectionIdOfTranslationsInState = (keys: string[], newSectionId: string | number) => {
  if (state.template) {
    state.template.translations
      .filter((translation: Translation) => keys.includes(translation.key))
      .forEach((translation: Translation) => {
        // eslint-disable-next-line no-param-reassign
        translation.section = newSectionId;
      });
  }
};

/**
 * Insert an HTML string in a DOM element based on selector and position
 * @param template
 * @param element
 * @param selector
 * @param position
 */
export const insertEmbedTemplateInDom = (template: Document, element: Element, selector: string, position: string) => {
  let targetElement = template.querySelector(selector);
  if (!targetElement) {
    targetElement = template.querySelector('body');
    position = 'prepend';
  }
  if (targetElement) {
    switch (position) {
      case 'append':
        // eslint-disable-next-line no-unused-expressions
        targetElement.insertAdjacentElement('beforeend', element);
        break;
      case 'prepend':
        // eslint-disable-next-line no-unused-expressions
        targetElement.insertAdjacentElement('afterbegin', element);
        break;
      case 'before':
        // eslint-disable-next-line no-unused-expressions
        targetElement.insertAdjacentElement('beforebegin', element);
        break;
      case 'after':
        // eslint-disable-next-line no-unused-expressions
        targetElement.insertAdjacentElement('afterend', element);
        break;
      case 'replace':
        // We add a class to the target element to hide it and insert the new element after
        targetElement.classList.add(BUILDER_EMBED_REPLACED_ELEMENT_CLASS);
        targetElement.insertAdjacentElement('afterend', element);
        break;
      default:
        // Insert at the end if no position
        targetElement.insertAdjacentElement('beforeend', element);
        break;
    }
  }
};

export const resetIframe = (element: HTMLElement | null = null) => {
  // Get shop's default language
  const defaultLanguage = getShopDefaultLang();

  triggerAdditionalEditorActivationFunctions();
  translateDocument(defaultLanguage, element);
  prepareTemplateForEdition();
  getSmartProductsListsOriginalStructure();
  resetListeners();
  refreshSmartProductList();
};

/**
 * Create HTML code for embedded element and insert in the chosen selector at chosen position
 */
export const insertEmbedTemplateInSelectedElement = () => {
  const template = getTemplateIframeDocument();

  // Remove current template if exists in DOM
  const element = template?.querySelector('spm-template');
  if (element) {
    element.remove();
  }

  // Remove class for hidden element on website
  const hiddenElement = template?.querySelector(`.${BUILDER_EMBED_REPLACED_ELEMENT_CLASS}`);
  if (hiddenElement) {
    hiddenElement.classList.remove(BUILDER_EMBED_REPLACED_ELEMENT_CLASS);
  }

  const spmTemplate = document.createElement('spm-template');
  spmTemplate.innerHTML = getActiveTemplateFullHtml();
  const selector = state.template?.configuration?.embedSelector;
  const position = state.template?.configuration?.embedPosition;
  insertEmbedTemplateInDom(template, spmTemplate, selector, position);
};
type TimeoutId = ReturnType<typeof setTimeout>;

const handlePropertyValuesChangeTimeouts: { [key: string]: TimeoutId | undefined } = {};

export const handlePropertyValuesChange = async (
  { selector, properties }: { selector: string; properties: Array<Property> },
  updateSelectedStructure?: { tabItemLabel?: string; fieldGroupLabel?: string; fieldLabel?: string },
) => {
  if (updateSelectedStructure) {
    store.dispatch('liveEditor/updateSelectedStructureParserValues', { properties, ...updateSelectedStructure });
  }

  // Générer une clé unique basée sur selector et properties
  const key = `${selector}-${JSON.stringify(properties.map((p) => p.name))}`;

  if (handlePropertyValuesChangeTimeouts[key] !== undefined) {
    clearTimeout(handlePropertyValuesChangeTimeouts[key] as TimeoutId);
  }

  // Récupérer property.callbacks avant la réduction
  const propertyCallbacksArray = properties.map((property) => property.callbacks ?? [false]);
  // Récupérer property.refreshWidgetFields avant la réduction
  const propertyRefreshFieldsArray = properties.map((property) => property.refreshWidgetFields ?? [false]);

  let currentTimeout = 0;
  if (propertyCallbacksArray[0] || propertyRefreshFieldsArray[0]) {
    currentTimeout = 400;
  }

  handlePropertyValuesChangeTimeouts[key] = setTimeout(async () => {
    const widgetId = store.getters['liveEditor/getSelectedStructure'].identifier;
    let needRefreshWidgetFields = false;

    await properties.reduce(async (a, property: Property) => {
      await a;

      // if property has callbacks
      await (property.preCallbacks ?? []).reduce(async (b, callback: Function) => {
        await b;
        await callback(widgetId);
      }, Promise.resolve());

      // Update Property
      property.setters.forEach((setter: Function) => setter(selector, property, widgetId));

      // Update translation in state
      updateTranslation(selector, property);

      // if property has callbacks
      await (property.callbacks ?? []).reduce(async (b, callback: Function) => {
        await b;
        await callback(widgetId);
      }, Promise.resolve());

      // if widget fields need to be refreshed
      if (property.refreshWidgetFields) {
        needRefreshWidgetFields = true;
      }
    }, Promise.resolve());

    // if widget fields need to be refreshed
    if (needRefreshWidgetFields) {
      store.dispatch('liveEditor/refreshWidgetFields');
    }

    setChangeInLeftToolbar();
    // Remove the timeout from the object after execution
    delete handlePropertyValuesChangeTimeouts[key];
  }, currentTimeout) as unknown as TimeoutId;
};
const INITIAL_TEMPLATE_STRING_REMOVAL = {
  find: [
    'overflow-wrap:\\s?break-word;',
    'hyphens:\\s?auto;',
    // eslint-disable-next-line max-len
    '(<!--\\[if( gte)? mso( 9)?\\]><xml>\\s*\\t*<o:OfficeDocumentSettings>\\s*\\t*<o:AllowPNG\\s*\\t*/?>(</o:AllowPNG>)?\\s*\\t*<o:PixelsPerInch>96</o:PixelsPerInch>\\s*\\t*</o:OfficeDocumentSettings></xml><!\\[endif\\]-->){2,}',
  ],
  replace: [
    '',
    '',
    '',
  ],
};

/**
 * Does some checks on template before integrating in live view to add properties, elements, ...
 * @param iframeElement
 */
export const templateChecksOnOpening = (iframeElement: Document): Document => {
  /**
   * Checks to do on HTML Elements
   */
  // Add cellspacing and cellpadding properties on table elements
  const tablesElements = iframeElement.querySelectorAll('table');

  if (tablesElements.length > 0) {
    tablesElements.forEach((table) => {
      if (!table.hasAttribute('cellpadding')) {
        table.setAttribute('cellpadding', '0');
      }

      if (!table.hasAttribute('cellspacing')) {
        table.setAttribute('cellspacing', '0');
      }
    });
  }

  /**
   * Checks to do on HTML string
   */
  // Remove useless CSS properties from elements and stylesheets
  let htmlOfTemplate = iframeElement.documentElement.outerHTML;
  htmlOfTemplate = htmlOfTemplate.replaceArray(INITIAL_TEMPLATE_STRING_REMOVAL.find, INITIAL_TEMPLATE_STRING_REMOVAL.replace);
  // Rebuild spm-template element
  return createDomFromString(htmlOfTemplate);
};

// Validate InformationPanel settings
export const informationPanelSettingsValidate = async (onlyName = false, customValue: string | undefined = undefined): Promise<ErrorConfigForm> => {
  const templateType = getTemplateParentTypeByType(state.template?.type);

  const data = computed(() => {
    let returnValue: any = {
      name: customValue ?? state.template?.informations.name,
    };

    if (!onlyName) {
      if (templateType === TemplateParentTypeEnum.EMAIL) {
        returnValue = {
          ...returnValue,
          subject: state.template?.informations.subject,
          fromName: state.template?.informations.fromName,
          fromEmail: state.template?.informations.fromEmail,
        };
      } else if (templateType === TemplateParentTypeEnum.SMS) {
        returnValue = {
          ...returnValue,
          fromName: state.template?.informations.fromName,
        };
      }
    }

    return returnValue;
  });

  const rules = computed(() => {
    let rulesByType: any = {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(128),
      },
    };

    if (!onlyName) {
      if (templateType === TemplateParentTypeEnum.EMAIL) {
        rulesByType = {
          ...rulesByType,
          subject: {
            localizedTextInputValidator: localizedTextInputValidator('text'),
          },
          fromName: {
            localizedTextInputValidator: localizedTextInputValidator('text'),
          },
          fromEmail: {
            localizedTextInputValidator: localizedTextInputValidator('email'),
          },
        };
      } else if (templateType === TemplateParentTypeEnum.SMS) {
        rulesByType = {
          ...rulesByType,
          fromName: {
            localizedTextInputValidator: localizedTextInputValidator('smsSenderName'),
          },
        };
      }
    }

    return rulesByType;
  });

  const v$ = useVuelidate(rules, data);

  const success = await v$.value.$validate();
  return {
    success,
    validate: v$,
  };
};

export const updateLastRollbackGroupId = (groupId: number) => {
  state.lastRollbackId = groupId;
};

export const setTestEmailSent = (value: boolean) => {
  state.testEmailSent = value;
};

export const setTemplateManually = (template: Template) => {
  state.template = template;
};

export const setTemplateWidth = (width: string) => {
  state.templateWidth = width;
};

export const refreshSmartProductsListsOriginalStructure = () => {
  if (state.template) {
    const smartLists: SmartLists = {};
    const template = getTemplateIframeDocument();
    if (template) {
      const type: TemplateParentTypeEnum = getTemplateParentTypeByType(state.template?.type);
      const productsLists = template.querySelectorAll(`.${PRODUCTS_LISTS_CLASSES[type]}`);

      if (productsLists) {
        Array.from(productsLists).forEach((list) => {
          const originalCode = list.querySelector('.originalCode');

          if (originalCode) {
            const { parentElement } = originalCode;

            if (parentElement) {
              parentElement.innerHTML = originalCode.innerHTML.trim();
            }
          }
          const widgetId = list.getAttribute('id') ?? '';
          smartLists[widgetId] = list.innerHTML;
        });
      }

      state.template.smartLists = smartLists;
    }
  }
};

export const refreshAttributeInDOM = (locale: string, defaultKey = '') => {
  if (state.template) {
    const template = getTemplateIframeDocument();

    let defaultConditon = (translation: any) => translation.fieldType === 'attribute' && translation.language === locale;
    if (defaultKey) {
      defaultConditon = (translation: any) => translation.fieldType === 'attribute' && translation.language === locale && translation.key === defaultKey;
    }

    const translationAttributes = state.template.translations.filter(defaultConditon);

    translationAttributes.forEach((translation) => {
      const key = `LANG_${translation.key}`;
      const matchesAttributes = template.body.innerHTML.match(new RegExp(`data-spmtranslationid-([^=]+)="${key}"`, 'g'));

      if (matchesAttributes && matchesAttributes.length) {
        matchesAttributes.forEach((matchesAttribute) => {
          const attributeRegex = new RegExp(`(${TRANSLATION_ATTRIBUTE}-[^=]*)=`, 'gm');
          const matches = matchesAttribute.match(attributeRegex);
          if (matches && matches.length) {
            const attribute = matches[0].replace('=', '');
            const elements = template.querySelectorAll(`[${attribute}]`);
            elements.forEach((element) => {
              const attribut = Array.from(element.attributes).find((attr) => attr.name === attribute);
              let attributeName = '';
              if (attribut) {
                attributeName = attribut.name.substring('data-spmtranslationid-'.length);
              }
              if (attributeName && element.hasAttribute(attributeName)) {
                element.setAttribute(attributeName, translation.value);
              }
            });
          }
        });
      }
    });
  }
};

export const createHtmlTemplateFromZipFile = async (
  idShop: number,
  zipFile: ZipFile,
  templateType: string,
  uploadedExternalImage = 0,
  returnAndNewElement = false,
  widgetId = '',
): Promise<any> => {
  let { html } = zipFile;

  const uploadImageAndReplace = async (regex: RegExp, image: string) => {
    // eslint-disable-next-line no-await-in-loop
    const uploadResult = await uploadFileToMedia(image, idShop);
    if (uploadResult.data === false) {
      throw new Error();
    }
    const newPath = `${uploadResult.data.replace('\\', '')}`;
    html = html.replace(regex, `${newPath}`);
  };

  if (uploadedExternalImage) {
    // Upload external images and update old images paths
    const externalImagePathRegex = /(https?:\/\/[^"')]+\.(?:png|jpg|jpeg|gif))/gm;
    let imageMatch;
    const uploadedExternalImages = new Set();
    // eslint-disable-next-line no-cond-assign
    while ((imageMatch = externalImagePathRegex.exec(html)) !== null) {
      const imageLink = imageMatch[0];
      try {
        // eslint-disable-next-line no-await-in-loop
        const base64 = await getBase64FromUrl(imageLink);

        if (!uploadedExternalImages.has(imageLink)) {
          uploadedExternalImages.add(imageLink);

          // eslint-disable-next-line no-await-in-loop
          await uploadImageAndReplace(new RegExp(imageLink, 'g'), base64);
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    }
  }

  // Upload local images and Update old images paths
  const uploadedLocallImages = new Set();
  // eslint-disable-next-line no-restricted-syntax
  for (const image of zipFile.images) {
    const { originalPath } = image;

    const escapedOriginalPath = originalPath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const originalPathRegex = new RegExp(`(?<="|'|\\()(?:[^"'\\(]*?${escapedOriginalPath}[^"'\\)]*?)(?="|'|\\))`, 'g');

    let localImageMatch;
    // eslint-disable-next-line no-cond-assign
    while ((localImageMatch = originalPathRegex.exec(html)) !== null) {
      const localImageLink = localImageMatch[0];
      // Check if the image is really local but not an external url
      if (localImageLink.match(new RegExp(`(?<!https{0,1}:\\/\\/.{0,})${escapedOriginalPath}`, 'g'))) {
        // eslint-disable-next-line no-await-in-loop
        if (!uploadedLocallImages.has(localImageLink)) {
          uploadedLocallImages.add(localImageLink);

          // eslint-disable-next-line no-await-in-loop
          await uploadImageAndReplace(originalPathRegex, image.base64String);
        }
      }
    }
  }

  const css: Record<string, any> = {};

  const addToCss = (cssString: string) => {
    const mediaQueryRegex = /@media[^{]+{([^{}]*({[^{}]*}[^{}]*)*)}/g;

    let mqMatch;

    let mobileKey = 'dynamic_persistent_mobile';
    let key = 'dynamic';
    if (widgetId) {
      mobileKey = `${CUSTOM_WIDGET_CSS_PREFIX}${widgetId.split(TEMPLATE_WIDGET_IDENTIFIER_PREFIX)[1]}_mobile`;
      key = `${CUSTOM_WIDGET_CSS_PREFIX}${widgetId.split(TEMPLATE_WIDGET_IDENTIFIER_PREFIX)[1]}`;
    }

    // eslint-disable-next-line no-cond-assign
    while ((mqMatch = mediaQueryRegex.exec(cssString)) !== null) {
      const rules = mqMatch[0].trim().replace(/\s\s+/g, ' ');
      if (!css[mobileKey]) {
        css[mobileKey] = '';
      }
      css[mobileKey] += `${rules}\n`;
    }
    // Remove media queries from the CSS
    const cleanedCSS = cssString.replace(mediaQueryRegex, '').trim().replace(/\s*([{}])\s*/g, '$1');
    if (cleanedCSS) {
      if (!css[key]) {
        css[key] = '';
      }
      css[key] += `${cleanedCSS}\n\n`;
    }
  };

  // Add external css if linked in html file
  // eslint-disable-next-line no-restricted-syntax
  for (const cssFile of zipFile.cssFiles) {
    const { originalPath, cssString } = cssFile;

    const escapedOriginalPath = originalPath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const cssLinkRegex = new RegExp(`<link[^>]+href=['"](\\.\\/)?${escapedOriginalPath}['"][^>]*>`, 'g');

    if (html.match(cssLinkRegex)) {
      // Remove link in the html
      html = html.replace(cssLinkRegex, '');
      // Add css rules to css (dynamic and dynamic_persistent_mobile)
      addToCss(cssString);
    }
  }

  // Add inline css to css object and remove it from html
  const styleRegex = /<style.*?>([\s\S]*?)<\/style>/gi;
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = styleRegex.exec(html)) !== null) {
    const cssString = match[1];
    addToCss(cssString);
  }
  // Remove inline css style from html
  html = beautifier.html(html.replace(styleRegex, ''));

  const cssBeautifyOptions = {
    'brace-style': 'collapse',
    'selector-separator-newline': false,
    'newline-between-rules': false,
    'end-with-newline': false,
    'indent-size': 0,
  };

  if (widgetId) {
    Object.keys(css).forEach((cssKey) => {
      css[cssKey] = addSelectorToRules(css[cssKey], `#${widgetId}`);
    });
  }

  Object.keys(css).forEach((cssKey) => {
    css[cssKey] = beautifier.css(css[cssKey], cssBeautifyOptions).replace(/\n/g, '');
  });

  let cssString = JSON.stringify(css);

  // Sort css keys
  const cssOrder = [
    'static',
    'static_persistent',
    'static_persistent_mobile',
    'dynamic',
    'dynamic_persistent',
    'dynamic_persistent_mobile',
  ];

  const jsonMap = new Map<string, any>();
  cssOrder.forEach((key) => {
    if (key in css) {
      jsonMap.set(key, css[key]);
    }
  });

  cssString = JSON.stringify(Object.fromEntries(jsonMap));

  const { newHtml, translations } = generateTranslations(html, { newElement: returnAndNewElement, translateVariables: false });

  if (returnAndNewElement) {
    const orderedCss = new Map<string, any>();
    Object.keys(css).forEach((key) => {
      if (!key.match(/_mobile/g)) {
        orderedCss.set(key, css[key]);
      }
    });
    Object.keys(css).forEach((key) => {
      if (key.match(/_mobile/g)) {
        orderedCss.set(key, css[key]);
      }
    });
    return {
      html: newHtml,
      css: orderedCss,
    };
  }

  const inputsElements: TemplatesInputItem[] = [{
    id_shop: idShop,
    label: zipFile.name,
    type: templateType,
    version: 3,
    deleted: false,
    id_relaunch: 11,
    date_creation: moment().format('YYYY-MM-DD HH:mm:ss'),
    date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
  }];

  const {
    id, err,
  } = await Insert<TemplatesInputItem>({
    name: 'Templates',
    input: inputsElements,
    type: 'TemplatesInput',
  });

  if (err === '') {
    if (UserState.activeShop && UserState.activeShop.langs.length) {
      const templateLangs: TemplatesLangInputItem[] = UserState.activeShop.langs.map((lang) => ({
        body: '',
        lang: lang.id,
        id_template: id,
      }));
      await Insert<TemplatesLangInputItem>({
        name: 'TemplatesLang',
        input: templateLangs,
        type: 'TemplatesLangInput',
      });
    }

    const templateElements: TemplatesElementsInputItem[] = [{
      id_template: id,
      id_shop: idShop,
      type: 'design',
      data: null,
      html: newHtml,
      css: cssString,
      translations: JSON.stringify(translations),
      date_creation: moment().format('YYYY-MM-DD HH:mm:ss'),
      date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
    }];

    const { id: idTemplateElements, err: errTemplate } = await Insert<TemplatesElementsInputItem>({
      name: 'TemplatesElements',
      input: templateElements,
      type: 'TemplatesElementsInput',
    });
    if (errTemplate === '') {
      const informations = {
        name: zipFile.name,
        lang: getShopDefaultLang(),
        imported: true,
      };
      const templateInput = {
        id: parseInt(id.toString(), 10),
        content: {
          design: idTemplateElements,
          sections: [],
        },
        type: templateType,
        informations,
        sections: [{
          css: cssString,
          data: null,
          html: newHtml,
          id_template_elements: idTemplateElements,
          translations: JSON.stringify(translations),
          type: 'design',
        }],
        configuration: {
          body_structure: {
            design: idTemplateElements,
            sections: [],
            sections_before_save: [],
          },
          informations,
        },
      };

      const { err: templateInputErr } = await CustomMutation<TemplateAndTemplateElementsUpdateInput>({
        name: 'UpdateTemplateAndTemplateElements',
        input: {
          id_shop: idShop,
          template: JSON.stringify(templateInput),
        },
        type: 'TemplateAndTemplateElementsUpdateInput',
      });

      if (templateInputErr === '') {
        return {
          id,
          type: templateType,
          label: zipFile.name,
        };
      }
    }
  }
  throw new Error();
};

export const updateSectionCss = (css: Map<string, any>, widgetId: string) => {
  const activeSection = state.template?.sections.find((section) => section.id_template_elements === state.template?.activeSection);
  if (activeSection) {
    css.forEach((value, styleType) => {
      const template = getTemplateIframeDocument();
      if (template) {
        const style = template.querySelector(`style[data-spm-section="${state.template?.activeSection}"][data-spm-styles="${styleType}"]`);
        if (style) {
          style.innerHTML = value;
        } else {
          const s = document.createElement('style');

          // If styles are for mobile devices, we add the media query rule if it's not in the CSS rule
          if (new RegExp('_mobile$').test(styleType) && !(new RegExp('@media')).test(value)) {
            s.innerHTML = `@media only screen and (max-width: 599px) { ${value} }`;
          } else {
            s.innerHTML = value;
          }

          s.setAttribute('data-spm-styles', styleType);
          s.setAttribute('data-spm-section', state.template?.activeSection.toString() || '');
          s.setAttribute('type', 'text/css');

          template.head.appendChild(s);
        }
      }
    });
  }
};

/**
 * Rrturn the deepest child of an element
 * @param element
 */
const findDeepestChild = (element: any) => {
  while (element.firstElementChild) {
    element = (element).firstElementChild;
  }
  return element;
};

export const fixBrokenSmartProductsLists = () => {
  let refreshSmartLists = false;

  if (state.template) {
    Object.keys(state.template.smartLists).forEach((widgetId) => {
      const code = state.template?.smartLists[widgetId];

      // We check if the HTML code contains the collection attribute
      if (code && !/s-collection="[^"]+"/.test(code)) {
        // Attribute is not in the HTML code, we need to rebuild the code
        // We get widget's code
        const widget = getTemplateIframeDocument().querySelector(`#${widgetId}`);

        // Get products elements and remove all of them except first one. For the first one, we will reapply collection's attributes
        let products = widget?.querySelectorAll('.spm_product_list_element_wrapper');

        if (!products || products.length === 0) {
          products = widget?.querySelectorAll('.spm_product_list_element');
        }

        const nbProducts = (products ?? []).length;
        (products ?? []).forEach((product: any, index: number) => {
          if (index > 0) {
            product.remove();
          } else {
            product.setAttribute('s-collection', 'products');
            product.setAttribute('s-item', 'product');
            product.setAttribute('s-grid', nbProducts);
            product.setAttribute('s-nb', nbProducts);
            product.setAttribute('s-engine', 'crossselling');
            product.setAttribute('s-ids', '');
            product.setAttribute('s-excluded_ids', '');
            product.setAttribute('s-excluded_categories', '');
            product.setAttribute('s-excluded_manufacturers', '');
            product.setAttribute('s-nb_weeks', '4');

            // Restore product title
            const title = product.querySelector('.spm_product_list_element_title');
            if (title) {
              // We need to check the children of the element (in case there is a link or other element)
              const { children } = title;
              if (Array.from(children).length > 0) {
                let removeChild = false;
                Array.from(children)
                  .forEach((child: any) => {
                    if (child.nodeName === 'A') {
                      child.setAttribute('href', '{var=product.url}');
                      child.setAttribute('title', '{var=product.name}');
                      child.innerHTML = '{var=product.name}';
                    } else {
                      // Child is not a link, so we remove it because we don't know what to do according to its type
                      child.remove();
                      removeChild = true;
                    }
                  });

                if (removeChild) {
                  title.innerHTML = '{var=product.name}';
                }
              } else {
                // Title doesn't have children, so we set its content with the product's name
                title.innerHTML = '{var=product.name}';
              }
            }

            // Restore product's description
            const description = product.querySelector('.spm_product_list_element_description');
            if (description) {
              const deepestChild = findDeepestChild(description);

              if (deepestChild) {
                deepestChild.innerHTML = '{var=product.description_short}';
              }
            }

            // Restore product's image
            const image = product.querySelector('.spm_product_list_element_image');
            if (image) {
              // Check if image has a parent and that the parent is a link. If true, we restore product's URL as href of the link
              const parent = image.parentElement;
              if (parent.nodeType === 'A') {
                parent.setAttribute('href', '{var=product.url}');
              }

              // Restore image's src, alt and title attributes
              image.setAttribute('src', '{var=product.image_url resize=300x150 resizeType=fill-to-fit}');
              image.setAttribute('title', '{var=product.name}');
              image.setAttribute('alt', '{var=product.name}');
            }

            // Restore product's price and price strike
            const priceStrike = product.querySelector('.spm_product_list_element_price_strike');
            if (priceStrike) {
              priceStrike.innerHTML = '{var=product.price_strike}';

              if (priceStrike.nodeType === 'SPAN' || priceStrike.nodeType === 'DIV') {
                // Add condition to hide the element if price and price strike are equal
                priceStrike.setAttribute('s-if', 'product.price_strike');
                priceStrike.setAttribute('s-condition', '!=');
                priceStrike.setAttribute('s-value', 'product.price');
              } else {
                // Apply condition to parent element
                const parentPriceStrike = priceStrike.parentElement;
                parentPriceStrike.setAttribute('s-if', 'product.price_strike');
                parentPriceStrike.setAttribute('s-condition', '!=');
                parentPriceStrike.setAttribute('s-value', 'product.price');
              }
            }

            const price = product.querySelector('.spm_product_list_element_price');
            if (price) {
              price.innerHTML = '{var=product.price}';
            }

            // Restore product's button
            const button = product.querySelector('.spm_product_list_element_button a');
            if (button) {
              // Restore product's URL in href attribute
              button.setAttribute('href', '{var=product.url}');
            }
          }
        });

        // Get widget's code and store in the state the refresh the widget
        const widgetHtml = widget?.innerHTML;
        if (widgetHtml && state.template) {
          state.template.smartLists[widgetId] = widgetHtml;
          refreshSmartLists = true;
        }
      }

      // Fix product lists with image sizes set to 0
      const template = getTemplateIframeDocument();
      const images = template.querySelectorAll('img.spm_product_list_element_image');

      if (images && images.length) {
        images.forEach((image) => {
          const hasSmartProductListWidgetParent = getParents(image, '.spm_smart_products_list');
          // eslint-disable-next-line max-len
          if (hasSmartProductListWidgetParent && hasSmartProductListWidgetParent.length && typeof image.getAttribute('width') === 'string' && image.getAttribute('width') === '0') {
            const parentWidget = hasSmartProductListWidgetParent[0];
            const selector = `body #spm_body #${parentWidget.id} img.spm_product_list_element_image`;

            const currentSize = getCssPropertyByName(selector, 'width');

            if (currentSize) {
              removeDynamicStyle(selector, { width: '' });
            }
            const property: Property = {
              name: 'width', // Nom de la propriété
              getter: () => '', // Fonction getter
              setters: [], // Tableau de fonctions setters
              value: '150px', // Valeur de la largeur selon vos besoins
            };
            setSmartListImageWidth(selector, property, parentWidget.id);
            refreshSmartLists = true;
          }
        });
      }
    });
  }

  // If at least one code has been corrected, we refresh the smartlists
  if (refreshSmartLists) {
    refreshSmartProductList();
  }
};

export const removeCustomWidgetCss = (selector: string) => {
  const template = getTemplateIframeDocument();
  const selectorQuery = `${CUSTOM_WIDGET_CSS_PREFIX}${selector.replace(`#${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}`, '')}`;
  const selectors = [
    selectorQuery,
    `${selectorQuery}_mobile`,
  ];
  if (template) {
    selectors.forEach((sel) => {
      const stylesElement = template.querySelectorAll(`style[data-spm-styles="${sel}"]`);
      stylesElement.forEach((styleElement) => {
        const sectionId = styleElement.getAttribute('data-spm-section');
        if (sectionId) {
          const section = state.template?.sections.find((s) => s.id_template_elements.toString() === sectionId.toString());
          if (section) {
            const sectionCss = JSON.parse(section.css);
            delete sectionCss[sel];
            section.css = JSON.stringify(sectionCss);
          }
        }
        styleElement.remove();
      });
    });
  }
};

export const removeTranslation = (key: string) => {
  if (state.template) {
    state.template.translations = state.template.translations.filter((translation) => translation.key !== key);
  }
};

export const getTranslationIdFromImportedTemplateElement = (target: HTMLElement): any => {
  const targetTagName = target.tagName.toLowerCase();

  const getTranslationId = (element: HTMLElement) => {
    const elementId = element.getAttribute('id');
    if (elementId) {
      return elementId.replace('LANG_', '');
    }
    return '';
  };

  if (targetTagName === 'spm-template') {
    return '';
  }
  // Check if target element is a translation markup and get the attribute id
  if (targetTagName === TRANSLATION_MARKUP) {
    return getTranslationId(target);
  }
  // Check if the target element has a translation marku child
  if (target.children.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const childElement of Array.from(target.children)) {
      const elementId = getTranslationIdFromImportedTemplateElement(childElement as HTMLElement);
      if (elementId) {
        return elementId;
      }
    }
  }

  // Loop through parent until it finds the translation markup
  let parent: HTMLElement | null = target.parentElement;
  while (parent && parent.tagName.toLowerCase() !== 'body' && parent.tagName.toLowerCase() !== 'spm-template') {
    if (parent.tagName.toLowerCase() === TRANSLATION_MARKUP) {
      return getTranslationId(parent);
    }
    parent = parent.parentElement;
  }

  // Check attributes
  // eslint-disable-next-line no-restricted-syntax
  for (const attribute of Array.from(target.attributes)) {
    if (attribute.name.match(new RegExp(TRANSLATION_ATTRIBUTE))) {
      return attribute.value.replace('LANG_', '');
    }
  }

  return '';
};

export const setSelectedTranslationId = (translationId: string) => {
  state.selectedTranslationId = translationId;
};

export const setShowConfigurationPanelCallback = (showPanelCallback: Function | null) => {
  state.showConfigurationPanelCallback = showPanelCallback;
};

export const fixMissingTranslationsForShopLanguages = () => {
  const defaultLang = getShopDefaultLang();
  if (state.template && UserState.activeShop) {
    const defaultTranslations = state.template.translations.filter((item) => item.language === defaultLang);
    const otherLanguages = UserState.activeShop.langs.filter((lang) => lang.id !== defaultLang).map((lang) => lang.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const translationItem of defaultTranslations) {
      // eslint-disable-next-line no-restricted-syntax
      for (const otherLang of otherLanguages) {
        if (!state.template.translations.find((translation) => translationItem.key === translation.key && translation.language === otherLang)) {
          const newTranslation = JSON.parse(JSON.stringify(translationItem));
          newTranslation.language = otherLang;
          newTranslation.callback = translationItem.callback;
          addTranslationsToState([newTranslation]);
        }
      }
    }
  }
};

export const cleanUpTranslations = () => {
  if (state.template) {
    const keysToDelete: string[] = [];
    state.template.translations.forEach((translation) => {
      // For each translation key, we check if it exists in the HTML code, otherwise we delete the key from the translations
      const template = getTemplateIframeDocument();
      if (template) {
        if (!(new RegExp(`LANG_${translation.key}`)).test(template.body.innerHTML)) {
          keysToDelete.push(translation.key);
        }
      }
    });

    keysToDelete.forEach((key) => {
      removeTranslation(key);
    });
  }
};

export const removeItemFromSectionsToRefresh = (itemId: number) => {
  state.sectionsToRefresh = state.sectionsToRefresh.filter((sectionId) => sectionId !== itemId);
};

export const fixBrokenWidgetVoucher = () => {
  if (state.template) {
    const template = getTemplateIframeDocument();
    if (template) {
      const voucherWidgets = template.body.querySelectorAll('.spm_generate_voucher.spm_button.spm_inline_editing');
      Array.from(voucherWidgets).forEach((voucherWidget) => {
        if (voucherWidget.childNodes.length === 0 && !voucherWidget.hasAttribute(`${TRANSLATION_ATTRIBUTE}-href`)) {
          voucherWidget.remove();
        }
      });
    }
  }
};

export const linkSection = (sectionId: any, syncElementId: string, syncElementName: string, sectionType = '') => {
  const { index, template, templates } = state;
  if (template) {
    let section = sectionType
      ? template.sections.find((s) => s.type === sectionType)
      : template.sections.find((s) => s.id_template_elements === asInt(sectionId));
    if (isDisplayTemplate(state.template?.type) && sectionType === TemplateStructureEnum.DESIGN) {
      // eslint-disable-next-line prefer-destructuring
      section = template.sections[0];
    }
    if (section) {
      const data = JSON.parse(section.data || '{}');
      data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] = syncElementId;
      data[TEMPLATE_SYNC_ELEMENT_NAME] = syncElementName;
      section.data = JSON.stringify(data);
    }
    templates[index] = template;
  }
};

export const addNewSyncElementToSection = (sectionId: any, syncElementId: string) => {
  const { index, template, templates } = state;
  if (template) {
    const section = template.sections.find((s) => s.id_template_elements === asInt(sectionId));
    if (section) {
      const data = JSON.parse(section.data || '{}');
      if (!data[TEMPLATE_SYNC_ELEMENT_IDs]) {
        data[TEMPLATE_SYNC_ELEMENT_IDs] = [];
      }
      data[TEMPLATE_SYNC_ELEMENT_IDs].push(syncElementId);
      section.data = JSON.stringify(data);
    }
    templates[index] = template;
  }
};

export const resetSpecificDesignRules = (html: string, templateType: string) => {
  const $ = cheerio.load(html);
  const customDataDefinitions = customDataDisplay[TemplateStructureEnum.DESIGN][templateType] || [];
  // eslint-disable-next-line no-restricted-syntax
  for (const customDataDefinition of customDataDefinitions) {
    const selectedElement = $('body').find(customDataDefinition.selector);
    if (selectedElement) {
      customDataDefinition.classes.forEach((cls) => {
        selectedElement.removeClass(cls);
      });
      customDataDefinition.attributes.forEach((attr) => {
        selectedElement.removeAttr(attr);
      });
    }
  }
  return $('body').html() || '';
};

export const applyNewSpecificDesignRules = (html: string, specificRules: any[]) => {
  const $ = cheerio.load(html);
  // eslint-disable-next-line no-restricted-syntax
  for (const specificRule of specificRules) {
    const selectedElement = $('body').find(specificRule.selector);
    if (selectedElement) {
      specificRule.classes.forEach((cls: string) => {
        selectedElement.addClass(cls);
      });
      specificRule.attributes.forEach((attr: any) => {
        selectedElement.attr(attr.attribute, attr.value);
      });
    }
  }
  return $('body').html() || '';
};

export const loadDesign = (item: TemplatePart) => {
  const { template } = state;
  if (!template) return;

  let design = template.sections.find((sec) => sec.type === TemplateStructureEnum.DESIGN);
  if (isDisplayTemplate(state.template?.type)) {
    // eslint-disable-next-line prefer-destructuring
    design = template.sections[0];
  }
  if (!design) return;

  const parser = new DOMParser();
  const doc = parser.parseFromString(item.html ?? '', 'text/html');

  const itemData = JSON.parse(item.data || '{}');
  if (itemData[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] && isEmailTemplate(state.template?.type)) {
    const { body } = doc;
    body.setAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER, itemData[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]);
    body.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, item.name ?? '');
  }
  if (itemData[HIDE_MOBILE_ATTRIBUTE] && isEmailTemplate(state.template?.type)) {
    const { body } = doc;
    body.setAttribute(HIDE_MOBILE_ATTRIBUTE, 'true');
  } else if (!itemData[HIDE_MOBILE_ATTRIBUTE] && isEmailTemplate(state.template?.type)) {
    const { body } = doc;
    body.removeAttribute(HIDE_MOBILE_ATTRIBUTE);
  }

  if (isDisplayTemplate(state.template?.type)) {
    const objectCss = JSON.parse(item.css || '{}');
    const designCss = JSON.parse(design.css || '{}');
    Object.keys(objectCss).forEach((key) => {
      if (key in designCss) {
        const regex = /([^{}]*#spm_(?:widget|column|row)_[a-zA-Z0-9]+[^{]*{[^}]*})/gm;
        const matches = [...designCss[key].matchAll(regex)];
        const capturedBlocks = matches.map((match) => match[0]);

        designCss[key] = `${objectCss[key]} ${capturedBlocks.join(' ')}`;
      }
    });
    design.css = JSON.stringify(designCss);

    // Reset specific css rules and apply new rules
    const data = JSON.parse(item.data || '{}');
    design.html = resetSpecificDesignRules(design.html, getTemplateParentTypeByType(state.template?.type));
    if (data.specificCssRules) {
      design.html = applyNewSpecificDesignRules(design.html, data.specificCssRules);
    }
  } else if (isEmailTemplate(state.template?.type)) {
    const updatedHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd">${doc.documentElement.outerHTML}`;
    design.css = item.css ?? '';
    design.html = updatedHtml;
    design.data = item.data ?? '';
  }

  const data = JSON.parse(item.data || '{}');
  const syncId = data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER];
  if (syncId) {
    const templateHtml = getTemplateIframeDocument();

    if (!templateHtml) return;

    const body = isEmailTemplate(state.template?.type) ? templateHtml.body : templateHtml.querySelector('#spm_body');
    if (!body) return;

    body.setAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER, syncId);
    body.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, item.name ?? '');
    linkSection('', syncId, item.name ?? '', TemplateStructureEnum.DESIGN);
  }
};

export const unlinkDesign = () => {
  const { index, template: currentTemplate, templates } = state;
  if (!currentTemplate) return;

  let design = currentTemplate.sections.find((item) => item.type === TemplateStructureEnum.DESIGN);
  if (isDisplayTemplate(currentTemplate.type)) {
    // eslint-disable-next-line prefer-destructuring
    design = currentTemplate.sections[0];
  }
  if (!design) return;

  const data = JSON.parse(design.data || '{}');
  delete data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER];
  delete data[TEMPLATE_SYNC_ELEMENT_NAME];

  design.data = Object.keys(data).length ? JSON.stringify(data) : '';
  templates[index] = currentTemplate;

  const template = getTemplateIframeDocument();
  if (!template) return;

  const body = isDisplayTemplate(currentTemplate.type) ? template.querySelector('#spm_body') : template.body;
  if (!body) return;

  body.removeAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
  body.removeAttribute(TEMPLATE_SYNC_ELEMENT_NAME);
};

export const unlinkSyncElement = (selectedSyncElement: { elementType: string; elementId: string }, oldParentSectionId = '') => {
  const { elementType, elementId } = selectedSyncElement;

  if (elementType === TemplateStructureEnum.DESIGN) {
    unlinkDesign();
  } else {
    const template = getTemplateIframeDocument();
    if (!template) return;

    const element = template.body.querySelector(`#${elementId}`);
    if (!element) return;

    const syncElementId = element.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);

    element.removeAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
    element.removeAttribute(TEMPLATE_SYNC_ELEMENT_NAME);
    element.classList.remove(TEMPLATE_SYNC_ELEMENT_CLASS);

    const { index, template: currentTemplate, templates } = state;
    if (!currentTemplate) return;

    const updateSectionData = (section: any, data: any) => {
      section.data = Object.keys(data).length ? JSON.stringify(data) : '';
      templates[index] = currentTemplate;
    };

    const processSection = (sectionId: string, filterSyncElements = false) => {
      const section = currentTemplate.sections.find((item) => asInt(item.id_template_elements) === asInt(sectionId));
      if (!section) return;

      const data = JSON.parse(section.data || '{}');
      if (filterSyncElements && data[TEMPLATE_SYNC_ELEMENT_IDs]) {
        data[TEMPLATE_SYNC_ELEMENT_IDs] = data[TEMPLATE_SYNC_ELEMENT_IDs].filter((item: string) => item !== syncElementId);
        if (!data[TEMPLATE_SYNC_ELEMENT_IDs].length) delete data[TEMPLATE_SYNC_ELEMENT_IDs];
      } else {
        delete data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER];
      }
      updateSectionData(section, data);
    };

    if (elementType === TemplateStructureEnum.SECTION) {
      const id = element.getAttribute('data-spmelementid');
      if (!id) return;
      processSection(id);
    } else if (elementType === TemplateStructureEnum.WIDGET) {
      if (oldParentSectionId) {
        processSection(oldParentSectionId, true);
      } else {
        const parentSection = getSectionParent(element as HTMLElement);
        if (parentSection) {
          const parentSectionId = parentSection.getAttribute('data-spmelementid');
          if (parentSectionId) {
            processSection(parentSectionId, true);
          }
        }
      }
    }
  }
};

export const unlinkElementsInSection = (selector: string) => {
  const template = getTemplateIframeDocument();
  if (!template) return;

  const section = template.querySelector(selector);
  if (!section) return;

  const syncElements = section.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}]`);
  Array.from(syncElements).forEach((syncElement) => {
    unlinkSyncElement({
      elementType: TemplateStructureEnum.WIDGET,
      elementId: syncElement.id,
    });
  });
};

export const updateUsedSyncElements = async (idShop: number) => {
  const checkSyncElements = store.getters['liveEditor/getCheckSyncElements'];
  if (checkSyncElements) {
    let continueCheck = true;
    if (state.template && state.template.dateModification) {
      const existingTemplate = await Get<TemplateRaw>({
        name: 'Templates',
        id: state.template?.id ?? '',
        keyName: 'id_template',
        fields: [
          'date_modification',
        ],
      });
      const dateModification = moment(state.template.dateModification, 'YYYY-MM-DD HH:mm:ss', true);
      if (existingTemplate && existingTemplate.item && dateModification.isValid()) {
        const existingDateModifcation = moment(existingTemplate.item.date_modification, 'YYYY-MM-DD HH:mm:ss', true);
        if (existingDateModifcation.isValid() && existingDateModifcation.isSameOrBefore(dateModification)) {
          continueCheck = false;
        }
        if (store.getters['liveEditor/getForceUpdateSyncElements']) {
          continueCheck = true;
          store.commit('liveEditor/setForceUpdateSyncElements', false);
        }
        if (continueCheck) {
          state.template.dateModification = existingTemplate.item.date_modification ?? '';
        }
      }
    }
    if (continueCheck) {
      const template = getTemplateIframeDocument();
      if (!template) return;

      const syncElements = template.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}]`);
      const syncElementIds = new Set();
      for (let i = 0; i < syncElements.length; i++) {
        const syncElement = syncElements.item(i);
        const syncElementId = syncElement.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
        if (syncElementId) {
          syncElementIds.add(syncElementId);
        }
      }
      const query = `query ( $id_shop: Int!, $sync_element_ids: String! ) {
        TemplatePartsBySyncElementIds(id_shop: $id_shop, sync_element_ids: $sync_element_ids)
          {
            items {
              id_template_parts
              id_template
              type
              name
              data
              html
              css
              translations
              template_part_hash
              part_type
            }
            total
          }
        }`;

      const variables = { id_shop: idShop, sync_element_ids: Array.from(syncElementIds).join(',') };

      const {
        data,
        err,
      } = await Request<ListResponse<TemplatesParts>>({
        name: 'TemplatePartsBySyncElementIds',
        query,
        variables,
      });

      const result: ListResponse<TemplatesParts> = data ?? {
        items: [],
        total: 0,
        err: '',
      };

      const unlink = (syncElement: any) => {
        const elements = template.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="${syncElement.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER)}"]`);
        // eslint-disable-next-line no-loop-func
        Array.from(elements).forEach((element) => {
          let elementType = TemplateStructureEnum.DESIGN;
          if (element.id.startsWith('spm_section')) {
            elementType = TemplateStructureEnum.SECTION;
          } else if (element.id.startsWith('spm_widget')) {
            elementType = TemplateStructureEnum.WIDGET;
          }
          unlinkSyncElement({ elementType, elementId: element.id });
        });
      };

      if (result.items.length) {
        for (let i = 0; i < syncElements.length; i++) {
          const syncElement = syncElements.item(i);
          const { id } = syncElement;
          const itemPart = result.items.find((item: TemplatesParts) => {
            const jsonData = JSON.parse(item.data || '{}');
            if (jsonData[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
              return jsonData[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] === syncElement.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
            }
            return false;
          });
          if (itemPart) {
            if (itemPart.type !== TemplateStructureEnum.DESIGN) {
              // eslint-disable-next-line no-await-in-loop
              await insertSavedElement(itemPart, `#${id}`, 'top', false, syncElement.getAttribute('data-spmelementid') || '');
              syncElement.remove();
            } else {
              loadDesign(itemPart);
              // Reapply design css
              const activeStyleSheets = getActiveTemplateStylesheets(TemplateStructureEnum.DESIGN);
              activeStyleSheets.forEach((stylesheet: HTMLStyleElement) => {
                const stylesAttribute = stylesheet.getAttribute('data-spm-styles');
                const sectionAttribute = stylesheet.getAttribute('data-spm-section');
                const oldStyle = template.querySelector(`style[data-spm-styles="${stylesAttribute}"][data-spm-section="${sectionAttribute}"]`);
                if (oldStyle) {
                  oldStyle.innerHTML = stylesheet.innerHTML;
                }
              });
            }
          } else {
            unlink(syncElement);
          }
        }
      } else {
        for (let i = 0; i < syncElements.length; i++) {
          const syncElement = syncElements.item(i);
          unlink(syncElement);
        }
      }
      updateSectionsInState();
    }
  }
  store.commit('liveEditor/setCheckSyncElements', false);
};

export const getAllSyncElements = (syncElementId: string, excludeId: string) => {
  const template = getTemplateIframeDocument();
  if (!template) return [];

  const syncElements = Array.from(template.body.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="${syncElementId}"]`));

  return syncElements.filter((element) => element.id !== excludeId);
};

export const getTemplatePartsBySyncElementIds = async (idShop: number, syncElementIds: Array<string>) => {
  const query = `query ( $id_shop: Int!, $sync_element_ids: String! ) {
    TemplatePartsBySyncElementIds(id_shop: $id_shop, sync_element_ids: $sync_element_ids)
      {
        items {
          id_template_parts
          id_template
          type
          name
          data
          html
          css
          translations
          template_part_hash
          part_type
        }
        total
      }
    }`;

  const variables = { id_shop: idShop, sync_element_ids: Array.from(syncElementIds).join(',') };

  const {
    data,
    err,
  } = await Request<ListResponse<TemplatesParts>>({
    name: 'TemplatePartsBySyncElementIds',
    query,
    variables,
  });

  const result: ListResponse<TemplatesParts> = data ?? {
    items: [],
    total: 0,
    err: '',
  };

  return result;
};

export const updateUsedSyncElementsName = (syncElementId: string, newName: string) => {
  const template = getTemplateIframeDocument();
  if (!template) return;

  const syncElements = template.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="${syncElementId}"]`);
  Array.from(syncElements).forEach((syncElement) => {
    syncElement.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, newName);
  });

  updateSectionsInState();
};

export const updateOpenedTemplate = (templateId: number, sectionId: string | number, newSection: any) => {
  const templateToUpdate = state.templates.find((template) => template.id.toString() === templateId.toString());
  if (templateToUpdate) {
    const sectionToUpdate = templateToUpdate.sections.find((s) => s.id_template_elements.toString() === sectionId.toString());
    if (sectionToUpdate) {
      sectionToUpdate.css = newSection.css;
      sectionToUpdate.html = newSection.html;
      sectionToUpdate.translations = newSection.translations;
      sectionToUpdate.data = JSON.stringify(newSection.data);
    }
    // Update translations
    templateToUpdate.translations = [];
    templateToUpdate.sections.forEach((section) => {
      const sectionTranslation = JSON.parse((section.translations && section.translations !== '' ? section.translations : '{}').replace(/(\r\n|\n|\r)/gm, ' '));

      Object.keys(sectionTranslation).forEach((language: string) => {
        Object.keys(sectionTranslation[language]).forEach((key: string) => {
          templateToUpdate.translations.push(...(addTranslation(sectionTranslation[language], key, section.id_template_elements, language, templateToUpdate.type) ?? []));
        });
      });
    });
  }
};
