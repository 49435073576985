
import {
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
  computed,
} from 'vue';
import InputText from 'primevue/inputtext';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';

import LoadElementsPreview from '@/components/template-builder/LoadElementsPreview.vue';

import { useI18n } from 'vue-i18n';
import { store } from '@/store';
import {
  LoadSectionPayload,
  TemplatePart,
  TemplateStructureEnum,
  SelectedSyncElement,
  HistoryType,
} from '@/types';
import {
  ANIMATION_SPEED,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
  BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS,
  BUILDER_ELEMENT_HOVER_CLASS,
  TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
  MEDIA_URL_PART_THUMB_GENERATION,
  TEMPLATE_SYNC_ELEMENT_CLASS,
} from '@/components/template-builder/utils/constants';
import {
  TemplateEditorState,
  getTemplatesParts,
  deleteSavedSection,
  insertSavedElement,
  loadDesign,
  updateSectionsInState,
  togglePanel,
  checkIfPartExistsWithSameName,
  duplicatePartInDatabase,
  unlinkSyncElement,
} from '@/composables/template-editor/TemplateEditor';
import { RAW_SECTION_STRUCTURE_MAP } from '@/components/template-builder/utils/raw-html-templates';
import { Maybe, TemplatesParts } from '@/types/generated-types/graphql';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { asInt, generateSha1Hash, showToastError } from '@/helpers';
import {
  getTemplateIframeDocument,
  elementsSortableFilterAndGroup,
  clearPlaceholder,
  getSectionParent,
  isDisplayTemplate,
  isEmailTemplate,
  getDisplayTemplateTypes,
} from '@/components/template-builder/utils/helpers';
import { UserState } from '@/composables/User';

import Sortable, { GroupOptions } from 'sortablejs';
import { createHistory } from '@/composables/template-editor/History';
import { nestPost } from '@/composables/nestApi';
import { updateSyncElementsInOpenedTemplates } from './utils/sync-elements-helpers';

export default defineComponent({
  name: 'LoadElements',

  components: {
    InputText,
    ConfirmPopup,
    LoadElementsPreview,
    ConfirmDialog,
  },

  props: {
    payload: {
      type: Object as PropType<LoadSectionPayload>,
      required: true,
    },

    showDragIcon: {
      type: Boolean,
      required: false,
      default: true,
    },

    types: {
      type: Array as PropType<string[]>,
      required: false,
      default: [TemplateStructureEnum.SECTION, TemplateStructureEnum.WIDGET],
    },

    loadActionTitle: {
      type: String,
      default: '',
    },
  },

  emits: ['insert-section', 'insert-element'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const confirm = useConfirm();
    const toast = useToast();
    const insertSelector: Ref<string> = ref(props.payload.selector);
    const insertPosition: Ref<string> = ref(props.payload.position);

    const panelHeader: Ref<Maybe<HTMLElement>> = ref(null);
    const search: Ref<string> = ref('');
    const savedSectionsList = computed<TemplatePart[]>(() => store.getters['templateEditor/getSections']);
    const selectedSyncElement = computed<SelectedSyncElement>(() => store.getters['liveEditor/getSelectedSyncElement']);
    const displayedSections: Ref<TemplatePart[]> = ref([]);

    const getSavedSections = async () => {
      let sections: Maybe<TemplatePart[]>;

      let type = props.types;
      if (selectedSyncElement.value && selectedSyncElement.value.elementType === TemplateStructureEnum.SECTION) {
        type = [TemplateStructureEnum.WIDGET];
      }

      if (isDisplayTemplate(TemplateEditorState.template?.type) && props.types.length === 2) {
        type = [TemplateStructureEnum.WIDGET];
      }

      let templateType = TemplateEditorState.template?.type ? [TemplateEditorState.template.type] : [];
      if (isDisplayTemplate(TemplateEditorState.template?.type) && !type.includes(TemplateStructureEnum.DESIGN)) {
        templateType = getDisplayTemplateTypes();
      }

      // Sections loading
      sections = await getTemplatesParts({
        shopId: UserState.activeShop?.id ?? 0,
        type,
        templateType,
        limit: 0,
        offset: 0,
      });

      if (!sections) {
        sections = [];
      }

      if (props.types.find((item) => item === TemplateStructureEnum.SECTION) && !selectedSyncElement.value && !isDisplayTemplate(TemplateEditorState.template?.type)) {
        const emptySection: TemplatePart = {
          id_template_parts: 0,
          name: 'Section vide',
          type: 'empty_section',
          html: RAW_SECTION_STRUCTURE_MAP[TemplateEditorState.template?.type ?? ''](),
        };
        sections.unshift(emptySection);
      }

      store.commit('templateEditor/setSections', sections);
    };

    const handleSelection = async (item: TemplatePart, selector: any = null, position: any = null) => {
      if (item.type !== TemplateStructureEnum.DESIGN) {
        await insertSavedElement(item, selector || insertSelector.value, position || insertPosition.value);
        store.commit('liveEditor/resetSelectedStructure');
        // Emit event to let parent components execute actions
        emit('insert-section');
      } else {
        loadDesign(item);
        let isSync = false;
        if (item.data) {
          const data = JSON.parse(item.data);
          if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
            isSync = true;
          }
        }
        if (isSync) {
          await createHistory(HistoryType.LOAD_DESIGN_SYNC);
        } else {
          await createHistory(HistoryType.LOAD_DESIGN);
        }
        emit('insert-element');
      }
    };

    const handleDeletion = (item: TemplatePart, event: any, done: Function) => {
      // Confirm deletion
      confirm.require({
        group: 'deleteElement',
        target: event.currentTarget,
        message: t('templateBuilder.confirm.deleteSavedSection.message'),
        icon: 'fal fa-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: () => {
          deleteSavedSection(item).then(async (deleted: boolean | string) => {
            if (deleted === true) {
              store.dispatch('templateEditor/removeSection', { id: item.id_template_parts });

              toast.add({
                severity: 'success',
                summary: t('templateBuilder.confirm.deleteSavedSection.success.header'),
                detail: t('templateBuilder.confirm.deleteSavedSection.success.message'),
                life: 3000,
              });
              if (item.data) {
                const data = JSON.parse(item.data);
                if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
                  const template = getTemplateIframeDocument();
                  if (template) {
                    const elements = template.querySelectorAll(`[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="${data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]}"]`);
                    Array.from(elements).forEach((element) => {
                      unlinkSyncElement({ elementType: item.type ?? '', elementId: element.id });
                    });
                  }
                  await nestPost(
                    'v4',
                    '/template/updateSyncElements',
                    {},
                    {
                      idShop: UserState.activeShop?.id ?? 0,
                      syncElementId: data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER],
                      elementType: item.type,
                      idTemplate: TemplateEditorState.template?.id,
                      deleted: true,
                    },
                  );
                  updateSyncElementsInOpenedTemplates(TemplateEditorState?.template?.id ?? 0, item as TemplatesParts, true);
                }
              }
            } else {
              toast.add({
                severity: 'error',
                summary: t('templateBuilder.confirm.deleteSavedSection.error.header'),
                detail: t('templateBuilder.confirm.deleteSavedSection.error.message'),
                life: 3000,
              });
            }
            done();
          });
        },
      });
    };

    const handleRename = (item: TemplatePart, event: any, done: Function) => {
      const renameElement: Record<string, any> = {
        oldName: item.name,
        itemId: item.id_template_parts,
        itemType: item.type,
      };
      const data = JSON.parse(item.data || '{}');
      if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
        renameElement.syncElementId = data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER];
      }
      store.commit('liveEditor/showSaveElementModal', {
        selector: '',
        type: item.type,
        renameElement,
      });
      done();
    };

    const duplicateElementName = ref('');
    const toEraseElementId = ref();

    const handleDuplicate = (item: TemplatePart, event: any, done: Function) => {
      duplicateElementName.value = t('myLists.manage.actions.cloneText') + item.name;
      confirm.require({
        group: 'duplicateElement',
        target: event.currentTarget,
        message: t('templateBuilder.confirm.duplicateElement.message'),
        icon: 'fal fa-info-circle',
        acceptLabel: t('validate'),
        acceptClass: 'p-button-success',
        rejectLabel: t('cancel'),
        rejectClass: 'p-button-secondary',
        accept: async () => {
          if (duplicateElementName.value.toLowerCase().trim() !== item.name?.toLocaleLowerCase().trim()) {
            const eraseId = await checkIfPartExistsWithSameName(item.type ?? '', duplicateElementName.value, '');

            const updateElementsInState = (newElement: TemplatePart, update = false) => {
              if (update) {
                store.dispatch('templateEditor/updateSection', { section: newElement });
              } else {
                store.dispatch('templateEditor/addSection', { section: newElement });
              }
            };

            if (eraseId) {
              toEraseElementId.value = eraseId;
              confirm.require({
                group: 'saveDuplicateElement',
                message: t('templateBuilder.confirm.saveNewSection.duplicateName.message', [duplicateElementName.value]),
                header: t('templateBuilder.confirm.saveNewSection.duplicateName.header'),
                icon: 'far fa-exclamation-triangle',
                acceptLabel: t('yes'),
                acceptClass: 'p-button-danger',
                rejectLabel: t('no'),
                rejectClass: 'p-button-secondary',
                accept: async () => {
                  const newPart = await duplicatePartInDatabase(item, duplicateElementName.value, eraseId as number);
                  if (newPart) {
                    toEraseElementId.value = null;
                    updateElementsInState(newPart.newSection, true);
                    toast.add({
                      severity: 'success',
                      summary: t('templateBuilder.confirm.duplicateElement.success.header'),
                      detail: t('templateBuilder.confirm.duplicateElement.success.message'),
                      life: 3000,
                    });
                  } else {
                    showToastError(t('errorMessages.GENERIC_ERROR'));
                  }
                },
                reject: () => {
                  toEraseElementId.value = null;
                },
              });
            } else {
              const newPart = await duplicatePartInDatabase(item, duplicateElementName.value, null);
              if (newPart) {
                updateElementsInState(newPart.newSection);
                toast.add({
                  severity: 'success',
                  summary: t('templateBuilder.confirm.duplicateElement.success.header'),
                  detail: t('templateBuilder.confirm.duplicateElement.success.message'),
                  life: 3000,
                });
              } else {
                showToastError(t('errorMessages.GENERIC_ERROR'));
              }
            }
          }
          done();
        },
      });
    };

    const sortable = ref();
    const sortableFilter = ref('');
    const sortableGroup = ref({ name: TemplateStructureEnum.SECTION, pull: 'clone', put: false });

    const updateFilterAndGroup = (elementType: string) => {
      const { group, filter } = elementsSortableFilterAndGroup[elementType];
      sortableFilter.value = filter;
      sortableGroup.value.name = group;
    };

    onMounted(async () => {
      if (panelHeader.value) {
        const scrollPanel = document.getElementById('loadElementsScrollPanel');

        if (scrollPanel) {
          scrollPanel.style.height = `calc(100% - ${panelHeader.value?.offsetHeight}px - 15px)`;
        }
      }

      await getSavedSections();

      const regexpSearch = new RegExp(search.value ?? '', 'i');
      displayedSections.value = savedSectionsList.value.filter((section: TemplatePart) => section.name?.match(regexpSearch));

      const panel = document.querySelector('#loadElementsScrollPanel') as HTMLElement;
      if (panel && props.showDragIcon) {
        sortable.value = Sortable.create(panel, {
          group: sortableGroup.value as GroupOptions,
          sort: false,
          animation: ANIMATION_SPEED,
          filter: sortableFilter.value,
          onChoose: (evt) => {
            const elementType = evt.item.getAttribute('data-element-type');
            updateFilterAndGroup(elementType ?? '');
          },
          onMove: (evt) => {
            clearPlaceholder();
            const elementType = evt.dragged.getAttribute('data-element-type');
            if (elementType === TemplateStructureEnum.WIDGET) {
              const target = evt.related;
              if (target.classList.contains(WIDGETS_PLACEHOLDER_IDENTIFIER)) {
                const parent = target.parentElement;
                if (parent) {
                  parent.classList.add(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS);
                }
              }
              const section = getSectionParent(target);
              if (section) {
                section.classList.add(BUILDER_ELEMENT_HOVER_CLASS);
              }
            }
          },
          onUnchoose: () => {
            clearPlaceholder();
          },
          onEnd: async (evt) => {
            if (evt.from !== evt.to) {
              const elementType = evt.item.getAttribute('data-element-type');
              const elementsPlaceholderSelector = `.sortable-group-${elementType}.sortable-placeholder`;
              const removePlaceholder = () => {
                const template = getTemplateIframeDocument();
                const placeholders = template?.querySelectorAll(elementsPlaceholderSelector);
                (placeholders ?? []).forEach((placeholder) => placeholder.remove());
                updateSectionsInState();
              };

              let item: any = null;
              if (elementType === TemplateStructureEnum.EMPTY_SECTION) {
                item = displayedSections.value.find((section) => section.type === TemplateStructureEnum.EMPTY_SECTION);
              } else {
                const elementId = evt.item.getAttribute('data-element-id');
                if (elementId) {
                  item = displayedSections.value.find((section) => section.id_template_parts === asInt(elementId));
                }
              }
              if (item) {
                const sectionTo = getSectionParent(evt.to);
                if (sectionTo && elementType === TemplateStructureEnum.WIDGET) {
                  const isSectionSync = sectionTo.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
                  if (isSectionSync && isEmailTemplate(TemplateEditorState.template?.type)) {
                    let additionalMessage = '';
                    if (item.data) {
                      const data = JSON.parse(item.data);
                      if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
                        additionalMessage = 'templateBuilder.confirm.editSyncElement.unlinkWidgetMessageWithoutConfirmation';
                      }
                    }
                    const acceptCallback = async () => {
                      item.data = null;
                      item.html = item.html.replace(TEMPLATE_SYNC_ELEMENT_CLASS, '');
                      item.html = item.html.replace(/data-spm-element-sync-name="([^"]+)"/g, '');
                      item.html = item.html.replace(/data-spm-element-sync-id="([^"]+)"/g, '');
                      await handleSelection(item, elementsPlaceholderSelector, 'bottom');
                      removePlaceholder();
                      togglePanel('configurationPanel', null, !selectedSyncElement.value).then(() => {
                        if (selectedSyncElement.value) {
                          store.dispatch('liveEditor/prepareStructureConfiguration', selectedSyncElement.value.structureConfiguration);
                        }
                      });
                    };

                    if (selectedSyncElement.value && selectedSyncElement.value.syncElementId === isSectionSync) {
                      await acceptCallback();
                      return;
                    }

                    store.dispatch('liveEditor/showSyncElementWarning', {
                      elementId: sectionTo.getAttribute('id'),
                      elementType: TemplateStructureEnum.SECTION,
                      acceptCallback,
                      rejectCallback: () => removePlaceholder(),
                      additionalMessage,
                    });
                    return;
                  }
                }
                await handleSelection(item, elementsPlaceholderSelector, 'bottom');
              }
              // Remove placeholder after drag ended
              removePlaceholder();
            }
          },
        });
      }
    });

    watch([savedSectionsList, search], ([newSectionsList, newSearch]) => {
      const regexpSearch = new RegExp(newSearch ?? '', 'i');
      displayedSections.value = newSectionsList.filter((section: TemplatePart) => section.name?.match(regexpSearch));
    });

    watch(() => sortableGroup, () => {
      if (sortable.value) {
        // Update sortable group option
        sortable.value.option('group', sortableGroup.value);
      }
    }, { deep: true });

    watch(() => sortableFilter, () => {
      if (sortable.value) {
        // Update sortable filter option
        sortable.value.option('filter', sortableFilter.value);
      }
    }, { deep: true });

    return {
      t,
      panelHeader,
      search,
      displayedSections,
      duplicateElementName,
      MEDIA_URL_PART_THUMB_GENERATION,
      toEraseElementId,
      generateSha1Hash,
      handleSelection,
      handleDeletion,
      handleRename,
      handleDuplicate,
    };
  },
});
