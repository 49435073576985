<template>
  <div
    class="elements-preview"
  >
    <Button
      v-if="!showDragIcon"
      class="section-overlay section-insert p-button-success"
      icon="far fa-check"
      :label="loadActionTitle"
      @click="handleSelection(item)"
    />
    <i
      v-else
      class="fas fa-grip-dots-vertical elements-preview__drag-icon ml-2"
    />
    <Chip
      :label="t(`templateBuilder.panels.loadElements.chip.${item.type}`)"
      :icon="isSync ? 'fas fa-arrows-repeat' : ''"
      class="flex elements-preview__chip"
    />
    <SpmOverlayPanel
      v-if="item.type !== 'empty_section'"
      class-trigger="elements-preview__action-menu"
      :close-panel="closeMenuActionsPanel"
    >
      <template #trigger>
        <i class="far fa-ellipsis-v fa-md elements-preview__action-button" />
      </template>
      <SpmPanelMenu
        :items="actionItems"
      />
    </SpmOverlayPanel>
    <div
      class="elements-preview__name"
    >
      {{ item.name }}
    </div>
    <div
      class="elements-preview__image"
    >
      <ProgressSpinner
        style="width: 25px; height: 25px"
        stroke-width="8"
        fill="var(--surface-ground)"
        animation-duration="1.0s"
        aria-label="Custom ProgressSpinner"
        :class="{ block: loadingPreview, hidden: !loadingPreview }"
      />
      <img
        :key="backgroundImageUrl"
        :src="backgroundImageUrl"
        :class="{ block: !loadingPreview, hidden: loadingPreview }"
        @load="onImageLoaded"
        @error="onImageError"
      >
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  SetupContext,
  watch,
  ref,
  onMounted,
  Ref,
  computed,
} from 'vue';

import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Chip from 'primevue/chip';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { MenuItem } from 'primevue/menuitem';

import { MEDIA_URL_PART_THUMB_GENERATION, TEMPLATE_SYNC_ELEMENT_IDENTIFIER } from '@/components/template-builder/utils/constants';

import {
  TemplateEditorState,
  removeItemFromSectionsToRefresh,
} from '@/composables/template-editor/TemplateEditor';

import { generateSha1Hash } from '@/helpers';

import {
  TemplatePart,
} from '@/types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LoadElementsPreview',

  components: {
    Button,
    ProgressSpinner,
    SpmOverlayPanel,
    SpmPanelMenu,
    Chip,
  },

  props: {
    item: {
      type: Object as PropType<TemplatePart>,
      required: true,
    },

    showDragIcon: {
      type: Boolean,
      required: false,
      default: true,
    },

    loadActionTitle: {
      type: String,
      default: '',
    },
  },

  emits: ['handle-selection', 'handle-deletion', 'handle-rename', 'handle-duplicate'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const getBackgoundImageUrl = () => (props.item.type === 'empty_section'
      ? '/images/template-builder/new_empty_section.png'
      : `${MEDIA_URL_PART_THUMB_GENERATION.replace('{SHA1_KEY}', generateSha1Hash(props.item.id_template_parts))}&t=${new Date().getTime()}`);

    const backgroundImageUrl = ref(getBackgoundImageUrl());

    const maxRetries = ref(30);
    const retryCount = ref(0);
    const loadingPreview = ref(false);
    const closeMenuActionsPanel = ref(false);

    const isSync = computed(() => {
      if (props.item.data) {
        const data = JSON.parse(props.item.data);
        if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
          return true;
        }
      }
      return false;
    });

    const reRenderPreview = () => {
      backgroundImageUrl.value = getBackgoundImageUrl();
    };

    const onImageLoaded = () => {
      loadingPreview.value = false;
    };

    const onImageError = () => {
      loadingPreview.value = true;
      // Retry loading image
      if (retryCount.value < maxRetries.value) {
        retryCount.value += 1;
        setTimeout(() => {
          reRenderPreview();
        }, 1000);
      } else {
        loadingPreview.value = false;
      }
    };

    const handleSelection = async (item: TemplatePart) => {
      emit('handle-selection', item);
    };

    const handleDeletion = (item: TemplatePart, event: any) => {
      closeMenuActionsPanel.value = false;
      emit('handle-deletion', item, event, () => {
        closeMenuActionsPanel.value = true;
      });
    };

    const handleRename = (item: TemplatePart, event: any) => {
      closeMenuActionsPanel.value = false;
      emit('handle-rename', item, event, () => {
        closeMenuActionsPanel.value = true;
      });
    };

    const handleDuplicate = (item: TemplatePart, event: any) => {
      closeMenuActionsPanel.value = false;
      emit('handle-duplicate', item, event, () => {
        closeMenuActionsPanel.value = true;
      });
    };

    const checkRefreshPreview = () => {
      if (TemplateEditorState.sectionsToRefresh.find((section) => section === props.item.id_template_parts)) {
        loadingPreview.value = true;
        setTimeout(() => {
          reRenderPreview();
          removeItemFromSectionsToRefresh(props.item.id_template_parts);
        }, 1000);
      }
    };

    const actionItems: Ref<MenuItem[]> = ref([
      {
        label: t('templateBuilder.panels.loadElements.actions.rename'),
        icon: 'far fa-edit',
        command: (event) => {
          handleRename(props.item, event.originalEvent);
        },
      },
      {
        label: t('templateBuilder.panels.loadElements.actions.duplicate'),
        icon: 'far fa-clone',
        command: (event) => {
          handleDuplicate(props.item, event.originalEvent);
        },
      },
      {
        label: t('templateBuilder.panels.loadElements.actions.delete'),
        icon: 'far fa-trash',
        command: (event) => {
          handleDeletion(props.item, event.originalEvent);
        },
      },
    ]);

    watch(() => TemplateEditorState.sectionsToRefresh, () => {
      checkRefreshPreview();
    }, { deep: true });

    onMounted(() => {
      checkRefreshPreview();
    });

    return {
      t,
      backgroundImageUrl,
      loadingPreview,
      actionItems,
      isSync,
      closeMenuActionsPanel,

      handleSelection,
      onImageLoaded,
      onImageError,
    };
  },
});
</script>

<style lang="scss" scoped>
.elements-preview {
  border: $template-builder-panels-sections-section-border;
  position: relative;
  cursor: grab;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    .section-overlay {
      display: block;
    }
  }

  .section-overlay {
    display: none;
    position: absolute;

    &.section-insert {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      font-size: 0.875rem;
      padding: 0.25rem 0.875rem;
    }
  }

  &__chip {
    display: block;
    position: absolute;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    font-size: 12px;
  }

  &__drag-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $mid-grey;
  }

  &__name {
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;
    padding: 5px 0px;
  }

  &__image {
    width: 100%;
    height: 150px;
    overflow: hidden;
    justify-content: center;
    align-items: flex-start;
    display: flex;

    img {
      height: auto;
      width: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
</style>

<style lang="scss">
.elements-preview__action-menu {
  display: block;
  position: absolute;
  right: 5px;
  font-size: 1.2rem;
  padding: 0.1rem 0.1rem !important;
  width: auto !important;
  border: none !important;

  :deep() .p-button-label {
    display: none;
  }
}
.elements-preview__action-button {
  padding: 0.4rem 0.8rem !important;
  margin-top: .2rem;
  border-radius: 4px;
  cursor: pointer;
  width: auto !important;
  border: none !important;
  color: $mid-grey;
  &:hover {
    backdrop-filter: brightness(0.96);
    transition: .2s ease;
  }
}
</style>
