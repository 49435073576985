
import {
  defineComponent,
  PropType,
  SetupContext,
  watch,
  ref,
  onMounted,
  Ref,
  computed,
} from 'vue';

import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Chip from 'primevue/chip';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { MenuItem } from 'primevue/menuitem';

import { MEDIA_URL_PART_THUMB_GENERATION, TEMPLATE_SYNC_ELEMENT_IDENTIFIER } from '@/components/template-builder/utils/constants';

import {
  TemplateEditorState,
  removeItemFromSectionsToRefresh,
} from '@/composables/template-editor/TemplateEditor';

import { generateSha1Hash } from '@/helpers';

import {
  TemplatePart,
} from '@/types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LoadElementsPreview',

  components: {
    Button,
    ProgressSpinner,
    SpmOverlayPanel,
    SpmPanelMenu,
    Chip,
  },

  props: {
    item: {
      type: Object as PropType<TemplatePart>,
      required: true,
    },

    showDragIcon: {
      type: Boolean,
      required: false,
      default: true,
    },

    loadActionTitle: {
      type: String,
      default: '',
    },
  },

  emits: ['handle-selection', 'handle-deletion', 'handle-rename', 'handle-duplicate'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const getBackgoundImageUrl = () => (props.item.type === 'empty_section'
      ? '/images/template-builder/new_empty_section.png'
      : `${MEDIA_URL_PART_THUMB_GENERATION.replace('{SHA1_KEY}', generateSha1Hash(props.item.id_template_parts))}&t=${new Date().getTime()}`);

    const backgroundImageUrl = ref(getBackgoundImageUrl());

    const maxRetries = ref(30);
    const retryCount = ref(0);
    const loadingPreview = ref(false);
    const closeMenuActionsPanel = ref(false);

    const isSync = computed(() => {
      if (props.item.data) {
        const data = JSON.parse(props.item.data);
        if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
          return true;
        }
      }
      return false;
    });

    const reRenderPreview = () => {
      backgroundImageUrl.value = getBackgoundImageUrl();
    };

    const onImageLoaded = () => {
      loadingPreview.value = false;
    };

    const onImageError = () => {
      loadingPreview.value = true;
      // Retry loading image
      if (retryCount.value < maxRetries.value) {
        retryCount.value += 1;
        setTimeout(() => {
          reRenderPreview();
        }, 1000);
      } else {
        loadingPreview.value = false;
      }
    };

    const handleSelection = async (item: TemplatePart) => {
      emit('handle-selection', item);
    };

    const handleDeletion = (item: TemplatePart, event: any) => {
      closeMenuActionsPanel.value = false;
      emit('handle-deletion', item, event, () => {
        closeMenuActionsPanel.value = true;
      });
    };

    const handleRename = (item: TemplatePart, event: any) => {
      closeMenuActionsPanel.value = false;
      emit('handle-rename', item, event, () => {
        closeMenuActionsPanel.value = true;
      });
    };

    const handleDuplicate = (item: TemplatePart, event: any) => {
      closeMenuActionsPanel.value = false;
      emit('handle-duplicate', item, event, () => {
        closeMenuActionsPanel.value = true;
      });
    };

    const checkRefreshPreview = () => {
      if (TemplateEditorState.sectionsToRefresh.find((section) => section === props.item.id_template_parts)) {
        loadingPreview.value = true;
        setTimeout(() => {
          reRenderPreview();
          removeItemFromSectionsToRefresh(props.item.id_template_parts);
        }, 1000);
      }
    };

    const actionItems: Ref<MenuItem[]> = ref([
      {
        label: t('templateBuilder.panels.loadElements.actions.rename'),
        icon: 'far fa-edit',
        command: (event) => {
          handleRename(props.item, event.originalEvent);
        },
      },
      {
        label: t('templateBuilder.panels.loadElements.actions.duplicate'),
        icon: 'far fa-clone',
        command: (event) => {
          handleDuplicate(props.item, event.originalEvent);
        },
      },
      {
        label: t('templateBuilder.panels.loadElements.actions.delete'),
        icon: 'far fa-trash',
        command: (event) => {
          handleDeletion(props.item, event.originalEvent);
        },
      },
    ]);

    watch(() => TemplateEditorState.sectionsToRefresh, () => {
      checkRefreshPreview();
    }, { deep: true });

    onMounted(() => {
      checkRefreshPreview();
    });

    return {
      t,
      backgroundImageUrl,
      loadingPreview,
      actionItems,
      isSync,
      closeMenuActionsPanel,

      handleSelection,
      onImageLoaded,
      onImageError,
    };
  },
});
