import { render } from "./TemplateEditor.vue?vue&type=template&id=388e2ed3&scoped=true"
import script from "./TemplateEditor.vue?vue&type=script&lang=ts"
export * from "./TemplateEditor.vue?vue&type=script&lang=ts"

import "./TemplateEditor.vue?vue&type=style&index=0&id=388e2ed3&scoped=true&lang=scss"
import "./TemplateEditor.vue?vue&type=style&index=1&id=388e2ed3&lang=scss"
import "./TemplateEditor.vue?vue&type=style&index=2&id=388e2ed3&lang=scss"
script.render = render
script.__scopeId = "data-v-388e2ed3"

export default script