<template>
  <InputSwitch
    v-if="isVisible"
    v-model="displayProperties"
    v-tooltip="t('templateBuilder.ToggleStyleActiveTooltip')"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
  computed,
} from 'vue';

import { store } from '@/store';

import {
  TemplateEditorState,
} from '@/composables/template-editor/TemplateEditor';

import {
  ParserFieldObject,
  Property,
  TemplateStructureEnum,
} from '@/types';

import {
  getDynamicStylesForSelector,
  removeDynamicStyle,
} from '@/components/template-builder/utils/parser';

import InputSwitch from 'primevue/inputswitch';
import { isCssProperty, isCustomProperty } from '@/helpers/Css';
import { getTemplateIframeDocument, isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import { getCssPropertyByName } from '@/components/template-builder/getters';
import { setAttribute, setCssProperty, setMediaDisplay } from '@/components/template-builder/setters';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import { TEMPLATE_SYNC_ELEMENT_IDENTIFIER, TEMPLATE_SYNC_ELEMENT_NAME } from '../../utils/constants';

export default defineComponent({
  name: 'ToggleDisplaySettings',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    InputSwitch,
  },

  props: {
    configs: {
      type: Object,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },

    defaultValue: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: {
    'display-settings': Boolean,
  },

  setup(props, context) {
    const { t } = useI18n();
    const isVisible = ref(true);
    const displayProperties: Ref<boolean> = ref(false);
    const properties: Ref<Property[]> = ref(props.configs.properties);
    const isMounted = ref(false); // Variable de contrôle
    const selectedStructure = computed(() => store.getters['liveEditor/getSelectedStructure']);
    const selectedSyncElement = computed(() => store.getters['liveEditor/getSelectedSyncElement']);
    const isSynchronizedPage = computed(() => {
      if (selectedStructure.value && selectedStructure.value.type === TemplateStructureEnum.PAGE) {
        let designSection = TemplateEditorState?.template?.sections.find((item) => item.type === TemplateStructureEnum.DESIGN);
        if (isDisplayTemplate(TemplateEditorState?.template?.type)) {
          designSection = TemplateEditorState?.template?.sections[0];
        }
        if (designSection) {
          const data = JSON.parse(designSection.data || '{}');
          if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
            return true;
          }
        }
      }
      return false;
    });
    const headerTitle = computed(() => {
      if (isSynchronizedPage.value) {
        let designSection = TemplateEditorState?.template?.sections.find((item) => item.type === TemplateStructureEnum.DESIGN);
        if (isDisplayTemplate(TemplateEditorState?.template?.type)) {
          designSection = TemplateEditorState?.template?.sections[0];
        }
        if (designSection) {
          const data = JSON.parse(designSection.data || '{}');
          if (data[TEMPLATE_SYNC_ELEMENT_NAME]) {
            return data[TEMPLATE_SYNC_ELEMENT_NAME];
          }
        }
      }
      return '';
    });

    onBeforeMount(() => {
      // Check if a CSS rule exists for the current selector and property name
      properties.value.some((property: Property) => {
        if (property.value) {
          let propertyName = property.name;

          // eslint-disable-next-line prefer-destructuring
          let selector = props.parserValues.selector;

          const backgroundImageOptions = ['background-size', 'background-repeat', 'background-position-x', 'background-position-y'];
          if (backgroundImageOptions.includes(propertyName)) {
            propertyName = 'background-image';
          }
          if (propertyName === 'backgroundImageCustomSelector') {
            propertyName = 'background-image';
            selector = property.value;
          }

          if (isCssProperty(propertyName)) {
            // For some properties, we need to check a specific property name because they are dispatched on many properties
            switch (propertyName) {
              case 'border-color':
                propertyName = 'border-top-color';
                break;
              default:
                break;
            }

            const cssValue = getCssPropertyByName(selector, propertyName, false, props.configs.mobile ?? false);
            const currentDynamicStyles = getDynamicStylesForSelector(selector, props.configs.mobile ?? false);
            if (
              cssValue
              && typeof cssValue === 'object'
              && Object.prototype.hasOwnProperty.call(cssValue, 'type')
              && (cssValue.type !== 'parent' || propertyName === 'max-width' || (['background-image'].includes(propertyName) && cssValue.value !== 'none')
                || (['background'].includes(propertyName) && Object.prototype.hasOwnProperty.call(currentDynamicStyles, 'background-image')
                  && currentDynamicStyles['background-image'].match('gradient'))
                || (['background'].includes(propertyName) && getCssPropertyByName(props.parserValues.selector, 'background-color', true, props.configs.mobile ?? false)))
            ) {
              // If true, we display the properties
              displayProperties.value = true;
              if (cssValue.type === 'element' && propertyName === 'background-image' && !cssValue.value.match('url\\(')) {
                displayProperties.value = false;
              }
            }
          } else {
            const customProperty = isCustomProperty(propertyName);

            if (customProperty.length > 0) {
              let cssValue;
              let element;
              switch (customProperty[0].type) {
                case 'class':
                  // eslint-disable-next-line no-case-declarations
                  element = getTemplateIframeDocument().querySelector(props.parserValues.selector);

                  // We check if the element contains the needed class name
                  if (element && Array.from(element.classList).some((className) => (new RegExp(`^${customProperty[0].property}`)).test(className))) {
                    displayProperties.value = true;
                  }
                  break;
                case 'style':
                  cssValue = getCssPropertyByName(props.parserValues.selector, customProperty[0].cssProperty ?? '', false, props.configs.mobile ?? false);

                  // We check if the current selector contains the CSS property
                  if (cssValue && typeof cssValue === 'object' && Object.prototype.hasOwnProperty.call(cssValue, 'type') && cssValue.type !== 'parent') {
                    // If true, we display the properties
                    displayProperties.value = true;
                  }
                  break;
                case 'attribute':
                  element = getTemplateIframeDocument().querySelector(props.parserValues.selector);

                  if (element && element.hasAttribute(customProperty[0].property) && element.getAttribute(customProperty[0].property)) {
                    displayProperties.value = true;
                  }
                  break;
                default:
                  break;
              }
            } else {
              isVisible.value = false;
              displayProperties.value = true;
            }
          }
        }

        return displayProperties.value;
      });
      if (props.defaultValue) {
        displayProperties.value = props.defaultValue;
      }
    });

    onMounted(() => {
      isMounted.value = true; // Le composant est monté
    });

    const ignoreWatch = ref(false);

    watch(displayProperties, (newValue) => {
      if (ignoreWatch.value) {
        ignoreWatch.value = false;
        return;
      }
      if (isMounted.value && properties.value.length > 0) {
        const acceptCallback = () => {
          properties.value.forEach((property: Property) => {
            if (property) {
              const propertyName = property.name;
              const customProperty = isCustomProperty(propertyName);
              // Toogle management for "Restrict display to a media"
              if (propertyName === 'show-for-') {
                if (!displayProperties.value || property.value) {
                  const property2: Property = {
                    name: property.name, // Nom de la propriété
                    getter: () => '', // Fonction getter
                    setters: [], // Tableau de fonctions setters
                    value: (!displayProperties.value ? 'all' : property.value), // Valeur de la largeur selon vos besoins
                  };
                  setMediaDisplay(props.parserValues.selector, property2);
                }
              } else if (customProperty.length > 0 && !displayProperties.value) {
                switch (customProperty[0].type) {
                  case 'attribute':
                    // eslint-disable-next-line no-param-reassign
                    property.value = '';
                    setAttribute(props.parserValues.selector, property);
                    break;
                  default:
                    break;
                }
              } else if (isCssProperty(propertyName) && !displayProperties.value) {
                if (property.name === 'background') {
                  removeDynamicStyle(props.parserValues.selector, { 'background-color': 'background-color' }, props.configs.mobile ?? false);
                }
                removeDynamicStyle(props.parserValues.selector, { [property.name]: property.name }, props.configs.mobile ?? false);
              } else if (isCssProperty(propertyName)) {
                // Ensure the roperty.value object is complete
                const property2: Property = {
                  name: property.name, // Nom de la propriété
                  getter: () => '', // Fonction getter
                  setters: [], // Tableau de fonctions setters
                  value: property.value, // Valeur de la largeur selon vos besoins
                };
                setCssProperty(props.parserValues.selector, property2, props.configs.mobile ?? false);
              }
            }
          });
          context.emit('display-settings', newValue);
        };
        if (isSynchronizedPage.value
          && !selectedSyncElement.value
          && selectedStructure.value
          && selectedStructure.value.type === TemplateStructureEnum.PAGE
        ) {
          store.dispatch('liveEditor/showSyncElementWarning', {
            elementId: selectedStructure.value.identifier,
            elementType: TemplateStructureEnum.DESIGN,
            name: headerTitle.value,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            acceptCallback,
            rejectCallback: () => {
              ignoreWatch.value = true;
              displayProperties.value = !displayProperties.value;
              context.emit('display-settings', !newValue);
            },
          });
        } else {
          acceptCallback();
        }
      } else {
        context.emit('display-settings', newValue);
      }
    });

    return {
      isVisible,
      displayProperties,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.p-inputswitch {
  width: 32px;
  height: 18px;

  &:not(.p-disabled):hover:deep() .p-inputswitch-slider {
    background: #ced4da;
  }

  &:deep() .p-inputswitch-slider {
    outline: none !important;
    box-shadow: none !important;

    &::before {
      background: #ffffff;
      width: 0.9rem;
      height: 0.9rem;
      left: 0.1rem;
      transform: translateY(-50%);
      border-radius: 50%;
      transition-duration: 0.2s;
      margin-top: 0;
    }
  }

  &.p-inputswitch-checked:deep()  .p-inputswitch-slider {
    background: $brand-color-primary !important;

    &::before {
      transform: translate(0.85rem, -50%);
    }
  }
}
</style>
