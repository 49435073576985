import {
  AppearEffectEnum,
  ElementPositionEnum,
  TemplateParentTypeEnum,
  TemplateStructureEnum,
} from '@/types';

export interface CustomDataAttributes {
  selector: string;
  classes: string[];
  attributes: string[];
}

export interface CustomDataStructure {
  [key: string]: {
    [key: string]: CustomDataAttributes[];
  };
}

export const customDataDisplay: CustomDataStructure = {
  [TemplateStructureEnum.DESIGN]: {
    [TemplateParentTypeEnum.POPUP]: [
      {
        selector: '#spm_content',
        classes: Object.values(AppearEffectEnum).map((effect) => `spmEffect_${effect}`),
        attributes: [],
      },
      {
        selector: '#spm_body .spm_content_wrapper',
        classes: Object.values(ElementPositionEnum).map((position) => `spm_box${position}`),
        attributes: [],
      },
      {
        selector: '#spm_body .spm_display_overlay',
        classes: Object.values(AppearEffectEnum).map((effect) => `spmEffect_${effect}`),
        attributes: [],
      },
      {
        selector: '#spm_body',
        classes: ['spm_auto_close'],
        attributes: ['data-spm_auto_close'],
      },
    ],
  },
};
