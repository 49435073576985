<template>
  <div class="configuration-wrapper">
    <Panel>
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.panels.cssPanelMediaQueries.header') }}</h4>
        </div>
      </template>

      <template
        v-if="!widgetId"
        #icons
      >
        <i
          class="far fa-times icons-header-panel"
          @click="hideLeftToolbar"
        />
      </template>

      <div class="field-group pt-3">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <div class="p-fluid flex">
                <BaseInformation
                  message="templateBuilder.informations.cssPanelMediaQueries"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field-group pt-3">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <div class="p-fluid flex">
                <VCodeMirror
                  ref="codeMirrorEditorRef"
                  v-model:value="inputValue"
                  :options="cmOptions"
                  :auto-height="true"
                  @keyup="handleValueChange"
                  @paste="handleValueChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  computed,
} from 'vue';
import BaseInformation from '@/components/fields/BaseInformation.vue';
import { VCodeMirror } from 'vue3-code-mirror';
import {
  getCssStyles,
  setCssStyles,
} from '@/components/template-builder/setters';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import {
  CUSTOM_WIDGET_CSS_PREFIX,
  TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
  TEMPLATE_SYNC_ELEMENT_NAME,
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
} from '@/components/template-builder/utils/constants';
import {
  hideLeftToolbar,
  setChangeInLeftToolbar,
  TemplateEditorState,
} from '@/composables/template-editor/TemplateEditor';
import Panel from 'primevue/panel';
import { isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import { TemplateStructureEnum } from '@/types';

export default defineComponent({
  name: 'CssPanelMediaQueries',

  components: {
    BaseInformation,
    VCodeMirror,
    Panel,
  },

  props: {
    widgetId: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const cmOptions = {
      mode: 'css', // Language mode
      theme: 'default', // Theme
      lineNumbers: true, // Show line number
      smartIndent: true, // Smart indent
      indentUnit: 4, // The smart indent unit is 2 spaces in length
      foldGutter: true, // Code folding
      styleActiveLine: true, // Display the style of the selected row
    };

    const inputValue: Ref<string> = ref('');
    const codeMirrorEditorRef = ref();
    const cssType = ref('static_persistent_mobile');
    const isDesignSync = ref(false);
    const headerTitle = ref('');
    const backupValue = ref('');
    const selectedSyncElement = computed(() => store.getters['liveEditor/getSelectedSyncElement']);

    const handleValueChange = () => {
      // Insert new value in iframe (optional : create the style tag if doesn't exist)
      const acceptCallback = (addHistory = true) => {
        let sectionId = null;
        if (isDisplayTemplate(TemplateEditorState.template?.type)) {
          sectionId = TemplateEditorState.template?.sections[0].id_template_elements.toString();
        } else {
          sectionId = TemplateEditorState.template?.content.design.toString();
        }
        setCssStyles(cssType.value, inputValue.value, sectionId);
        if (addHistory) {
          setChangeInLeftToolbar();
        }
      };

      if (isDesignSync.value && !selectedSyncElement.value) {
        store.dispatch('liveEditor/showSyncElementWarning', {
          elementId: 'spm_body',
          elementType: TemplateStructureEnum.DESIGN,
          name: headerTitle.value,
          acceptCallback: () => acceptCallback(false),
          rejectCallback: () => {
            inputValue.value = backupValue.value;
          },
        });
      } else {
        acceptCallback(selectedSyncElement.value === null);
      }
    };

    onMounted(() => {
      if (TemplateEditorState.template && TemplateEditorState.template.informations.imported) {
        cssType.value = 'dynamic_persistent_mobile';
      }
      if (props.widgetId) {
        cssType.value = `${CUSTOM_WIDGET_CSS_PREFIX}${props.widgetId.split(TEMPLATE_WIDGET_IDENTIFIER_PREFIX)[1]}_mobile`;
      }
      inputValue.value = getCssStyles(cssType.value, true);
      if (TemplateEditorState.template) {
        let design = null;
        if (isDisplayTemplate(TemplateEditorState.template?.type)) {
          design = TemplateEditorState.template?.sections[0];
        } else {
          design = TemplateEditorState.template.sections.find((section) => section.type === TemplateStructureEnum.DESIGN);
        }
        if (design) {
          const data = JSON.parse(design.data || '{}');
          if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] && data[TEMPLATE_SYNC_ELEMENT_NAME]) {
            isDesignSync.value = true;
            backupValue.value = inputValue.value;
            headerTitle.value = data[TEMPLATE_SYNC_ELEMENT_NAME];
          }
        }
      }
    });

    return {
      t,
      cmOptions,
      inputValue,
      codeMirrorEditorRef,
      handleValueChange,
      hideLeftToolbar,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-code-mirror {
  width: 100%;
}

:deep() .CodeMirror {
  height: 100% !important;
}

.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}

.field-group-content {
  .field-wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
</style>
