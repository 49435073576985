
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';

import { useStore } from '@/store';

import {
  FieldsGroup,
  TabItem,
  TabSubitem,
  ParserObject,
} from '@/types';
import StructureEdit from '@/components/template-builder/fields/StructureEdit.vue';
import ToggleMobileSettings from '@/components/template-builder/fields/partials/ToggleMobileSettings.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Divider from 'primevue/divider';

import ConfigurationPanelTabContentFieldGroup from '@/components/template-builder/panels/ConfigurationPanelTabContentFieldGroup.vue';

export default defineComponent({
  name: 'ConfigurationPanelTabContentItem',

  components: {
    StructureEdit,
    TabView,
    TabPanel,
    ConfigurationPanelTabContentFieldGroup,
    Divider,
    ToggleMobileSettings,
  },

  props: {
    tabItem: {
      type: Object as PropType<TabItem | TabSubitem>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    fieldGroup: {
      type: Object as PropType<FieldsGroup | TabItem>,
      required: true,
    },

    fieldGroupIndex: {
      type: Number,
      required: true,
    },

    activeFieldGroupIndex: {
      type: Array as PropType<number[]>,
      required: true,
    },

    headerTitle: {
      type: String,
      required: false,
      default: '',
    },

    isSynchronizedPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['toggle-field-group', 'refresh'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const showFieldGroup = computed(() => props.parserValues[props.tabItem.label].toggleItemsDisplay?.[props.fieldGroup.label]?.value ?? true);

    const toggleFieldGroup = (index: number) => {
      emit('toggle-field-group', index);
    };

    const handleRefresh = () => {
      emit('refresh');
    };

    const handleChangeMobileSettings = (data: { tabItemLabel: string; fieldGroupLabel: string; value: boolean }) => {
      store.dispatch('liveEditor/toggleSetting', data);
    };

    return {
      t,
      showFieldGroup,

      toggleFieldGroup,
      handleRefresh,
      handleChangeMobileSettings,
    };
  },
});
