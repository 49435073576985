import {
  ComponentNameEnum,
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TemplateStructureEnum,
  TabItem,
  StepperSignEnum,
  FieldsGroup,
  Property,
} from '@/types';
import textStylesFieldConfig from '@/components/template-builder/config/fields/text-styles-config';
import getTextAlignFieldConfig from '@/components/template-builder/config/fields/text-align-config';
import getBorderRadiusFieldConfig from '@/components/template-builder/config/fields/border-radius-config';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import modelWidthFieldConfig from '@/components/template-builder/config/fields/model-width-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
// eslint-disable-next-line import/no-cycle
import {
  cleanCssByProperty,
  toggleMobileStyle,
  recalculateLineHeightValue,
  setCssButtonWidthProperty,
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setCssPropertyWithImportantAndRemoveIfZero,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getCssPropertyByName } from '@/components/template-builder/getters';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import lineHeightFieldConfig from '@/components/template-builder/config/fields/line-height-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';
// eslint-disable-next-line import/no-cycle
import { getElementFromIframe } from '@/components/template-builder/utils/helpers';
import { HIDE_MOBILE_ATTRIBUTE } from '@/components/template-builder/utils/constants';

const getTextFieldsGroup = (mobile = false): Array<FieldsGroup> => {
  const pageLinksGroup = {
    type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
    label: 'templateBuilder.configs.pageLinks',
    selector: 'body table#spm_body',
    items: [
      {
        label: 'templateBuilder.fields.color',
        selector: '{SELECTOR} a, {SELECTOR} a:hover, {SELECTOR} a:visited, {SELECTOR} a:active',
        mobile,
        properties: [
          {
            name: 'color',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          },
        ],
        ...getColorFieldConfig('#333333'),
        options: {
          defaultValue: '#2795b6',
        },
      },
      {
        label: 'templateBuilder.fields.style',
        selector: '{SELECTOR} a, {SELECTOR} a:hover, {SELECTOR} a:visited, {SELECTOR} a:active',
        mobile,
        properties: [
          {
            name: 'font-weight',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          },
          {
            name: 'font-style',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          },
          {
            name: 'text-decoration-line',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          },
          {
            name: 'text-transform',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          },
        ],
        ...textStylesFieldConfig,
      },
    ],
  };

  const textFieldsGroup = [
    {
      type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
      label: 'templateBuilder.configs.pageTexts',
      selector: 'body table#spm_body',
      items: [
        {
          label: 'templateBuilder.fields.font',
          selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
          mobile,
          properties: [
            {
              name: 'font-family',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-size',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [
                (selector: string, property: Property) => recalculateLineHeightValue(selector, property, mobile),
                (selector: string, property: Property) => setCssProperty(selector, property, mobile),
              ],
            },
            {
              name: 'font-weight',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-style',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-decoration-line',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-transform',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'color',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...fontFieldConfig,
        },
        {
          label: 'templateBuilder.fields.textAlign',
          selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
          mobile,
          properties: [
            {
              name: 'text-align',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getTextAlignFieldConfig(),
        },
        {
          label: 'templateBuilder.fields.lineHeight.label',
          selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
          mobile,
          properties: [
            {
              name: 'line-height',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [
                (selector: string, property: Property) => cleanCssByProperty(selector, property, mobile),
                (selector: string, property: Property) => setCssProperty(selector, property, mobile),
              ],
            },
          ],
          ...lineHeightFieldConfig,
          displayToggle: false,
        },
        {
          label: 'templateBuilder.fields.spaceBetweenChars',
          selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
          mobile,
          properties: [
            {
              name: 'letter-spacing',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getStepperFieldConfig(StepperSignEnum.PX),
        },
      ],
    },
    {
      type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
      label: 'templateBuilder.configs.title1',
      selector: 'body table#spm_body',
      items: [
        {
          label: 'templateBuilder.fields.font',
          selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
          mobile,
          properties: [
            {
              name: 'font-family',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-size',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [
                (selector: string, property: Property) => recalculateLineHeightValue(selector, property, mobile),
                (selector: string, property: Property) => setCssProperty(selector, property, mobile),
              ],
            },
            {
              name: 'font-weight',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-style',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-decoration-line',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-transform',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'color',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...fontFieldConfig,
        },
        {
          label: 'templateBuilder.fields.titleMarginVertical',
          selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
          mobile,
          properties: [
            {
              name: 'margin-top',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-bottom',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getVerticalSpacesFieldConfig('margin'),
        },
        {
          label: 'templateBuilder.fields.titleMarginHorizontal',
          selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
          mobile,
          properties: [
            {
              name: 'margin-left',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-right',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getHorizontalSpacesFieldConfig('margin'),
        },
        {
          label: 'templateBuilder.fields.lineHeight.label',
          selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1, {SELECTOR} h1.spm_product_list_title',
          mobile,
          properties: [{
            name: 'line-height',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...lineHeightFieldConfig,
          displayToggle: false,
        },
        {
          label: 'templateBuilder.fields.spaceBetweenChars',
          selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
          mobile,
          properties: [{
            name: 'letter-spacing',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getStepperFieldConfig(StepperSignEnum.PX),
        },
        {
          label: 'templateBuilder.fields.titleAlign',
          selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
          mobile,
          properties: [{
            name: 'text-align',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getTextAlignFieldConfig(),

        },
      ],
    },
    {
      type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
      label: 'templateBuilder.configs.title2',
      selector: 'body table#spm_body',
      items: [
        {
          label: 'templateBuilder.fields.font',
          selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
          mobile,
          properties: [
            {
              name: 'font-family',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-size',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [
                (selector: string, property: Property) => recalculateLineHeightValue(selector, property, mobile),
                (selector: string, property: Property) => setCssProperty(selector, property, mobile),
              ],
            },
            {
              name: 'font-weight',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-style',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-decoration-line',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-transform',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'color',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...fontFieldConfig,
        },
        {
          label: 'templateBuilder.fields.titleMarginVertical',
          selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
          mobile,
          properties: [
            {
              name: 'margin-top',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-bottom',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getVerticalSpacesFieldConfig('margin'),
        },
        {
          label: 'templateBuilder.fields.titleMarginHorizontal',
          selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
          mobile,
          properties: [
            {
              name: 'margin-left',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-right',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getHorizontalSpacesFieldConfig('margin'),
        },
        {
          label: 'templateBuilder.fields.lineHeight.label',
          selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
          mobile,
          properties: [{
            name: 'line-height',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...lineHeightFieldConfig,
          displayToggle: false,
        },
        {
          label: 'templateBuilder.fields.spaceBetweenChars',
          selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
          mobile,
          properties: [{
            name: 'letter-spacing',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getStepperFieldConfig(StepperSignEnum.PX),
        },
        {
          label: 'templateBuilder.fields.titleAlign',
          selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
          mobile,
          properties: [{
            name: 'text-align',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getTextAlignFieldConfig(),

        },
      ],
    },
    {
      type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
      label: 'templateBuilder.configs.title3',
      selector: 'body table#spm_body',
      items: [
        {
          label: 'templateBuilder.fields.font',
          selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
          mobile,
          properties: [
            {
              name: 'font-family',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-size',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [
                (selector: string, property: Property) => recalculateLineHeightValue(selector, property, mobile),
                (selector: string, property: Property) => setCssProperty(selector, property, mobile),
              ],
            },
            {
              name: 'font-weight',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-style',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-decoration-line',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-transform',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'color',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...fontFieldConfig,
        },
        {
          label: 'templateBuilder.fields.titleMarginVertical',
          selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
          mobile,
          properties: [
            {
              name: 'margin-top',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-bottom',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getVerticalSpacesFieldConfig('margin'),
        },
        {
          label: 'templateBuilder.fields.titleMarginHorizontal',
          selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
          mobile,
          properties: [
            {
              name: 'margin-left',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-right',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getHorizontalSpacesFieldConfig('margin'),
        },
        {
          label: 'templateBuilder.fields.lineHeight.label',
          selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
          mobile,
          properties: [{
            name: 'line-height',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...lineHeightFieldConfig,
          displayToggle: false,
        },
        {
          label: 'templateBuilder.fields.spaceBetweenChars',
          selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
          mobile,
          properties: [{
            name: 'letter-spacing',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getStepperFieldConfig(StepperSignEnum.PX),
        },
        {
          label: 'templateBuilder.fields.titleAlign',
          selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
          mobile,
          properties: [{
            name: 'text-align',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getTextAlignFieldConfig(),

        },
      ],
    },
    {
      type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
      label: 'templateBuilder.configs.title4',
      selector: 'body table#spm_body',
      items: [
        {
          label: 'templateBuilder.fields.font',
          selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
          mobile,
          properties: [
            {
              name: 'font-family',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-size',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [
                (selector: string, property: Property) => recalculateLineHeightValue(selector, property, mobile),
                (selector: string, property: Property) => setCssProperty(selector, property, mobile),
              ],
            },
            {
              name: 'font-weight',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'font-style',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-decoration-line',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'text-transform',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'color',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...fontFieldConfig,
        },
        {
          label: 'templateBuilder.fields.titleMarginVertical',
          selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
          mobile,
          properties: [
            {
              name: 'margin-top',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-bottom',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getVerticalSpacesFieldConfig('margin', 10),
        },
        {
          label: 'templateBuilder.fields.titleMarginHorizontal',
          selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
          mobile,
          properties: [
            {
              name: 'margin-left',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
            {
              name: 'margin-right',
              getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
              setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
            },
          ],
          ...getHorizontalSpacesFieldConfig('margin'),
        },
        {
          label: 'templateBuilder.fields.lineHeight.label',
          selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
          mobile,
          properties: [{
            name: 'line-height',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...lineHeightFieldConfig,
          displayToggle: false,
        },
        {
          label: 'templateBuilder.fields.spaceBetweenChars',
          selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
          mobile,
          properties: [{
            name: 'letter-spacing',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getStepperFieldConfig(StepperSignEnum.PX),
        },
        {
          label: 'templateBuilder.fields.titleAlign',
          selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
          mobile,
          properties: [{
            name: 'text-align',
            getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, mobile),
            setters: [(selector: string, property: Property) => setCssProperty(selector, property, mobile)],
          }],
          ...getTextAlignFieldConfig(),
        },
      ],
    },
  ];
  if (!mobile) {
    textFieldsGroup.splice(1, 0, pageLinksGroup);
  }
  return textFieldsGroup;
};

const initMobileVersionHandler = (structureId: string) => {
  const structure = getElementFromIframe(`#${structureId}`);
  let returnValue = true;
  if (structure) {
    const parentSection = structure.getAttribute('data-spmelementid') ? structure : structure.closest('[data-spmelementid]');
    if (parentSection && parentSection.getAttribute(HIDE_MOBILE_ATTRIBUTE)) {
      returnValue = false;
    }
  }
  return returnValue;
};

export const pageTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.PAGE,
  label: 'templateBuilder.configs.page',
  canToggleSettings: {
    'templateBuilder.configs.mobileVersion': {
      initValueHandler: initMobileVersionHandler,
      label: 'templateBuilder.toogleMobileActiveLabel',
      tooltipLabel: 'templateBuilder.toogleMobileActiveTooltip',
    },
  },
  tabPanel: {
    label: 'templateBuilder.configs.page',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageStyles',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: 'body table#spm_body',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.modelWidth',
            selector: 'body table#spm_body table.container',
            properties: [
              { name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'min-width', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...modelWidthFieldConfig,
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: 'body table#spm_body',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageMargins',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '#spm_body table.spm_draggable_row > tbody > tr > td',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.columnInnerMargins',
            selector: '#spm_body th.spm_droppable_widget, #spm_body td.spm_droppable_widget',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
          {
            label: 'templateBuilder.fields.widgetInnerMargins',
            selector: '#spm_body table.spm_draggable_widget > tbody > tr > th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.pageTextsAndTitles',
        tabPanel: {
          items: getTextFieldsGroup(),
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageButtons',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.contours',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getBorderRadiusFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th,'
              + ' {SELECTOR} table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited,'
              + ' {SELECTOR} table.button th a:hover, {SELECTOR} table.button th a:active',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
            options: {
              defaultValue: '#E0E0E0',
            },
          },
          {
            label: 'templateBuilder.fields.font',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th, {SELECTOR} '
              + 'table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited, {SELECTOR} table.button th a:hover, {SELECTOR} table.button '
              + 'th a:active',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.lineHeight.label',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [cleanCssByProperty, setCssProperty] }],
            ...lineHeightFieldConfig,
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.textAlign',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getTextAlignFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.spaceBetweenChars',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th, '
              + '{SELECTOR} table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited, {SELECTOR} table.button th a:hover, '
              + '{SELECTOR} table.button th a:active',
            properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
          {
            label: 'templateBuilder.fields.buttonWidth',
            selector: '{SELECTOR} table.button',
            properties: [{
              name: 'width',
              getter: getCssPropertyByName,
              setters: [setCssButtonWidthProperty],
            }],
            ...getButtonWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.spaceWithinButton',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.separators',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.style',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th table.spm_separator th',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.width',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th table.spm_separator',
            properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.marginVertical',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
            ],
            ...getVerticalSpacesFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.marginHorizontal',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th',
            properties: [
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
            ],
            ...getHorizontalSpacesFieldConfig(),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.PARENT_FIELDS,
        label: 'templateBuilder.configs.mobileVersion',
        toggleHandler: toggleMobileStyle,
        fieldGroups: [
          {
            type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
            tabItemType: TemplateStructureEnum.GROUP,
            label: 'templateBuilder.configs.pageTextsAndTitles',
            tabPanel: {
              items: getTextFieldsGroup(true),
            },
          },
          {
            type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
            label: 'templateBuilder.configs.pageMargins',
            selector: 'body table#spm_body',
            items: [
              {
                label: 'templateBuilder.fields.internalMargins',
                selector: '#spm_body table.spm_draggable_row > tbody > tr > td',
                mobile: true,
                properties: [
                  {
                    name: 'padding-top',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-bottom',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-left',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-right',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                ],
                componentName: ComponentNameEnum.SPACES,
                options: {
                  ...getVerticalSpacesFieldConfig('padding').options,
                  ...getHorizontalSpacesFieldConfig('padding').options,
                },
              },
              {
                label: 'templateBuilder.fields.columnInnerMargins',
                selector: '#spm_body th.spm_droppable_widget, #spm_body td.spm_droppable_widget',
                mobile: true,
                properties: [
                  {
                    name: 'padding-top',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-bottom',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-left',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-right',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                ],
                componentName: ComponentNameEnum.SPACES,
                options: {
                  ...getVerticalSpacesFieldConfig('padding').options,
                  ...getHorizontalSpacesFieldConfig().options,
                },
              },
              {
                label: 'templateBuilder.fields.widgetInnerMargins',
                selector: '#spm_body table.spm_draggable_widget > tbody > tr > th',
                mobile: true,
                properties: [
                  {
                    name: 'padding-top',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-bottom',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-left',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                  {
                    name: 'padding-right',
                    getter: (selector: string, name: string, onlyValue: any) => getCssPropertyByName(selector, name, onlyValue, true),
                    setters: [(selector: string, property: Property) => setCssPropertyAndRemoveIfZero(selector, property, true)],
                  },
                ],
                componentName: ComponentNameEnum.SPACES,
                options: {
                  ...getVerticalSpacesFieldConfig('padding').options,
                  ...getHorizontalSpacesFieldConfig().options,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

const pageItemConfig: ConfigurationPanelStructure = {
  items: [
    pageTabItemConfig,
  ],
};

export default pageItemConfig;
