// eslint-disable-next-line import/no-cycle
import { StringMap, TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, NumberRange, Period,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import {
  integer, maxLength, minLength, required, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { atLeastOneSegmentValidator, includesValuesValidator } from '@/helpers/CustomValidator';

interface Locations {
  [key: string]: Location;
}

export interface Location {
  address: string;
  radius: string;
  lat: string;
  long: string;
}

export interface FiltreCustomerFilters {
  filtershopdatecreation?: {
    segment_number: number;
    time_unit_select: Period[];
    litteral_title: StringMap;
  };
  filtercreateaccountfrom?: {
    segment_number: number;
    time_unit_select: Period[];
    litteral_title: StringMap;
  };
  filtergender?: {
    segment_number: number;
    gender: StringMap[];
    litteral_title: StringMap;
  };
  filteragerange?: {
    segment_number: number;
    age_range: NumberRange;
    litteral_title: StringMap;
  };
  filtergroup?: {
    segment_number: number;
    groups: StringMap[];
    litteral_title: StringMap;
    operator: string[];
  };
  filterareas?: {
    segment_number: number;
    areas: Locations[];
    operator: string[];
  };
  filtercustomercountrie?: {
    segment_number: number;
    customers_countries_list: StringMap[];
    litteral_title: StringMap;
  };
  filterbirthdate?: {
    segment_number: number;
    time_unit_select: Period[];
    litteral_title: StringMap;
  };
  filtercreationdate?: {
    segment_number: number;
    time_unit_select: Period[];
    litteral_title: StringMap;
  };
  filterhasvouchernearexpiration?: {
    segment_number: number;
    time_unit_select: Period[];
    code_start: string[];
    litteral_title: StringMap;
  };
  filtercanreceivesms?: {
    segment_number: number;
    hidden: string;
    litteral_title: StringMap;
  };
  filtercanreceivefbmessenger?: {
    segment_number: number;
    hidden: string;
    litteral_title: StringMap;
  };
  filtercanreceivepushnotifications?: {
    segment_number: number;
    hidden: string;
    litteral_title: StringMap;
  };
  filtercustomersubscription?: {
    segment_number: number;
    hidden: string;
    litteral_title: StringMap;
  };
  filternewslettersubscription?: {
    segment_number: number;
    type_optin: StringMap[];
    litteral_title: StringMap;
  };
  filterhaschattedonfb?: {
    segment_number: number;
    time_unit_select: Period[];
    litteral_title: StringMap;
  };
  filterhascustomertag?: {
    segment_number: number;
    tags: StringMap[];
    operator: string[];
    litteral_title: StringMap;
  };
  filtermemberoflist?: {
    segment_number: number;
    lists: StringMap[];
    operator: string[];
    litteral_title: StringMap;
  };
  filterdeliverability?: {
    segment_number: number;
    time_unit_select: Period[];
    litteral_title: StringMap;
  };
}

export interface FiltreCustomerData {
  custom_title: string;
  custom_description: string;
  outputs_number: number;
  initial_number_outputs?: null|number; // for updating outputs
  grant_additional_delay: string;
  extra_time: Period;
  inclusion: FiltreCustomerFilters;
  exclusion: FiltreCustomerFilters;
}

const FiltreCustomerMetadata: ComponentMetadata<FiltreCustomerData> = {
  Meta: {
    kind: 'filtre',
    id: 'boxfiltre_3',
    label: 'automatedScenarios.filters.customers',
    icon: 'fa-cogs',
    hasConfiguration: true,
    persistent: [
      { segmentId: 'filtershopdatecreation', boxId: '' },
      { segmentId: 'filtercreateaccountfrom', boxId: '' },
      { segmentId: 'filtercreatecartfrom', boxId: '' },
      { segmentId: 'filteragerange', boxId: '' },
      { segmentId: 'filtergroup', boxId: '' },
      { segmentId: 'filterhasvouchernearexpiration', boxId: '' },
      { segmentId: 'filtercustomersubscription', boxId: 'registeronsite' },
      { segmentId: 'filternewslettersubscription', boxId: 'subscribetonewsletter' },
    ],
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching',
        ],
      },
      bulk: {
        next_operators: [
          'boxchoosebulklist',
        ],
      },
    },
    component: 'FiltreCustomer',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create(data: FiltreCustomerData = {
    custom_title: '',
    custom_description: '',
    outputs_number: 1,
    grant_additional_delay: '0',
    extra_time: {
      value: 1,
      unit: 'DAY',
    },
    inclusion: {},
    exclusion: {},
  }): ComponentData<FiltreCustomerData> {
    return {
      id: 'boxfiltre_3',
      form_name: 'boxfiltre_3',
      logo_box: 'fa-cogs',
      ...data,
    };
  },
  async Validate(data: FiltreCustomerData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      outputs_number: {
        required,
        includesValuesValidator: includesValuesValidator([1, 2]),
      },
      grant_additional_delay: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      extra_time: {
        value: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          integer,
        },
        unit: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR', 'DAY']),
        },
      },
      inclusion: {
        required: atLeastOneSegmentValidator(data.exclusion),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: FiltreCustomerData): FiltreCustomerData {
    const dataToReturn: FiltreCustomerData = data;
    const { initial_number_outputs } = dataToReturn;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }

    if (initial_number_outputs !== null && initial_number_outputs !== undefined
      && data.outputs_number < initial_number_outputs) {
      /* En cas de dimunition du nombre de branches, on supprime la branche "non" */
      decreaseNumberOfBranchesOfAnOperator(['output_2']);
    }

    return dataToReturn;
  },
  Outputs(data: FiltreCustomerData): number {
    return data.outputs_number;
  },
  Label(data: FiltreCustomerData): string {
    return data.custom_title;
  },
};

export default FiltreCustomerMetadata;
