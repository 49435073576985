import { State, TemplateEditorState } from '@/types/store-types';
import {
  TemplatePart,
} from '@/types';
import { ActionContext } from 'vuex';

export default {
  namespaced: true,
  state: (): TemplateEditorState => ({
    sections: [],
  }),

  getters: {
    getSections: (state: TemplateEditorState) => state.sections,
  },

  mutations: {
    setSections: (state: TemplateEditorState, payload: TemplatePart[]) => {
      state.sections = payload;
    },
  },

  actions: {
    removeSection: ({ state, commit }: ActionContext<TemplateEditorState, State>, { id }: { id: number }) => {
      let clonedSections = JSON.parse(JSON.stringify(state.sections));

      clonedSections = clonedSections.filter((section: TemplatePart) => section.id_template_parts !== id);

      commit('setSections', clonedSections);
    },

    addSection: ({ state, commit }: ActionContext<TemplateEditorState, State>, { section }: { section: TemplatePart }) => {
      const clonedSections = JSON.parse(JSON.stringify(state.sections));

      clonedSections.splice(1, 0, section);

      commit('setSections', clonedSections);
    },

    updateSection: ({ state, commit }: ActionContext<TemplateEditorState, State>, { section }: { section: TemplatePart }) => {
      const clonedSections = JSON.parse(JSON.stringify(state.sections));

      const index = clonedSections.findIndex((s: TemplatePart) => s.id_template_parts === section.id_template_parts);

      if (index !== -1) {
        clonedSections.splice(index, 1, section);
      }

      commit('setSections', clonedSections);
    },

    updateSectionName: ({ state, commit }: ActionContext<TemplateEditorState, State>, { id, name }: { id: number; name: string }) => {
      const clonedSections = JSON.parse(JSON.stringify(state.sections));

      const section = clonedSections.find((s: TemplatePart) => s.id_template_parts === id);
      if (section) {
        section.name = name;
      }

      commit('setSections', clonedSections);
    },
  },
};
