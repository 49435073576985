<template>
  <Dialog
    v-model:visible="displayModal"
    data-test-id="choose-items-dialog"
    :header="t(headerTitle)"
    :modal="true"
    :dismissable-mask="displayCancelButton"
    :position="position"
    @update:visible="handleClose"
  >
    <InputText
      v-model="v$.name.$model"
      data-test-id="element-name-input"
      :style="{ minWidth: '30rem' }"
    />
    <FieldErrors
      :errors="v$"
      field="fieldValue"
      class="text-left"
    />

    <div
      v-if="!removeSyncOption && !forceSync && !renameElement"
      class="flex no-wrap gap-2 py-3"
    >
      <InputSwitch
        v-model="v$.syncState.$model"
        data-test-id="element-sync-input"
      />
      <span class="is-synchronized__description">
        {{ t(inputIsSynchronizedDescription) }}
      </span>
    </div>

    <template #footer>
      <Button
        v-if="displayCancelButton"
        data-test-id="input-text-button-cancel"
        :label="t(cancelButtonText)"
        icon="far fa-times"
        class="p-button-secondary"
        @click="handleClose"
      />
      <Button
        data-test-id="input-text-button-ok"
        :label="t(validateButtonText)"
        :disabled="v$.$silentErrors.length"
        :loading="loading"
        icon="far fa-check"
        class="p-button-primary"
        @click="handleValidation"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  Ref,
  ref,
  watch,
  onMounted,
} from 'vue';

import { useStore } from '@/store';

import { TemplateStructureEnum } from '@/types/enums';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'SaveElementModal',

  components: {
    Dialog,
    InputText,
    InputSwitch,
    Button,
    FieldErrors,
  },

  props: {
    position: {
      type: String,
      required: false,
      default: 'center',
    },

    displayDialog: {
      type: Boolean,
      required: true,
    },

    displayCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    closeOnValidate: {
      type: Boolean,
      required: false,
      default: true,
    },

    cancelButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.cancel',
    },

    validateButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.validate',
    },

    elementName: {
      type: String,
      required: true,
    },

    elementSync: {
      type: Boolean,
      required: false,
      default: false,
    },

    elementType: {
      type: String,
      required: false,
      default: TemplateStructureEnum.SECTION,
    },

    showLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['on-close-dialog', 'on-validate'],

  setup(props, context) {
    const { t } = useI18n();
    const store = useStore();
    const displayModal: ComputedRef<boolean> = computed(() => props.displayDialog);
    const headerTitle: ComputedRef<string> = computed(() => {
      if (props.elementType === TemplateStructureEnum.DESIGN) {
        return 'templateBuilder.modals.giveNameToDesign';
      }
      return 'templateBuilder.modals.giveNameToElement';
    });

    const inputIsSynchronizedDescription: ComputedRef<string> = computed(() => {
      if (props.elementType === TemplateStructureEnum.DESIGN) {
        return 'templateBuilder.modals.saveAsSyncedDesign';
      }
      return 'templateBuilder.modals.saveAsSyncedElement';
    });

    const forceSync = computed(() => store.state.liveEditor.saveElementModalPayload.forceSync);
    const renameElement = computed(() => store.state.liveEditor.saveElementModalPayload.renameElement);
    const removeSyncOption = computed(() => store.state.liveEditor.saveElementModalPayload.removeSyncOption);

    const name: Ref<string> = ref(props.elementName);
    const syncState: Ref<boolean> = ref(props.elementSync);

    const loading = ref(false);

    const state = reactive({
      name,
      syncState,
    });

    const rules = {
      name: { required },
      syncState: {},
    };

    const v$ = useVuelidate(rules, state);

    watch(() => props.elementName, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        name.value = newValue;
      }
    });

    watch(() => props.elementSync, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        syncState.value = newValue;
      }
    });

    const handleClose = () => {
      context.emit('on-close-dialog');
      name.value = props.elementName;
      syncState.value = props.elementSync;
    };

    const handleValidation = async () => {
      const elementData = {
        name: name.value,
        type: props.elementType,
        isSync: forceSync.value ? true : syncState.value,
        renameElement: renameElement.value,
      };

      if (!props.showLoading) {
        context.emit('on-validate', elementData);

        if (props.closeOnValidate) {
          handleClose();
        }
      } else {
        loading.value = true;
        context.emit('on-validate', elementData, (close = true) => {
          loading.value = false;
          if (close) {
            handleClose();
          }
        });
      }
    };

    onMounted(() => {
      if (renameElement.value) {
        name.value = renameElement.value.oldName;
      }
    });

    return {
      t,
      v$,
      displayModal,
      headerTitle,
      inputIsSynchronizedDescription,
      loading,
      forceSync,
      renameElement,
      removeSyncOption,
      handleClose,
      handleValidation,
    };
  },
});
</script>
