import * as cheerio from 'cheerio';
import {
  applyNewSpecificDesignRules,
  calculatePartHash,
  resetSpecificDesignRules,
  Template,
  TemplateEditorState,
  updateOpenedTemplate,
} from '@/composables/template-editor/TemplateEditor';
import { TemplateStructureEnum } from '@/types';
import { TemplatesParts } from '@/types/generated-types/graphql';
import { generateUniqStructureId, getTemplateParentTypeByType, isDisplayTemplate } from './helpers';
import {
  HIDE_MOBILE_ATTRIBUTE,
  TEMPLATE_SYNC_ELEMENT_CLASS,
  TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
  TEMPLATE_SYNC_ELEMENT_IDs,
  TEMPLATE_SYNC_ELEMENT_NAME,
} from './constants';

const mergeTranslations = (oldTrans: any, newTrans: any): any => {
  const mergedTrans = { ...oldTrans };

  // eslint-disable-next-line no-restricted-syntax
  for (const lang in newTrans) {
    if (mergedTrans[lang]) {
      mergedTrans[lang] = {
        ...mergedTrans[lang],
        ...newTrans[lang],
      };
    } else {
      mergedTrans[lang] = newTrans[lang];
    }
  }

  return mergedTrans;
};

const applyMappingToTranslations = (
  translations: any,
  mapping: Record<string, string>,
): any => {
  const updatedTranslations: any = {};

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const lang in translations) {
    updatedTranslations[lang] = {};

    const langData = translations[lang];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const oldKey in langData) {
      const newKey = mapping[oldKey] || oldKey;
      updatedTranslations[lang][newKey] = langData[oldKey];
    }
  }

  return updatedTranslations;
};

const updateHtmlWithNewKeys = (
  html: string,
  keyMapping: Record<string, string>,
): string => {
  Object.keys(keyMapping).forEach((oldKey) => {
    const newKey = keyMapping[oldKey];
    const regex = new RegExp(oldKey, 'g');
    // eslint-disable-next-line no-param-reassign
    html = html.replace(regex, newKey);
  });

  return html;
};

const generateNewKeysMapping = (keys: string[]): Record<string, string> => {
  const keyMapping: Record<string, string> = {};

  keys.forEach((key) => {
    const newKey = generateUniqStructureId();
    keyMapping[key] = newKey;
  });

  return keyMapping;
};

const extractKeysFromLanguage = (translations: any): string[] => {
  const allKeys = new Set<string>();
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const lang in translations) {
    const keys = Object.keys(translations[lang] || {});
    keys.forEach((item) => allKeys.add(item));
  }
  return Array.from(allKeys);
};

const getNewPartTranslations = (newTrans: any, newHtml: string) => {
  const newTranslationsObject = JSON.parse(newTrans);

  const existingKeys = extractKeysFromLanguage(newTranslationsObject);

  const keyMapping = generateNewKeysMapping(existingKeys);

  // eslint-disable-next-line no-param-reassign
  newHtml = updateHtmlWithNewKeys(newHtml, keyMapping);

  const newPartTranslations = applyMappingToTranslations(
    newTranslationsObject,
    keyMapping,
  );

  return {
    newPartTranslations,
    newHtml,
  };
};

const mergeAndConcatCss = (oldObj: any, newObj: any) => {
  const mergedObj = { ...oldObj };

  // eslint-disable-next-line no-restricted-syntax
  for (const key in newObj) {
    if (mergedObj[key]) {
      mergedObj[key] = `${mergedObj[key]} ${newObj[key]}`;
    } else {
      mergedObj[key] = newObj[key];
    }
  }

  return mergedObj;
};

const getNewPartCss = (
  idMapping: Record<string, string>,
  newCss: string,
): any => {
  const newCssObject = JSON.parse(newCss || '{}');
  Object.keys(newCssObject).forEach((category) => {
    if (typeof newCssObject[category] === 'string') {
      Object.entries(idMapping).forEach(([oldId, newId]) => {
        const cssRegex = new RegExp(oldId, 'g');
        newCssObject[category] = newCssObject[category].replace(
          cssRegex,
          newId,
        );
      });
    }
  });

  // Import widget css
  Object.entries(idMapping).forEach(([oldId, newId]) => {
    const replacedOldId = oldId.replace('spm_widget_', '');
    const replacedNewId = newId.replace('spm_widget_', '');
    if (newCssObject[`custom_widget_${replacedOldId}`]) {
      newCssObject[`custom_widget_${replacedNewId}`] = newCssObject[`custom_widget_${replacedOldId}`];
      delete newCssObject[`custom_widget_${replacedOldId}`];
    }
    if (newCssObject[`custom_widget_${replacedOldId}_mobile`]) {
      newCssObject[`custom_widget_${replacedNewId}_mobile`] = newCssObject[`custom_widget_${replacedOldId}_mobile`];
      delete newCssObject[`custom_widget_${replacedOldId}_mobile`];
    }
  });

  return newCssObject;
};

const applyIdMappingToHtml = (
  html: string,
  idMapping: Record<string, string>,
): string => Object.keys(idMapping).reduce((acc, oldId) => {
  const regex = new RegExp(`id=["']${oldId}["']`, 'g');
  return acc.replace(regex, `id="${idMapping[oldId]}"`);
}, html);

const generateNewIdMapping = (ids: string[]): Record<string, string> => {
  const idMapping: Record<string, string> = {};

  const regex = /^(spm_(section|widget|row|column))_([a-zA-Z0-9]+)(.*)$/;

  ids.forEach((oldId) => {
    const match = oldId.match(regex);
    if (match) {
      const prefix = match[1]; // Prefix, e.g. "spm_section" or "spm_widget"
      const uniqueKey = match[3]; // Single portion, e.g. "9c480a741348f0fbd54"
      const suffix = match[4] || ''; // Possible suffix, e.g. "_content"

      const newKey = generateUniqStructureId();
      const newId = `${prefix}_${newKey}${suffix}`;

      idMapping[oldId] = newId;
    }
  });
  return idMapping;
};

const replaceOldIdsWithNew = (newHtml: string) => {
  const $newHtml = cheerio.load(newHtml);
  const newChildElements = $newHtml('body').find(
    '[id^="spm_column_"], [id^="spm_row_"], [id^="spm_widget_"], [id^="spm_section_"]',
  );
  const idsToRegenerate = [
    ...newChildElements.map((i, el) => $newHtml(el).attr('id')).get(),
  ];

  const idMapping = generateNewIdMapping(idsToRegenerate);
  return {
    newHtml: applyIdMappingToHtml(newHtml, idMapping),
    idMapping,
  };
};

const updateTranslations = (
  html: string,
  translations: string,
  syncElementId: string,
  elementId: string,
) => {
  const $ = cheerio.load(html);
  const syncElement = $('body').find(
    `[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="${syncElementId}"][id="${elementId}"]`,
  );

  const translationsObject = JSON.parse(translations);
  const keyRegExp = /\{LANG_([a-zA-Z0-9]+)(?:\.[a-zA-Z0-9]+)?\}/g;

  const elementHtml = $.html(syncElement);

  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = keyRegExp.exec(elementHtml)) !== null) {
    const key = match[1];

    // eslint-disable-next-line no-restricted-syntax
    for (const lang in translationsObject) {
      if (translationsObject[lang][key]) {
        delete translationsObject[lang][key];
      }
    }
  }

  return translationsObject;
};

const removeCssRules = (cssObject: any, idsToRemove: string[]): any => Object.keys(cssObject).reduce((acc, key) => {
  let css = cssObject[key];

  const idRemoved = idsToRemove.some((id) => {
    const regex = new RegExp(`([^{}]*#${id}[^{]*{[^}]*})`, 'gm');
    if (regex.test(css)) {
      css = css.replace(regex, '').trim();

      const refactoId = id.replace('spm_widget_', '');

      if (
        key === `custom_widget_${refactoId}`
          || key === `custom_widget_${refactoId}_mobile`
      ) {
        delete acc[`custom_widget_${refactoId}`];
        delete acc[`custom_widget_${refactoId}_mobile`];
        return true;
      }
    }
    return false;
  });

  if (!idRemoved) {
    acc[key] = css;
  }

  return acc;
}, {} as Record<string, string>);

const getElementIdsToRemove = (
  html: string,
  syncElementId: string,
  elementId: string,
): string[] => {
  const $ = cheerio.load(html);
  const syncElement = $('body').find(
    `[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="${syncElementId}"][id="${elementId}"]`,
  );
  const childElements = syncElement.find(
    '[id^="spm_column_"], [id^="spm_row_"], [id^="spm_widget_"], [id^="spm_section_"]',
  );
  return [
    syncElement.attr('id') || '',
    ...childElements.map((i, el) => $(el).attr('id')).get(),
  ];
};

const updateCss = (
  css: string,
  syncElementId: string,
  elementId: string,
  html: string,
) => {
  const idsToRemove = getElementIdsToRemove(
    html,
    syncElementId,
    elementId,
  );

  const cssObject = JSON.parse(css);
  return removeCssRules(cssObject, idsToRemove);
};

const findSyncElementIds = (syncElementId: string, html: string): string[] => {
  const $ = cheerio.load(html);
  const syncElements = $('body').find(
    `[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}=${syncElementId}]`,
  );

  const elementIds = syncElements.map((_, el) => $(el).attr('id')).get();

  return elementIds;
};

const processUpdateDesign = (
  data: any,
  templateElement: any,
  templatePart: any,
  unlinkOnly: boolean,
  template: any,
) => {
  if (template && isDisplayTemplate(template.type)) {
    const { html, translations } = templateElement;
    const { css } = templateElement;
    let { css: partCss } = templateElement;

    if (templatePart) {
      partCss = templatePart.css;
    }

    const $ = cheerio.load(html);
    const design = $('body').find('#spm_body');

    let designCss = JSON.parse(css || '{}');

    if (!unlinkOnly) {
      design.attr(TEMPLATE_SYNC_ELEMENT_NAME, templatePart.name);

      const partCssObject = JSON.parse(partCss || '{}');
      Object.keys(partCssObject).forEach((key) => {
        if (key in designCss) {
          const regex = /([^{}]*#spm_(?:widget|column|row)_[a-zA-Z0-9]+[^{]*{[^}]*})/gm;
          const matches = [...designCss[key].matchAll(regex)];
          const capturedBlocks = matches.map((match) => match[0]);

          designCss[key] = `${partCssObject[key]} ${capturedBlocks.join(
            ' ',
          )}`;
        }
      });
      designCss = JSON.parse(JSON.stringify(designCss));
      // Reset specific css rules and apply new rules
      const templatePartData = JSON.parse(templatePart.data || '{}');
      $('body').html(resetSpecificDesignRules($('body').html() || '', getTemplateParentTypeByType(template.type)));
      if (templatePartData.specificCssRules) {
        $('body').html(applyNewSpecificDesignRules($('body').html() || '', templatePartData.specificCssRules));
      }
    } else {
      design.removeAttr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
      design.removeAttr(TEMPLATE_SYNC_ELEMENT_NAME);
    }

    const newHtml = $('body').html();

    const newHash = calculatePartHash(
      newHtml || '',
      JSON.stringify(designCss),
      translations,
    );

    return {
      html: newHtml,
      css: JSON.stringify(designCss),
      translations,
      templateHash: newHash,
      data,
    };
  }
  const { html } = templateElement;
  let { css: partCss } = templateElement;

  if (templatePart) {
    partCss = templatePart.css;
  }

  let newHtml = html;

  const { data: partData } = templatePart;
  const partDataObject = JSON.parse(partData || '{}');
  if (partDataObject[HIDE_MOBILE_ATTRIBUTE]) {
    // eslint-disable-next-line no-param-reassign
    data[HIDE_MOBILE_ATTRIBUTE] = partDataObject[HIDE_MOBILE_ATTRIBUTE];
  } else {
    // eslint-disable-next-line no-param-reassign
    delete data[HIDE_MOBILE_ATTRIBUTE];
  }

  if (!unlinkOnly) {
    newHtml = html.replace(
      /data-spm-element-sync-name="([^"]+)"/g,
      `data-spm-element-sync-name="${templatePart.name}"`,
    );
  } else {
    newHtml = newHtml.replace(/data-spm-element-sync-name="([^"]+)"/g, '');
    newHtml = newHtml.replace(/data-spm-element-sync-id="([^"]+)"/g, '');
  }

  const newHash = calculatePartHash(newHtml, '', '');

  newHtml = newHtml.replace(
    /data-spm_element_hash="([^"]+)"/g,
    `data-spm_element_hash="${newHash}"`,
  );

  // Remove old mobile style
  newHtml = newHtml.replace(/data-mobilefontsizeincrease="([^"]+)"/g, '');
  newHtml = newHtml.replace(
    /data-spmtranslationid-data-mobilefontsizeincrease="([^"]+)"/g,
    '',
  );

  return {
    html: newHtml,
    css: partCss,
    translations: '',
    templateHash: newHash,
    data,
  };
};

const processUpdateElement = (
  data: any,
  templateElement: any,
  templatePart: any,
  syncElementId: string,
  unlinkOnly: boolean,
) => {
  const { html, css, translations } = templateElement;

  const ids = findSyncElementIds(syncElementId, html);

  const $ = cheerio.load(html);

  let initCss = JSON.stringify(JSON.parse(css || '{}'));
  let initTranslations = JSON.stringify(
    JSON.parse(translations || '{}'),
  );

  // Update sync elements if template part has been updated
  if (!unlinkOnly) {
    const toMergeTranslations = [];
    const toMergesCss = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const id of ids) {
      // Update current element
      const updatedCssObject = updateCss(
        initCss,
        syncElementId,
        id,
        html,
      );

      initCss = JSON.stringify(updatedCssObject);

      const updatedTranslations = updateTranslations(
        html,
        initTranslations,
        syncElementId,
        id,
      );

      initTranslations = JSON.stringify(updatedTranslations);

      // Template Part
      const {
        html: newHtml,
        css: newCss,
        translations: newTranslations,
      } = templatePart;
      // Replace template part ids
      const { newHtml: updatedNewHtml, idMapping } = replaceOldIdsWithNew(newHtml);

      const newPartCss = getNewPartCss(idMapping, newCss);

      const { newPartTranslations, newHtml: finalHtml } = getNewPartTranslations(newTranslations, updatedNewHtml);
      // Replace syncElement
      const syncElement = $('body').find(
        `[${TEMPLATE_SYNC_ELEMENT_IDENTIFIER}="${syncElementId}"][id="${id}"]`,
      );

      const $finalHtml = cheerio.load(finalHtml);

      const firstElementInBody = $finalHtml('body').children().first();

      firstElementInBody.attr(
        TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
        syncElementId,
      );
      firstElementInBody.attr(TEMPLATE_SYNC_ELEMENT_NAME, templatePart.name);
      firstElementInBody.removeClass(TEMPLATE_SYNC_ELEMENT_CLASS);
      firstElementInBody.addClass(TEMPLATE_SYNC_ELEMENT_CLASS);

      syncElement.replaceWith($finalHtml('body').html() || '');

      toMergeTranslations.push(newPartTranslations);
      toMergesCss.push(newPartCss);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const toMergeTranslation of toMergeTranslations) {
      const temp = mergeTranslations(
        JSON.parse(initTranslations),
        toMergeTranslation,
      );
      initTranslations = JSON.stringify(temp);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const toMergeCss of toMergesCss) {
      const temp = mergeAndConcatCss(JSON.parse(initCss), toMergeCss);
      initCss = JSON.stringify(temp);
    }
  } else {
    // Unlink sync elements
    // eslint-disable-next-line no-restricted-syntax
    for (const id of ids) {
      const syncElement = $('body').find(`[id="${id}"]`);
      if (syncElement) {
        syncElement.removeAttr(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
        syncElement.removeAttr(TEMPLATE_SYNC_ELEMENT_NAME);
        syncElement.removeClass(TEMPLATE_SYNC_ELEMENT_CLASS);
      }
    }
    initCss = JSON.stringify(JSON.parse(initCss));
    initTranslations = JSON.stringify(JSON.parse(initTranslations));
  }

  const returnHtml = $('body').html();

  const templateHash = calculatePartHash(
    returnHtml || '',
    initCss,
    initTranslations,
  );

  return {
    html: returnHtml,
    css: initCss,
    translations: initTranslations,
    templateHash,
    data,
  };
};

const updateTemplateElement = (
  template: any,
  templateElement: any,
  templatePart: any,
  elementType: string,
  syncElementId: string,
  unlinkOnly: boolean,
) => {
  const data = JSON.parse(templateElement.data || '{}');
  if (data[TEMPLATE_SYNC_ELEMENT_NAME] && templatePart) {
    data[TEMPLATE_SYNC_ELEMENT_NAME] = templatePart.name;
  }
  if (unlinkOnly) {
    if (
      [TemplateStructureEnum.SECTION, TemplateStructureEnum.DESIGN].includes(
        elementType as TemplateStructureEnum,
      )
    ) {
      delete data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER];
      delete data[TEMPLATE_SYNC_ELEMENT_NAME];
    } else if (
      elementType === TemplateStructureEnum.WIDGET
      && data[TEMPLATE_SYNC_ELEMENT_IDs]
    ) {
      data[TEMPLATE_SYNC_ELEMENT_IDs] = data[
        TEMPLATE_SYNC_ELEMENT_IDs
      ].filter((syncId: string) => syncId !== syncElementId);
    }
  }
  if (elementType !== TemplateStructureEnum.DESIGN) {
    return processUpdateElement(
      data,
      templateElement,
      templatePart,
      syncElementId,
      unlinkOnly,
    );
  }
  return processUpdateDesign(
    data,
    templateElement,
    templatePart,
    unlinkOnly,
    template,
  );
};

// eslint-disable-next-line import/prefer-default-export
export const updateSyncElementsInOpenedTemplates = (currentTemplateId: number, syncElement: TemplatesParts, deleted = false) => {
  const templatesInState = JSON.parse(JSON.stringify(TemplateEditorState.templates)) as Template[];

  const templatesToUpdate = templatesInState.filter((template: Template) => template.id !== currentTemplateId);

  const syncElementData = JSON.parse(syncElement.data || '{}');
  const syncElementId = syncElementData[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] || '';
  if (!syncElementId) return;

  // eslint-disable-next-line no-restricted-syntax
  for (const templateToUpdate of templatesToUpdate) {
    const sectionToUpdates = templateToUpdate.sections.filter((section) => {
      const data = JSON.parse(section.data || '{}');
      if (
        (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] && data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] === syncElementId)
        || (data[TEMPLATE_SYNC_ELEMENT_IDs] && data[TEMPLATE_SYNC_ELEMENT_IDs].find((syncId: string) => syncId === syncElementId))
      ) {
        return true;
      }
      return false;
    });
    // eslint-disable-next-line no-restricted-syntax
    for (const sectionToUpdate of sectionToUpdates) {
      const updatedSection = updateTemplateElement(
        templateToUpdate,
        sectionToUpdate,
        deleted ? null : syncElement,
        syncElement.type || '',
        syncElementId,
        !!deleted,
      );
      updateOpenedTemplate(templateToUpdate.id, sectionToUpdate.id_template_elements, updatedSection);
    }
  }
};
