import {
  TemplateStructureEnum,
} from '@/types';

const headerButtonsAvailable: Record<string, string[]> = {
  [TemplateStructureEnum.DESIGN]: ['informationPanel', 'designPanel', 'advancedCssPanel'],
  [TemplateStructureEnum.SECTION]: [
    'informationPanel',
    'configurationPanel',
    'widgetsPanel',
    'loadElementsPanel',
    'translationPanel',
  ],
  [TemplateStructureEnum.WIDGET]: ['informationPanel', 'configurationPanel', 'translationPanel'],
};

const defaultPanel: Record<string, string> = {
  [TemplateStructureEnum.DESIGN]: 'designPanel',
  [TemplateStructureEnum.SECTION]: 'configurationPanel',
  [TemplateStructureEnum.WIDGET]: 'configurationPanel',
};

// eslint-disable-next-line import/prefer-default-export
export const isPanelVisible = (syncElementType: string, panel: string) => {
  const headerButton = headerButtonsAvailable[syncElementType];
  if (!headerButton) return false;

  return !!headerButton.find((button) => button === panel);
};

export const getDefaultPanel = (syncElementType: string) => defaultPanel[syncElementType] || 'informationPanel';
