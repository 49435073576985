
// Vue related
import {
  computed, defineComponent, nextTick, onBeforeMount, ref, Ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

// Primevue components
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';

// Custom components
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import PlanFeature from '@/components/my-offer/MyOffer14/PlanFeature.vue';
import EditorModal from '@/components/modals/EditorModal.vue';
import SpmTable from '@/components/table/SpmTable.vue';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';

// Config and helpers
import pricingPlansFeatures, {
  allFeatures,
  pricingPlansLevel,
  productIds,
  smsPlans,
  smsProductIds,
  yearlyPriceReduction,
} from '@/configs/offers';
import getCountries from '@/configs/countries';
import { getMonthlyPagesViewed } from '@/composables/shop/Shops';
import {
  checkPlan, downgradePlan, findPermission, setPlan, setPricePlan, tryPlan, UserState,
} from '@/composables/User';
import { getConfigurationKey } from '@/composables/configs/configuration';
import { showToastError, showToastSuccess } from '@/helpers';
import { formatNumberToCurrency } from '@/helpers/Number';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import gleapActionForm from '@/configs/gleap-actions';

// Adyen
import AdyenCheckout from '@adyen/adyen-web';
import GetPaymentMethods, { adyenConfig, MakeDetailsCall, MakePaymentCall } from '@/composables/user/AdyenConfig';

// Types and models
import { PrimvueMenuModel, SpmTableColumns, SpmTableState } from '@/types';
import { SelectedPlanData, SmsPlan, SmsPricing } from '@/types/offer-types';
import { BuyableElementType, PlanName } from '@/types/enums';
import { PaymentData, PaymentElement } from '@/types/payment-types';
import { CountryCode } from 'libphonenumber-js';
import { useStore } from '@/store';

// External library
import moment from 'moment';
import Gleap from 'gleap';
import { nestPost } from '@/composables/nestApi';
import ProgressBar from 'primevue/progressbar';

export default defineComponent({
  name: 'ChoosePlanV14',
  components: {
    InputSwitch,
    Dropdown,
    Card,
    SpmButton,
    PlanFeature,
    EditorModal,
    Dialog,
    SpmTable,
    CountryDropdown,
    TermsAndConditions,
    Checkbox,
    Message,
    ProgressBar,
  },

  setup() {
    // Reactive States for Store, Routing, and User Information
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const monthlyPagesViewed: Ref<number | null | undefined> = ref(undefined);
    const idShop = UserState.activeShop?.id ?? 0;
    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;

    const smsPricing = ref<Record<string, SmsPricing[]>>({});
    const plans = JSON.parse(JSON.stringify(smsPlans));
    const userId = UserState.activeShop?.idUser ?? 0;
    const locale = UserState.user.lang;
    const countryCode = locale.toLocaleUpperCase();
    const planSmsPricing = ref<SmsPricing[]>([]);

    // Reactive States for UI Components and Features
    const pricingPlans: Ref<Record<string, any>> = ref({
      [PlanName.STANDARD]: [],
      [PlanName.PREMIUM]: [],
      [PlanName.ULTIMATE]: [],
    });
    const notSupported = ref(false);
    const selectedPlan: Ref<SelectedPlanData> = ref({ plan: '', isYearly: false });
    const selectedCountry = ref<{ country: CountryCode; state: string }>({
      country: (UserState.user.country ?? 'FR').toUpperCase() as CountryCode,
      state: '',
    });
    const selectedSmsPlan = ref(0);
    const planPriceNoteModal = ref(false);
    const yearlyRenewalDate = ref(moment().add(1, 'year').startOf('day'));
    const showPayment = ref(false);
    const dropInComponentRef = ref();
    const showToggleYearly = ref(true);
    const acceptTTC = ref(false);
    const showTACModal = ref(false);
    const discountOnPlan: Ref<number | null> = ref(null);
    const discountPercentageOnOfferV3 = ref(0);
    const discountPercentageLimitOnOfferV3 = ref();

    const monthlyRenewalDate = computed(() => {
      let dateLimit;

      if (Number(moment().format('D')) < 5) {
        // If current date is less than 5, we set dateLimit to 5th day of current month
        dateLimit = moment()
          .startOf('months')
          .date(5);
      } else {
        // Else, we set dateLimit to 5th day of next month
        dateLimit = moment()
          .startOf('months')
          .add(1, 'months')
          .date(5);
      }

      return dateLimit;
    });

    // Adyen configuration
    const adyenLocale = `${locale}_${countryCode}`;
    const adyenEnvironment: string = process.env.VUE_APP_ADYEN_ENVIRONMENT ?? 'live';

    const loadingBuyButton = ref(false);

    /* Shopify */
    const shopifyUrlConfirmationRedirect: any = ref('');
    const isRedirectShopifyModalVisible = computed(() => shopifyUrlConfirmationRedirect.value !== '');

    watch(isRedirectShopifyModalVisible, (newVal) => {
      if (newVal) {
        // Redirect after 5 seconds
        setTimeout(() => {
          window.location.href = shopifyUrlConfirmationRedirect.value;
        }, 5000);
      }
    });

    // Table Data and Configuration
    const columns: SpmTableColumns[] = [
      {
        field: 'nb_pv_max',
        header: t('shop.menu.myOffer.monthlyPagesViewedMax'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'price_standard',
        header: t('offers.plans.standard'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'price_premium',
        header: t('offers.plans.premium'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      // {
      //   field: 'price_ultimate',
      //   header: t('offers.plans.ultimate'),
      //   sortable: false,
      //   filterable: false,
      //   editable: false,
      //   style: '',
      //   type: 'text',
      // },
    ];
    const tableData = ref<SpmTableState>({
      items: [],
      total: 0,
      error: '',
      selectedItems: [],
    });

    // Computed Properties for UI and Data Handling
    const lastRoute = computed(() => store.getters['general/getLastRoute']);
    const onAuthorizedClickFunction = computed(() => store.getters['general/getOnAuthorizedClickFunction']);
    const gleapAction = computed(() => store.getters['general/getGleapAction']);
    const smsPlansOptions = computed(() => {
      if (selectedPlan.value.plan) {
        plans.forEach((plan: SmsPlan) => {
          const actualSmsPricingValue = planSmsPricing.value.find((item: any) => item.value === selectedCountry.value.country);
          if (actualSmsPricingValue) {
            const price = Math.round(actualSmsPricingValue.smsPrice * plan.value * 100) / 100;
            if (price) {
              // eslint-disable-next-line no-param-reassign
              plan.legend = formatNumberToCurrency(Math.round(actualSmsPricingValue.smsPrice * plan.value * 100) / 100, currency.value);
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            plan.legend = null;
          }
        });
      }
      return plans;
    });
    const country = computed(() => {
      const result = getCountries(t).find(((item) => item.value === selectedCountry.value.country));
      if (result) {
        return result.label;
      }
      return '--';
    });

    const trialAvailable = computed(() => {
      if (UserState.activeOffer && selectedPlan.value.plan && selectedPlan.value.plan !== PlanName.STANDARD) {
        const actualPlan = UserState.activeOffer.trialPlan || UserState.activeOffer.forcedPlan || UserState.activeOffer.currentPlan;
        if (actualPlan && actualPlan !== selectedPlan.value.plan) {
          if (!UserState.activeOffer.trials) {
            return true;
          }
          if (!UserState.activeOffer.trials[selectedPlan.value.plan]) {
            return true;
          }
        }
      }
      return false;
    });

    const toDowngrade = computed(() => {
      const actualPlan = checkPlan('forcedPlan', 'forcedPlanLimit') || UserState.activeOffer?.currentPlan;
      if (actualPlan && selectedPlan.value.plan) {
        return pricingPlansLevel[actualPlan] > pricingPlansLevel[selectedPlan.value.plan];
      }
      return false;
    });

    // Computed Properties related to price (SMS and Plan)
    const smsPrice = computed(() => {
      if (selectedPlan.value.plan) {
        const actualSmsPricingValue = planSmsPricing.value.find((item: any) => item.value === selectedCountry.value.country);

        if (actualSmsPricingValue) {
          let totalPrice = selectedPlan.value.isYearly ? actualSmsPricingValue.smsPrice * selectedSmsPlan.value * 12 : actualSmsPricingValue.smsPrice * selectedSmsPlan.value;
          const originalTotalPrice = totalPrice;
          const productPrice = Math.round(totalPrice * 100) / 100;

          if (UserState.activeOffer && UserState.activeOffer.currentPlan) {
            const currentSmsPlan = UserState.activeOffer?.smsPlan;
            const currentSmsPlanPricingValue = planSmsPricing.value.find((item: any) => item.value === UserState.activeOffer?.smsPlanCountry);
            if (currentSmsPlan && currentSmsPlanPricingValue) {
              const totalCurrentSmsPrice = currentSmsPlanPricingValue.smsPrice * currentSmsPlan;
              if (totalCurrentSmsPrice > totalPrice) {
                return {
                  label: '',
                  value: 0,
                  productPrice,
                  productPriceLabel: formatNumberToCurrency(productPrice, currency.value),
                };
              }
              totalPrice -= totalCurrentSmsPrice;
            }
          }

          // Check if user has discount
          if (UserState.planData && UserState.planData.discount && UserState.planData.discount.plan_sms) {
            if (!('discountLimit' in UserState.planData) || !UserState.planData.discountLimit || !UserState.planData.discountLimit.plan_sms
              || (UserState.planData.discountLimit.plan_sms
                && moment().isBefore(moment(UserState.planData.discountLimit.plan_sms, 'YYYY-MM-DD')))) {
              totalPrice *= (100 - UserState.planData.discount.plan_sms) / 100;
            }
          }
          totalPrice = Math.round(totalPrice * 100) / 100;

          return {
            label: formatNumberToCurrency(totalPrice, currency.value),
            value: totalPrice,
            originalValue: Math.round(originalTotalPrice * 100) / 100,
            productPrice,
            productPriceLabel: formatNumberToCurrency(productPrice, currency.value),
            discountSummaries: '',
          };
        }
      }
      return {
        label: '',
        value: 0,
        originalValue: 0,
        productPrice: 0,
        productPriceLabel: '',
        discountSummaries: '',
      };
    });

    const pricing = computed(() => {
      const plan = pricingPlans.value[selectedPlan.value.plan];

      if (monthlyPagesViewed.value !== null) {
        const monthlyPagesViewedValue = monthlyPagesViewed.value || 0;
        const exactPlan = plan.find((item: { monthlyPagesViewed: number }) => monthlyPagesViewedValue <= item.monthlyPagesViewed);
        if (exactPlan) {
          let planPrice = exactPlan.price;
          const originalPrice: number = planPrice;
          // Check if user has discount
          if (UserState.planData && UserState.planData.discount) {
            if (UserState.planData.discount.plan) {
              if (!('discountLimit' in UserState.planData) || !UserState.planData.discountLimit || !UserState.planData.discountLimit.plan
                || (UserState.planData.discountLimit.plan && moment()
                  .isBefore(moment(UserState.planData.discountLimit.plan, 'YYYY-MM-DD')))) {
                discountOnPlan.value = UserState.planData.discount.plan;
                planPrice *= (100 - UserState.planData.discount.plan) / 100;
              }
            }

            if ('plan_price' in UserState.planData.discount && planPrice > Number(UserState.planData.discount.plan_price)
              && (!('discountLimit' in UserState.planData) || !UserState.planData.discountLimit || !UserState.planData.discountLimit.plan_price
                || (UserState.planData.discountLimit.plan_price && moment()
                  .isBefore(moment(UserState.planData.discountLimit.plan_price, 'YYYY-MM-DD'))))) {
              planPrice = Number(UserState.planData.discount.plan_price);
            }
          }
          return {
            toPayValue: planPrice,
            value: originalPrice,
            label: formatNumberToCurrency(Math.round(originalPrice * 100) / 100, currency.value),
          };
        }
      }
      return {
        originalValue: 0,
        value: 0,
        originalLabel: '',
        label: '',
      };
    });

    const offerPriceToPay = computed(() => {
      const userPlan = checkPlan('forcedPlan', 'forcedPlanLimit') || UserState.activeOffer?.currentPlan;
      const checkOfferPriceToPay = selectedPlan.value.plan !== userPlan;
      let discountSummaries = '';
      // If the shop has a V3 offer, but not a V4 offer, we return 0 as price to let him switch from V3 to V4 free
      if (selectedPlan.value.plan && checkOfferPriceToPay && !UserState.hasOfferV3 && UserState.activeOffer) {
        let totalAmount;
        let originalTotalAmount;
        const now = moment().startOf('day');
        const startCurrentMonthDate = now
          .clone()
          .startOf('month')
          .date(5)
          .startOf('day');
        const startNextMonthDate = now
          .clone()
          .startOf('month')
          .add(1, 'month')
          .date(5)
          .startOf('day');
        const daysInMonthlyPeriod = startNextMonthDate.diff(now.clone().date(5).startOf('day'), 'days'); // Need to use startof day to ensure to get the exact days

        if (!selectedPlan.value.isYearly) {
          // Calculate the prorata amount for monthly subscription
          let daysRemainingUntil5th;

          // If current date is less than 5th day of month, we calculate diff between now and 5th day of current month. Otherwise, we calculate until next month's 5th day.
          if (Number(now.clone().format('D')) < 5) {
            daysRemainingUntil5th = startCurrentMonthDate.diff(now.clone().startOf('day'), 'days');
          } else {
            daysRemainingUntil5th = startNextMonthDate.diff(now.clone().startOf('day'), 'days');
          }

          totalAmount = (pricing.value.value / daysInMonthlyPeriod) * daysRemainingUntil5th;
          originalTotalAmount = totalAmount;

          // Check if user has discount
          if (UserState.planData && UserState.planData.discount) {
            if (UserState.planData.discount.plan && pricing.value.value > 0) {
              if (
                !('discountLimit' in UserState.planData)
                || !UserState.planData.discountLimit
                || !UserState.planData.discountLimit.plan
                || (UserState.planData.discountLimit.plan
                  && moment().isBefore(moment(UserState.planData.discountLimit.plan, 'YYYY-MM-DD')))
              ) {
                discountSummaries = t('shop.menu.myOffer.payment.summaries.discount.plan', [UserState.planData.discount.plan]);
                if ('discountLimit' in UserState.planData && UserState.planData.discountLimit && UserState.planData.discountLimit.plan) {
                  discountSummaries += t('shop.menu.myOffer.payment.summaries.discount.discountLimit',
                    [moment(UserState.planData.discountLimit.plan, 'YYYY-MM-DD')
                      .format('DD/MM/YYYY')]);
                }
                totalAmount *= (100 - UserState.planData.discount.plan) / 100;
              }
            }

            if (
              'plan_price' in UserState.planData.discount
              && totalAmount > ((Number(UserState.planData.discount.plan_price) / daysInMonthlyPeriod) * daysRemainingUntil5th)
              && (!('discountLimit' in UserState.planData)
                || !UserState.planData.discountLimit
                || !UserState.planData.discountLimit.plan_price
                || (UserState.planData.discountLimit.plan_price && moment()
                  .isBefore(moment(UserState.planData.discountLimit.plan_price, 'YYYY-MM-DD'))))
            ) {
              discountSummaries = t('shop.menu.myOffer.payment.summaries.discount.plan_price', [formatNumberToCurrency(UserState.planData.discount.plan_price, currency.value)]);

              if ('discountLimit' in UserState.planData && UserState.planData.discountLimit.plan_price && moment()
                .isBefore(moment(UserState.planData.discountLimit.plan_price, 'YYYY-MM-DD'))) {
                t('shop.menu.myOffer.payment.summaries.discount.discountLimit',
                  [moment(UserState.planData.discountLimit.plan_price, 'YYYY-MM-DD')
                    .format('DD/MM/YYYY')]);
              }
              totalAmount = (Number(UserState.planData.discount.plan_price) / daysInMonthlyPeriod) * daysRemainingUntil5th;
            }
          }
        } else {
          // Get the next renewal date
          let currentRenewalDate;
          if (UserState.activeOffer && UserState.activeOffer.currentPlanLimit) {
            currentRenewalDate = moment(UserState.activeOffer.currentPlanLimit, 'YYYY-MM-DD');
          } else {
            currentRenewalDate = yearlyRenewalDate.value.clone();
          }
          const nextRenewalDate = moment(currentRenewalDate);

          totalAmount = (pricing.value.value * 12);

          // Calculate the prorata amount for annual subscription
          const remainingMonths = moment(nextRenewalDate).diff(now.clone(), 'months');
          const remainingDays = moment(nextRenewalDate).diff(now.clone().add(remainingMonths, 'months'), 'days');
          const currentDailyRate = pricing.value.value / now.clone().daysInMonth();

          totalAmount = totalAmount - (pricing.value.value * remainingMonths) - (currentDailyRate * remainingDays);
          originalTotalAmount = totalAmount;

          // Apply the promotion for the annual subscription
          totalAmount *= (1 - yearlyPriceReduction);
        }

        return {
          label: formatNumberToCurrency(totalAmount, currency.value),
          value: Math.round(totalAmount * 100) / 100,
          originalValue: Math.round(originalTotalAmount * 100) / 100,
          discountSummaries,
        };
      }
      return {
        label: formatNumberToCurrency(0, currency.value),
        value: 0,
        originalValue: Math.round(pricing.value.value * 100) / 100,
        discountSummaries:
          UserState.hasOfferV3 && !UserState.activeOffer && discountPercentageOnOfferV3.value > 0
            ? `${t('shop.menu.myOffer.payment.summaries.discount.plan_offerV3', [discountPercentageOnOfferV3.value])} ${t(
              'shop.menu.myOffer.payment.summaries.discount.discountLimit',
              [moment(discountPercentageLimitOnOfferV3.value, 'YYYY-MM-DD').format('DD/MM/YYYY')],
            )}`
            : '',
      };
    });

    const totalPriceToPay = computed(() => {
      let totalPrice = offerPriceToPay.value.value;
      let originalTotalPrice = offerPriceToPay.value.originalValue;
      let fullProductPrice = pricing.value.value;
      let productPrice = pricing.value.toPayValue;

      if (discountPercentageOnOfferV3.value > 0) {
        totalPrice *= (1 - (discountPercentageOnOfferV3.value / 100));
        productPrice *= (1 - (discountPercentageOnOfferV3.value / 100));
      }

      // if to downgrade and the plan is not trial
      const isTrialPlan = !!UserState?.activeOffer?.trialPlan;

      if (toDowngrade.value && !isTrialPlan) {
        if (smsPrice.value && smsPrice.value.value > 0) {
          totalPrice = smsPrice.value.value;
          originalTotalPrice = smsPrice.value.originalValue !== undefined ? smsPrice.value.originalValue : smsPrice.value.value;
        } else {
          totalPrice = 0;
        }
        fullProductPrice += smsPrice.value.productPrice;
        productPrice += smsPrice.value.value;
      } else {
        if (smsPrice.value && smsPrice.value.value) {
          totalPrice += smsPrice.value.value;
          originalTotalPrice += smsPrice.value.originalValue !== undefined ? smsPrice.value.originalValue : smsPrice.value.value;
        }
        fullProductPrice += smsPrice.value.productPrice;
        productPrice += smsPrice.value.value;
      }
      productPrice = Math.round(productPrice * 100) / 100;
      fullProductPrice = Math.round(fullProductPrice * 100) / 100;

      const $return: any = {
        label: formatNumberToCurrency(totalPrice, currency.value),
        value: Math.round(totalPrice * 100) / 100,
        productPriceLabel: formatNumberToCurrency(productPrice, currency.value),
      };

      if (productPrice !== originalTotalPrice) {
        $return.originalLabel = formatNumberToCurrency(originalTotalPrice, currency.value);
        $return.originalValue = Math.round(originalTotalPrice * 100) / 100;
        $return.fullProductPriceLabel = formatNumberToCurrency(fullProductPrice, currency.value);
      }
      setPricePlan($return.productPriceLabel);
      return $return;
    });

    // Methods for User Interaction
    const choosePlan = async (plan: string) => {
      selectedPlan.value.plan = plan;
    };

    const leavePage = () => {
      store.commit('choosePlan/setIsVisible', false);
      store.commit('general/setLastRoute', { to: null, from: null });
      store.commit('general/setFeatureUnavailableMinPlan', null);
      store.commit('general/setOnAuthorizedClickFunction', null);
      store.commit('general/setGleapAction', null);
    };

    const makePayment = async (elements: PaymentElement[], totalPrice: number, onSuccess: Function) => {
      // Payment
      showPayment.value = true;

      const paymentMethods = await GetPaymentMethods({
        id_user: userId, currency: 'EUR', amount: totalPrice, country_code: countryCode, locale: adyenLocale,
      });

      const dataQuery: PaymentData = {
        idShop: UserState.activeShop?.id ?? 0,
        idUser: userId,
        elements,
        paymentDetails: null,
      };

      const configuration = {
        translations: {
          'fr-FR': {
            payButton: t('offers.pay'),
          },
        },

        paymentMethodsResponse: paymentMethods.data,
        removePaymentMethods: ['paypal'], // Retrait temporaire avant de pouvoir générer des tokens
        clientKey: adyenConfig[adyenEnvironment]['client-key'],
        locale: adyenLocale,
        environment: adyenConfig[adyenEnvironment].environment,
        onSubmit: async (state: any, dropin: any) => {
          dropin.setStatus('loading');
          dataQuery.paymentDetails = state.data;

          // Your function calling your server to make the `/payments` request
          MakePaymentCall(JSON.stringify(dataQuery)).then(async (response) => {
            if (response.data.action) {
              // Drop-in handles the action object from the /payments response
              dropin.handleAction(response.data.action);
            } else if (response.data.resultCode === 'Authorised' || response.data.resultCode === 'Received') {
              // Your function to show the final result to the shopper
              dropin.setStatus('success', { message: t('offers.paymentAccepted') });

              // Update user plan
              await onSuccess();

              showToastSuccess(t('offers.success.buyElement'));
            } else {
              dropin.setStatus('error', { message: response.data.message });
              showToastError(t('offers.errors.buyElement'));
            }
          }).catch((reason: any) => {
            dropin.setStatus('error', { message: reason.response.data.refusalReason });
          });
        },

        onAdditionalDetails: (state: any, dropin: any) => {
          // Your function calling your server to make a `/payments/details` request
          MakeDetailsCall(JSON.stringify(state.data))
            .then(async (response) => {
              if (response.data.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.data.action);
              } else if (response.data.resultCode === 'Authorised') {
                // Your function to show the final result to the shopper
                if (response.data.resultCode === 'Authorised') {
                  // Update user plan
                  dropin.setStatus('success', { message: t('offers.paymentAccepted') });

                  await onSuccess();

                  showToastSuccess(t('offers.success.buyElement'));
                }
              } else {
                dropin.setStatus('error', { message: response.message });
                showToastError(t('offers.errors.buyElement'));
              }
            })
            .catch((error) => {
              throw Error(error);
            });
        },

        onPaymentCompleted: (result: any, component: any) => {
          console.info(result, component);
        },

        onError: (error: any, component: any) => {
          console.error(error.name, error.message);
        },

        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            hideCVC: false,
          },

          threeDS2: {
            challengeWindowSize: '05',
          },
        },
      };

      const checkout = await AdyenCheckout(configuration);
      const dropIn = checkout.create('dropin', {
        showStoredPaymentMethods: true,
        onReady: () => {
          nextTick(() => {
            const dropInComponent = document.querySelector('#dropInComponent');
            if (dropInComponent) {
              dropInComponent.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }
          });
        },
      });
      dropIn.mount('#dropInComponent').setComponentRef(dropInComponentRef);
    };

    const makeShopifyPayment = async (elements: PaymentElement[], totalPrice: number, onSuccess: Function) => {
      const dataQuery: any = {
        idShop: UserState.activeShop?.id ?? 0,
        idUser: userId,
        elements,
        totalPrice,
      };

      try {
        loadingBuyButton.value = true;
        MakePaymentCall(JSON.stringify(dataQuery)).then(async (response) => {
          if (response.success) {
            if (response.data.confirmation_url) {
              shopifyUrlConfirmationRedirect.value = response.data.confirmation_url;
            } else {
              await onSuccess();
            }
          } else {
            showToastError(t('offers.errors.buyElement'));
          }
        }).catch((reason: any) => {
          loadingBuyButton.value = false;
          showToastError('GENERIC_ERROR');
        }).then(() => {
          loadingBuyButton.value = false;
        });
      } catch (error) {
        loadingBuyButton.value = false;
        showToastError('GENERIC_ERROR');
      }
    };

    const pay = async () => {
      if (!selectedPlan.value.plan) {
        showToastError(t('shop.menu.myOffer.payment.noSelectedPlanError'));
      } else if (toDowngrade.value) {
        const onDowngrade = async () => {
          store.commit('general/showTheSpinner');
          await downgradePlan(
            selectedPlan.value.plan,
            currency.value,
            selectedSmsPlan.value,
            selectedCountry.value.country,
          );
          store.commit('choosePlan/setIsVisible', false);
          store.commit('general/setLastRoute', { to: null, from: null });
          store.commit('general/setFeatureUnavailableMinPlan', null);
          store.commit('general/hideTheSpinner');
        };

        const elements: PaymentElement[] = [];

        if (selectedSmsPlan.value && smsPrice.value && smsPrice.value.value) {
          elements.push(
            {
              type: BuyableElementType.PLAN_CREDITS,
              name: 'plan_sms',
              productId: selectedPlan.value.isYearly ? smsProductIds.yearly : smsProductIds.monthly,
              productTitle: t('offers.buySmsCredits.buyableElementTitle', {
                qty: selectedPlan.value.isYearly ? selectedSmsPlan.value * 12 : selectedSmsPlan.value,
                country: selectedCountry.value ? t(`countries.codes.${selectedCountry.value.country}`) : '',
              }),

              country: selectedCountry.value ?? null,
              selectedValue: selectedSmsPlan.value,
              autoRenew: false,
              totalPrice: smsPrice.value?.value,
              productPrice: smsPrice.value?.productPrice,
            },
          );
        }

        if (elements.length) {
          const priceToPay = smsPrice.value?.value || 0;

          if (priceToPay > 0) {
            if (UserState.activeShop && UserState.activeShop.solutionEcommerce === 'shopify') {
              await makeShopifyPayment(elements, priceToPay, onDowngrade);
            } else {
              await makePayment(elements, priceToPay, onDowngrade);
            }
          } else {
            await onDowngrade();
          }
        } else {
          await onDowngrade();
        }
      } else {
        const updateUserPlan = async (elements: any = null) => {
          if (elements) {
            // In case we have elements as parameters, we need to apply the elements to the shop because we do not redirect the user to payment page
            const data: PaymentData = {
              idShop: UserState.activeShop?.id ?? 0,
              idUser: userId,
              elements,
            };

            await nestPost('payments', '/apply-elements', {}, { data });
          }

          store.commit('general/showTheSpinner');
          let dateLimit;
          if (selectedPlan.value.isYearly) {
            dateLimit = yearlyRenewalDate.value.format('YYYY-MM-DD');
          } else if (Number(moment().format('D')) < 5) {
            // If current date is less than 5, we set dateLimit to 5th day of current month
            dateLimit = moment()
              .startOf('months')
              .date(5)
              .format('YYYY-MM-DD');
          } else {
            // Else, we set dateLimit to 5th day of next month
            dateLimit = moment()
              .startOf('months')
              .add(1, 'months')
              .date(5)
              .format('YYYY-MM-DD');
          }

          const planCurrency = selectedPlan.value.isYearly ? 'annually' : 'monthly';
          const exactPrice = selectedPlan.value.isYearly ? pricing.value.value * 12 * (1 - yearlyPriceReduction) : pricing.value.value;
          await setPlan(
            selectedPlan.value.plan,
            dateLimit,
            planCurrency,
            exactPrice,
            currency.value,
            selectedSmsPlan.value,
            selectedCountry.value.country,
            discountPercentageOnOfferV3.value > 0 ? discountPercentageOnOfferV3.value : null,
            discountPercentageLimitOnOfferV3.value,
          );
          const minimumFeaturePlan = store.getters['general/getFeatureUnavailableMinPlan'];
          if (minimumFeaturePlan) {
            if (pricingPlansLevel[selectedPlan.value.plan] >= pricingPlansLevel[minimumFeaturePlan]) {
              store.commit('choosePlan/setIsVisible', false);

              // Handle feature route type block
              if (lastRoute.value.to) {
                await router.push({ name: lastRoute.value.to.name });
                store.commit('general/setLastRoute', { to: null, from: null });
              }

              // Handle feature button type block
              if (onAuthorizedClickFunction.value) {
                await onAuthorizedClickFunction.value();
                store.commit('general/setOnAuthorizedClickFunction', null);
              }
              store.commit('general/setFeatureUnavailableMinPlan', null);
              store.commit('general/hideTheSpinner');

              // Handle feature gleap type block
              if (gleapAction.value) {
                const gleapActions = gleapActionForm[gleapAction.value];
                if (gleapActions.isForm) {
                  Gleap.startClassicForm(gleapActions.value, true);
                } else if (gleapActions.isLink) {
                  window.open(gleapActions.value, '_blank');
                } else if (gleapActions.isPhone) {
                  window.open(`tel:${gleapActions.value}`);
                }
                store.commit('general/setGleapAction', null);
              }
            } else {
              store.commit('general/hideTheSpinner');
              leavePage();
            }
          } else {
            store.commit('general/hideTheSpinner');
            leavePage();
          }
        };

        let planProductTitle = t('shop.menu.myOffer.payment.summaries.monthlyPlan', [t(`offers.plans.${selectedPlan.value.plan}`)]);
        if (selectedPlan.value.isYearly) {
          planProductTitle = t('shop.menu.myOffer.payment.summaries.yearlyPlan', [t(`offers.plans.${selectedPlan.value.plan}`)]);
        }

        const elements: PaymentElement[] = [];

        const actualPlan = UserState.activeOffer?.forcedPlan || UserState.activeOffer?.currentPlan;
        if (actualPlan !== selectedPlan.value.plan) {
          elements.push(
            {
              type: selectedPlan.value.isYearly ? BuyableElementType.YEARLY_PLAN : BuyableElementType.MONTHLY_PLAN,
              name: selectedPlan.value.plan,
              productId: selectedPlan.value.isYearly ? productIds[selectedPlan.value.plan].yearly : productIds[selectedPlan.value.plan].monthly,
              productTitle: planProductTitle,
              country: selectedCountry.value ?? null,
              selectedValue: selectedSmsPlan.value,
              autoRenew: false,
              totalPrice: offerPriceToPay.value.value,
              productPrice: selectedPlan.value.isYearly ? pricing.value.value * 12 * (1 - yearlyPriceReduction) : pricing.value.value,
            },
          );
        }
        if (selectedSmsPlan.value && smsPrice.value && smsPrice.value.value) {
          elements.push(
            {
              type: BuyableElementType.PLAN_CREDITS,
              name: 'plan_sms',
              productId: selectedPlan.value.isYearly ? smsProductIds.yearly : smsProductIds.monthly,
              productTitle: t('offers.buySmsCredits.buyableElementTitle', {
                qty: selectedPlan.value.isYearly ? selectedSmsPlan.value * 12 : selectedSmsPlan.value,
                country: selectedCountry.value ? t(`countries.codes.${selectedCountry.value.country}`) : '',
              }),

              country: selectedCountry.value ?? null,
              selectedValue: selectedSmsPlan.value,
              autoRenew: false,
              totalPrice: smsPrice.value?.value,
              productPrice: smsPrice.value?.productPrice,
            },
          );
        }

        if (elements.length) {
          if (totalPriceToPay.value.value > 0) {
            if (UserState.activeShop && UserState.activeShop.solutionEcommerce === 'shopify') {
              await makeShopifyPayment(elements, totalPriceToPay.value.value, updateUserPlan);
            } else {
              await makePayment(elements, totalPriceToPay.value.value, updateUserPlan);
            }
          } else {
            await updateUserPlan(elements);
          }
        } else {
          await updateUserPlan();
        }
      }
    };

    const handleMouseOver = (evt: any, planName: string) => {
      evt.currentTarget.classList.add(`card--hovered${t(`offers.plans.${planName}`)}`);
    };

    const handleMouseLeave = (evt: any, planName: string) => {
      evt.currentTarget.classList.remove(`card--hovered${t(`offers.plans.${planName}`)}`);
    };

    const handleTryPlan = async () => {
      store.commit('general/showTheSpinner');
      await tryPlan(selectedPlan.value.plan);
      store.commit('choosePlan/setIsVisible', false);
      store.commit('general/hideTheSpinner');
    };

    const getIntlNumber = (numberToBeFormatted: number) => new Intl.NumberFormat(locale).format(numberToBeFormatted);

    const scrollToSmsBloc = () => {
      const choosePlanSmsBloc = document.querySelector('#choosePlanSmsBloc');
      if (choosePlanSmsBloc) {
        choosePlanSmsBloc.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    // Configuration and Miscellaneous
    const toolbarHeaderRightButtons: PrimvueMenuModel[] = [
      {
        label: 'Quitter',
        class: 'p-button-secondary',
        command: () => leavePage(),
      },
    ];
    const countryDropdownConfiguration = {
      showStates: false,
      showLabels: false,
      showFlags: true,
      showPhonesCodes: false,
      showOnlyPhoneCodeForSelection: false,
    };

    // API Calls and Lifecycle Hooks
    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      const isShopify = UserState.activeShop && UserState.activeShop.solutionEcommerce === 'shopify';
      const key = isShopify ? await getConfigurationKey('prices_plans_usd_version_14') : await getConfigurationKey('prices_plans_version_14');
      const keyPriceSms = isShopify ? await getConfigurationKey('prices_sms_usd_version_14') : await getConfigurationKey('prices_sms_version_14');
      const keyPricePlanSms = isShopify ? await getConfigurationKey('prices_sms_plan_usd_version_14') : await getConfigurationKey('prices_sms_plan_version_14');
      if (key) {
        // convert array of plans prices to dict with plan name as key
        const activeShop = UserState.activeShop?.solutionEcommerce;
        const list = JSON.parse(key.value).sort((a: any, b: any) => a.nb_pv_max - b.nb_pv_max);
        tableData.value.items = JSON.parse(JSON.stringify(list)).map((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.nb_pv_max = getIntlNumber(item.nb_pv_max);

          // eslint-disable-next-line no-param-reassign
          item.price_standard = formatNumberToCurrency(item.price_standard, activeShop === 'shopify' ? 'USD' : 'EUR');

          // eslint-disable-next-line no-param-reassign
          item.price_premium = formatNumberToCurrency(item.price_premium, activeShop === 'shopify' ? 'USD' : 'EUR');

          // eslint-disable-next-line no-param-reassign
          item.price_ultimate = formatNumberToCurrency(item.price_ultimate, activeShop === 'shopify' ? 'USD' : 'EUR');
          return item;
        });
        tableData.value.total = list.length;

        // eslint-disable-next-line no-restricted-syntax
        for (const item of list) {
          pricingPlans.value[PlanName.STANDARD].push({
            monthlyPagesViewed: item.nb_pv_max,
            price: item.price_standard,
          });
          pricingPlans.value[PlanName.PREMIUM].push({
            monthlyPagesViewed: item.nb_pv_max,
            price: item.price_premium,
          });
          pricingPlans.value[PlanName.ULTIMATE].push({
            monthlyPagesViewed: item.nb_pv_max,
            price: item.price_ultimate,
          });
        }

        const pagesViews = await getMonthlyPagesViewed();
        if (pagesViews) {
          const plan = pricingPlans.value[Object.keys(pricingPlans.value)[0]];
          const exactPlan = plan.find((item: any) => pagesViews <= item.monthlyPagesViewed);
          if (exactPlan) {
            monthlyPagesViewed.value = pagesViews;
          } else {
            notSupported.value = true;
          }
        } else {
          monthlyPagesViewed.value = null;
        }
      } else {
        monthlyPagesViewed.value = null;
      }
      if (keyPriceSms && keyPriceSms.value) {
        smsPricing.value = JSON.parse(keyPriceSms.value);
      }
      if (keyPricePlanSms && keyPricePlanSms.value) {
        planSmsPricing.value = JSON.parse(keyPricePlanSms.value);
      }

      if (UserState.hasOfferV3 && !UserState.activeOffer) {
        // Get percentage and duration of discount for old customers
        const data = {
          idShop: UserState.activeShop?.id ?? 0,
        };

        const offerV3Discount: { success: boolean; percentage?: number; dateLimit?: string } = await nestPost('payments', '/get-discount-for-offer-V3', {}, { data });

        if (
          offerV3Discount
          && offerV3Discount.success
          && 'percentage' in offerV3Discount
          && offerV3Discount.percentage
          && offerV3Discount.percentage > 0
          && 'dateLimit' in offerV3Discount
          && offerV3Discount.dateLimit
          && offerV3Discount.dateLimit !== ''
        ) {
          // Calculation is successful, we apply and display the discounted price
          discountPercentageOnOfferV3.value = offerV3Discount.percentage;
          discountOnPlan.value = offerV3Discount.percentage;
          discountPercentageLimitOnOfferV3.value = offerV3Discount.dateLimit;
        }
      }

      store.commit('general/hideTheSpinner');

      const actualPlan = UserState.activeOffer?.trialPlan || UserState.activeOffer?.forcedPlan || UserState.activeOffer?.currentPlan;
      if (actualPlan) {
        selectedPlan.value.plan = actualPlan;
        selectedPlan.value.isYearly = false;
        if (UserState.activeOffer?.currentPlanFrequency === 'annually') {
          selectedPlan.value.isYearly = true;
          showToggleYearly.value = false;
        }
        if (UserState.activeOffer?.smsPlan) {
          selectedSmsPlan.value = UserState.activeOffer?.smsPlan;
        }
        if (UserState.activeOffer?.smsPlanCountry) {
          selectedCountry.value.country = UserState.activeOffer?.smsPlanCountry;
        }
      }
      const minimumFeaturePlan = store.getters['general/getFeatureUnavailableMinPlan'];
      if (minimumFeaturePlan) {
        selectedPlan.value.plan = minimumFeaturePlan;
      }
      if (!actualPlan) {
        if (!minimumFeaturePlan) {
          selectedPlan.value.plan = PlanName.PREMIUM;
        }
      }
    });

    const filteredPricingPlansFeatures = {
      [PlanName.STANDARD]: pricingPlansFeatures[PlanName.STANDARD],
      [PlanName.PREMIUM]: pricingPlansFeatures[PlanName.PREMIUM],
    };

    return {
      t,
      selectedPlan,
      filteredPricingPlansFeatures,
      allFeatures,
      smsPrice,
      offerPriceToPay,
      yearlyRenewalDate,
      monthlyPagesViewed,
      smsPricing,
      planSmsPricing,
      notSupported,
      toolbarHeaderRightButtons,
      pricingPlans,
      planPriceNoteModal,
      tableData,
      columns,
      idShop,
      countryDropdownConfiguration,
      selectedCountry,
      selectedSmsPlan,
      smsPlansOptions,
      country,
      totalPriceToPay,
      showPayment,
      trialAvailable,
      dropInComponentRef,
      showToggleYearly,
      toDowngrade,
      pricingPlansLevel,
      acceptTTC,
      showTACModal,
      monthlyRenewalDate,
      discountOnPlan,
      discountPercentageOnOfferV3,
      PlanName,
      choosePlan,
      pay,
      handleMouseOver,
      handleMouseLeave,
      findPermission,
      handleTryPlan,
      getIntlNumber,
      scrollToSmsBloc,
      loadingBuyButton,
      isRedirectShopifyModalVisible,
    };
  },
});
