import { ActionContext } from 'vuex';

import {
  FieldsGroup,
  LiveEditorState,
  ParserFieldGroupObject,
  ParserFieldObject,
  ParserObject,
  ParserStructureObject,
  SelectedStructure,
  State,
  TabItem,
  WidgetTypeEnum,
  ActiveLiveEditorItemData,
  FieldConfig,
  Property,
  TemplateStructureEnum,
  ParserToggleItemsDisplayObject,
  SyncElementWarning,
  SelectedSyncElement,
  RenameElementPayload,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  hideLeftToolbar,
  setActiveSection,
  TemplateEditorState,
  togglePanel,
} from '@/composables/template-editor/TemplateEditor';
// eslint-disable-next-line import/no-cycle
import getTemplateConfiguration from '@/components/template-builder/config/templates-config';
// eslint-disable-next-line import/no-cycle
import {
  getTemplateIframeDocument,
  getTemplateParentTypeByType,
  isDisplayTemplate,
  isPushTemplate,
  isSmsTemplate,
  replaceSelectors,
} from '@/components/template-builder/utils/helpers';
// eslint-disable-next-line import/no-cycle
import {
  addValuesToProperties,
  computeActiveSection,
  getChildrenStructure,
  getChildrenType,
  getParentClosestStructure,
} from '@/components/template-builder/utils/parser';
// eslint-disable-next-line import/no-cycle
import getElementStructureConfig from '@/components/template-builder/config/elements-structure-config';

import { cloneDeep } from 'lodash';
import { TEMPLATE_SYNC_ELEMENT_IDENTIFIER, TEMPLATE_SYNC_ELEMENT_NAME } from '@/components/template-builder/utils/constants';
import Sortable from 'sortablejs';

const initField = (item: FieldConfig, fieldGroupSelector: string, structureId: string, parentColumnId: string, parentLineId: string): ParserFieldObject => {
  const field: ParserFieldObject = { selector: '', properties: [] };

  let propertySelector = replaceSelectors(item.selector, '{SELECTOR}', fieldGroupSelector);
  propertySelector = replaceSelectors(propertySelector, '{ID}', structureId);
  propertySelector = replaceSelectors(propertySelector, '{COLUMN_ID}', parentColumnId);
  propertySelector = replaceSelectors(propertySelector, '{LINE_ID}', parentLineId);

  if (item.properties) {
    field.properties = addValuesToProperties(propertySelector, item.properties, structureId);
  }
  field.selector = propertySelector;
  return field;
};

export default {
  namespaced: true,
  state: (): LiveEditorState => ({
    selectedStructure: null,
    activeItemData: null,
    loadElementsPanel: { visible: false },
    saveElementModalPayload: { visible: false, selector: null, type: null },
    enableEmbedPositionChooser: false,
    websiteContents: {},
    isSliderDragged: false,
    isChooseZipFileModalVisible: false,
    selectedWidget: null,
    selectedWidgetId: '',
    selectedColumnId: '',
    syncElementWarning: null,
    selectedSyncElement: null,
    currentTemplateState: null,
    sortableInstances: [],
    checkSyncElements: false,
    backupSelectedStructure: null,
    forceUpdateSyncElements: false,
    refreshConfigurationPanelKey: 0,
    exitEditModeModalVisible: false,
    exitEditModeCallback: null,
  }),

  getters: {
    getSelectedStructure: (state: LiveEditorState) => state.selectedStructure,

    getSelectedStructureConfig: (state: LiveEditorState) => state.selectedStructure?.config,

    getActiveItemData: (state: LiveEditorState) => state.activeItemData,

    getActiveItemSelector: (state: LiveEditorState) => state.activeItemData?.selector,

    getActiveEmbedPositionChooser: (state: LiveEditorState) => state.enableEmbedPositionChooser,

    getWebsiteContents: (state: LiveEditorState) => state.websiteContents,

    getSaveElementSelector: (state: LiveEditorState) => state.saveElementModalPayload.selector,

    getIsSliderDragged: (state: LiveEditorState) => state.isSliderDragged,

    getIsChooseZipFileModalVisible: (state: LiveEditorState) => state.isChooseZipFileModalVisible,

    getSelectedWidget: (state: LiveEditorState) => state.selectedWidget,

    getSelectedWidgetId: (state: LiveEditorState) => state.selectedWidgetId,

    getSelectedColumnId: (state: LiveEditorState) => state.selectedColumnId,

    getSyncElementWarning: (state: LiveEditorState) => state.syncElementWarning,

    getSelectedSyncElement: (state: LiveEditorState) => state.selectedSyncElement,

    getCurrentTemplateState: (state: LiveEditorState) => state.currentTemplateState,

    getCheckSyncElements: (state: LiveEditorState) => state.checkSyncElements,

    getBackupSelectedStructure: (state: LiveEditorState) => state.backupSelectedStructure,

    getForceUpdateSyncElements: (state: LiveEditorState) => state.forceUpdateSyncElements,

    getRefreshConfigurationPanelKey: (state: LiveEditorState) => state.refreshConfigurationPanelKey,

    getExitEditModeModalVisible: (state: LiveEditorState) => state.exitEditModeModalVisible,

    getExitEditModeCallback: (state: LiveEditorState) => state.exitEditModeCallback,
  },

  mutations: {
    setSelectedStructure: (state: LiveEditorState, structure: SelectedStructure) => {
      state.selectedStructure = { ...structure };
    },

    resetSelectedStructure: (state: LiveEditorState) => {
      state.selectedStructure = null;
      state.backupSelectedStructure = null;
      state.refreshConfigurationPanelKey = 0;
    },

    setActiveItemData: (state: LiveEditorState, activeItemData: ActiveLiveEditorItemData) => {
      state.activeItemData = activeItemData;
    },

    resetActiveItemData: (state: LiveEditorState) => {
      state.activeItemData = null;
    },

    showLoadElementsPanel: (state: LiveEditorState) => {
      state.loadElementsPanel.visible = true;

      hideLeftToolbar().then(() => {
        togglePanel('loadElementsPanel');
      });
    },

    hideLoadElementsPanel: (state: LiveEditorState) => {
      state.loadElementsPanel.visible = false;

      hideLeftToolbar();
    },

    showSaveElementModal: (
      state: LiveEditorState,
      payload: {
        selector: string;
        type: TemplateStructureEnum | null;
        forceSync: boolean;
        renameElement?: RenameElementPayload;
        removeSyncOption?: boolean;
      },
    ) => {
      state.saveElementModalPayload = {
        visible: true,
        ...payload,
      };
      if (payload.forceSync) {
        state.saveElementModalPayload.forceSync = true;
      } else {
        state.saveElementModalPayload.forceSync = false;
      }
      if (payload.removeSyncOption) {
        state.saveElementModalPayload.removeSyncOption = true;
      } else {
        state.saveElementModalPayload.removeSyncOption = false;
      }
    },

    hideSaveElementModal: (state: LiveEditorState) => {
      state.saveElementModalPayload = { visible: false, selector: null, type: null };
    },

    toggleEmbedPositionChooser: (state: LiveEditorState, active: boolean) => {
      state.enableEmbedPositionChooser = active;
    },

    setWebsiteContents: (state: LiveEditorState, payload: { url: string; content: string }) => {
      state.websiteContents[payload.url] = payload.content;
    },

    resetWebsiteContents: (state: LiveEditorState) => {
      state.websiteContents = {};
    },

    setIsSliderDragged: (state: LiveEditorState, payload: boolean) => {
      state.isSliderDragged = payload;
    },

    setIsChooseZipFileModalVisible: (state: LiveEditorState, payload: boolean) => {
      state.isChooseZipFileModalVisible = payload;
    },

    setSelectedWidget: (state: LiveEditorState, payload: boolean) => {
      state.selectedWidget = payload;
    },

    setSelectedWidgetId: (state: LiveEditorState, payload: string) => {
      state.selectedWidgetId = payload;
    },

    setSelectedColumnId: (state: LiveEditorState, payload: string) => {
      state.selectedColumnId = payload;
    },

    setSyncElementWarning: (state: LiveEditorState, payload: SyncElementWarning | null) => {
      state.syncElementWarning = payload;
    },

    setSelectedSyncElement: (state: LiveEditorState, payload: SelectedSyncElement | null) => {
      state.selectedSyncElement = payload;
    },

    setCurrentTemplateState: (state: LiveEditorState, payload: any) => {
      if (payload) {
        state.currentTemplateState = {
          index: payload.index,
          template: cloneDeep(payload.template),
        };
      } else {
        state.currentTemplateState = null;
      }
    },

    setSelectedSyncElementName: (state: LiveEditorState, payload: string) => {
      if (state.selectedSyncElement) {
        state.selectedSyncElement.elementName = payload;
      }
    },

    addToSortableInstances: (state: LiveEditorState, payload: Sortable) => {
      state.sortableInstances.push(payload);
    },

    destroySortableInstances: (state: LiveEditorState) => {
      state.sortableInstances.forEach((instance) => {
        if (instance.el) {
          instance.destroy();
        }
      });
      state.sortableInstances = [];
    },

    setCheckSyncElements: (state: LiveEditorState, payload: boolean) => {
      state.checkSyncElements = payload;
    },

    setBackupSelectedStructure: (state: LiveEditorState, structure: SelectedStructure) => {
      state.backupSelectedStructure = cloneDeep(structure);
    },

    setForceUpdateSyncElements: (state: LiveEditorState, payload: boolean) => {
      state.forceUpdateSyncElements = payload;
    },

    refreshConfigurationPanelKey: (state: LiveEditorState) => {
      state.refreshConfigurationPanelKey += 1;
    },

    setSelectedStructureParserValues: (state: LiveEditorState, payload: ParserObject) => {
      if (state.selectedStructure) {
        state.selectedStructure.parserValues = cloneDeep(payload);
      }
    },

    setExitEditModeModalVisible: (state: LiveEditorState, payload: boolean) => {
      state.exitEditModeModalVisible = payload;
    },

    setExitEditModeCallback: (state: LiveEditorState, payload: Function | null) => {
      state.exitEditModeCallback = payload;
    },
  },

  actions: {
    prepareStructureConfiguration: (
      { commit }: ActionContext<LiveEditorState, State>,
      { identifier, type, widgetType = null }: { identifier: string; type: string; widgetType: WidgetTypeEnum | null },
    ) => {
      if (!TemplateEditorState.template) return; // No active template

      setActiveSection(computeActiveSection(type, identifier)); // Set active section in state (usefull to get/set css values in correct stylesheet)

      const parserValues: ParserObject = {};
      const templateParentType = getTemplateParentTypeByType(TemplateEditorState.template.type);
      const config = getTemplateConfiguration(templateParentType, widgetType, TemplateEditorState.template.informations.imported)[type];

      if (!config) return; // Configuration not found for this template

      // We get the parent column and parent line to replace their IDs if element is a widget
      const parentColumnId = widgetType ? getParentClosestStructure(identifier, 'column') ?? null : null;
      const parentLineId = widgetType ? getParentClosestStructure(identifier, 'line') ?? null : null;

      config.items.forEach((tabItem: TabItem) => {
        const structureId = getParentClosestStructure(identifier, tabItem.tabItemType) ?? identifier;
        if (structureId) {
          parserValues[tabItem.label] = { items: {} };
          parserValues[tabItem.label].selector = `#${structureId}`;
          parserValues[tabItem.label].childrenStructureCount = getChildrenStructure(structureId, getChildrenType(tabItem.tabItemType, templateParentType)).length;

          const constructTabPanelStructure = (groupTabItem: TabItem, tabStructure: ParserStructureObject) => {
            // eslint-disable-next-line no-unused-expressions
            groupTabItem.tabPanel?.items.forEach((itemGroupTabItem) => {
              const fieldGroup = itemGroupTabItem as FieldsGroup;
              let fieldGroupSelector = replaceSelectors(fieldGroup.selector || '', '{ID}', structureId);
              fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{COLUMN_ID}', parentColumnId ?? structureId);
              fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{LINE_ID}', parentLineId ?? structureId);
              const group: ParserFieldGroupObject = {};
              (fieldGroup.items || []).forEach((item) => {
                const field: ParserFieldObject = initField(item, fieldGroupSelector, structureId, parentColumnId ?? structureId, parentLineId ?? structureId);
                group[item.label] = field;
              });
              // eslint-disable-next-line no-param-reassign
              tabStructure[fieldGroup.label] = group;
            });
          };

          const constructFieldGroupStructure = (fieldGroup: FieldsGroup, tabStructure: ParserStructureObject, subField = false) => {
            let fieldGroupSelector = replaceSelectors(fieldGroup.selector || '', '{ID}', structureId);
            fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{COLUMN_ID}', parentColumnId ?? structureId);
            fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{LINE_ID}', parentLineId ?? structureId);
            const group: ParserFieldGroupObject = {};

            (fieldGroup.items || []).forEach((item) => {
              const field: ParserFieldObject = initField(item, fieldGroupSelector, structureId, parentColumnId ?? structureId, parentLineId ?? structureId);
              group[item.label] = field;
            });
            if (!subField) {
              // eslint-disable-next-line no-param-reassign
              tabStructure[fieldGroup.label] = group;
            } else {
              // eslint-disable-next-line no-param-reassign
              Object.assign(tabStructure, group);
            }
          };

          const tabStructure: ParserStructureObject = {};
          const toggleItemsDisplay: ParserToggleItemsDisplayObject = {};

          (tabItem.tabPanel?.items || []).forEach((groupItem: FieldsGroup | TabItem) => {
            if (Object.prototype.hasOwnProperty.call(groupItem, 'tabPanel')) {
              // Is TabItem
              const groupTabItem = groupItem as TabItem;
              constructTabPanelStructure(groupTabItem, tabStructure);
            } else if (Object.prototype.hasOwnProperty.call(groupItem, 'items')) {
              // Is FieldsGroup
              const fieldGroup = groupItem as FieldsGroup;
              constructFieldGroupStructure(fieldGroup, tabStructure);
            } else if (Object.prototype.hasOwnProperty.call(groupItem, 'fieldGroups')) {
              const fieldGroup = groupItem as FieldsGroup;
              const fieldGroupsStructure: ParserStructureObject = {};
              // eslint-disable-next-line no-unused-expressions
              fieldGroup.fieldGroups?.forEach((group) => {
                if (Object.prototype.hasOwnProperty.call(group, 'tabPanel')) {
                  const tabGroupItem = group as TabItem;
                  constructTabPanelStructure(tabGroupItem, fieldGroupsStructure);
                } else if (Object.prototype.hasOwnProperty.call(group, 'items')) {
                  const fieldGroupItem = group as FieldsGroup;
                  constructFieldGroupStructure(fieldGroupItem, fieldGroupsStructure, true);
                }
              });
              tabStructure[fieldGroup.label] = fieldGroupsStructure;
            }

            if (tabItem.canToggleSettings && tabItem.canToggleSettings[groupItem.label]) {
              const initValue = tabItem.canToggleSettings[groupItem.label].initValueHandler(structureId);
              toggleItemsDisplay[groupItem.label] = {
                value: initValue,
                toggleHandler: groupItem.toggleHandler,
              };
            }
          });
          parserValues[tabItem.label].items = tabStructure;
          if (Object.keys(toggleItemsDisplay).length > 0) {
            parserValues[tabItem.label].toggleItemsDisplay = toggleItemsDisplay;
          }
        }
      });

      commit('setSelectedStructure', {
        identifier,
        type,
        config,
        parserValues,
        test: 'test',
      });
      commit('setBackupSelectedStructure', {
        identifier,
        type,
        config,
        parserValues,
        test: 'test',
      });
    },
    refreshWidgetFields: ({ state }: ActionContext<LiveEditorState, State>) => {
      if (!state.selectedStructure) return;
      const config = state.selectedStructure?.config;
      if (!config) return;
      if (!TemplateEditorState.template) return; // No active template

      const templateParentType = getTemplateParentTypeByType(TemplateEditorState.template.type);

      // eslint-disable-next-line max-len
      const openedTab = config.items.filter((tabItem: TabItem) => ((isPushTemplate(templateParentType) || isSmsTemplate(templateParentType)) && tabItem.tabItemType === TemplateStructureEnum.PAGE) || tabItem.tabItemType === TemplateStructureEnum.WIDGET)[0];
      const structureId = getParentClosestStructure(state.selectedStructure?.identifier ?? '', openedTab.tabItemType) ?? state.selectedStructure.identifier;

      // We get the parent column and parent line to replace their IDs if element is a widget
      const parentColumnId = getParentClosestStructure(structureId, 'column') ?? null;
      const parentLineId = getParentClosestStructure(structureId, 'line') ?? null;

      (openedTab.tabPanel?.items || []).forEach((fieldGroup: FieldsGroup) => {
        let fieldGroupSelector = replaceSelectors(fieldGroup.selector || '', '{ID}', structureId);
        fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{COLUMN_ID}', parentColumnId ?? structureId);
        fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{LINE_ID}', parentLineId ?? structureId);
        (fieldGroup.items || []).forEach((item: FieldConfig) => {
          let propertySelector = replaceSelectors(item.selector, '{SELECTOR}', fieldGroupSelector);
          propertySelector = replaceSelectors(propertySelector, '{ID}', structureId);
          propertySelector = replaceSelectors(propertySelector, '{COLUMN_ID}', parentColumnId ?? structureId);
          propertySelector = replaceSelectors(propertySelector, '{LINE_ID}', parentLineId ?? structureId);
          item.properties.forEach((property: Property) => {
            const updatedProperty = property;
            updatedProperty.value = updatedProperty.getter(propertySelector, updatedProperty.name, structureId);
          });
        });

        if ('tabPanel' in fieldGroup && fieldGroup.tabPanel && fieldGroup.tabPanel.items.length > 0) {
          (fieldGroup.tabPanel.items || []).forEach((subGroup: FieldsGroup) => {
            (subGroup.items || []).forEach((subItem: FieldConfig) => {
              let propertySelector = replaceSelectors(subItem.selector, '{SELECTOR}', fieldGroupSelector);
              propertySelector = replaceSelectors(propertySelector, '{ID}', structureId);
              propertySelector = replaceSelectors(propertySelector, '{COLUMN_ID}', parentColumnId ?? structureId);
              propertySelector = replaceSelectors(propertySelector, '{LINE_ID}', parentLineId ?? structureId);
              subItem.properties.forEach((property: Property) => {
                const updatedProperty = property;
                updatedProperty.value = updatedProperty.getter(propertySelector, updatedProperty.name, structureId);
              });
            });
          });
        }
      });
    },
    toggleSetting: (
      { state }: ActionContext<LiveEditorState, State>,
      { tabItemLabel, fieldGroupLabel, value }: { tabItemLabel: string; fieldGroupLabel: string; value: boolean },
    ) => {
      if (state.selectedStructure) {
        if (state.selectedStructure.parserValues && state.selectedStructure.parserValues[tabItemLabel].toggleItemsDisplay) {
          const toggleItemsDisplay = state.selectedStructure.parserValues[tabItemLabel].toggleItemsDisplay as ParserToggleItemsDisplayObject;
          toggleItemsDisplay[fieldGroupLabel].value = value;
          state.selectedStructure.parserValues[tabItemLabel].toggleItemsDisplay = toggleItemsDisplay;
        }
      }
    },
    showSyncElementWarning: (
      { commit }: ActionContext<LiveEditorState, State>,
      {
        elementId,
        elementType,
        acceptCallback,
        rejectCallback,
        name = '',
        additionalMessage = '',
        overrideMessage = '',
      }: {
        elementId: any;
        elementType: string;
        acceptCallback: Function;
        rejectCallback: Function | null;
        name: string;
        additionalMessage: string;
        overrideMessage: string;
      },
    ) => {
      if (!TemplateEditorState.template) return; // No active template

      commit('setCurrentTemplateState', TemplateEditorState);

      let elementName: string = name;
      let syncElementId = '';
      const template = getTemplateIframeDocument();
      if (template) {
        const elementHtml = template.querySelector(`#${elementId}`);
        if (elementHtml) {
          if (!name) {
            elementName = elementHtml.getAttribute(TEMPLATE_SYNC_ELEMENT_NAME) ?? '';
          }
          syncElementId = elementHtml.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER) ?? '';
        }
        if (elementType === TemplateStructureEnum.DESIGN) {
          const body = isDisplayTemplate(TemplateEditorState.template?.type) ? template.querySelector('#spm_body') : template.body;
          if (body) {
            syncElementId = body.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER) ?? '';
          }
        }
      }

      const accept = () => {
        commit('setSyncElementWarning', null);
        commit('setSelectedSyncElement', {
          elementId,
          elementType,
          elementName,
          initialState: null,
          structureConfiguration: getElementStructureConfig(elementId, elementType),
          syncElementId,
        });
        acceptCallback();
      };
      const reject = () => {
        if (rejectCallback) {
          rejectCallback();
        }
        commit('setSyncElementWarning', null);
        commit('setCurrentTemplateState', null);
      };

      commit('setSyncElementWarning', {
        elementType,
        acceptCallback: accept,
        rejectCallback: reject,
        additionalMessage,
        overrideMessage,
      });
    },
    updateSelectedStructureParserValues: (
      { state }: ActionContext<LiveEditorState, State>,
      {
        properties,
        tabItemLabel,
        fieldGroupLabel,
        fieldLabel,
      }: {
        properties: Array<any>;
        tabItemLabel: string;
        fieldGroupLabel: string;
        fieldLabel: string;
      },
    ) => {
      if (state.selectedStructure) {
        // eslint-disable-next-line prefer-destructuring
        const parserValues = state.selectedStructure.parserValues;
        const fieldGroup = parserValues[tabItemLabel].items[fieldGroupLabel];
        const field = fieldGroup[fieldLabel];

        if (Array.isArray(field.properties)) {
          for (let i = 0; i < properties.length; i++) {
            const source = properties[i];
            const target = field.properties[i];

            // eslint-disable-next-line no-restricted-syntax
            for (const key in source) {
              if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key as keyof Property] = source[key as keyof Property];
              }
            }
          }
        }
      }
    },
  },
};
