<template>
  <div>
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-center">
        <label class="block my-3">
          {{ t(configs.label) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => { displayProperties = value; resetComponentValues(); }"
        />
      </div>
    </div>
    <div
      class="grid"
      :class="{ 'hidden': !displayProperties, 'flex': displayProperties }"
    >
      <div
        v-if="displayFontFamilyComponent"
        class="col-12"
      >
        <FontFamily
          :key="keyComponents"
          :configs="configFontFamily"
          :parser-values="parserValuesFontFamily"
          @on-change-properties="handlePropertyValuesChange"
        />
      </div>
      <div
        v-if="displayFontSizeComponent"
        class="col-12"
      >
        <StepperPxPercentSlider
          :key="keyComponents"
          :configs="configFontSize"
          :parser-values="parserValuesFontSize"
          @on-change-properties="handlePropertyValuesChange"
        />
      </div>
      <div
        v-if="displayTextStyleComponent"
        class="col-7"
      >
        <ButtonGroup
          :key="keyComponents"
          :configs="configTextStyles"
          :parser-values="parserValuesTextStyles"
          @on-change-properties="handlePropertyValuesChange"
        />
      </div>
      <div
        v-if="displayFontColorComponent"
        class="col-5"
      >
        <Color
          :key="keyComponents"
          :configs="configFontColor"
          :parser-values="parserValuesFontColor"
          @on-change-properties="handlePropertyValuesChange"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ButtonFieldConfig, ColorFieldConfig,
  FieldConfig,
  FontFieldConfig,
  ParserFieldObject,
  Property,
  StepperPxPercentSliderFieldConfig,
} from '@/types';

import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import FontFamily from '@/components/template-builder/fields/FontFamily.vue';
import StepperPxPercentSlider from '@/components/template-builder/fields/StepperPxPercentSlider.vue';
import ButtonGroup from '@/components/template-builder/fields/ButtonGroup.vue';
import Color from '@/components/template-builder/fields/Color.vue';

export default defineComponent({
  name: 'Font',

  components: {
    Color,
    ButtonGroup,
    FontFamily,
    StepperPxPercentSlider,
    ToggleDisplaySettings,
  },

  props: {
    configs: {
      type: Object as PropType<FontFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const parserValues = ref(props.parserValues);
    const keyComponents = ref(0);

    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const displayFontFamilyComponent: ComputedRef<boolean> = computed(() => configs.value.properties.filter((property: Property) => property.name === 'font-family').length > 0);
    const displayFontSizeComponent: ComputedRef<boolean> = computed(() => configs.value.properties.filter((property: Property) => property.name === 'font-size').length > 0);
    const displayTextStyleComponent: ComputedRef<boolean> = computed(() => configs.value.properties.filter(
      (property: Property) => ['font-weight', 'font-style', 'text-decoration-line', 'text-transform'].indexOf(property.name) !== -1,
    ).length > 0);
    const displayFontColorComponent: ComputedRef<boolean> = computed(() => configs.value.properties.filter((property: Property) => property.name === 'color').length > 0);

    const parserValuesFontFamily: Ref<ParserFieldObject> = ref({ selector: '', properties: [] });
    const propertyFontFamily: Ref<Property | null> = ref(null);
    const configFontFamily: Ref<FieldConfig> = ref({ ...props.configs, ...props.configs.options.fontFamily });

    const parserValuesFontSize: Ref<ParserFieldObject> = ref({ selector: '', properties: [] });
    const propertyFontSize: Ref<Property | null> = ref(null);
    const configFontSize: Ref<StepperPxPercentSliderFieldConfig> = ref({ ...props.configs, ...props.configs.options.fontSize });

    const parserValuesTextStyles: Ref<ParserFieldObject> = ref({ selector: '', properties: [] });
    const propertiesTextStyles: Ref<Property[] | null> = ref(null);
    const configTextStyles: Ref<ButtonFieldConfig> = ref({ ...props.configs, ...props.configs.options.textStyles });

    const parserValuesFontColor: Ref<ParserFieldObject> = ref({ selector: '', properties: [] });
    const propertyFontColor: Ref<Property | null> = ref(null);
    const configFontColor: Ref<ColorFieldConfig> = ref({ ...props.configs, ...props.configs.options.fontColor });

    watch(parserValues, () => {
      if (displayFontFamilyComponent.value) {
        [propertyFontFamily.value] = parserValues.value.properties.filter((property: Property) => property.name === 'font-family');
        parserValuesFontFamily.value = { properties: [propertyFontFamily.value], selector: parserValues.value.selector };

        configFontFamily.value.properties = [propertyFontFamily.value];
        configFontFamily.value.displayToggle = false;
        configFontFamily.value.label = '';
      }

      if (displayFontSizeComponent.value) {
        [propertyFontSize.value] = parserValues.value.properties.filter((property: Property) => property.name === 'font-size');
        parserValuesFontSize.value = { properties: [propertyFontSize.value], selector: parserValues.value.selector };

        configFontSize.value.properties = [propertyFontSize.value];
        configFontSize.value.displayToggle = false;
        configFontSize.value.label = '';
      }

      if (displayTextStyleComponent.value) {
        propertiesTextStyles.value = parserValues.value.properties.filter(
          (property: Property) => ['font-weight', 'font-style', 'text-decoration-line', 'text-transform'].indexOf(property.name) !== -1,
        );
        parserValuesTextStyles.value = { properties: propertiesTextStyles.value, selector: parserValues.value.selector };

        configTextStyles.value.properties = propertiesTextStyles.value;
        configTextStyles.value.displayToggle = false;
        configTextStyles.value.label = '';
      }

      if (displayFontColorComponent.value) {
        [propertyFontColor.value] = parserValues.value.properties.filter((property: Property) => property.name === 'color');
        parserValuesFontColor.value = { properties: [propertyFontColor.value], selector: parserValues.value.selector };

        configFontColor.value.properties = [propertyFontColor.value];
        configFontColor.value.displayToggle = false;
        configFontColor.value.label = '';
      }
    }, { immediate: true, deep: true });

    const resetComponentValues = () => {
      if (!displayProperties.value) {
        parserValues.value.properties.forEach((property: Property) => {
          // eslint-disable-next-line no-param-reassign
          property.value = undefined;
        });
        keyComponents.value += 1;
      }
    };

    const handlePropertyValuesChange = (data: any) => {
      emit('on-change-properties', data);
    };

    return {
      t,
      displayProperties,
      displayFontFamilyComponent,
      displayFontSizeComponent,
      displayTextStyleComponent,
      displayFontColorComponent,
      keyComponents,
      configFontFamily,
      parserValuesFontFamily,
      configFontSize,
      parserValuesFontSize,
      configTextStyles,
      parserValuesTextStyles,
      configFontColor,
      parserValuesFontColor,
      resetComponentValues,
      handlePropertyValuesChange,
    };
  },
});
</script>
