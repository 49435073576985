// eslint-disable-next-line import/no-cycle
import { StringMap, TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, NumberRange, Period, PickerData,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import {
  integer, maxLength, minLength, required, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { atLeastOneSegmentValidator, includesValuesValidator } from '@/helpers/CustomValidator';

export interface FiltrePurchaseHistoryFilters {
  filterhasordered?: {
    segment_number: number;
    hidden_has_ordered: string[];
    check_if_order_valid: string[];
    litteral_title: StringMap;
  };
  filternoorderfrom?: {
    segment_number: number;
    time_unit_select: Period[];
    litteral_title: StringMap;
  };
  filtertimesincelastorder?: {
    segment_number: number;
    time_unit_select: Period[];
    check_if_order_valid: string[];
    litteral_title: StringMap;
  };
  filterdateordercount?: {
    segment_number: number;
    range_slider_date_order_count_time: NumberRange;
    time_unit_select: Period[];
    check_if_order_valid: string[];
    litteral_title: StringMap;
  };
  filterdateorderamountcurrency?: {
    segment_number: number;
    date_order_amount_currency: NumberRange;
    time_unit_select: Period[];
    check_if_order_valid: string[];
    litteral_title: StringMap;
  };
  filterdateorderproductcount?: {
    segment_number: number;
    product_picker_date_order_product_count: PickerData;
    time_unit_select: Period[];
    check_if_order_valid: string[];
    litteral_title: StringMap;
  };
  filterdateordercategorycount?: {
    segment_number: number;
    product_picker_date_order_category_count: PickerData;
    time_unit_select: Period[];
    check_if_order_valid: string[];
    litteral_title: StringMap;
  };
  filterdateordermanufacturercounttime?: {
    segment_number: number;
    product_picker_date_order_manufacturer_count: PickerData;
    time_unit_select: Period[];
    check_if_order_valid: string[];
    litteral_title: StringMap;
  };
}

export interface FiltrePurchaseHistoryData {
  custom_title: string;
  custom_description: string;
  outputs_number: number;
  initial_number_outputs?: null|number;
  grant_additional_delay: string;
  extra_time: Period;
  inclusion: FiltrePurchaseHistoryFilters;
  exclusion: FiltrePurchaseHistoryFilters;
}

const FiltrePurchaseHistoryMetadata: ComponentMetadata<FiltrePurchaseHistoryData> = {
  Meta: {
    kind: 'filtre',
    id: 'boxfiltre_4',
    label: 'automatedScenarios.filters.purchaseHistory',
    icon: 'fa-cogs',
    hasConfiguration: true,
    persistent: [
      { segmentId: 'filterhasordered', boxId: '' },
      { segmentId: 'filternoorderfrom', boxId: '' },
      { segmentId: 'filtertimesincelastorder', boxId: '' },
      { segmentId: 'filterdateordercount', boxId: '' },
      { segmentId: 'filterdateorderamountcurrency', boxId: '' },
      { segmentId: 'filterdateorderproductcount', boxId: '' },
      { segmentId: 'filterdateordercategorycount', boxId: '' },
      { segmentId: 'filterdateordermanufacturercounttime', boxId: '' },
    ],
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching',
        ],
      },
      bulk: {
        next_operators: [
          'boxchoosebulklist',
        ],
      },
    },
    component: 'FiltrePurchaseHistory',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create(data: FiltrePurchaseHistoryData = {
    custom_title: '',
    custom_description: '',
    outputs_number: 1,
    grant_additional_delay: '0',
    extra_time: {
      value: 1,
      unit: 'DAY',
    },
    inclusion: {},
    exclusion: {},
  }): ComponentData<FiltrePurchaseHistoryData> {
    return {
      id: 'boxfiltre_4',
      form_name: 'boxfiltre_4',
      logo_box: 'fa-cogs',
      ...data,
    };
  },
  async Validate(data: FiltrePurchaseHistoryData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      outputs_number: {
        required,
        includesValuesValidator: includesValuesValidator([1, 2]),
      },
      grant_additional_delay: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      extra_time: {
        value: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          integer,
        },
        unit: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR', 'DAY']),
        },
      },
      inclusion: {
        required: atLeastOneSegmentValidator(data.exclusion),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: FiltrePurchaseHistoryData): FiltrePurchaseHistoryData {
    const dataToReturn: FiltrePurchaseHistoryData = data;
    const { initial_number_outputs } = dataToReturn;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }

    if (initial_number_outputs !== null && initial_number_outputs !== undefined
      && data.outputs_number < initial_number_outputs) {
      /* En cas de dimunition du nombre de branches, on supprime la branche "non" */
      decreaseNumberOfBranchesOfAnOperator(['output_2']);
    }

    return dataToReturn;
  },
  Outputs(data: FiltrePurchaseHistoryData): number {
    return data.outputs_number;
  },
  Label(data: FiltrePurchaseHistoryData): string {
    return data.custom_title;
  },
};

export default FiltrePurchaseHistoryMetadata;
