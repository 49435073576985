<template>
  <OverlayPanel
    ref="displayEmojiPicker"
    :show-close-icon="false"
    class="inputOverlayPanel"
  >
    <VuemojiPicker
      class="emoji-picker"
      @emoji-click="handleEmojiClicked"
    />
  </OverlayPanel>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { VuemojiPicker, EmojiClickEventDetail } from 'vuemoji-picker';
import OverlayPanel from 'primevue/overlaypanel';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Emojis',

  components: {
    VuemojiPicker,
    OverlayPanel
  },

  props: {
    parentRef: {
      type: Object, // Ou tout autre type qui convient à votre référence parent
      required: true,
    },
  },

  emits: {
    'on-choose-emoji': Object,
    'add-data-menu': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const displayEmojiPicker = ref();

    const handleEmojiClicked = (detail: EmojiClickEventDetail) => {
      context.emit('on-choose-emoji', detail.unicode);
      displayEmojiPicker.value.hide();
    };

    context.emit('add-data-menu', {
      label: t('templateBuilder.insertEmoji'),
      icon: 'fal fa-grin',
      command(event: any) {
        setTimeout(() => {
          displayEmojiPicker.value.show(event, props.parentRef.querySelector('button'));
        }, 0);
      },
    });
    return {
      displayEmojiPicker,
      handleEmojiClicked,
    };
  },

});
</script>
