
import {
  defineComponent,
  computed,
  ComputedRef,
  ref,
  Ref,
  watch,
  reactive,
  nextTick,
} from 'vue';

import { useStore } from '@/store';

import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InputText from 'primevue/inputtext';
import InlineMessage from 'primevue/inlinemessage';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import InputMask from 'inputmask';
import { hideColumnWidgetModal, TemplateEditorState as state, updateSectionsInState } from '@/composables/template-editor/TemplateEditor';
import { HistoryType, SelectedSyncElement } from '@/types';
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import { resetListeners } from '@/components/template-builder/utils/listeners';
import { getTemplateIframeDocument, getTemplateParentTypeByType, isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import { getColumnsWidgetLayouts } from '@/components/template-builder/utils/raw-html-templates';
import { showToastError } from '@/helpers';
import { columnProportionsValidator } from '@/helpers/CustomValidator';
import { store } from '@/store';

interface ColumnsLayout {
  name: string;
  percentages: number[];
  grid: string[];
}

export default defineComponent({
  name: 'ColumnWidgetModal',

  components: {
    Dialog,
    SelectButton,
    Button,
    TabView,
    TabPanel,
    InputText,
    FieldErrors,
    InlineMessage,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const displayModal: ComputedRef<boolean> = computed(() => state.isColumnWidgetModalVisible);
    // Number of columns buttons
    const availableColumns = ref([
      { label: '1', value: 1, disabled: false },
      { label: '2', value: 2, disabled: false },
      { label: '3', value: 3, disabled: false },
      { label: '4', value: 4, disabled: false },
    ]);
    const chosenColumns: Ref<number> = ref(1);
    const isDisplay = computed(() => isDisplayTemplate(state.template?.type));
    const activeTab = ref(0);
    const customMask = computed(() => Array(chosenColumns.value).fill('9{1,2}%').join(' / '));
    const customMaskLabel = computed(() => {
      if (chosenColumns.value === 1) {
        return '100%';
      }
      if (chosenColumns.value === 2) {
        return '50% / 50%';
      }
      if (chosenColumns.value === 3) {
        return '33% / 33% / 33%';
      }
      return '25% / 25% / 25% / 25%';
    });
    const customColumnSize = ref('');
    const customColumnRules = ref({
      customColumnSize: {
        columnProportionsValidator: columnProportionsValidator(),
      },
    });
    const previewCustomColumnSizeLabel = ref('');
    const previewCustomPercentages = ref<number[]>([]);
    const error = ref();
    const componentFieldErrorsKey = ref(0);
    const customColumnState = reactive({
      customColumnSize,
    });
    const loading = ref(false);

    // Selected column (in case of column edition)
    const selectedColumnId = computed(() => store.getters['liveEditor/getSelectedColumnId']);
    const dialogHeader = computed(() => (selectedColumnId.value ? t('templateBuilder.modals.columnWidget.editColumnHeader') : t('templateBuilder.modals.columnWidget.header')));
    const columnsToUpdate = ref<{ id: string; percentage: number }[]>([]);
    const selectedColumnIndex = ref(-1);
    const currentColumnNumber = ref(0);
    const currentColumnSize = ref('');
    const customColumnSizeRef = ref();

    // Auto complete percentage
    const currentPos = ref(-1);

    // Columns layouts buttons
    const availableColumnsLayouts: ColumnsLayout[][] = [
      [
        { name: t('templateBuilder.modals.columnWidget.fullWidth'), percentages: [100], grid: ['csd-four'] },
      ],
      [
        { name: '50% / 50%', percentages: [50, 50], grid: ['csd-two', 'csd-two'] },
        { name: '33% / 67%', percentages: [33, 67], grid: ['csd-one-big', 'csd-three-little'] },
        { name: '67% / 33%', percentages: [67, 33], grid: ['csd-three-little', 'csd-one-big'] },
        { name: '25% / 75%', percentages: [25, 75], grid: ['csd-one', 'csd-three'] },
        { name: '75% / 25%', percentages: [75, 25], grid: ['csd-three', 'csd-one'] },
      ],
      [
        { name: '33% / 33% / 33%', percentages: [33, 33, 33], grid: ['csd-one-big', 'csd-one-big', 'csd-one-big'] },
        { name: '50% / 25% / 25%', percentages: [50, 25, 25], grid: ['csd-two', 'csd-one', 'csd-one'] },
        { name: '25% / 50% / 25%', percentages: [25, 50, 25], grid: ['csd-one', 'csd-two', 'csd-one'] },
        { name: '25% / 25% / 50%', percentages: [25, 25, 50], grid: ['csd-one', 'csd-one', 'csd-two'] },
      ],
      [
        { name: t('templateBuilder.modals.columnWidget.equalWidth'), percentages: [25, 25, 25, 25], grid: ['csd-one', 'csd-one', 'csd-one', 'csd-one'] },
      ],
    ];
    const columnsLayout: ComputedRef<ColumnsLayout[]> = computed(() => availableColumnsLayouts[chosenColumns.value - 1]);
    const layoutIndex: Ref<number> = ref(0);
    const chosenLayout: ComputedRef<ColumnsLayout> = computed(() => availableColumnsLayouts[chosenColumns.value - 1][layoutIndex.value]);

    const templateParentType: ComputedRef<string> = computed(() => getTemplateParentTypeByType(state.template?.type));
    const columnWidgetStructure = computed(() => getColumnsWidgetLayouts()[templateParentType.value]);

    const selectedSyncElement = computed<SelectedSyncElement>(() => store.getters['liveEditor/getSelectedSyncElement']);

    const handleClose = () => {
      // Reset values
      chosenColumns.value = 1;
      layoutIndex.value = 0;
      store.commit('liveEditor/setSelectedColumnId', '');

      hideColumnWidgetModal();
    };

    const insertNewColumn = async (percentages: number[]) => {
      const htmlToInsert = columnWidgetStructure.value(percentages);

      // Get placeholder element in template
      const template = getTemplateIframeDocument();
      const placeholder = template?.querySelector('.sortable-group-line.sortable-placeholder') as HTMLElement;

      if (placeholder) {
        await checkIfSavePointNeeded();
        placeholder.insertAdjacentHTML('afterend', htmlToInsert);

        // Remove placeholder from template
        placeholder.remove();

        // Update state
        updateSectionsInState(false);

          // Prevent creating history entry in edit mode
          if (!selectedSyncElement.value) {
        // Create history entry
        createHistory(HistoryType.ADD_COLUMN);
          }

        // Reset all listeners
        resetListeners();
        // Close modal
        handleClose();
      } else {
        // Close modal
        handleClose();
      }
    };

    const updateColumns = async (percentages: number[]) => {
      const template = getTemplateIframeDocument();
      if (!template) return;

      await checkIfSavePointNeeded();

      // eslint-disable-next-line no-restricted-syntax
      for (const column of columnsToUpdate.value) {
        const columnElement = template.querySelector(`#${column.id}`) as HTMLElement;
        if (columnElement) {
          columnElement.style.width = `calc(${percentages.shift()}% - 1.5px)`;
        }
      }
      if (currentColumnNumber.value < chosenColumns.value) {
        const htmlToInsert = columnWidgetStructure.value(percentages, true);
        const column = template.querySelector(selectedColumnId.value) as HTMLElement;
        if (column && column.parentElement) {
          // Insert html to the parent
          column.parentElement.insertAdjacentHTML('beforeend', htmlToInsert);
        }
      }

      updateSectionsInState(false);

      // Create history entry
      createHistory(HistoryType.EDIT_COLUMN_SIZE);

      resetListeners();
      handleClose();
    };

    const handleValidation = async () => {
      try {
        const insertOrUpdate = selectedColumnId.value ? updateColumns : insertNewColumn;
        loading.value = true;
        if (activeTab.value === 0) {
          await insertOrUpdate(chosenLayout.value.percentages);
        } else {
          const v$ = useVuelidate(customColumnRules.value, customColumnState);
          const success = await v$.value.$validate();

          if (!success) {
            error.value = v$;
            componentFieldErrorsKey.value += 1;
            return;
          }
          const percentages = customColumnSize.value
            .split('/')
            .map((part) => parseInt(part.trim().replace('%', ''), 10));
          await insertOrUpdate(percentages);
        }
      } catch (e) {
        await showToastError(t('errorMessages.GENERIC_ERROR'));
      } finally {
        loading.value = false;
      }
    };

    watch(chosenColumns, () => {
      // Reset layout on columns number change
      layoutIndex.value = 0;
      if (chosenColumns.value === 1) {
        activeTab.value = 0;
      }
      if (chosenColumns.value === currentColumnNumber.value && currentColumnSize.value) {
        customColumnSize.value = currentColumnSize.value;
      } else {
        customColumnSize.value = '';
      }
      previewCustomColumnSizeLabel.value = '';
      previewCustomPercentages.value = [];
      error.value = null;
      componentFieldErrorsKey.value += 1;
      currentPos.value = -1;
    });

    watch(() => customColumnSize.value, (newValue) => {
      if (newValue) {
        const percentages = newValue
          .split('/')
          .map((part) => parseInt(part.trim().replace('%', ''), 10));
        previewCustomPercentages.value = percentages;
        previewCustomColumnSizeLabel.value = customColumnSize.value;
      }
    });

    watch(() => customColumnSizeRef.value, () => {
      if (customColumnSizeRef.value) {
        InputMask({
          mask: customMask.value,
          showMaskOnHover: false,
          autoUnmask: false,
          jitMasking: false,
          greedy: false,
          tabThrough: true,
          noValuePatching: true,
          onKeyValidation: (key, result: any) => {
            if (result && 'pos' in result && result.pos >= 0) {
              const percentages = customColumnSize.value
                .split('/')
                .map((part) => parseInt(part.trim().replace('%', '').replace('_', '-1'), 10));

              if (currentPos.value === result.pos && percentages.filter((percentage) => percentage < 0).length === 1) {
                const toAutoCompleteIndex = percentages.findIndex((percentage) => percentage < 0);
                const remaining = 100 - percentages.filter((percentage) => percentage >= 0).reduce((acc, cur) => acc + cur, 0);
                percentages[toAutoCompleteIndex] = remaining;
                customColumnSize.value = percentages.map((percentage) => `${percentage}%`).join(' / ');
                customColumnSizeRef.value.$el.inputmask.setValue(customColumnSize.value);
              }
              currentPos.value = result.pos + 1;
            }
          },
        }).mask(customColumnSizeRef.value.$el);
      }
    }, { deep: true });

    const onShow = async () => {
      if (selectedColumnId.value) {
        const template = getTemplateIframeDocument();
        if (!template) return;
        const column = template.querySelector(selectedColumnId.value) as HTMLElement;
        if (!column) return;
        // Get siblings columns
        const { parentElement } = column;
        if (!parentElement) return;
        const siblings = Array.from(parentElement.children) as HTMLElement[];
        const columns = siblings.filter((sibling) => sibling.id.startsWith('spm_column'));
        availableColumns.value = availableColumns.value.map((availableColumn) => ({
          ...availableColumn,
          disabled: availableColumn.value < columns.length,
        }));
        chosenColumns.value = columns.length;

        await nextTick();

        // Get percentages of columns
        const columnsPercentages = columns.map((sibling) => {
          const { width } = sibling.style;
          const percentage = width.match(/([-+]?[0-9]*\.?[0-9]+)(%)/);
          if (percentage) {
            return {
              id: sibling.id,
              percentage: parseInt(percentage[1], 0),
            };
          }
          return {
            id: sibling.id,
            percentage: 0,
          };
        });
        columnsToUpdate.value = columnsPercentages;

        // Set selected column number
        currentColumnNumber.value = columns.length;

        // Set selected column index
        selectedColumnIndex.value = columns.findIndex((sibling) => `#${sibling.id}` === selectedColumnId.value);

        // Check if current percentages is in the predifined layouts and select it or use the custom tab
        let index = -1;
        availableColumnsLayouts[chosenColumns.value - 1].forEach((layout, i) => {
          if (layout.percentages.join('') === columnsPercentages.map((item) => item.percentage).join('')) {
            index = i;
          }
        });
        if (index > -1) {
          activeTab.value = 0;
          layoutIndex.value = index;
        } else {
          activeTab.value = 1;
          customColumnSize.value = columnsPercentages.map((item) => `${item.percentage}%`).join(' / ');
          currentColumnSize.value = customColumnSize.value;
        }
      }
    };

    return {
      t,
      displayModal,
      handleClose,
      handleValidation,
      chosenColumns,
      availableColumns,
      columnsLayout,
      layoutIndex,
      isDisplay,
      activeTab,
      customMask,
      customMaskLabel,
      customColumnSize,
      componentFieldErrorsKey,
      error,
      previewCustomColumnSizeLabel,
      previewCustomPercentages,
      loading,
      onShow,
      dialogHeader,
      selectedColumnIndex,
      customColumnSizeRef,
      currentColumnNumber,
    };
  },
});
