<template>
  <div class="flex justify-content-between flex-wrap">
    <span class="toggle-settings-label">{{ t(label) }}</span>
    <InputSwitch
      v-model="displaySettings"
      v-tooltip="t(tooltipLabel)"
      @change="handleChange"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  PropType,
  computed,
} from 'vue';

import { useStore } from '@/store';

import InputSwitch from 'primevue/inputswitch';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import { ParserTabItemObject, TemplateStructureEnum } from '@/types';

export default defineComponent({
  name: 'ToggleMobileSettings',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    InputSwitch,
  },

  props: {
    tabItemLabel: {
      type: String,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserTabItemObject>,
      required: true,
    },

    fieldGroupLabel: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    tooltipLabel: {
      type: String,
      required: true,
    },

    headerTitle: {
      type: String,
      required: false,
      default: '',
    },

    isSynchronizedPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: {
    'toggle-settings': Object,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const displaySettings: Ref<boolean> = ref(false);
    const isMounted = ref(false); // Variable de contrôle
    const selectedStructure = computed(() => store.getters['liveEditor/getSelectedStructure']);
    const selectedSyncElement = computed(() => store.getters['liveEditor/getSelectedSyncElement']);

    const handleChange = async () => {
      const acceptCallback = async () => {
        const toggleHandler = props.parserValues.toggleItemsDisplay?.[props.fieldGroupLabel].toggleHandler;
        if (toggleHandler && selectedStructure.value) {
          await toggleHandler(`#${selectedStructure.value.identifier}`, displaySettings.value);
        }
        emit('toggle-settings', {
          tabItemLabel: props.tabItemLabel,
          fieldGroupLabel: props.fieldGroupLabel,
          value: displaySettings.value,
        });
      };

      if (props.isSynchronizedPage
          && !selectedSyncElement.value
          && selectedStructure.value
          && selectedStructure.value.type === TemplateStructureEnum.PAGE
      ) {
        store.dispatch('liveEditor/showSyncElementWarning', {
          elementId: selectedStructure.value.identifier,
          elementType: TemplateStructureEnum.DESIGN,
          name: props.headerTitle,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          acceptCallback,
          rejectCallback: () => {
            displaySettings.value = !displaySettings.value;
          },
        });
      } else {
        await acceptCallback();
      }
    };

    onMounted(() => {
      isMounted.value = true; // Le composant est monté
      displaySettings.value = props.parserValues.toggleItemsDisplay?.[props.fieldGroupLabel].value ?? false;
    });

    return {
      t,
      displaySettings,

      handleChange,
    };
  },
});
</script>

<style scoped lang="scss">
.toggle-settings-label {
  font-size: 0.95rem;
}

.p-inputswitch {
  width: 32px;
  height: 18px;

  &:not(.p-disabled):hover:deep() .p-inputswitch-slider {
    background: #ced4da;
  }

  &:deep() .p-inputswitch-slider {
    outline: none !important;
    box-shadow: none !important;

    &::before {
      background: #ffffff;
      width: 0.9rem;
      height: 0.9rem;
      left: 0.1rem;
      transform: translateY(-50%);
      border-radius: 50%;
      transition-duration: 0.2s;
      margin-top: 0;
    }
  }

  &.p-inputswitch-checked:deep()  .p-inputswitch-slider {
    background: $brand-color-primary !important;

    &::before {
      transform: translate(0.85rem, -50%);
    }
  }
}
</style>
