import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  ComponentNameEnum,
  TextAlignValueEnum,
  ActionTypeEnum,
  SocialNetworkIconStyleEnum,
  StepperSignEnum,
  TemplateStructureEnum,
  Property,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import {
  setAttribute,
  setClass,
  setContent,
  setCssProperty,
  sortFields,
  setFieldLabels,
  setFields,
  setMediaDisplay,
  setMessage,
  setSocialNetworkIconStyle,
  setSocialNetworkIconWidth,
  toggleClass,
  recalculateLineHeightValue,
  setCssPropertyAndRemoveIfZero, setCssButtonWidthProperty,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getAttributeByName,
  getClassByName,
  getContent,
  getCssPropertyByName,
  getDecodedDataRedirect,
  getFieldLabels,
  getFields,
  getMessage,
  getSocialNetworkIconStyle,
  hasClass,
  isVisible,
} from '@/components/template-builder/getters';
import iconSizeFieldConfig from '@/components/template-builder/config/fields/icon-size-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getSpmSocialIconsFieldConfig from '@/components/template-builder/config/fields/spm-social-icons-config';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';
import getTextAlignFieldConfig from '../fields/text-align-config';
import fontSizeFieldConfig from '../fields/font-size-config';
import getColorFieldConfig from '../fields/color-config';
import textStylesFieldConfig from '../fields/text-styles-config';
import getFormFieldsFieldConfig from '../fields/form-fields-config';
import inputGroupFieldConfig from '../fields/input-group-config';
import getInputVariablesFieldConfig from '../fields/input-variables-config';
// eslint-disable-next-line import/no-cycle
import getActionTypeFieldConfig from '../fields/action-type-config';
import getBorderRadiusFieldConfig from '../fields/border-radius-config';
import bordersFieldConfig from '../fields/borders-config';
import getWidthFieldConfig from '../fields/width-config';
import getElementAlignFieldConfig from '../fields/element-align-config';
import mediaDisplayFieldConfig from '../fields/media-display-config';
import getSpmIconsFieldConfig from '../fields/spm-icons-config';
import getStepperFieldConfig from '../fields/stepper-config';
import getDisplayFieldConfig from '../fields/display-config';

const widgetNewsletterSubscriptionConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.newsletterSubscription',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.formFields',
            selector: '{SELECTOR} .spm_form .spm_form_field:not(.spm_form_checkbox)',
            properties: [
              {
                name: 'displayedElements',
                getter: getFields,
                setters: [setFields],
                refreshWidgetFields: true,
              },
            ],
            ...getFormFieldsFieldConfig(
              [],
              ['spm_form_registration_email', 'spm_form_registration_password'],
            ),
          },
          {
            label: 'templateBuilder.fields.fieldLabels',
            selector: '{SELECTOR} .spm_form .spm_form_field:not(.spm_form_checkbox)',
            properties: [{ name: 'fieldLabels', getter: getFieldLabels, setters: [setFieldLabels, sortFields] }],
            ...inputGroupFieldConfig,
            options: {
              ...inputGroupFieldConfig.options,
              draggable: true,
            },
          },
          {
            label: 'templateBuilder.fields.displayInline',
            selector: '{SELECTOR} .spm_form_newsletter',
            properties: [{
              name: 'spm_form_inline', getter: hasClass, setters: [toggleClass],
            }],
            ...getDisplayFieldConfig('spm_form_inline'),
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.displayLabelInside',
            selector: '{SELECTOR} .spm_form_newsletter',
            properties: [{
              name: 'spm_label_inside', getter: hasClass, setters: [toggleClass],
            }],
            ...getDisplayFieldConfig('spm_label_inside'),
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.enableCustomerAccountCreation',
            selector: '{SELECTOR} .spm_form_newsletter',
            properties: [{
              name: 'spm_form_no_create_account', getter: hasClass, setters: [toggleClass],
            }],
            ...getDisplayFieldConfig('spm_form_no_create_account', '', 'true', 'false'),
            displayToggle: false,
            fieldId: 'enableCustomerAccountCreation',
            enableValue: 'false',
          },
          {
            label: 'templateBuilder.fields.createCustomerAccountText',
            selector: '{SELECTOR} .spm_form_newsletter .spm_form_create_account_field.spm_form_checkbox label',
            properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
            ...getInputVariablesFieldConfig('', [], false, false),
            parentFieldId: 'enableCustomerAccountCreation',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.buttonText',
            selector: '{SELECTOR} .spm_form_newsletter .spm_form_button a',
            properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
            ...getInputVariablesFieldConfig('', variables.allVariables, false, true),
          },
          {
            label: 'templateBuilder.fields.facebookRegistrationButton',
            selector: '{SELECTOR} .spm_form_newsletter .spm_form_other_buttons',
            properties: [{
              name: 'spm_hidden', getter: hasClass, setters: [toggleClass],
            }],
            ...getDisplayFieldConfig('spm_hidden', 'true', 'true', 'false'),
            displayToggle: false,
            fieldId: 'facebookRegistrationButton',
            enableValue: 'false',
          },
          {
            label: 'templateBuilder.fields.facebookRegistrationText',
            selector: '{SELECTOR} .spm_form_newsletter .spm_form_other_buttons label',
            properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
            ...getInputVariablesFieldConfig('', [], false, false),
            parentFieldId: 'facebookRegistrationButton',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.action',
            selector: '{SELECTOR} .spm_form',
            properties: [
              { name: 'spm_action_', getter: getClassByName, setters: [setClass] },
              { name: 'data-redirect', getter: getDecodedDataRedirect, setters: [setAttribute] },
              { name: 'displayMessage', getter: getMessage, setters: [setMessage] },
            ],
            ...getActionTypeFieldConfig(ActionTypeEnum.DISPLAY_MESSAGE),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.widgetStyles',
        scrollable: true,
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.labelStyles',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.alignment',
                  selector: '{SELECTOR} .spm_form_field label, {SELECTOR} .spm_form_other_buttons',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
                },
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} .spm_form_field label, {SELECTOR} .spm_form_other_buttons label',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.lineInnerMargins',
                  selector: '{SELECTOR} .spm_form_field label, {SELECTOR} .spm_form_button label, {SELECTOR} .spm_form_other_buttons label',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('padding').options,
                    ...getHorizontalSpacesFieldConfig('padding').options,
                  },
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.fieldStyles',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.backgroundColor',
                  selector: '{SELECTOR} .spm_form_field .spm_form_input',
                  properties: [{
                    name: 'background',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getColorFieldConfig('#FFFFFF', false, true),
                },
                {
                  label: 'templateBuilder.fields.fieldContours',
                  selector: '{SELECTOR} .spm_form_field .spm_form_input',
                  properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getBorderRadiusFieldConfig('5px'),
                },
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} .spm_form_field .spm_form_input',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.borders',
                  selector: '{SELECTOR} .spm_form_field .spm_form_input',
                  properties: [
                    { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-style', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
                  ],
                  ...bordersFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.internalMargins',
                  selector: '{SELECTOR} .spm_form_field .spm_form_input',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('padding').options,
                    ...getHorizontalSpacesFieldConfig('padding').options,
                  },
                },
                {
                  label: 'templateBuilder.fields.externalMargins',
                  selector: '{SELECTOR} .spm_form_field .spm_form_input',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('margin').options,
                  },
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.formButtonStyles',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.buttonWidth',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [{
                    name: 'width',
                    getter: getCssPropertyByName,
                    setters: [setCssButtonWidthProperty],
                  }],
                  ...getButtonWidthFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.buttonAlignment',
                  selector: '{SELECTOR} .spm_form_button .spm_button',
                  properties: [{ name: 'spm_div_align_', getter: getClassByName, setters: [setClass] }],
                  ...getElementAlignFieldConfig('spm_div_align_'),
                },
                {
                  label: 'templateBuilder.fields.contours',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getBorderRadiusFieldConfig('5px'),
                },
                {
                  label: 'templateBuilder.fields.borders',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [
                    { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-style', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
                  ],
                  ...bordersFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonBackgroundColor',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [{
                    name: 'background',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getColorFieldConfig('#ECECEC', false, true),
                },
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.contentAlignment',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.internalMargins',
                  selector: '{SELECTOR} .spm_form_button .spm_button, {SELECTOR} .spm_form_button .spm_button:visited, {SELECTOR} .spm_form_button .spm_button:active',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('padding').options,
                    ...getHorizontalSpacesFieldConfig('padding').options,
                  },
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.buttonHoverStyles',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.buttonHoverBackgroundColor',
                  selector: '{SELECTOR} .spm_form_button a.spm_button:hover',
                  properties: [{
                    name: 'background',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getColorFieldConfig('#ECECEC', false, true),
                },
                {
                  label: 'templateBuilder.fields.buttonHoverTextColor',
                  selector: '{SELECTOR} .spm_form_button a.spm_button:hover',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#000000'),
                },
                {
                  label: 'templateBuilder.fields.buttonHoverFontSize',
                  selector: '{SELECTOR} .spm_form_button a.spm_button:hover',
                  properties: [{ name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] }],
                  ...fontSizeFieldConfig,
                  options: {
                    ...fontSizeFieldConfig.options,
                    px: {
                      ...fontSizeFieldConfig.options.px,
                      defaultValue: 21,
                    },
                  },
                },
                {
                  label: 'templateBuilder.fields.buttonHoverTextStyles',
                  selector: '{SELECTOR} .spm_form_button .spm_button:hover',
                  properties: [
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...textStylesFieldConfig,
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.socialNetworkButtonStyles',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.iconSize.label',
                  selector: '{SELECTOR} .spm_social_icons img',
                  properties: [
                    {
                      name: 'width', getter: getAttributeByName, setters: [setAttribute, setSocialNetworkIconWidth],
                    },
                  ],
                  ...iconSizeFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.iconStyle',
                  selector: '{SELECTOR} .spm_social_icons img',
                  properties: [{ name: 'iconStyle', getter: getSocialNetworkIconStyle, setters: [setSocialNetworkIconStyle] }],
                  ...getSpmSocialIconsFieldConfig(
                    Object.values(SocialNetworkIconStyleEnum),
                    SocialNetworkIconStyleEnum.STYLE_1,
                  ),
                },
                {
                  label: 'templateBuilder.fields.externalMargins',
                  selector: '{SELECTOR} .spm_form_other_buttons',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('margin').options,
                  },
                },
                {
                  label: 'templateBuilder.fields.internalMargins',
                  selector: '{SELECTOR} .spm_form_other_buttons .spm_social_icon_container',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('padding').options,
                    ...getHorizontalSpacesFieldConfig('padding').options,
                  },
                },
              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.background',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, false, true),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.marginsAndBorders',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: 'body #spm_body #{ID}',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.bordersCentralArea',
            selector: 'body #spm_body #{ID}',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.media',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetNewsletterSubscriptionConfig;
