<template>
  <div
    v-if="tabItem.tabPanel.label && displayLabel"
    class="tab-panel-label"
  >
    {{ t(tabItem.tabPanel.label) }}
  </div>
  <ul v-if="tabItem.tabPanel.items">
    <template
      v-for="(fieldGroup, fieldGroupIndex) of tabItem.tabPanel.items"
      :key="fieldGroup.id"
    >
      <ConfigurationPanelTabContentItem
        :tab-item="tabItem"
        :parser-values="parserValues"
        :field-group="fieldGroup"
        :field-group-index="fieldGroupIndex"
        :active-field-group-index="activeFieldGroupIndex"
        :is-synchronized-page="isSynchronizedPage"
        :header-title="headerTitle"
        @refresh="handleRefresh"
        @toggle-field-group="toggleFieldGroup"
      />
    </template>
  </ul>
</template>

<script lang="ts">
import {
  defineComponent, onMounted,
  PropType, Ref,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  TabItem,
  TabSubitem,
  ParserObject,
} from '@/types';

import ConfigurationPanelTabContentItem from '@/components/template-builder/panels/ConfigurationPanelTabContentItem.vue';

export default defineComponent({
  name: 'ConfigurationPanelTabContent',

  components: {
    ConfigurationPanelTabContentItem,
  },

  props: {
    tabItem: {
      type: Object as PropType<TabItem | TabSubitem>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },

    headerTitle: {
      type: String,
      required: false,
      default: '',
    },

    isSynchronizedPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['refresh'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const activeFieldGroupIndex: Ref<number[]> = ref([]);
    const toggleFieldGroup = (index: number) => {
      if (activeFieldGroupIndex.value.includes(index)) {
        activeFieldGroupIndex.value.splice(activeFieldGroupIndex.value.indexOf(index), 1);
      } else {
        activeFieldGroupIndex.value.push(index);
      }
    };

    const handleRefresh = () => {
      emit('refresh');
    };

    watch([
      () => props.parserValues,
      () => props.tabItem,
    ],
    () => {
      if (props.tabItem.tabPanel) {
        activeFieldGroupIndex.value = props.tabItem.tabPanel.items.reduce((acc: number[], item, index) => {
          acc.push(index);
          return acc;
        }, []);
      }
    });

    onMounted(() => {
      if (props.tabItem.tabPanel) {
        activeFieldGroupIndex.value = props.tabItem.tabPanel.items.reduce((acc: number[], item, index) => {
          if (index === 0) {
            acc.push(index);
          }
          return acc;
        }, []);
      }
    });

    return {
      activeFieldGroupIndex,
      toggleFieldGroup,
      handleRefresh,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
.tab-panel-label {
  font-size: 1.2rem;
  font-weight: 800;
  color: $montana;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}
</style>
