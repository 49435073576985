<template>
  <div class="sync-element-used-templates">
    <div class="sync-element-used-templates__description text-left mb-3">
      <slot name="description">
        {{ description }}
      </slot>
    </div>
    <slot>
      <div class="progress-spinner__wrapper flex justify-content-center">
        <ProgressSpinner
          style="width: 50px; height: 50px"
          stroke-width="8"
          fill="var(--surface-ground)"
          animation-duration="1.0s"
          aria-label="Custom ProgressSpinner"
          :class="{ block: loadingPreview, hidden: !loadingPreview }"
        />
      </div>
      <Dialog
        v-model:visible="visibleTemplatePreview"
        modal
        :header="t('templateBuilder.templatePreview')"
      >
        <img
          :src="urlTemplateToPreview"
          alt="Loading"
        >
      </Dialog>
      <div
        v-if="!loadingPreview"
        class="sync-element-used-templates__list"
      >
        <SpmTable
          key="sync-element-used-templates"
          v-model="templates"
          :table-columns="columns"
          :paginator="false"
        >
          <template #label="slotProps">
            <i
              class="fa-regular fa-magnifying-glass mr-2"
              style="cursor: pointer;"
              @click="(event) => preview(event, slotProps.data.id_template, slotProps.data.type)"
            />
            <span class="font-bold capitalize">
              {{ slotProps.data[slotProps.column.props.field] }}
            </span>
          </template>
          <template #type="slotProps">
            {{ t(`templateSelector.dialogTypeTemplate.types.${slotProps.data[slotProps.column.props.field]}.label`) }}
          </template>
        </SpmTable>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
} from 'vue';

import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import SpmTable from '@/components/table/SpmTable.vue';

import { useI18n } from 'vue-i18n';

import { SpmTableState, SpmTableColumns, SpmTableFilterOption } from '@/types';

import { TemplateTypeEnum } from '@/composables/shop/Templates';
import { TemplateEditorState as state } from '@/composables/template-editor/TemplateEditor';

import getTemplatesUsedBySyncElement from '@/composables/template/SyncElement';
import { loadImageUntilSuccess } from '@/composables/loadImage';
import { isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import CryptoJS from 'crypto-js';
import { TEMPLATE_SYNC_ELEMENT_IDENTIFIER, TEMPLATE_SYNC_ELEMENT_IDs } from './utils/constants';

export default defineComponent({
  name: 'SyncElementUsedTemplates',

  components: {
    ProgressSpinner,
    SpmTable,
    Dialog,
  },

  props: {
    syncElementId: {
      type: String,
      required: true,
    },

    idShop: {
      type: Number,
      required: false,
      default: 0,
    },

    description: {
      type: String,
      required: false,
      default: 'Used templates with sync element',
    },
  },

  emits: ['loaded'],

  setup(props: { idShop: number; syncElementId: string }, { emit }) {
    const { t } = useI18n();
    const templates = ref<SpmTableState>({
      items: [],
      error: '',
      total: 0,
    });

    const loadingPreview = ref(false);
    const baseUrl = `${process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX_WITH_RESIZE}`;
    const urlTemplateToPreview = ref('');
    const visibleTemplatePreview = ref(false);

    const typeOptions: SpmTableFilterOption[] = Object.values(TemplateTypeEnum)
      .map((key: string) => ({ value: key, label: t(`templateSelector.dialogTypeTemplate.types.${key}.label`) }));

    const columns: SpmTableColumns[] = [
      {
        field: 'id_template',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: 'display:none',
        type: 'preview',
      },
      {
        field: 'label',
        header: t('templates.name'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'type',
        header: t('templates.type'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: typeOptions, hideFilterMenu: true },
      },
    ];

    const preview = async (event: Event, idTemplate: number, typeTemplate: any) => {
      event.stopPropagation();
      const targetElement = event.target as HTMLElement;
      targetElement.classList.remove('fa-magnifying-glass');
      targetElement.classList.add('fa-spinner', 'fa-spin');

      urlTemplateToPreview.value = '';
      const cryptedImageKey = CryptoJS.SHA1(`salt-${idTemplate}`).toString();

      const potentialImageUrl = `${baseUrl + cryptedImageKey}${isDisplayTemplate(typeTemplate) ? '-isdisplay' : ''}.png&w=400&${new Date().getTime()}`;
      try {
        await loadImageUntilSuccess(potentialImageUrl);
        urlTemplateToPreview.value = potentialImageUrl;
        visibleTemplatePreview.value = true;
      } catch (error) {
        console.error("Erreur lors du chargement de l'image", error);
      }

      targetElement.classList.remove('fa-spinner', 'fa-spin');
      targetElement.classList.add('fa-magnifying-glass');
    };

    onMounted(async () => {
      loadingPreview.value = true;
      templates.value = await getTemplatesUsedBySyncElement({ idShop: props.idShop, syncElementId: props.syncElementId });
      const templatesInState = state.templates.filter((template) => {
        const section = template.sections.find((item) => {
          const data = JSON.parse(item.data || '{}');
          if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] && data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER] === props.syncElementId) {
            return true;
          }
          if (data[TEMPLATE_SYNC_ELEMENT_IDs] && data[TEMPLATE_SYNC_ELEMENT_IDs].find((syncId: string) => syncId === props.syncElementId)) {
            return true;
          }
          return false;
        });
        if (section) return true;
        return false;
      }).map((template) => ({
        id_template: template.id,
        label: template.informations.name,
        type: template.type,
      }));
      templatesInState.forEach((template) => {
        if (!templates.value.items.find((item: any) => item.id_template.toString() === template.id_template.toString())) {
          templates.value.items.push(template);
          templates.value.total += 1;
        }
      });
      loadingPreview.value = false;
      emit('loaded', templates.value.total);
    });

    return {
      t,
      templates,
      columns,
      loadingPreview,
      preview,
      urlTemplateToPreview,
      visibleTemplatePreview,
    };
  },
});
</script>
