// eslint-disable-next-line import/no-cycle
import { store } from '@/store';
import {
  ActiveLiveEditorItemData,
  HistoryType,
  TemplateStructureEnum,
  Translation,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  addNewSectionToState,
  addNewSyncElementToSection,
  addTranslationsToState,
  hideLeftToolbar,
  refreshSectionsConfig,
  refreshTranslations,
  resetActiveSection,
  resetIframe,
  restoreSmartListsOriginalCode,
  setActiveSection,
  TemplateEditorState, updateSectionsInState,
} from '@/composables/template-editor/TemplateEditor';
import {
  asInt,
  br2nl, getParents, nl2br, showToastError,
} from '@/helpers';
// eslint-disable-next-line import/no-cycle
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import { Maybe } from '@/types/generated-types/graphql';
import { getShopDefaultLang, Lang, UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import {
  addTranslation,
  createNewStylesheet,
  decorateElementWithStyles,
  generateUniqStructureId,
  getCssOfElement,
  getElementFromIframe,
  getElementResetStylesByType,
  getSectionParent,
  getTemplateIframeDocument,
  isDisplayTemplate,
  isEmailTemplate,
  reconstructDynamicPersistentMobileCss,
} from './helpers';
import {
  ACTIONS_BUTTON_GROUP,
  BUILDER_ELEMENT_ACTIVE_CLASS,
  BUILDER_ELEMENT_HOVER_CLASS,
  CUSTOM_WIDGET_CSS_PREFIX,
  MAX_COLUMN_COUNT,
  NEW_SECTION_ID_PREFIX,
  TEMPLATE_COLUMN_IDENTIFIER_PREFIX,
  TEMPLATE_ITEM_IDENTIFIER_PREFIX_MAP,
  TEMPLATE_LINE_CLASS,
  TEMPLATE_LINE_IDENTIFIER_PREFIX,
  TEMPLATE_SECTION_CLASS,
  TEMPLATE_SECTION_CONTENT_ID_SUFFIX,
  TEMPLATE_SECTION_CONTENT_IDENTIFIER,
  TEMPLATE_SECTION_IDENTIFIER_PREFIX,
  TEMPLATE_STRUCTURE_MAP,
  TEMPLATE_SYNC_ELEMENT_CLASS,
  TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
  TEMPLATE_SYNC_ELEMENT_NAME,
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
  TRANSLATION_ATTRIBUTE,
  WIDGET_DROPPABLE_CLASS,
} from './constants';
import { hoverResetStyles } from './elements-style-definitions';
// eslint-disable-next-line import/no-cycle
import { resetListeners } from './listeners';
// eslint-disable-next-line import/no-cycle
import { addOrUpdateDynamicStyle, getDynamicStylesForSelector } from './parser';

const removeChildActionsButtonGroup = (parentElement: HTMLElement) => {
  const actionsButtonGroup = parentElement.querySelectorAll(`.${ACTIONS_BUTTON_GROUP}`);
  (actionsButtonGroup ?? []).forEach((element) => {
    try {
      parentElement.removeChild(element);
    } catch (e) {
      // Do nothing
    }
  });
};

const setClonedSectionContentAttributes = (section: HTMLElement) => {
  const sectionContent = section.querySelector(TEMPLATE_SECTION_CONTENT_IDENTIFIER) as HTMLElement;
  if (sectionContent) {
    const styles = getDynamicStylesForSelector(`#${sectionContent.id}`);
    decorateElementWithStyles(sectionContent, hoverResetStyles);
    addOrUpdateDynamicStyle(`#${sectionContent.id}`, styles);
  }
};

const changeChildItemsId = (parentElement: HTMLElement, itemType: TemplateStructureEnum): { [key: string]: string } => {
  const idMapping: { [key: string]: string } = {};

  Array.from(parentElement.querySelectorAll(TEMPLATE_STRUCTURE_MAP[itemType])).forEach((item) => {
    const oldId = item.id;
    // eslint-disable-next-line max-len
    const newId = `${TEMPLATE_ITEM_IDENTIFIER_PREFIX_MAP[itemType]}${generateUniqStructureId()}${itemType === TemplateStructureEnum.SECTION_CONTENT ? TEMPLATE_SECTION_CONTENT_ID_SUFFIX : ''}`;
    idMapping[oldId] = newId;

    // eslint-disable-next-line no-param-reassign
    item.id = newId;

    // For customWidget
    if (item.hasAttribute('data-widgetid')) {
      item.setAttribute('data-widgetid', newId);
    }
  });

  return idMapping;
};

const setChildColumnsWidth = (columnList: HTMLElement[]) => {
  const columnWidth = 100 / columnList.length;

  columnList.forEach((column: HTMLElement) => {
    // eslint-disable-next-line no-param-reassign
    column.style.width = `${columnWidth}%`;
  });
};

/**
 * Duplicate element, generate new ID and apply reset styles
 * @param type
 * @param element
 */
const duplicateHtmlOfElement = (type: TemplateStructureEnum, element: HTMLElement): HTMLElement => {
  let prefix;
  let resetStyles = {};

  // Create new element
  const clonedElement = element.cloneNode(true) as HTMLElement;

  switch (type) {
    case TemplateStructureEnum.SECTION:
      prefix = TEMPLATE_SECTION_IDENTIFIER_PREFIX;
      resetStyles = {
        ...hoverResetStyles,
      };
      break;
    case TemplateStructureEnum.LINE:
      prefix = TEMPLATE_LINE_IDENTIFIER_PREFIX;
      break;
    case TemplateStructureEnum.COLUMN:
      prefix = `${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}_`;
      break;
    case TemplateStructureEnum.WIDGET:
      prefix = TEMPLATE_WIDGET_IDENTIFIER_PREFIX;
      resetStyles = {
        ...hoverResetStyles,
      };
      break;
    default:
      prefix = TEMPLATE_WIDGET_IDENTIFIER_PREFIX;
      resetStyles = {
        ...hoverResetStyles,
      };
      break;
  }

  // Change element ID
  clonedElement.id = `${prefix}${generateUniqStructureId()}`;

  if (type === TemplateStructureEnum.SECTION) {
    const tmpElementId = `${NEW_SECTION_ID_PREFIX}${generateUniqStructureId()}`;
    // We remove useless data attributes and add a temporary template_element_id
    clonedElement.removeAttribute('data-spm_element_hash');
    clonedElement.removeAttribute('data-spmelementid');
    clonedElement.removeAttribute('data-spm_element_sync');
    clonedElement.setAttribute('data-spmelementid', tmpElementId);

    // We set the section as the active section in the state
    setActiveSection(tmpElementId);
  }

  // Apply reset CSS for builder CSS
  const resetActiveItemStyles = getElementResetStylesByType(type);
  decorateElementWithStyles(clonedElement, {
    ...resetActiveItemStyles,
    ...resetStyles,
  });

  return clonedElement;
};

/**
 * Duplicate all CSS rules of an element and set the new element ID
 * @param sectionId
 * @param oldElementId
 * @param newElementId
 * @param cssString
 */
const duplicateCssOfElement = (
  sectionId: string,
  oldElementId: string,
  newElementId: string,
  cssString: Maybe<string> = null,
  customCss: Maybe<Record<string, any>> = null,
) => {
  const createStylesheet = (type = 'dynamic', customCssString = '') => {
    // Create CSS stylesheet if doesn't exist
    let dynamicStylesheet = getElementFromIframe(`style[data-spm-styles="${type}"][data-spm-section="${sectionId}"]`);

    if (!dynamicStylesheet) {
      // Create dynamic stylesheet if it doesn't exist
      dynamicStylesheet = createNewStylesheet(type, sectionId);
    }

    // Get all CSS of element
    let dynamicStylesOfOldElement = cssString ?? getCssOfElement(oldElementId);
    if (customCssString) {
      dynamicStylesOfOldElement = customCssString;
    }

    // Replace widget ID in CSS
    const reg = new RegExp(`${oldElementId}(,?(\\.[a-zA-Z0-9_,-]*)? )`, 'gm');
    const matches = dynamicStylesOfOldElement.match(reg);
    const finalDynamicStyles = dynamicStylesOfOldElement.replace(reg, `${newElementId}$1`);

    // Append new CSS in dynamic stylesheet
    if (dynamicStylesheet && finalDynamicStyles) {
      dynamicStylesheet.append(finalDynamicStyles);
    }
  };

  if (!customCss) {
    createStylesheet();
  } else {
    Object.keys(customCss).forEach((key) => {
      createStylesheet(key, customCss[key]);
    });
  }
};

/**
 * Duplicate and change keys of all translation keys inside an element (section/row/column/widget)
 * @param element
 * @param translations
 * @param sectionId
 */
const duplicateTranslationsOfElement = (element: HTMLElement, translations: Maybe<Record<string, any>> = null, sectionId: Maybe<string> = null) => {
  const regExpTranslations = new RegExp('LANG_[a-zA-Z0-9.]+', 'g');

  // Wrap element to get complete HTML
  const wrap = document.createElement('div');
  wrap.appendChild(element.cloneNode(true));

  // Matches all translation keys inside element
  const matches = wrap.innerHTML.matchAll(regExpTranslations);

  if (matches) {
    const keyMapping: any = {}; // Store the mapping of old and new keys
    let translationToAddToState: Translation[] = [];
    const clonedTranslations = translations ? { ...translations } : null; // Clone translations of element if exists
    const shopLanguages = UserState.activeShop?.langs.map((language: Lang) => language.id) ?? [];

    if (clonedTranslations) {
      // Create translations for missing languages if any
      const availableLanguagesInTranslations = Object.keys(clonedTranslations);
      const defaultLanguage = getShopDefaultLang();
      const missingLanguages = shopLanguages.filter((lang) => !availableLanguagesInTranslations.includes(lang));

      if (missingLanguages.length > 0) {
        // We have missing languages in element's translations, we copy default language, or at least the first available language
        const languageToCopy = availableLanguagesInTranslations.includes(defaultLanguage) ? defaultLanguage : availableLanguagesInTranslations[0];

        missingLanguages.forEach((missingLanguage) => {
          clonedTranslations[missingLanguage] = clonedTranslations[languageToCopy];
        });
      }
    }

    // Loop on each translation key to generate a new one and store in the state
    Array.from(matches, (m) => m[0]).forEach((match) => {
      const key = match.split('.')[0].replace('LANG_', '');

      // Generate a new key in the mapping
      if (!Object.getOwnPropertyDescriptor(keyMapping, key)) {
        keyMapping[key] = generateUniqStructureId();
      }

      if (clonedTranslations) {
        // Change keys in translations object according to new keys, and store them in state
        Object.keys(clonedTranslations).forEach((language: string) => {
          clonedTranslations[language][keyMapping[key]] = clonedTranslations[language][key];
          translationToAddToState.push(
            ...(addTranslation(clonedTranslations[language], keyMapping[key], sectionId ?? '', language, TemplateEditorState.template?.type ?? 'email') ?? []),
          );
        });
      } else {
        // Get the translations matching the current key
        const translationsInState = TemplateEditorState.template?.translations.filter((trl: Translation) => trl.key === match.replace('LANG_', '')) ?? [];

        // Create new translations based on new keys
        translationsInState.forEach((trl: Translation) => {
          const newTranslation = { ...trl };
          const currentKey = newTranslation.key.split('.');
          currentKey[0] = keyMapping[key];
          newTranslation.key = currentKey.join('.');

          if (sectionId) {
            newTranslation.section = sectionId;
          }

          translationToAddToState.push(newTranslation);
        });
      }

      // eslint-disable-next-line no-param-reassign
      element.innerHTML = element.innerHTML.replace(key, keyMapping[key]);
    });

    // We search for translations in HTML not in translations object
    const regExpMissingTranslationsAttributes = new RegExp(`${TRANSLATION_ATTRIBUTE}-([^=]+)="LANG_([^"]+)"`, 'g');
    const regExpMissingTranslationsText = new RegExp('{LANG_([^}]+)}', 'g');
    const matchesTranslationsAsAttributes = element.innerHTML.matchAll(regExpMissingTranslationsAttributes);
    const matchesTranslationsAsText = element.innerHTML.matchAll(regExpMissingTranslationsText);

    // Loop on matched translations as attributes
    Array.from(matchesTranslationsAsAttributes).forEach((match) => {
      const attribute = match[1];
      const key = match[2];
      const found = translationToAddToState.filter((translation) => translation.key === key);

      if (found.length === 0) {
        // Key is not in the array of translations to add to state
        const elementWithTranslation = element.querySelector(`[${TRANSLATION_ATTRIBUTE}-${match[1]}="LANG_${key}"]`);
        let valueOfTranslation = '';

        if (elementWithTranslation && elementWithTranslation.hasAttribute(`${match[1]}`)) {
          valueOfTranslation = elementWithTranslation.getAttribute(`${match[1]}`) ?? '';
        }

        let fieldType = 'text';

        switch (attribute) {
          case 'href':
            fieldType = 'link';
            break;
          case 'src':
            fieldType = 'image';
            break;
          case 'data-redirect':
          case 'placeholder':
            fieldType = attribute;
            break;
          default:
            break;
        }

        let callback = (value: string) => value;
        let translationValue = valueOfTranslation;
        if (valueOfTranslation.match(/^base64:/)) {
          callback = (value: string) => `base64:${btoa(value)}`;
          translationValue = atob(valueOfTranslation.replaceAll(/base64:/g, ''));
        }
        if (TemplateEditorState.template?.type === 'sms') {
          callback = (value: string) => nl2br(value, true);
          translationValue = br2nl(valueOfTranslation);
        }

        // Create translations for each language of current shop
        const translationsToAdd: Translation[] = UserState.activeShop?.langs.map((language: Lang) => ({
          section: sectionId ?? 0,
          language: language.id,
          fieldType,
          key,
          value: translationValue,
          callback,
        })) ?? [];

        // Merge with translations to add
        translationToAddToState = [...translationToAddToState, ...translationsToAdd];
      }
    });

    // Loop on matched translations as text
    Array.from(matchesTranslationsAsText).forEach((match) => {
      const key = match[1];
      const found = translationToAddToState.filter((translation) => translation.key === key);

      if (found.length === 0) {
        // Create empty translation because we don't know the value, for each language of current shop
        const translationsToAdd: Translation[] = UserState.activeShop?.langs.map((language: Lang) => ({
          section: sectionId ?? 0,
          language: language.id,
          fieldType: 'text',
          key,
          value: '',
          callback: (value: string) => value,
        })) ?? [];

        // Merge with translations to add
        translationToAddToState = [...translationToAddToState, ...translationsToAdd];
      }
    });

    if (translationToAddToState.length) {
      addTranslationsToState(translationToAddToState);
    }
  }
};

interface SyncElement {
  name: string;
  syncElementId: string;
}

/**
 * Duplicate an element (html/css/translations) and add listeners
 * @param activeItemData
 * @param type
 * @param element
 * @param cssString
 * @param position
 * @param translationsOfElement
 */
export const duplicateElement = async (
  activeItemData: ActiveLiveEditorItemData,
  type: TemplateStructureEnum,
  element: HTMLElement,
  cssString: Maybe<string> = null,
  position: Maybe<string> = null,
  translationsOfElement: Maybe<Record<string, any>> = null,
  customWidgetCss: Maybe<Record<string, any>> = null,
  syncElement: { name: string; syncElementId: string } | null = null,
  addHistory = true,
  toPlaceNextTo: Maybe<string> = null,
  replaceSectionId = '',
) => {
  if (!replaceSectionId) {
    await checkIfSavePointNeeded();
  }
  const parentSection = element.closest(`.${TEMPLATE_SECTION_CLASS}`);
  let parentSectionId = parentSection?.getAttribute('data-spmelementid') ?? '';
  const oldId = element.id;
  let childrenIds: { [key: string]: string } = {};
  let lines: HTMLElement[];
  let columnsList: HTMLElement[];

  // First, we need to reset the smart products lists original codes (to duplicate the real HTML code)
  restoreSmartListsOriginalCode();

  // Clone element and duplicate its CSS
  const clonedElement = duplicateHtmlOfElement(type, element);

  // Hide element and insert after the source element
  clonedElement.style.display = 'none';

  clonedElement.classList.remove(BUILDER_ELEMENT_ACTIVE_CLASS, BUILDER_ELEMENT_HOVER_CLASS);
  if (syncElement) {
    clonedElement.classList.add(TEMPLATE_SYNC_ELEMENT_CLASS);
    clonedElement.setAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER, syncElement.syncElementId);
    clonedElement.setAttribute(TEMPLATE_SYNC_ELEMENT_NAME, syncElement.name);
  }

  const template = getTemplateIframeDocument();
  const sourceElement = template.querySelector(activeItemData.selector);

  if (!sourceElement) {
    return;
  }

  if (toPlaceNextTo) {
    const toPlaceNextToElement = template.querySelector(toPlaceNextTo);
    if (toPlaceNextToElement) {
      toPlaceNextToElement.after(clonedElement);
    }
  } else if (!toPlaceNextTo && (!position || position === 'bottom')) {
    sourceElement.after(clonedElement);
  } else {
    sourceElement.before(clonedElement);
  }

  if (type === TemplateStructureEnum.SECTION) {
    parentSectionId = clonedElement.getAttribute('data-spmelementid') ?? '';
  }
  // Get parent section id when drag and drop saved element
  if (position && !parentSectionId) {
    let sectionClass = `.${TEMPLATE_SECTION_CLASS}`;
    if (isDisplayTemplate(TemplateEditorState.template?.type)) {
      sectionClass = '#spm_body';
    }
    const closestSection = sourceElement.closest(sectionClass);
    parentSectionId = closestSection?.getAttribute('data-spmelementid') ?? '';
  }

  if (type === TemplateStructureEnum.WIDGET) {
    const oldWidgetId = element.id.replace(TEMPLATE_WIDGET_IDENTIFIER_PREFIX, '');
    const newWidgetId = clonedElement.id.replace(TEMPLATE_WIDGET_IDENTIFIER_PREFIX, '');

    let customCss: Record<string, any> | null = null;
    if (getElementFromIframe(`style[data-spm-styles="${CUSTOM_WIDGET_CSS_PREFIX}${oldWidgetId}"]`)) {
      customCss = {};
      const customWidgetOldCss = getCssOfElement(element.id, `${CUSTOM_WIDGET_CSS_PREFIX}${oldWidgetId}`);
      if (customWidgetOldCss) {
        customCss[`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}`] = customWidgetOldCss;
      }
      const customWidgetOldMobileCss = getCssOfElement(element.id, `${CUSTOM_WIDGET_CSS_PREFIX}${oldWidgetId}_mobile`);
      if (customWidgetOldMobileCss) {
        customCss[`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}_mobile`] = customWidgetOldMobileCss;
      }
    }
    if (customWidgetCss) {
      customCss = {};
      if (customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${oldWidgetId}`]) {
        customCss[`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}`] = customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${oldWidgetId}`];
      }
      if (customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${oldWidgetId}_mobile`]) {
        customCss[`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}_mobile`] = customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${oldWidgetId}_mobile`];
      }
    }
    duplicateCssOfElement(parentSectionId, oldId, clonedElement.id, cssString, customCss);
  } else {
    duplicateCssOfElement(parentSectionId, oldId, clonedElement.id, cssString);
  }

  const customCssTypes: string[] = [];

  // Get children if any
  if ([TemplateStructureEnum.LINE, TemplateStructureEnum.COLUMN, TemplateStructureEnum.SECTION].includes(type)) {
    childrenIds = { ...childrenIds, ...changeChildItemsId(clonedElement, TemplateStructureEnum.SECTION_CONTENT) };
    childrenIds = { ...childrenIds, ...changeChildItemsId(clonedElement, TemplateStructureEnum.LINE) };
    childrenIds = { ...childrenIds, ...changeChildItemsId(clonedElement, TemplateStructureEnum.COLUMN) };
    childrenIds = { ...childrenIds, ...changeChildItemsId(clonedElement, TemplateStructureEnum.WIDGET) };

    // Duplicate CSS of all children
    Object.keys(childrenIds).forEach((key) => {
      const oldChildId = key;
      const newChildId: string = childrenIds[key];

      let customCss: Record<string, any> | null = null;

      if (customWidgetCss) {
        if (oldChildId.startsWith(TEMPLATE_WIDGET_IDENTIFIER_PREFIX)) {
          const widgetId = oldChildId.replace(TEMPLATE_WIDGET_IDENTIFIER_PREFIX, '');
          const newWidgetId = newChildId.replace(TEMPLATE_WIDGET_IDENTIFIER_PREFIX, '');
          customCss = {};
          if (customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${widgetId}`]) {
            customCss[`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}`] = customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${widgetId}`];
            customCssTypes.push(`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}`);
          }
          if (customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${widgetId}_mobile`]) {
            customCss[`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}_mobile`] = customWidgetCss[`${CUSTOM_WIDGET_CSS_PREFIX}${widgetId}_mobile`];
            customCssTypes.push(`${CUSTOM_WIDGET_CSS_PREFIX}${newWidgetId}_mobile`);
          }
        }
      }

      if (!customCss) {
        duplicateCssOfElement(parentSectionId, oldChildId, newChildId);
      } else {
        duplicateCssOfElement(parentSectionId, oldChildId, newChildId, null, customCss);
      }
    });

    // Clean CSS
    const dynamicStylesheet = getElementFromIframe(`style[data-spm-styles="dynamic"][data-spm-section="${parentSectionId}"]`);
    const dynamicMobileStylesheet = getElementFromIframe(`style[data-spm-styles="dynamic_persistent_mobile"][data-spm-section="${parentSectionId}"]`);

    const cleanCss = (stringCss: string) => {
      let newCss = stringCss;

      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(childrenIds)) {
        const regex = new RegExp(`[^}]*${key}[^}]*{[^}]*}\\s*`, 'g');
        newCss = newCss.replace(regex, '');
      }
      return newCss;
    };

    if (dynamicStylesheet) {
      const newCss = cleanCss(dynamicStylesheet.innerHTML);
      dynamicStylesheet.innerHTML = newCss;
    }
    if (dynamicMobileStylesheet) {
      const newCss = cleanCss(dynamicMobileStylesheet.innerHTML);
      dynamicMobileStylesheet.innerHTML = newCss;
    }
  }

  // Duplicate translations of element
  if (type === TemplateStructureEnum.SECTION) {
    duplicateTranslationsOfElement(clonedElement, translationsOfElement, clonedElement.getAttribute('data-spmelementid'));
  } else {
    duplicateTranslationsOfElement(clonedElement, translationsOfElement, parentSectionId);
  }

  switch (type) {
    case TemplateStructureEnum.LINE:
    case TemplateStructureEnum.COLUMN:
    case TemplateStructureEnum.SECTION:
      // Remove buttons of builder
      removeChildActionsButtonGroup(clonedElement);

      // Add listeners
      if (type === TemplateStructureEnum.SECTION) {
        // If the new element is a section, we save it first in database to get its real ID
        await addNewSectionToState(clonedElement, customCssTypes.length ? customCssTypes : ['dynamic'], replaceSectionId);
        setClonedSectionContentAttributes(clonedElement);
        parentSectionId = clonedElement.getAttribute('data-spmelementid') ?? '';
      }
      break;
    default:
      if (type === TemplateStructureEnum.WIDGET && syncElement) {
        addNewSyncElementToSection(parentSectionId, syncElement.syncElementId);
      }
      // Remove buttons of builder
      removeChildActionsButtonGroup(clonedElement);
      break;
  }

  // Mark section as active
  setActiveSection(parseInt(parentSectionId, 10));

  // Show duplicated element
  clonedElement.style.display = '';

  if ([TemplateStructureEnum.LINE, TemplateStructureEnum.COLUMN, TemplateStructureEnum.SECTION].includes(type)) {
    // Set width of columns for each line
    if (type === TemplateStructureEnum.COLUMN) {
      const parentRow: HTMLElement | null = clonedElement.closest(TEMPLATE_STRUCTURE_MAP[TemplateStructureEnum.LINE]);
      lines = parentRow ? [parentRow] : [];
    } else {
      lines = Array.from(clonedElement.querySelectorAll(TEMPLATE_STRUCTURE_MAP[TemplateStructureEnum.LINE]));

      if (type === TemplateStructureEnum.LINE) {
        lines.push(clonedElement);
      }
    }

    lines.forEach((line: HTMLElement) => {
      columnsList = Array.from(line.querySelectorAll(TEMPLATE_STRUCTURE_MAP[TemplateStructureEnum.COLUMN]));

      if (columnsList) {
        setChildColumnsWidth(columnsList);
      }
    });
  }

  // Update state
  updateSectionsInState();

  if (addHistory) {
    // Prevent creating history in edit mode
    const selectedSyncElement = store.getters['liveEditor/getSelectedSyncElement'];
    if (!selectedSyncElement) {
      if (type === TemplateStructureEnum.LINE) {
        createHistory(HistoryType.DUPLICATE_ROW);
      } else if (type === TemplateStructureEnum.COLUMN) {
        createHistory(HistoryType.DUPLICATE_COLUMN);
      } else if (type === TemplateStructureEnum.WIDGET) {
        let historyType;
        if (position) {
          historyType = syncElement ? HistoryType.ADD_WIDGET_SYNC : HistoryType.ADD_WIDGET;
        } else {
          historyType = syncElement ? HistoryType.DUPLICATE_WIDGET_SYNC : HistoryType.DUPLICATE_WIDGET;
        }
        createHistory(historyType);
      } else if (type === TemplateStructureEnum.SECTION) {
        let historyType;
        if (position) {
          historyType = syncElement ? HistoryType.ADD_SECTION_SYNC : HistoryType.ADD_SECTION;
        } else {
          historyType = syncElement ? HistoryType.DUPLICATE_SECTION_SYNC : HistoryType.DUPLICATE_SECTION;
        }
        createHistory(historyType);
      }
    }
  }

  resetActiveSection();
  resetIframe(clonedElement);
};

export const onDuplicateWidget = (activeItemData: ActiveLiveEditorItemData) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template.querySelector(activeItemData.selector);

  if (element) {
    duplicateElement(activeItemData, TemplateStructureEnum.WIDGET, element);
  }
};

export const onDuplicateColumn = (activeItemData: ActiveLiveEditorItemData) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template.querySelector(activeItemData.selector);

  if (element) {
    const columnList = element?.parentElement?.children;

    if (columnList && columnList.length >= MAX_COLUMN_COUNT) {
      showToastError('MAX_COLUMNS_COUNT');
      return;
    }

    duplicateElement(activeItemData, TemplateStructureEnum.COLUMN, element);
  }
};

export const onDuplicateLine = (activeItemData: ActiveLiveEditorItemData) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template.querySelector(activeItemData.selector);

  if (element) {
    duplicateElement(activeItemData, TemplateStructureEnum.LINE, element);
  }
};

export const onDuplicateSection = (activeItemData: ActiveLiveEditorItemData) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template.querySelector(activeItemData.selector);

  if (element) {
    duplicateElement(activeItemData, TemplateStructureEnum.SECTION, element);
  }
};

// common action handlers
export const onRemoveElement = (selector: string) => {
  const template = getTemplateIframeDocument();
  const elementToRemove: HTMLElement | null = template.querySelector(selector);
  hideLeftToolbar();
  if (elementToRemove) {
    elementToRemove.remove();
  }
};

export const removeColumn = (event: any, column: HTMLElement) => {
  event.preventDefault();
  event.stopPropagation();

  const columnId = column.id;
  if (columnId) {
    const acceptCallback = () => {
      checkIfSavePointNeeded().then(() => {
        const section = column.closest('[data-spmelementid]');

        if (section) {
          const sectionId = section.getAttribute('data-spmelementid');

          if (sectionId) {
            // Mark section as active
            setActiveSection(parseInt(sectionId, 10));
          }

          // Get parent row
          const rows = getParents(column, TEMPLATE_LINE_CLASS);

          // Remove column
          onRemoveElement(`#${column.getAttribute('id')}`);

          if (rows && rows.length) {
            const row = rows[0];

            // Remove row if there is no column inside
            if (Array.from(row.querySelectorAll(WIDGET_DROPPABLE_CLASS)).length === 0) {
              onRemoveElement(`#${row.getAttribute('id')}`);

              // Remove section if there is no line inside
              if (Array.from(section.querySelectorAll(TEMPLATE_LINE_CLASS)).length === 0) {
                onRemoveElement(`#${section.getAttribute('id')}`);
              }
            }
          }
        }

        // Update state
        updateSectionsInState(false);

        // Prevent creating history entry in edit mode
        const currentSyncElement = store.getters['liveEditor/getSelectedSyncElement'];
        if (!currentSyncElement) {
          createHistory(HistoryType.DELETE_COLUMN);
        }

        refreshSectionsConfig();
        refreshTranslations();
        resetListeners();
        resetActiveSection();
      });
    };

    // TODO : SYNC ELEMENT WARNING
    const selectedSyncElement = store.getters['liveEditor/getSelectedSyncElement'];
    const sectionParent = getSectionParent(column);
    if (sectionParent) {
      const elementSyncId = sectionParent.getAttribute(TEMPLATE_SYNC_ELEMENT_IDENTIFIER);
      const sectionId = sectionParent.getAttribute('id');
      if (elementSyncId && !selectedSyncElement) {
        store.dispatch('liveEditor/showSyncElementWarning', {
          elementId: sectionParent.getAttribute('id'),
          elementType: TemplateStructureEnum.SECTION,
          acceptCallback,
          rejectCallback: null,
        });
      } else if (sectionId && selectedSyncElement && sectionId === selectedSyncElement.elementId) {
        acceptCallback();
      } else if (!elementSyncId && !selectedSyncElement) {
        acceptCallback();
      }
    } else {
      acceptCallback();
    }
  }
};
