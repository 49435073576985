<template>
  <div class="configuration-wrapper">
    <div
      v-if="tabItems.length === 1"
    >
      <Panel>
        <template #header>
          <div class="panel-header-content">
            <div class="flex gap-2">
              <h4>{{ headerTitle }}</h4>
            </div>
          </div>
        </template>

        <template #icons>
          <i
            v-if="isClosable && !hasActions && !isSynchronizedPage"
            class="far fa-times icons-header-panel"
            @click="hideLeftToolbar"
          />
          <SpmOverlayPanel
            v-else-if="!isSynchronizedPage && structureActions.length"
            class-trigger="flex align-items-center justify-content-center"
            class-content="flex align-items-center justify-content-center spm-overlay-panel-content"
          >
            <template #trigger>
              <i class="far fa-ellipsis-v cursor-pointer" />
            </template>
            <SpmPanelMenu
              :items="structureActions"
            />
          </SpmOverlayPanel>
        </template>
        <ConfigurationPanelTabContent
          :key="refreshKey"
          :parser-values="parserValues"
          :tab-item="tabItems[0]"
          :display-label="false"
          :is-synchronized-page="isSynchronizedPage"
          :header-title="headerTitle"
          @refresh="handleRefresh"
        />
      </Panel>
    </div>
    <Panel
      v-else
    >
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.configs.widgetConfiguration') }}</h4>
        </div>
      </template>

      <template #icons>
        <i
          v-if="isClosable"
          class="far fa-times icons-header-panel"
          @click="hideLeftToolbar"
        />
      </template>

      <TabView
        :key="activeTabItem.label"
        :multiple="true"
        :active-index="activeTabItemIndex"
        @tab-change="({ index }) => activeTabItemIndex = index"
      >
        <TabPanel
          v-for="(tabItem, index) of tabItems"
          :key="index"
        >
          <template
            #header
            :title="t(tabItem.label)"
          >
            {{ t(tabItem.label) }}
          </template>
          <template v-if="tabItem.tabPanel">
            <ConfigurationPanelTabContent
              :parser-values="parserValues"
              :tab-item="tabItem"
            />
          </template>
        </TabPanel>
      </TabView>
    </Panel>
  </div>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  nextTick,
  PropType,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  ConfigurationPanelStructure,
  HistoryType,
  ParserObject,
  TabItem,
  TemplateStructureEnum,
} from '@/types';
import {
  addActiveItemElementDecorations,
  setActiveItemData,
} from '@/components/template-builder/utils/active-item-builder';

import {
  isDisplayTemplate,
  isEmailTemplate,
} from '@/components/template-builder/utils/helpers';
import {
  TEMPLATE_SYNC_ELEMENT_IDENTIFIER,
  TEMPLATE_SYNC_ELEMENT_NAME,
} from '@/components/template-builder/utils/constants';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Panel from 'primevue/panel';
import Tooltip from 'primevue/tooltip';
import {
  setChangeInLeftToolbar,
  TemplateEditorState,
  hideLeftToolbar,
  updateSectionsInState,
} from '@/composables/template-editor/TemplateEditor';
import { store } from '@/store';
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';

import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';

import ConfigurationPanelTabContent from './ConfigurationPanelTabContent.vue';

export default defineComponent({
  name: 'ConfigurationPanel',

  components: {
    ConfigurationPanelTabContent,
    TabView,
    TabPanel,
    Panel,
    SpmPanelMenu,
    SpmOverlayPanel,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    structureConfig: {
      type: Object as PropType<ConfigurationPanelStructure>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    isClosable: {
      type: Boolean,
      required: false,
      default: true,
    },

    structureActions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props) {
    const { t } = useI18n();
    const tabItems = computed(() => props.structureConfig.items);
    const activeTabItemIndex = ref(0);
    const activeTabItem: ComputedRef<TabItem> = computed(() => tabItems.value[activeTabItemIndex.value] || tabItems.value[0]);
    const leftToolbarHasChanged = computed(() => TemplateEditorState.leftToolbar.hasChanged);
    const currentActiveItemData = computed(() => store.getters['liveEditor/getActiveItemData']);
    const selectedStructure = computed(() => store.getters['liveEditor/getSelectedStructure']);
    const selectedSyncElement = computed(() => store.getters['liveEditor/getSelectedSyncElement']);
    const hasActions = computed(() => props.structureActions
      && props.structureActions.length > 0
      && (isEmailTemplate(TemplateEditorState.template?.type) || isDisplayTemplate(TemplateEditorState.template?.type)
      ));
    const isSynchronizedPage = computed(() => {
      if (selectedStructure.value && selectedStructure.value.type === TemplateStructureEnum.PAGE) {
        let designSection = TemplateEditorState?.template?.sections.find((item) => item.type === TemplateStructureEnum.DESIGN);
        if (isDisplayTemplate(TemplateEditorState?.template?.type)) {
          designSection = TemplateEditorState?.template?.sections[0];
        }
        if (designSection) {
          const data = JSON.parse(designSection.data || '{}');
          if (data[TEMPLATE_SYNC_ELEMENT_IDENTIFIER]) {
            return true;
          }
        }
      }
      return false;
    });
    const headerTitle = computed(() => {
      if (isSynchronizedPage.value) {
        let designSection = TemplateEditorState?.template?.sections.find((item) => item.type === TemplateStructureEnum.DESIGN);
        if (isDisplayTemplate(TemplateEditorState?.template?.type)) {
          designSection = TemplateEditorState?.template?.sections[0];
        }
        if (designSection) {
          const data = JSON.parse(designSection.data || '{}');
          if (data[TEMPLATE_SYNC_ELEMENT_NAME]) {
            return data[TEMPLATE_SYNC_ELEMENT_NAME];
          }
        }
      }
      return t(tabItems.value[0].tabPanel?.label ?? '');
    });

    checkIfSavePointNeeded();
    const changeLiveEditorActiveItem = async () => {
      // Create history point if changes made in left panel before switching to other tab
      if (leftToolbarHasChanged.value) {
        if (currentActiveItemData.value) {
          let historyType: HistoryType | null;
          switch (currentActiveItemData.value.type) {
            case TemplateStructureEnum.WIDGET:
              historyType = HistoryType.WIDGET_CHANGE;
              break;
            case TemplateStructureEnum.COLUMN:
              historyType = HistoryType.COLUMN_CHANGE;
              break;
            case TemplateStructureEnum.LINE:
              historyType = HistoryType.ROW_CHANGE;
              break;
            case TemplateStructureEnum.SECTION:
              historyType = HistoryType.SECTION_CHANGE;
              break;
            default:
              historyType = null;
              break;
          }

          if (historyType) {
            // Update state
            updateSectionsInState(false);

            // Prevent creating history in edit mode
            if (!selectedSyncElement.value) {
              createHistory(historyType);
            }
          }
        }

        setChangeInLeftToolbar(false);
      }

      const activeItemData = {
        selector: props.parserValues[activeTabItem.value.label].selector || '',
        type: activeTabItem.value.tabItemType,
      };

      setActiveItemData(activeItemData);

      if (
        activeItemData.type === TemplateStructureEnum.SECTION
        || activeItemData.type === TemplateStructureEnum.WIDGET
        || activeItemData.type === TemplateStructureEnum.COLUMN
      ) {
        addActiveItemElementDecorations(activeItemData);
      }
    };

    const refreshKey = computed(() => store.getters['liveEditor/getRefreshConfigurationPanelKey']);
    const handleRefresh = async () => {
      await nextTick();
      store.commit('liveEditor/refreshConfigurationPanelKey');
    };

    watch([
      () => props.parserValues,
      () => props.structureConfig,
    ],
    () => {
      if (activeTabItemIndex.value !== 0) {
        activeTabItemIndex.value = 0;
      }
      changeLiveEditorActiveItem();
    });

    watch(activeTabItemIndex, () => {
      changeLiveEditorActiveItem();
    }, { immediate: true });

    return {
      t,
      tabItems,
      headerTitle,
      isSynchronizedPage,
      activeTabItemIndex,
      activeTabItem,
      hasActions,
      refreshKey,
      hideLeftToolbar,
      handleRefresh,
    };
  },
});
</script>

<style lang="scss" scoped>
.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}

.field-group-content {
  .field-wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.tab-items-wrapper {
  background-color: $shuttle-light-grey;
  margin-bottom: 0.625rem;
  width: 100%;
}

.tab-item {
  list-style: none;
  border-right: 1px solid $whisper;

  &.tab-item--active {
    border-right: 0 none;

    .tab-item__button {
      background-color: $white;
    }
  }

  .tab-item__button {
    border: 0 none;
    color: $montana;
    background-color: $shuttle-light-grey;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    padding: .78125rem .75rem;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
    width: 100%;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>
